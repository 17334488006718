import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetailsTitle: {
        id: "wmic-pe-capability-gateway-quoteandbind-pa-react.pages.WMICPolicyDetails.WMICPolicyDetailsPage.Title",
        defaultMessage : "Policy Details"
    },
    defaultAnswers: {
        id: "quoteandbind.pa.views.pa-policy-details.Create Default Answer",
        defaultMessage : "Creating Default Answer"
    }
})