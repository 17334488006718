import { defineMessages } from 'react-intl';

export default defineMessages({
    saveAndExit: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.PolicyChangeCommon.Save and exit',
        defaultMessage: 'Save and exit'
    },
    policyDetailsTitle: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.PolicyChangeCommon.Policy Details',
        defaultMessage: "Policy Details"
    },
    wantToJump: {
        id: 'endorsement.wizard.ho-policy-change.Leave this page',
        defaultMessage: 'Leave this page?'
    },
    wantToJumpMessage: {
        id: 'endorsement.wizard.ho-policy-change.Changes not saved will be lost',
        defaultMessage: 'Changes not saved will be lost, do you want to continue?'
    },
    savingTransactionDetails: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.PolicyChangeCommon.Saving Transaction Details',
        defaultMessage: 'Saving Transaction Details'
    },
    quoting: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.PolicyChangeCommon.Quoting',
        defaultMessage: 'Quoting...'
    },
	unableToStartPolicy: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.PolicyChangeCommon.Unable to start policy change',
        defaultMessage: 'Something went wrong during this process. Please try again.'
    },
	anErrorOccurred: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.PolicyChangeCommon.An error occurred while attempting to start the policy change.',
        defaultMessage: 'Something went wrong during this process. Please try again.'
    },
	unableToDraftPolicy: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.PolicyChangeCommon.Unable to save draft policy change',
        defaultMessage: 'Unable to save draft policy change'
    },
    infoWillBeSavedAsDraft: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.PolicyChangeCommon.Any unsaved information previously entered may be lost',
        defaultMessage: 'Any unsaved information previously entered may be lost'
    },
    leaveWithoutSaving: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.PolicyChangeCommon.Leave without saving?',
        defaultMessage: 'Leave without saving?'
    },
    weAreUnableToProcess: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.PolicyChangeCommon.We are unable to process this policy change online',
        defaultMessage: 'We are unable to process this policy change online.',
    },
    pleaseContactUnderwriter: {
        id: 'wmic-pe-capability-gateway-policychange-common-react.PolicyChangeCommon.Please contact underwriting for assistance with submitting your policy change',
        defaultMessage: 'Please contact underwriting for assistance with submitting your policy change.',
    },
});
