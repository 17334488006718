import React, { useCallback, useState, useEffect, useContext, useMemo } from 'react';
import _ from "lodash";
import cx from 'classnames';
import { WMICIntlFormattedDateCell, WMICIcon } from 'wmic-pe-components-platform-react';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardViewPage, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { BillingService } from 'wmic-pe-capability-gateway-billing';
import { WMICLogger, SUITES, ERROR_STATUS_CODES, WMICUserAccessUtil, TRANSACTION_STATUS } from 'wmic-pe-portals-utils-js';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { PolicyService } from 'wmic-pe-capability-gateway-policy';
import { GatewayDocumentService } from 'wmic-pe-capability-gateway-document';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { LocalDateUtil } from '@xengage/gw-portals-util-js'
import CONSTANTS from 'wmic-pe-portals-utils-js/StringConstants';
import WMICChangeMonthlyAutomatedForm from './WMICChangeMonthlyAutomatedForm/WMICChangeMonthlyAutomatedForm';
import WMICLastPaymentForm from './WMICLastPaymentForm/WMICLastPaymentForm';
import WMICMakePaymentModal from './WMICMakePaymentModal/WMICMakePaymentModal';
import metadata from './WMICPolicyBilling.metadata.json5'
import messages from './WMICPolicyBilling.messages';
import messagesCMA from './WMICChangeMonthlyAutomatedForm/WMICChangeMonthlyAutomatedForm.messages';
import styles from './WMICPolicyBilling.module.scss';

const NEW_PAYMENT_INSTRUMENT_ID = '-1';
const NEW_PERSON_CONTACT_ID = '-1';
const NEW_COMPANY_CONTACT_ID = '-2';
const AGENCYBILL_CODE = 'AgencyBill';

function WMICPolicyBilling(props) {
    const {
        wizardData: {
            policyVM,
            isEditButtonEnabled,
            policyBillingSummaryVM
        },
        wizardData,
        updateWizardData
    } = props;

    const translator = useContext(TranslatorContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { setWizardLoading, showError, showCustom } = useWizardModals();
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { isComponentValid, onValidate, disregardFieldValidation } = useValidation(
        'WMICPolicyBilling'
    );
    const paymentMethod = _.get(policyVM, 'policyPeriodBillingSummary_WMIC.billingMethod.value.code');
    const [showNoBillingDataAvailable, updateShowNoBillingDataAvailable] = useState(paymentMethod === undefined);
    const isBrokerBillPolicy = paymentMethod === AGENCYBILL_CODE;
    const termNumber = _.get(policyVM, "latestPeriod.termNumber_WMIC.value");
    const policyNumber = _.get(policyVM, "policyNumber.value");
    const [editingPaymentPlan, updateEditingPaymentPlan] = useState(false);
    const [availablePaymentPlansWithInvoices, updateAvailablePaymentPlansWithInvoices] = useState();
    const [selectedPaymentPlan, updateSelectedPaymentPlan] = useState();
    const [selectedPayer, updateSelectedPayer] = useState();
    const [eftVM, updateEftVM] = useState();
    const [newContactVM, updateNewContactVM] = useState();
    const [selectedContactPublicId, updateSelectedContactPublicId] = useState();
    const [bankDetails, updateBankDetails] = useState();
    const [bankDetailsList, updateBankDetailsList] = useState();
    const [editingBankingDetails, updateEditingBankingDetails] = useState();
    const [editOnlyEftData, updateEditOnlyEftData] = useState();
    const [selectedContact, updateSelectedContact] = useState();
    const [lastPaymentVM, updateLastPaymentVM] = useState();
    const initialPaymentPlan = _.get(policyBillingSummaryVM, "paymentPlan.value");
    const isInitialMAC = _.get(policyBillingSummaryVM, "isPaymentPlanMAR_Ext.value");
    const [billingDocument, updateBillingDocument] = useState();
    const [isDropdownLoading, setIsDropdownLoading] = useState(false);
    const [documentsLoaded, updateDocumentsLoaded] = useState(false);

    const canEditPaymentPlan = useMemo(() => WMICUserAccessUtil.canEditPaymentPlan(authUserData.permission_Ext), [authUserData.permission_Ext]);

    const getBankDetailsRequest = useCallback(() => {
        const bankDetailsChangeRequestPromise = BillingService.getBankDetailsChangeRequest(policyNumber, termNumber, authHeader);
        bankDetailsChangeRequestPromise.then((response) => {
            updateBankDetails(response);
            const bankDetailsVM = viewModelService.create(
                response,
                'bc',
                'wmic.edge.ca.capabilities.billing.dto.BankDetailsChangeRequestDTO'
            );
            updateBankDetailsList([bankDetailsVM]);
        }).catch((err) => {
            WMICLogger.error('WMICPolicyBilling getBankDetailsChangeRequest', err);
        });
    }, [authHeader, policyNumber, termNumber, viewModelService]);

    const getBillingDetails = useCallback(() => {
        const policyBillingSummaryInfoPromise = BillingService.getPolicyBillingSummaryInfo(policyNumber, termNumber, authHeader);
        policyBillingSummaryInfoPromise.then((response) => {
            if (!_.isEmpty(response)) {
                const policyBillingSummary = viewModelService.create(
                    response,
                    'bc',
                    'wmic.edge.ca.capabilities.billing.dto.PolicyBillingSummaryDTO'
                );
                updateWizardData({ ...wizardData, policyBillingSummaryVM: policyBillingSummary });
                if (!_.isNil(_.get(response, "lastPayment"))) {
                    updateLastPaymentVM(_.get(policyBillingSummary, "lastPayment"));
                }
                if (_.get(response, "isPaymentPlanMAR_Ext")) {
                    getBankDetailsRequest();
                }
            }
        }).catch((err) => {
            updateShowNoBillingDataAvailable(true);
            WMICLogger.error('WMICPolicyBilling getPolicyBillingSummaryInfo', err);
        });
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authHeader, getBankDetailsRequest, policyNumber, termNumber, viewModelService, wizardData]);

    const getPaymentPlanChangesWithInvoices = useCallback(() => {
        setIsDropdownLoading(true);
        const billingPaymentPlansPromise = BillingService.getPaymentPlanChangesWithInvoices(policyNumber, termNumber, authHeader);
        billingPaymentPlansPromise.then((response) => {
            updateAvailablePaymentPlansWithInvoices(response);
        }).catch((err) => {
            WMICLogger.error('WMICPolicyBilling getPaymentPlanChangesWithInvoices', err);
            if (WMICErrorHandler.getErrorCode(err) === ERROR_STATUS_CODES.WMIC_NEGATIVE_INVOICE_STREAM) {
                WMICErrorHandler.processAsModal(err);
            }
        }).finally(() => setIsDropdownLoading(false));
    }, [authHeader, policyNumber, termNumber]);
    
    const getDocumentDownloadLink = (document, suite) => {
        const { publicID, sessionID } = document;
        const link = GatewayDocumentService.downloadDocument(publicID, sessionID, suite);
        return link;
    }

    useEffect(() => {
        if (!isBrokerBillPolicy) {
            if (initialPaymentPlan === CONSTANTS.FULL_PAY_CANADA) {
                updateDocumentsLoaded(true);
            }
            const accountNumber = _.get(policyVM, 'account.accountNumber.value');
            const billingDocumentPromise = BillingService.getDocumentForEDelivery(accountNumber, policyNumber, authHeader);
            billingDocumentPromise.then((document) => {
                if(!_.isEmpty(document)){
                    document.link = getDocumentDownloadLink(document, SUITES.BC);
                    updateBillingDocument(document);
                    updateDocumentsLoaded(true);
                }
            }).catch((err) => {
                WMICLogger.error('WMICPolicyBilling getDocumentForEDelivery', err);
            });

            if ([TRANSACTION_STATUS.BOUND, TRANSACTION_STATUS.BOUND_FR].includes(_.get(policyVM, 'latestPeriod.status.value'))) {
                getPaymentPlanChangesWithInvoices();
                getBillingDetails();
            } else {
                updateShowNoBillingDataAvailable(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDateCell = (item, index, property) => {
        const date =  LocalDateUtil.toMidnightDate(item[property.id]);
        return <WMICIntlFormattedDateCell date={date} />;
    };

    const getStatusCell = (item, index, property) => {
        switch (item[property.id]) {
            case CONSTANTS.INVOICE_PAID_STATUS.UNPAID:
                return (
                    <div className={cx(styles.unpaidContainer, styles.statusContainer)}>
                        <WMICIcon
                            id = "expandIcon"
                            icon = "mi-date-range"
                            size = "small"
                            className={cx(styles.unpaidColor, styles.statusIcon)} />

                        <span className={cx(styles.unpaidColor, styles.statusText)}> {translator(messages.unpaid)}</span>
                    </div>
                );
            case CONSTANTS.INVOICE_PAID_STATUS.FULLY_PAID:
                return (
                    <div className={cx(styles.fullyPaidContainer, styles.statusContainer)}>
                        <WMICIcon
                            id = "expandIcon"
                            icon = "mi-done"
                            size = "large"
                            className={cx(styles.fullyPaidColor, styles.statusIcon)} />

                        <span className={cx(styles.fullyPaidColor, styles.statusText)}> {translator(messages.fullyPaid)}</span>
                    </div>
                );
            case CONSTANTS.INVOICE_PAID_STATUS.PARTIALLY_PAID:
                return (
                    <div className={cx(styles.partiallyPaidContainer, styles.statusContainer)}>
                        <WMICIcon
                            id = "expandIcon"
                            icon = "mi-error"
                            size = "large"
                            className={cx(styles.partiallyPaidColor, styles.statusIcon)} />

                        <span className={cx(styles.partiallyPaidColor, styles.statusText)}> {translator(messages.partiallyPaid)}</span>
                    </div>
                );
            default:
                break;
        }
        return "";
    };


    const getCurrencyCell = (item, index, property) => (
            <CurrencyField
                id={`currencyCell_${index}`}
                value={item[property.id]}
                datatype="object"
                readOnly
                hideLabel
            />
        );

    const availablePaymentPlansValue = () => {
        const paymentPlansList = _.get(availablePaymentPlansWithInvoices, "paymentPlanChanges", []);
        return paymentPlansList.map((paymentPlan, index) => ({
                name: paymentPlan.paymentPlanDisplayName,
                isPaymentPlanMAR: paymentPlan.isPaymentPlanMAR,
                code: index
            }));
    };

    const onPaymentPlanValueChange = (value) => {
        updateSelectedPaymentPlan(value);
        if (!_.get(availablePaymentPlansWithInvoices, `paymentPlanChanges.${value}.isPaymentPlanMAR`, false)) {
            disregardFieldValidation('changeMonthlyAutomatedForm');
        }
    }

    const onEditPaymentPlan = () => {
        updateEditingPaymentPlan(true);
        getPaymentPlanChangesWithInvoices();
    }

    const hidePaymentPlanChange = () => {
        updateSelectedPaymentPlan(undefined);
        updateSelectedPayer(undefined);
        updateEftVM(undefined);
        updateNewContactVM(undefined);
        updateSelectedContactPublicId(undefined);
        updateEditingPaymentPlan(false);
        updateEditOnlyEftData(undefined);
        updateEditingBankingDetails(undefined);
        if(isInitialMAC){
            disregardFieldValidation('changeMonthlyAutomatedForm');
        }
    }

    const isSelectedPaymentPlanMAC = () => {
        const isPaymentPlanMAR = _.get(availablePaymentPlansWithInvoices, `paymentPlanChanges.${selectedPaymentPlan}.isPaymentPlanMAR`, false);
        return !_.isNil(selectedPaymentPlan) ? isPaymentPlanMAR : isInitialMAC
    }

    const updatePolicyData = async () => {
        try {
            policyVM.value = await PolicyService.getPolicyWithTermNumber(
                policyNumber,
                termNumber,
                authHeader
            );
            updateWizardData({ ...wizardData, policyVM, isEditButtonEnabled });
        } catch (e) {
            WMICLogger.error('WMICPolicyBilling getPolicyWithTermNumber', e);
        }
    }

    const submitPaymentPlanChange = () => {
        const newPaymentPlanName = _.get(availablePaymentPlansWithInvoices, `paymentPlanChanges.${selectedPaymentPlan}.paymentPlanName`, '');
        let changePaymentPlanPromise;
        let promiseMethodName;
        if (!isSelectedPaymentPlanMAC()) {
            changePaymentPlanPromise = BillingService.changePaymentPlanTo(policyNumber, termNumber, newPaymentPlanName, authHeader);
            promiseMethodName = 'changePaymentPlanTo';
        } else if (!_.isNil(selectedPayer) && selectedPayer !== NEW_PAYMENT_INSTRUMENT_ID) {
            changePaymentPlanPromise = BillingService.changePaymentInstrumentTo(policyNumber, termNumber, selectedPayer, authHeader);
            promiseMethodName = 'changePaymentInstrumentTo';
        } else if (!_.isNil(eftVM) && !_.isNil(newContactVM)) {
            changePaymentPlanPromise = BillingService.applyNewPaymentInstrumentForNewContact(policyNumber, termNumber, newContactVM.value, eftVM.value, authHeader);
            promiseMethodName = 'applyNewPaymentInstrumentForNewContact';
        } else if (!_.isNil(eftVM) && _.isNil(newContactVM) && !_.isNil(selectedContactPublicId)) {
            changePaymentPlanPromise = BillingService.applyNewPaymentInstrumentForExistingContact(policyNumber, termNumber, selectedContactPublicId, eftVM.value, authHeader);
            promiseMethodName = 'applyNewPaymentInstrumentForExistingContact';
        }
        if (changePaymentPlanPromise) {
            setWizardLoading(true);
            changePaymentPlanPromise.then(async (response) => {
                if (response) {
                    await updatePolicyData();
                    hidePaymentPlanChange();
                    getBankDetailsRequest();
                    getBillingDetails();
                }
            }).catch((err) => {
                if (WMICErrorHandler.getErrorCode(err) === ERROR_STATUS_CODES.DUPLICATE_BANK_RECORD) {
                    showError({
                        title: translator(commonMessages.genericError),
                        message: translator(messages.bankRecordAlreadyExists),
                    });
                } else if (typeof err === 'object') {
                    WMICErrorHandler.processAsModal(err);
                } else {
                    showError({
                        title: translator(commonMessages.genericError),
                        message: translator(commonMessages.genericErrorMessage),
                    });
                }
                WMICLogger.error(`WMICPolicyBilling ${promiseMethodName}`, err);
            }).finally(() => setWizardLoading(false));
        }
    }

    const onSubmitEditBankingDetails = () => {
        if (!_.isNil(editOnlyEftData)) {
            const changePaymentPlanPromise = BillingService.submitBankDetailsChangeRequest(policyNumber, termNumber, eftVM.value, authHeader);
            changePaymentPlanPromise.then((response) => {
                getBankDetailsRequest();
                updateEftVM(undefined);
                updateEditingBankingDetails(undefined);
                updateEditOnlyEftData(undefined);
            }).catch((err) => {
                WMICLogger.error('WMICPolicyBilling changePaymentPlanTo', err);
            });
        } else {
            submitPaymentPlanChange();
        }
    }

    const onCancelEditBankingDetails = () => {
        updateEftVM(undefined);
        updateEditingBankingDetails(undefined);
        updateEditOnlyEftData(undefined);
    }

    const onEditBankingDetails = () => {
        if (bankDetails) {
            const eftData = viewModelService.create(
                bankDetails,
                'bc',
                'wmic.edge.ca.capabilities.billing.dto.PaymentPlanChangeEFTDataDTO'
            );
            updateEftVM(eftData);
            updateEditOnlyEftData(true);
            updateEditingBankingDetails(true);
            updateSelectedPayer(bankDetails.paymentInstrumentPublicID);
        }
    }

    const createPersonContact = () => {
        const newPersonValue = {
            subtype: 'Person',
            firstName: '',
            middleName: '',
            lastName: '',
            primaryAddress: {
                country: 'CA',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: 'AB',
                postalCode: '',
                addressType: 'mailing_wmic'
            },
        }
        const newPersonVM = viewModelService.create(
            newPersonValue,
            'bc',
            'wmic.edge.ca.capabilities.billing.dto.PaymentPlanChangeUpdateContactDTO'
        );
        updateNewContactVM(newPersonVM);
    };

    const createCompanyContact = () => {
        const newCompanyValue = {
            subtype: 'Company',
            companyName: '',
            primaryAddress: {
                country: 'CA',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: 'AB',
                postalCode: '',
                addressType: 'mailing_wmic'
            }
        }
        const newCompanyVM = viewModelService.create(
            newCompanyValue,
            'bc',
            'wmic.edge.ca.capabilities.billing.dto.PaymentPlanChangeUpdateContactDTO'
        );
        updateNewContactVM(newCompanyVM);
    };

    const addNewPayerFromDropdown = (value) => {
        updateSelectedPayer(NEW_PAYMENT_INSTRUMENT_ID);
        updateSelectedContact(value);
        const eftData = viewModelService.create(
            {
                bankName: '',
                bankAccountNumber: '',
                transitNumber: '',
                accountHolder: '',
                institutionNumber: '',
                withdrawalDayOfMonth: 1
            },
            'bc',
            'wmic.edge.ca.capabilities.billing.dto.PaymentPlanChangeEFTDataDTO'
        );
        if(value !== NEW_PERSON_CONTACT_ID && value !== NEW_COMPANY_CONTACT_ID) {
            const eligibleContacts = _.get(availablePaymentPlansWithInvoices, "eligibleContacts", []);
            const selectedContactObj = _.find(eligibleContacts, (contact) => { return contact.publicID === value });
            const selectedContactName = _.get(selectedContactObj, 'displayName', '');
            _.set(eftData, "accountHolder.value", selectedContactName);
            updateSelectedContactPublicId(value);
        } else {
            _.set(eftVM, "accountHolder.value", "");
            if (value === NEW_PERSON_CONTACT_ID) {
                createPersonContact()
            } else {
                createCompanyContact();
            }
            updateSelectedContactPublicId(undefined);
        }
        const paymentPlanList = availablePaymentPlansValue();
        const mar = _.find(paymentPlanList, (pp) => pp.isPaymentPlanMAR);
        if (mar) {
            updateSelectedPaymentPlan(mar.code);
        }
        updateEftVM(eftData);
        updateEditingBankingDetails(false);
    }

    const getAddNewPayerDropdownAvailableValues = () => {
        const eligibleContacts = _.get(availablePaymentPlansWithInvoices, "eligibleContacts", []);
        const contactsList = [];
        for(let i = 0; i < eligibleContacts.length; i++) {
            contactsList.push({
                id: eligibleContacts[i].publicID,
                code: eligibleContacts[i].publicID,
                text: eligibleContacts[i].displayName
            });
        }
        contactsList.push({
            text: translator(messagesCMA.newPerson),
            code: NEW_PERSON_CONTACT_ID,
            id: NEW_PERSON_CONTACT_ID
        });
        contactsList.push({
            text: translator(messagesCMA.newCompany),
            code: NEW_COMPANY_CONTACT_ID,
            id: NEW_COMPANY_CONTACT_ID
        });
        return contactsList;
    }

    const getAmountDue = () => {
        const amountDue = _.get(policyBillingSummaryVM, 'nextDueAmount.value');
        const remainingBalance = _.get(policyBillingSummaryVM, 'remainingBalance.value');
        if (_.get(amountDue, "amount", 0) !== 0) {
            return amountDue;
        } else if (_.get(remainingBalance, "amount", 0) !== 0) {
            return remainingBalance;
        }
        return {};
    };

    const onMakePayment = () => {
        const amountDueVM = viewModelService.create(
            getAmountDue(),
            'pc',
            'wmic.edge.common.capabilities.currency.dto.AmountDTO'
        );
        showCustom(
            <WMICMakePaymentModal
                amountDueVM={amountDueVM}
            />
        ).then((amount) => {
            setWizardLoading(true);
            const email = _.get(policyVM, 'account.accountHolder.confirmEmailAddress.value');
            LoadSaveService.getPolicyVirtualTerminalURL(
                policyNumber,
                termNumber,
                amount,
                email,
                authHeader
            ).then((paymentControllerURL) => {
                window.location.href = paymentControllerURL;
            }).catch((err) => {
                showError({
                    title: translator(commonMessages.genericError),
                    message: translator(commonMessages.genericErrorMessage),
                })
                WMICLogger.error('WMICPolicyBilling getPaymentPlanChangesWithInvoices', err);
            }).finally(() => setWizardLoading(false));
        }).catch(() => {
            _.noop();
        });
    }

    const billingDocumentName = translator(
        {
            id: `typekey.DocumentType.${_.get(billingDocument, 'documentType', '')}`,
            defaultMessage: _.get(billingDocument, 'name', '')
        }
    );

    const overrideProps = {
        '@field': {
            parentNode: policyBillingSummaryVM,
            showRequired: true,
        },
        brokerBillMessage: {
            visible: isBrokerBillPolicy
        },
        noBillingDataMessage: {
            visible: showNoBillingDataAvailable
        },
        billingDashboardGrid: {
            visible: !isBrokerBillPolicy && !showNoBillingDataAvailable
        },
        editPaymentPlanButton: {
            disabled: editingPaymentPlan || (availablePaymentPlansValue().length === 0) || (_.get(policyBillingSummaryVM, "restrictedToFullPay.value", false) && _.get(policyBillingSummaryVM, "paymentPlan.value") === CONSTANTS.FULL_PAY_CANADA),
            'aria-label': translator({ id: "policy.common.views.account-policy-details.billing-details.Edit", defaultMessage: "Edit Plan" }),
            visible: canEditPaymentPlan
        },
        editPaymentPlanContainer: {
            visible: editingPaymentPlan
        },
        newPaymentPlan:{
            availableValues: availablePaymentPlansValue(),
            value: selectedPaymentPlan
        },
        newPaymentPlanInvoiceTable: {
            visible: !_.isNil(selectedPaymentPlan) && !isSelectedPaymentPlanMAC(),
            data: _.get(availablePaymentPlansWithInvoices, `paymentPlanChanges.${selectedPaymentPlan}.invoices`, [])
        },
        monthlyAutomaticChequingForm: {
            visible: !_.isNil(selectedPaymentPlan) && isSelectedPaymentPlanMAC()
        },
        changeMonthlyAutomatedForm: {
            availablePaymentPlansWithInvoices,
            createPersonContact,
            createCompanyContact,
            viewModelService,
            onValidate,
            eftVM,
            updateEftVM,
            newContactVM,
            updateNewContactVM,
            updateSelectedContactPublicId,
            selectedPayer,
            updateSelectedPayer,
            selectedContact,
            updateSelectedContact
        },
        editPaymentPlanButtons: {
            disableSave: !isComponentValid,
            onCancel: hidePaymentPlanChange,
            onSave: submitPaymentPlanChange,
            saveLabel: translator(messages.submit)
        },
        paymentPlanInfo: {
            visible: !editingPaymentPlan
        },
        paymentScheduleTable:{
            data: _.get(policyBillingSummaryVM, "value.invoices", [])
        },
        bankingDetailsCard: {
            visible: isInitialMAC
        },
        bankingDetailsDataList: {
            VMList: bankDetailsList,
            VMData: [
                {
                    headerText: translator(messages.bankingInformation),
                    path: 'accountHolder',
                },
                {
                    headerText: translator(messages.payer),
                    path: 'maskedBankAccountNumber',
                },
            ],
            onEditAction: !editingBankingDetails ? onEditBankingDetails : undefined,
            flatCards: true,
            clickable: false,
            updateSelectedCardIndex: () => {},
            canDelete: () => false,
            readOnly: !canEditPaymentPlan
        },
        editBankingDetailsContainer: {
            visible: !_.isNil(editingBankingDetails),
        },
        editBankingDetails: {
            availablePaymentPlansWithInvoices,
            createPersonContact,
            createCompanyContact,
            viewModelService,
            onValidate,
            eftVM,
            updateEftVM,
            newContactVM,
            updateNewContactVM,
            updateSelectedContactPublicId,
            selectedPayer,
            updateSelectedPayer,
            selectedContact,
            updateSelectedContact,
            showOnlyEftData: editOnlyEftData,
        },
        editBankingDetailsButtons: {
            disableSave: !isComponentValid,
            onCancel: onCancelEditBankingDetails,
            onSave: onSubmitEditBankingDetails,
            saveLabel: translator(messages.submit)
        },
        addNewPayerDropdown: {
            visible: canEditPaymentPlan && _.isNil(editingBankingDetails) && !isDropdownLoading,
            onItemClick: addNewPayerFromDropdown,
            items: getAddNewPayerDropdownAvailableValues(),
        },
        newPayerButtonLoader: {
            visible: isDropdownLoading
        },
        lastPaymentCard: {
            visible: !_.isNil(lastPaymentVM)
        },
        lastPayment: {
            lastPaymentVM
        },
        makeLastPaymentButton: {
            disabled: editingPaymentPlan,
            visible: canEditPaymentPlan
        },
        generatingDocumentsDiv: {
            visible: !documentsLoaded,
        },
        billingDocumentLink: {
            visible: !_.isEmpty(billingDocument),
            content: billingDocumentName,
            href: _.get(billingDocument, 'link', undefined)
        },
        makePaymentButton: {
            visible: canEditPaymentPlan && _.isNil(lastPaymentVM),
            disabled: editingPaymentPlan
        }
    }

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getDateCell,
            getStatusCell,
            getCurrencyCell,
            onEditPaymentPlan,
            onPaymentPlanValueChange,
            onMakePayment
        },
        resolveComponentMap: {
            WMICChangeMonthlyAutomatedForm,
            WMICLastPaymentForm,
        }
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                overrideProps={overrideProps}
                model={policyBillingSummaryVM}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyBilling.propTypes = wizardProps;
export default WMICPolicyBilling;
