import { defineMessages } from 'react-intl';

export default defineMessages({
    constructionTitle: {
        id: "wmic-pe-capability-gateway-policychange-ho-react.pages.Construction.WMICHOPolicyChangeConstructionPage.Title",
        defaultMessage: "Construction"
    },
    riskNumber: {
        id: "wmic-pe-capability-gateway-policychange-ho-react.WMICHOPolicyChangeConstructionPage.Risk Number",
        defaultMessage: "Risk #"
    },
    riskPrimary: {
        id: "wmic-pe-capability-gateway-policychange-ho-react.WMICHOPolicyChangeConstructionPage.Primary",
        defaultMessage: "Primary"
    },
    riskDescription: {
        id: "wmic-pe-capability-gateway-policychange-ho-react.WMICHOPolicyChangeConstructionPage.Description",
        defaultMessage: "Description"
    },
    riskType: {
        id: "wmic-pe-capability-gateway-policychange-ho-react.WMICHOPolicyChangeConstructionPage.Risk Type",
        defaultMessage: "Risk Type"
    },
    noRisks: {
        id: "wmic-pe-capability-gateway-policychange-ho-react.WMICHOPolicyChangeConstructionPage.No Risks",
        defaultMessage: "A risk must be added prior to selecting Construction Details."
    },
    savingConstruction: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICHOPolicyChangeConstructionPage.Saving Construction',
        defaultMessage: 'Saving Construction'
    },
    validationKitchenError: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICHOConstructionDetailView.KitchensAndBaths.Validation.Modal.Error.Header',
        defaultMessage: 'Please address the following errors for kitchens / bathrooms :',
    }
});
