import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import { CONSTANTS, MVR_INCIDENT_CODE, WMICDateTimeService } from 'wmic-pe-portals-utils-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICPolicyMVRDetailView.metadata.json5';
import messages from './WMICPolicyMVRDetailView.messages.js'

function WMICPolicyMVRDetailView(props){
    const { value : selectedMVR } = props;

    const translator = useContext(TranslatorContext);
    const ViewModelService = useContext(ViewModelServiceContext);

    const DRIVER_SUSPEND_REASON_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('DriverSuspendReason_wmic');
    const PA_RESTRICTION_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('PARestriction_WMIC');

    const isSuspensionIncident = (incident) => {
        return incident && incident.incidentType ? incident.incidentType === MVR_INCIDENT_CODE.SUSPENSION : false;
    };

    const isRestrictionIncident = (incident) => {
        return incident && incident.incidentType ? incident.incidentType === MVR_INCIDENT_CODE.RESTRICTION : false;
    };

    const displayIncidentViolationDate = (data) => {
        return data?.value?.violationDate ? <span>{WMICDateTimeService.toMidnightDate(data.value.violationDate)}</span> : '';
    };

    const displayIncidentConvictionDate = (data) => {
        return data?.value?.convictionDate ? <span>{WMICDateTimeService.toMidnightDate(data.value.convictionDate)}</span> : '';
    };

    const getOverrideRatingDisplayName = data => data?.value?.overrideRating ? commonMessages.yesModel : commonMessages.noModel;

    const isOverrideReasonVisible = data => !!data?.value?.overrideReasonDisplayName;

    const displayIncidentDescription = (data) => {
        const dataValue = data.value;
        const isReadOnly = _.get(selectedMVR, 'value.isReadOnly')
        let description = '';

        if(isReadOnly) {
            description = dataValue.readOnlyIncidentDescription
        } else if (isSuspensionIncident(dataValue)) {
            if(dataValue.driverSuspendReason_wmic) {
                if (dataValue.driverSuspendReason_wmic === CONSTANTS.OTHER) {
                    description = translator(messages.mvrIncidentDescriptionOther, { desc: (dataValue.otherTypeDescribeOther || '')})
                } else {
                    description = translator({id: DRIVER_SUSPEND_REASON_TYPELIST.getCode(dataValue.driverSuspendReason_wmic).name});
                }
            }
        } else if (isRestrictionIncident(dataValue)) {
            if (dataValue.restrictionType) {
                if (dataValue.restrictionType === CONSTANTS.REASON.OTHER) {
                    description = translator(messages.mvrIncidentDescriptionOther, { desc: (dataValue.otherTypeDescribeOther || '')})
                } else {
                    description = translator({id: PA_RESTRICTION_TYPELIST.getCode(dataValue.restrictionType).name});
                }
            }
        } else {
            description = dataValue.convictionDescription;
        }
        return description || ''
    };

    const violationsSuspensionsYesNoLabel = useMemo(() => {
        const incidents = _.get(selectedMVR, 'mvrIncidents_WMIC.children', []);
        const hasIncidents = incidents && incidents.length > 0;
        return hasIncidents ? translator(messages.yesIncidentLength, {incidents:incidents.length}) : translator(messages.No);
    }, [selectedMVR, translator]);

    const overrideProps = {
        '@field': {
            parentNode: selectedMVR,
            readOnly: true
        },
        WMICPolicyMVRDetailViewForm: {
            hideButtons: true
        },
        mvrIncidentsCount: {
            value: violationsSuspensionsYesNoLabel
        },
        incidentListView: {
            VMData: [
                {
                    headerText: translator(messages.MVRViolationsSuspensionsDate),
                    getData: displayIncidentViolationDate
                },
                {
                    headerText: translator(messages.MVRConvictionReinstatementDate),
                    getData: displayIncidentConvictionDate
                },
                {
                    headerText: translator(messages.MVRType),
                    path: 'incidentType'
                },
                {
                    headerText: translator(messages.MVROverrideApplied),
                    getData: getOverrideRatingDisplayName
                },
                {
                    headerText: translator(messages.MVROverrideReason),
                    path: 'overrideReasonDisplayName',
                    visibilityCondition: isOverrideReasonVisible
                },
                {
                    headerText: translator(messages.MVRDescription),
                    getData: displayIncidentDescription
                },
            ],
            clickable: false,
            readOnly: true,
            startOpen: false
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={selectedMVR}
            overrideProps={overrideProps}
        />
    )
}

WMICPolicyMVRDetailView.propTypes = {
    id: PropTypes.string.isRequired,
    selectedMVR: PropTypes.shape({}).isRequired
};

export default WMICPolicyMVRDetailView;