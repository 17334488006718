import React, {
    useContext, useState
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardViewPage } from 'wmic-pe-portals-wizard-components-ui';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import WMICPAPolicyVehiclesDetailView from '../../components/WMICPAPolicyVehiclesDetailView/WMICPAPolicyVehiclesDetailView';


import metadata from './WMICPolicyVehicles.metadata.json5';
import messages from './WMICPolicyVehicles.messages';

function getVehicleDescription(vehicle) {
    return (`${vehicle.year ? `${vehicle.year} ` : ''}
        ${vehicle.make ? `${vehicle.make} ` : ''}
        ${vehicle.model ? `${vehicle.model} ` : ''}`);
}

const getVehicleDescriptionCell = (item) => {
    const vehicle = item;
    return (
        <span>
            {getVehicleDescription(vehicle)}
            <div>
                {_.get(vehicle, 'vin')}
            </div>
        </span>
    );
};

const VEHICLES_PATH = 'lobs.personalAuto.vehicleDTOs';
function WMICPolicyVehicles(props) {
    const { wizardData: { policyVM } } = props;
    const translator = useContext(TranslatorContext);
    const defaultVehicle = _.get(policyVM, `${VEHICLES_PATH}.children[0]`);
    const [selectedVehicle, setSelectedVehicle] = useState(defaultVehicle);
    const { refreshData } = useDataRefresh()
    const vehicleVMList =  _.get(policyVM, `${VEHICLES_PATH}.children`, []);

    const updateSelectedVehicle = (vehicleVM) => {
        setSelectedVehicle(vehicleVM);
        refreshData();
    };

    const commonAccordionContentProps = {
        vehicleVM: selectedVehicle,
        updateVehicle: updateSelectedVehicle,
        policyVM,
        readOnly: true
    };

    const overrideProps = {
        policyVehicleInfoComponent:{
            ...commonAccordionContentProps,
        },
        policyVehicleDetailsListView:{
            readOnly:true,
            value: vehicleVMList,
            clickable: true,
            startOpen: true,
            detailViewComponent:WMICPAPolicyVehiclesDetailView,
            VMData: [
                {
                    headerText: translator(messages.policyVehicleNumber),
                    path: 'vehicleNumber_Ext'
                },
                {
                    headerText: translator(messages.policyVehicleTheftIdentifier),
                    path: 'theftIdentifier_Ext',
                    visibilityCondition: item => item?.theftIdentifier_Ext?.aspects?.ocular
                },
                {
                    headerText: translator(messages.policyVehicleType),
                    path: 'vehicleType'
                },
                {
                    headerText: translator(messages.policyVehicleDescription),
                    getData: (item) => getVehicleDescriptionCell(item.value)
                },
                {
                    headerText: translator(messages.policyVehicleBusinessSegment),
                    path: 'businessSegment'
                }
            ],
            detailViewComponentProps: {
                policyVM,
            },
        },
    };

    return (
        <WMICWizardViewPage>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyVM}
                overrideProps={overrideProps}
            />
        </WMICWizardViewPage>
    );
}

WMICPolicyVehicles.propTypes = wizardProps;

export default WMICPolicyVehicles;
