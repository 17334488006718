import { UW_BLOCKING_POINT, BOT_STATUS, JobType, LINE_OF_BUSINESS } from 'wmic-pe-portals-utils-js';
import _ from 'lodash';

const hasIssuanceBlockingUWIssues = (underwritingIssues) => {
    if (underwritingIssues) {
        const blockingUnderwritingIssues = underwritingIssues.filter((underwritingIssue) => ( 
            (underwritingIssue.botStatus === BOT_STATUS.MANUAL_APPROVED_UW_ISSUE) && 
            (underwritingIssue.currentBlockingPoint === UW_BLOCKING_POINT.BLOCK_ISSUANCE) || 
            (underwritingIssue.currentBlockingPoint === UW_BLOCKING_POINT.REJECTED) ));
        return blockingUnderwritingIssues.length > 0;
    }
    return false;
};

const isBindBlockingUWIssuePresent = (underwritingIssues) => _.some(underwritingIssues, (underwritingIssue) => (underwritingIssue.currentBlockingPoint === UW_BLOCKING_POINT.BLOCK_BIND) )

const isBlockingUWIssuePresent = (submissionVM) => {
    const underwritingIssues = _.get(submissionVM, 'errorsAndWarnings.underwritingIssues.value');
    return hasIssuanceBlockingUWIssues(underwritingIssues) || isBindBlockingUWIssuePresent(underwritingIssues);
};

const hasValidationErrors = (submissionVM) => {
    const issues = _.get(submissionVM, 'errorsAndWarnings.validationIssues.issues.value');
    return issues ? !!issues.find((e) => e.type === 'error') : false;
}

const isUWIBrokerNotifierMsgVisible = (jobVM, isGenericMsg) => {
    const isValidLOB = [LINE_OF_BUSINESS.PERSONAL_AUTO, LINE_OF_BUSINESS.HOME_OWNER, LINE_OF_BUSINESS.PERSONAL_UMBRELLA].includes(jobVM?.lob?.value?.code);
    const jobType = jobVM?.baseData?.jobType?.value?.code;
    const isGenericVisible = isValidLOB && jobType === JobType.SUBMISSION && (isGenericMsg === jobVM?.canBeReferredForUWReview_Ext?.value);

    return isGenericMsg ? isGenericVisible : jobVM?.uwIssueNotificationEmail?.value !== undefined && isGenericVisible
}

const isQuoteBlockingUWIssuePresent = (underwritingIssues) =>  _.some(underwritingIssues, (underwritingIssue) => underwritingIssue.currentBlockingPoint === UW_BLOCKING_POINT.BLOCK_QUOTE)

const isIssueNotApproved = (issue) => (issue?.hasApprovalOrRejection === false || issue?.currentBlockingPoint !== UW_BLOCKING_POINT.NON_BLOCKING) && issue?.botStatus === BOT_STATUS.MANUAL_APPROVED_UW_ISSUE;

const hasUnapprovedUWIssues = (underwritingIssues) => {
    const unapprovedIssues = underwritingIssues ? underwritingIssues.filter((issue) => isIssueNotApproved(issue)) : [];
    return unapprovedIssues.length > 0;
}

export default {
    hasIssuanceBlockingUWIssues,
    isBlockingUWIssuePresent,
    isQuoteBlockingUWIssuePresent,
    isBindBlockingUWIssuePresent,
    hasValidationErrors,
    isUWIBrokerNotifierMsgVisible,
    hasUnapprovedUWIssues,
    isIssueNotApproved
}
