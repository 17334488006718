/* eslint-disable no-secrets/no-secrets */
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import {
    WMICLogger,
    RENEWAL_DISPLAY_STATUSES,
    LINE_OF_BUSINESS, 
    CONSTANTS, 
    DISTRIBUTION_METHODS,
    JURISDICTIONS, 
    AddressType, 
    WMICRPCUtil, 
    WMICCreditConsentUtil 
} from 'wmic-pe-portals-utils-js';
import { PolicyService } from 'wmic-pe-capability-gateway-policy';

const updateDrivers = (jobVM, namedInsured) => {
    const allDrivers = _.get(jobVM, 'lobData.personalAuto.coverables.drivers.value', [])

    if (allDrivers.length > 0) {
        let found = false;
        // eslint-disable-next-line no-plusplus
        for (let driverCounter = 0; !found && driverCounter < allDrivers.length; driverCounter++) {
            const driver = allDrivers[driverCounter];
            if (driver.person.publicID === namedInsured.contactPublicID
                || driver.person.publicID === namedInsured.publicID) {

                driver.dateOfBirth = namedInsured.dateOfBirth;

                driver.priInsuredRelation_wmic = namedInsured.relationshipToPrimaryInsured_WMIC;

                // make sure the named insured's marital status matchs the driver's
                if (!namedInsured.maritalStatus) {
                    namedInsured.maritalStatus = driver.person.maritalStatus;
                }

                found = true;
            }
        }
    }
};

const changePrimaryInsured = (jobVM, oldANI, viewModelService) => {
    const lob = _.get(jobVM, 'lob.value.code');
    const isPA = lob  === LINE_OF_BUSINESS.PERSONAL_AUTO;
    const isHO = lob  === LINE_OF_BUSINESS.HOME_OWNER;
    const isPUP = lob  === LINE_OF_BUSINESS.PERSONAL_UMBRELLA;

    const oldPNI = _.get(jobVM, 'baseData.primaryNamedInsured_WMIC.value');

    const isPersonPNI = _.upperFirst(_.get(jobVM.value, 'baseData.primaryNamedInsured_WMIC.subtype')) === CONSTANTS.Person;
    const isCompanyPNI = _.upperFirst(_.get(jobVM.value, 'baseData.primaryNamedInsured_WMIC.subtype')) === CONSTANTS.Company;

    const baseState = _.get(jobVM, 'baseData.baseState.value.code');
    const rateAsOfDate = _.get(jobVM, 'baseData.rateAsOfDate.value');
    const isQuebecPA = isPA && baseState === JURISDICTIONS.QUEBEC;
    const hasPACredit = isPA
        && !isQuebecPA
        && WMICRPCUtil.getIsRPCEffective(baseState, rateAsOfDate, '1296', '1297', '1503', '1504', '1576', '1543');

    let hasPaymentPlanAndDiscountCreditConsent = false;
    if (isPA) {
        const showAutoCreditConsentRPCIsEffective = WMICRPCUtil.getIsRPCEffective(
            baseState,
            rateAsOfDate,
            '1503', '1504', '1576', '1543'
        );
        const hasCreditableVehicle = WMICCreditConsentUtil.hasPersonalVehicleOrMotorHome(_.get(jobVM, 'lobData.personalAuto.coverables.vehicles.value'));
        const showPaymentPlanConsent = WMICCreditConsentUtil.showPaymentPlan(baseState);

        hasPaymentPlanAndDiscountCreditConsent = showAutoCreditConsentRPCIsEffective && hasCreditableVehicle && showPaymentPlanConsent;
    }


    const showCreditConsentInfoPNI = isPersonPNI
        && (isHO || isQuebecPA || hasPACredit);

    let lobContext = LINE_OF_BUSINESS.PERSONAL_AUTO;

    if (isHO) {
        lobContext = LINE_OF_BUSINESS.HOME_OWNER;
    } else if (isPUP) {
        lobContext = LINE_OF_BUSINESS.PERSONAL_UMBRELLA;
    }

    // add the old PNI as a new ANI
    const newANI = viewModelService.create(
        oldPNI,
        'pc',
        'wmic.edge.ca.capabilities.policyjob.draft.dto.AdditionalNamedInsuredDTO_WMIC',
        jobVM.aspects.context(),
        { 'LobContext': lobContext });

    switch (oldANI.relationshipToPrimaryInsured_WMIC.value.code) {
        case 'spouse':
            newANI.relationshipToPrimaryInsured_WMIC.value = 'spouse';
            break;
        case 'common-law':
            newANI.relationshipToPrimaryInsured_WMIC.value = 'common-law';
            break;
        case 'child':
        case 'relative':
            newANI.relationshipToPrimaryInsured_WMIC.value = 'relative';
            break;
        case 'unknown':
            newANI.relationshipToPrimaryInsured_WMIC.value = 'unknown';
            break;
        case 'notrelated':
        case 'employee':
            newANI.relationshipToPrimaryInsured_WMIC.value = 'notrelated';
            break;
        default:
            newANI.relationshipToPrimaryInsured_WMIC.value = 'unknown';
            break;
    }

    newANI.subtype.value = oldPNI.subtype.toLowerCase();
    newANI.prefDistributionMethod_WMIC.value = _.find(
        newANI.prefDistributionMethod_WMIC.aspects.availableValues,
        (distributionMethod) => distributionMethod.code.toLowerCase() === DISTRIBUTION_METHODS.MAIL
    );

    newANI.locationAddress.value = oldPNI.primaryAddress;
    newANI.emailAddress.value = oldPNI.emailAddress1;
    newANI.contactPublicID.value = newANI.publicID.value;
    newANI.publicID.value = undefined;

    if (showCreditConsentInfoPNI) {
        newANI.creditConsentReceived.value = jobVM.baseData.creditConsentReceived.value;
        newANI.creditConsentDate.value = jobVM.baseData.creditConsentDate.value;
        newANI.personalInfoConsentForm.value = jobVM.baseData.personalInfoConsentForm.value;
        newANI.creditInfoWithdrawalConsent.value = jobVM.baseData.creditInfoWithdrawalConsent.value;
    }

    if (hasPaymentPlanAndDiscountCreditConsent) {
        newANI.usedForPaymentPlanConsent.value = jobVM.baseData.usedForPaymentPlanConsent.value;
    }

    if (isCompanyPNI) {
        newANI.companyName.value = oldPNI.contactName;
    }

    jobVM.baseData.additionalNamedInsureds_WMIC.pushElement(newANI.value);

    // add old ANI as new PNI
    jobVM.baseData.primaryNamedInsured_WMIC.value = oldANI.value;

    // copying over extra data that we know since it might be the same-
    if (isPersonPNI) {
        jobVM.baseData.primaryNamedInsured_WMIC.primaryAddress.value = oldANI.locationAddress.value ?? oldPNI.primaryAddress;
        jobVM.baseData.primaryNamedInsured_WMIC.primaryPhoneType.value = oldPNI.primaryPhoneType ?? jobVM.baseData.primaryNamedInsured_WMIC.primaryPhoneType.value;
        jobVM.baseData.primaryNamedInsured_WMIC.homeNumber.value = oldPNI.homeNumber ?? jobVM.baseData.primaryNamedInsured_WMIC.homeNumber.value;
        jobVM.baseData.primaryNamedInsured_WMIC.workNumber.value = oldPNI.workNumber ?? jobVM.baseData.primaryNamedInsured_WMIC.workNumber.value;
        jobVM.baseData.primaryNamedInsured_WMIC.cellNumber.value = oldPNI.cellNumber ?? jobVM.baseData.primaryNamedInsured_WMIC.cellNumber.value;
        jobVM.baseData.primaryNamedInsured_WMIC.emailAddress1.value = oldPNI.emailAddress1 ?? jobVM.baseData.primaryNamedInsured_WMIC.emailAddress1.value;
        jobVM.baseData.primaryNamedInsured_WMIC.confirmEmailAddress.value = oldPNI.confirmEmailAddress ?? jobVM.baseData.primaryNamedInsured_WMIC.confirmEmailAddress.value;
    }
    else {
        jobVM.baseData.primaryNamedInsured_WMIC.primaryAddress.value = oldANI.locationAddress.value;
    }

    if (showCreditConsentInfoPNI) {
        jobVM.baseData.creditConsentReceived.value = oldANI.creditConsentReceived.value;
        jobVM.baseData.creditConsentDate.value = oldANI.creditConsentDate.value;
        jobVM.baseData.personalInfoConsentForm.value = oldANI.personalInfoConsentForm.value;
        jobVM.baseData.creditInfoWithdrawalConsent.value = oldANI.creditInfoWithdrawalConsent.value;
    }

    if (hasPaymentPlanAndDiscountCreditConsent) {
        jobVM.baseData.usedForPaymentPlanConsent.value = oldANI.usedForPaymentPlanConsent.value;
    }

    // copying over extra data needed for the validations
    if (isPersonPNI) {
        jobVM.baseData.primaryNamedInsured_WMIC.minDOB_WMIC.value = oldPNI.minDOB_WMIC;
        jobVM.baseData.primaryNamedInsured_WMIC.maxDOB_WMIC.value = oldPNI.maxDOB_WMIC;
    }
    else {
        jobVM.baseData.primaryNamedInsured_WMIC.contactName.value = oldANI.companyName.value;
    }

    jobVM.baseData.primaryNamedInsured_WMIC.primaryAddress.value.addressType = isPersonPNI ? AddressType.HOME : AddressType.BUSINESS;

    if (isPA) {
        // check the primary insured relationship
        jobVM.baseData.primaryNamedInsured_WMIC.value.relationshipToPrimaryInsured_WMIC = 'insured';

        if (_.get(jobVM, 'lobData.personalAuto.coverables.drivers.value', []).length > 0) {
            updateDrivers(jobVM, jobVM.baseData.primaryNamedInsured_WMIC.value);

            // eslint-disable-next-line no-plusplus
            for (let addInsuredCounter = 0; addInsuredCounter < jobVM.baseData.additionalNamedInsureds_WMIC.value.length; addInsuredCounter++) {
                updateDrivers(jobVM, jobVM.baseData.additionalNamedInsureds_WMIC.value[addInsuredCounter]);
            }
        }
    }

    // remove old ANI as last thing, as this is changing the ani we use
    const index = _.findIndex(
        _.get(jobVM, 'baseData.additionalNamedInsureds_WMIC.value', []),
        (ani) => ani.publicID === oldANI.publicID.value
    );
    jobVM.baseData.additionalNamedInsureds_WMIC.value.splice(index, 1);

    return jobVM;
};

const hasUpcomingRenewal = async (policyNumber, termNumber, authHeader) => {
    const response = await PolicyService.getPolicyTerms(
        policyNumber,
        authHeader
    ).catch((e) => {
        WMICLogger.error('Error getting policy terms', e);
    });

    const { policyTerms } = response;

    const selectedTerm = _.find(
        policyTerms,
        (policyTerm) => policyTerm.termNumber === termNumber
    );

    const renewalTerm = selectedTerm.openRenewalExists
        ? _.sortBy(policyTerms, ['termNumber']).reverse()[0]
        : undefined;

    if (renewalTerm) {
        if (
            selectedTerm.termNumber !== renewalTerm.termNumber &&
            renewalTerm.timeUntilRenewalIssued > 0 &&
            renewalTerm.timeUntilRenewalIssued < 10
        ) {
            for (const status in RENEWAL_DISPLAY_STATUSES) {
                if (
                    renewalTerm.renewalDisplayStatus ===
                    RENEWAL_DISPLAY_STATUSES[status]
                ) {
                    return true;
                }
            }
        }
    }

    return false;
};

export default {
    changePrimaryInsured,
    hasUpcomingRenewal,
};
