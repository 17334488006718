import React, {
    useContext, useCallback, useState, useEffect
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { MockUpUtil } from '@xengage/gw-portals-util-js'
import { useDocumentTitle, useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICUnderwritingIssues } from 'wmic-pe-components-platform-react';
import { WMICPUPPrintableAgreement } from 'wmic-pe-capability-gateway-common-pup-react';
import { DocumentService } from 'wmic-pe-capability-gateway-policycommon';
import { WMICLogger } from 'wmic-pe-portals-utils-js';
import { WMICDocuments } from 'wmic-pe-capability-gateway-common-react';
import WMICQuoteUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICQuoteUtil';

// eslint-disable-next-line import/no-unresolved
import messages from './WMICPUPQuote.messages';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import styles from './WMICPUPQuote.module.scss';
import metadata from './WMICPUPQuote.metadata.json5';

function WMICPUPQuote(props) {
    const { authHeader } = useAuthentication();
    const { isComponentValid, onValidate, disregardFieldValidation, registerInitialComponentValidation, initialValidation } = useValidation('QuotePage');
    const viewModelService = useContext(ViewModelServiceContext);
    const { isSkipping } = useContext(WizardContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const {
        wizardSnapshot,
        wizardData: submissionVM,
        updateWizardData,
        markStepSubmitted
    } = props;
    const { setWizardLoading } = useWizardModals();
    const translator = useContext(TranslatorContext);
    const [underwritingIssuesErrors, updateUnderwritingIssues] = useState(_.get(submissionVM, 'errorsAndWarnings.underwritingIssues.value'));
    const [userDocuments, setUserDocuments] = useState([]);

    useDocumentTitle(translator(messages.pupQuoteTitle), submissionVM);

    useEffect(() => {
        // inject mock contactPhone to prevent wizardData.subtreeValid is invalid
        submissionVM.value = MockUpUtil.setMockData(
            submissionVM.value,
            'quote.ho',
            'bindData.contactPhone'
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        registerInitialComponentValidation(() => !WMICQuoteUtil.isBlockingUWIssuePresent(submissionVM));
    }, [registerInitialComponentValidation, submissionVM]);

    const fetchDocuments = useCallback(async () => {
        try {
            const documents = await DocumentService.getDocumentsForJobNumber(submissionVM.quoteID.value, authHeader)

            if (userDocuments.length !== documents.length){
                setUserDocuments(documents);
            }
        } catch(err) {
            WMICLogger.error('WMICPUPQuotePage getDocumentsForJobNumber', err);
        }
    }, [authHeader, submissionVM, userDocuments]);

    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments, userDocuments])

    const validateSubmission = useCallback(async () => {
        setWizardLoading(!isSkipping);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.unset(newSubmissionVM.value, 'bindData');
        const result = await LoadSaveService.validateSubmissionForIssue(newSubmissionVM.value, authHeader);
        _.extend(submissionVM.value, result);
        updateWizardData(submissionVM);
        markStepSubmitted(); // the step needs to be marked as submitted for the errors associated with this step to show up
        setWizardLoading(false);
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LoadSaveService, authHeader, submissionVM, viewModelService, setWizardLoading, markStepSubmitted]);

    useEffect(() => {
        validateSubmission();
    }, [validateSubmission]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const onNext = useCallback(async () => {
        try {
            setWizardLoading(true);

            if (!isComponentValid) {
                return false;
            }

            if (
                WMICQuoteUtil.isBlockingUWIssuePresent(submissionVM) ||
                WMICQuoteUtil.hasValidationErrors(submissionVM)
            ) {
                scrollToTop();
                return false;
            }

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [isComponentValid, setWizardLoading, submissionVM]);

    const overrideProps = {
        wmicQuoteAlertMessage: {
            jobVM: submissionVM
        },
        quotePagePremiumCosts: {
            premium: _.get(submissionVM, 'quoteData.offeredQuotes.value[0].premium', {}),
            jobVM: submissionVM
        },
        quotePageUWIssues: {
            underwritingIssues:  underwritingIssuesErrors,
            quoteID: _.get(submissionVM, 'quoteID.value'),
            authHeader,
            jobVM: submissionVM
        },
        quotePageSummary: {
            jobVM: submissionVM,
            lobData: _.get(submissionVM, 'lobData.personalUmbrella', {})
        },
        quotePageUploadDocuments: {
            documents: userDocuments,
            updateDocuments: setUserDocuments,
            value: {
                documentDTOs: userDocuments,
                canUploadDocument: true,
                jobNumber: _.get(submissionVM, 'quoteID.value')
            }
        },
        quotePagePrintableAgreement: {
            jobVM: submissionVM
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        componentMap: {
            WMICUnderwritingIssues,
            WMICDocuments,
            WMICPUPPrintableAgreement
        }
    };

    return (
        <WMICWizardSubmissionPage
            disableNext={!isComponentValid}
            onNext={onNext}
            skipWhen={initialValidation}
            cancelLabel={translator(commonMessages.saveAndExit)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.componentMap}
                classNameMap={resolvers.resolveClassNameMap}
            />

        </WMICWizardSubmissionPage>
    );
}

WMICPUPQuote.propTypes = wizardProps;
export default WMICPUPQuote;
