import React, {
    useContext, useState, useCallback, useMemo, useEffect
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    InlineLoader,
    Tooltip,
    Loader
} from '@jutro/components';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { CONSTANTS, WMICBMSIUtil, JobType, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js'
import { WMICButton, WMICRequiredText } from 'wmic-pe-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { pageTemplateProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import {WMICDefaultedPropertyWarning} from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import {WMICDTOError} from 'wmic-pe-capability-gateway-common-react';

import styles from './WMICDigitalWizardPageTemplate.module.scss';
import WMICWizardSidebar from '../components/WMICWizardSidebar';

function renderLoader(isLoading) {
    return isLoading ? <InlineLoader loading={isLoading} className="gw-inline-loader" /> : null;
}

function WMICDigitalWizardPageTemplate(props) {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const { steps, currentStep, isSkipping } = useContext(WizardContext);
    const { authUserData: currentUser } = useAuthentication();
    const { wizardLoading } = useWizardModals();

    const isTablet = ['phone', 'phoneWide', 'tablet'].includes(breakpoint)
    const [showSidebar, setShowSidebar] = useState(false);
    const [wizardIsLoading, setWizardIsLoadingClass] = useState(true);

    const {
        children: content,
        onNext,
        isLoadingNext,
        disableNext,
        showNext,
        nextLabel,
        nextButtonTooltip,
        onPrevious,
        isLoadingPrevious,
        disablePrevious,
        showPrevious,
        previousLabel,
        onCancel,
        isLoadingCancel,
        disableCancel,
        showCancel,
        cancelLabel,
        renderContextComponent,
        renderWidgets,
        wizardData,
        wizardSnapshot,
        showContextComponent,
        showWizardHeader,
        customMessageModal,
        showRequired,
        hideBmsiWarning,
        flowStepId
    } = props;

    const canEditJob = useMemo(
        () => WMICUserAccessUtil.canEditJob(currentUser.permission_Ext, wizardData?.baseData?.jobType?.value?.code),
        [currentUser.permission_Ext, wizardData?.baseData?.jobType?.value?.code]
    );

    useEffect(() => {
        switch (breakpoint) {
            case 'phone':
            case 'phoneWide':
            case 'tablet' :
                setShowSidebar(false);
                break;
            default:
                setShowSidebar(true);
                break;
        }
    }, [breakpoint]);

    const contextComponent = useCallback(() =>
        <div className={styles.wizardContext}>{renderContextComponent(wizardSnapshot)}</div>
    , [renderContextComponent, wizardSnapshot]);

    const shouldShowButtons = useMemo(() => !(
        showNext === false
        && showPrevious === false
        && showCancel === false
    ), [showCancel, showNext, showPrevious]);

    const isPerformingTransiton = useMemo(() => (
        isLoadingCancel || isLoadingPrevious || isLoadingNext
    ), [isLoadingCancel, isLoadingNext, isLoadingPrevious]);

    const wizardClassName = classNames(styles.wizardMain, {
        [styles.wizardMainShowSidebar]: showSidebar && isTablet
    });

    const isBMSI = _.get(wizardData, "isFromBMS_WMIC.value");
    const defaultedProperties = _.get(wizardData, "defaultedProperties_WMIC.value", []);

    let dtoErrors = _.get(wizardData, "dtoValidations_Ext.value", []);
    if(flowStepId) {
        dtoErrors = dtoErrors.filter((error) =>error.flowStepIds.indexOf(flowStepId) !== -1);
    }

    const isSubmission = _.get(wizardData, "baseData.jobType.value.code") === JobType.SUBMISSION;
    const showDTOErrors = !wizardLoading && (isBMSI || !isSubmission || currentStep.submitted) && dtoErrors.length > 0;
    const isQuoteButton = nextLabel?.defaultMessage === 'Quote';
    const hasUnconfirmedBmsiFields = defaultedProperties?.some((p) => p.status !== CONSTANTS.REVIEWED);

    const nextButton = useMemo(() => (
        <WMICButton
            id="gw-wizard-Next"
            type="primary"
            className={styles.nextbutton}
            disabled={disableNext || isPerformingTransiton || (isQuoteButton && hasUnconfirmedBmsiFields)}
            onClick={onNext}
        >
            {translator(nextLabel)}
            {renderLoader(isLoadingNext)}
        </WMICButton>
    ), [disableNext, isLoadingNext, isPerformingTransiton, nextLabel, onNext, translator]);

    const nextButtonWithTooltip = useMemo(() => (
        <Tooltip placement="bottom" content={nextButtonTooltip}>
            <span>{nextButton}</span>
        </Tooltip>
    ), [nextButton, nextButtonTooltip]);

    const renderNextButton = nextButtonTooltip ? nextButtonWithTooltip : nextButton;
    const buttonContainerClassName = classNames(styles.navTransitionButtons, {
        [styles.navTransitionButtonOnlyNext]: !showPrevious && isTablet
    });

    const getNodePublicId = (node, propName) => {
        if (node) {
            if (node.accidentWaiverDriverEndorsementPublicID) {
                return node.accidentWaiverDriverEndorsementPublicID.value;
            }
            if (node.creditConsentPublicId_Ext
                && [CONSTANTS.PERSONAL_INFO_CONSENT_FORM, CONSTANTS.CREDIT_CONSENT_RECEIVED].includes(propName.toLowerCase())) {
                return node.creditConsentPublicId_Ext.value;
            }
            if (node.publicID) {
                return node.publicID.value;
            } 
            if (node.publicId) {
                return node.publicId.value;
            } 
            if (node.policyPublicID_WMIC) {
                return node.policyPublicID_WMIC.value;    
            } 
            if (node.exteriorWallId) {
                return node.exteriorWallId;
            }
        }
        return undefined;
    };

    const erroredPages = useMemo(() => {
        const result = [];
        if (isBMSI) {
            const stepsRegexsMap = new Map();

            steps.forEach((step) => {
                const bmsiRegexs = step.bmsiRegexPathToDisplayObjects || [];
                stepsRegexsMap.set(step.id, bmsiRegexs);
            });


            const condition = (node) => {
                const checkWithStepRegex = (n, rx) => {
                    const pathToItem = n?.baseVMNode?.pathToItem?.asString || '';
                    return (new RegExp(rx, 'i')).test(pathToItem);
                };

                for (const [stepId, stepsRegexs] of stepsRegexsMap.entries()) {
                    if (!result.includes(stepId)) {
                        let matchFound = false;
                        stepsRegexs.every((stepRegex) => {
                            const dtoProps = node?._metadataInfo.valueType.typeInfo.properties || [];
                            matchFound = dtoProps.some((dtoProp) => {
                                const propNode = node[dtoProp.jsProperty];
                                const propMatchRx = checkWithStepRegex(propNode, stepRegex);
                                if (propMatchRx) {
                                    const defaultedProp = defaultedProperties.find((p) => 
                                        p.status !== CONSTANTS.REVIEWED
                                        && p.className === node?._dtoName
                                        && p.propertyName === dtoProp.name
                                        && p.classPublicID === getNodePublicId(node, dtoProp.name)
                                    );

                                    if (defaultedProp) {
                                        result.push(stepId);
                                        return true;
                                    }
                                }
                                return false;
                            });
                            return !matchFound;
                        });
                    }
                }
            };

            WMICBMSIUtil.checkSubtree(wizardData, condition, true, false);
        }
        
        return result;
    }, [wizardData, defaultedProperties, steps, isBMSI]);

    const getWizardIsLoadingClass = () => wizardIsLoading ? 'wizardIsLoading' : '';

    useEffect(() => { setWizardIsLoadingClass(false); }, []);

    const wizardIsSkippingClassName = useMemo(() => isSkipping ? "wizardIsSkipping" : null, [isSkipping]);

    return (
        <div className={classNames(styles.digitalWizard, getWizardIsLoadingClass())}>
            {isSkipping && <Loader /> }
            <div className={classNames(wizardIsSkippingClassName)}>
                {showContextComponent && contextComponent()}
                <div className={wizardClassName}>
                    {!isTablet || showSidebar ? (
                        <div className={classNames(styles.wizardNavigation, 'hiddenPrintElement')}>
                            <WMICWizardSidebar customMessageModal={customMessageModal} isBMSI={isBMSI} stepsWithErrors={erroredPages}/>
                            <div className={styles.wizardWidgets}>
                                {renderWidgets(wizardData)}
                            </div>
                        </div>
                    ) : null}


                    <div className={styles.wizardContent}>

                        {isTablet && <WMICWizardSidebar />}
                        {showWizardHeader && contextComponent()}
                        {showRequired && <WMICRequiredText/>}
                        {isBMSI && !hideBmsiWarning && <WMICDefaultedPropertyWarning defaultedProperties={defaultedProperties} />}
                        {showDTOErrors && <WMICDTOError dtoErrors={dtoErrors} />}
                        {content}
                        {canEditJob && (
                            <div className={classNames({ [styles.navButtons]: shouldShowButtons }, 'noPrint')}>
                                {showCancel && (
                                    <WMICButton
                                        id="gw-wizard-cancel"
                                        disabled={disableCancel || isPerformingTransiton}
                                        type="secondary-outlined"
                                        onClick={onCancel}
                                    >
                                        {translator(cancelLabel)}
                                        {renderLoader(isLoadingCancel)}
                                    </WMICButton>
                                )}
                                <div className={buttonContainerClassName}>
                                    {showPrevious && (
                                        <WMICButton
                                            id="gw-wizard-previous"
                                            disabled={disablePrevious || isPerformingTransiton}
                                            type="primary-outlined"
                                            onClick={onPrevious}
                                        >
                                            {translator(previousLabel)}
                                            {renderLoader(isLoadingPrevious)}
                                        </WMICButton>
                                    )}
                                    {showNext && renderNextButton}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

WMICDigitalWizardPageTemplate.propTypes = {
    ...pageTemplateProps,
    /** a function that accepts wizardData to render a React component */
    renderContextComponent: PropTypes.func,
    /** a function that accepts wizardData to render a React component */
    renderWidgets: PropTypes.func,
    showContextComponent: PropTypes.bool,
    showWizardHeader: PropTypes.bool,
    nextButtonTooltip: PropTypes.string,
    hideBmsiWarning: PropTypes.bool,
    flowStepId: PropTypes.string,
};

WMICDigitalWizardPageTemplate.defaultProps = {
    renderContextComponent: () => null,
    renderWidgets: () => null,
    showContextComponent: true,
    showWizardHeader: false,
    nextButtonTooltip: '',
    hideBmsiWarning: false,
    flowStepId: null
};

export default WMICDigitalWizardPageTemplate;
