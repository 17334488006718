import { defineMessages } from 'react-intl';

export default defineMessages({
    specialVehicleTypeLabel: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.SpecialVehicleType',
        defaultMessage: 'Special Vehicle Type',
    },
    yearLabel: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.YearLabel',
        defaultMessage: 'Year',
    },
    makeLabel: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.MakeLabel',
        defaultMessage: 'Make',
    },
    modelLabel: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.ModelLabel',
        defaultMessage: 'Model',
    },
    vinLabel: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.VinLabel',
        defaultMessage: 'Vin',
    },
    ToBindMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.ToBind',
        defaultMessage: 'To bind this policy you will need to enter a valid VIN',
    },
    CannotChangeVINMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.CannotChangeVIN',
        defaultMessage: 'Cannot change the VIN on an existing vehicle.',
    },
    editVinButton: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.EditVehicle',
        defaultMessage: 'Edit Vehicle',
    },
    vinOverride: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.VINOverride',
        defaultMessage: 'VIN Override',
    },
    vinOverrideReason: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.OverrideReason',
        defaultMessage: 'Override Reason',
    },
    trailerSubtype: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.SubType',
        defaultMessage: 'Sub Type',
    },
    trailerHaulingType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.Hauled separately or as train?',
        defaultMessage: 'Hauled separately or as train?',
    },
    vehicleLength: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.Length',
        defaultMessage: 'Length',
    },
    bodyStyle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.BodyType',
        defaultMessage: 'Body Type',
    },
    EngineStroke: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.EngineStroke',
        defaultMessage: 'Engine Stroke',
    },
    CC: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.CC',
        defaultMessage: 'CC',
    },
    vehicleMsrp: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.vehicleMsrp',
        defaultMessage: 'Vehicle Purchase Price (MSRP)',
    },
    CostNew: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.CostNew',
        defaultMessage: 'Current Value/Purchase Price',
    },
    VehicleInspectionRequired: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.VehicleInspectionRequired',
        defaultMessage: 'Vehicle Inspection Required',
    },
    VehicleInspectionComplete: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.VehicleInspectionComplete',
        defaultMessage: 'Vehicle Inspection Complete?',
    },
    VehicleInspectionDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.VehicleInspectionDate',
        defaultMessage: 'Date Inspection Completed',
    },
    TypeOfAntiTheftDevice: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.TypeOfAntiTheftDevice',
        defaultMessage: 'Type Of anti-theft device',
    },
    DescriptionOfAntiTheftDevice: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.DescriptionOfAntiTheftDevice',
        defaultMessage: 'Description',
    },
    AntiTheftAndRecoveryDeviceManufacturer: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.AntiTheftAndRecoveryDeviceManufacturer',
        defaultMessage: 'Anti-theft and recovery device manufacturer',
    },
    AutonomousEmergencyBrakingSys: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.AutonomousEmergencyBrakingSys',
        defaultMessage: 'Autonomous Emergency Braking System',
    },
    SearchAndSelect: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.SearchAndSelect',
        defaultMessage: 'Search and select a vehicle by clicking Vehicle Search',
    },
    Search: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.Search',
        defaultMessage: 'Vehicle Search',
    },
    notTrailerTitle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.VehicleTypeSearch',
        defaultMessage: '{type} Search',
    },
    vehicleTypeInfo: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.VehicleTypeInfo',
        defaultMessage: '{type} Information',
    },
    VINRequiredSearchFields: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.VINRequiredSearchFields',
        defaultMessage: '&lt;span&gt;The vehicle\'s &lt;b&gt;Year&lt;/b&gt; along with either it\'s &lt;b&gt;Make&lt;/b&gt; or &lt;b&gt;VIN&lt;/b&gt; is required for a vehicle search&lt;/span&gt;',
    },
    NoSearchResults: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.NoSearchResults',
        defaultMessage: 'No vehicle match found. Try again or use VIN override.',
    },
    SearchingForVehicleInformation: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.SearchingForVehicleInformation',
        defaultMessage: 'Searching for vehicle information...'
    },
    Select: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.Select',
        defaultMessage: 'Select'
    },
    PleaseTryAgain: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.PleaseTryAgain',
        defaultMessage: 'Please try again later.'
    },
    engineForcedInduction: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.Engine Forced Induction',
        defaultMessage: 'Engine Forced Induction'
    },
    theftDeterrentSystem: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.Theft Deterrent System',
        defaultMessage: 'Theft Deterrent System'
    },
    MSRP: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.MSRP',
        defaultMessage: 'MSRP'
    },
    MSRPUnavailable: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.MSRP Unavailable',
        defaultMessage: 'Unavailable'
    },
    selectVehicle: {
        id: 'wmic-pe-capability-gateway-common-pa-react.vehicle-info.SelectVehicle',
        defaultMessage: 'Select a vehicle'
    }

})