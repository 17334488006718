import { defineMessages } from 'react-intl';

export default defineMessages({
    editUninsuredTitle: {
        id: 'wmic.policy-change.component.edit-uninsured.Adjust Coverages or Deductibles',
        defaultMessage: 'Adjust Coverages or Deductibles'
    },
    uninsuredMotoristPDTitle: {
        id: 'wmic.policy-change.component.edit-uninsured.Uninsured Motorist Property Damage',
        defaultMessage: 'Uninsured Motorist Property Damage'
    },
    uninsuredMotoristDWTitle: {
        id: 'wmic.policy-change.component.edit-uninsured.Uninsured Motorist Collision Deductible Waiver',
        defaultMessage: 'Uninsured Motorist Collision Deductible Waiver'
    },
    vehManufRepPartsTitle: {
        id: 'wmic.policy-change.component.edit-uninsured.Vehicle Manufacturer Replacement Parts',
        defaultMessage: 'Vehicle Manufacturer Replacement Parts'
    },
    vehManufRepPartsSubtitle: {
        id: 'wmic.policy-change.component.edit-uninsured.This coverage covers the amount needed to repair or replace property or parts with Original Equipment Manufacturer parts',
        defaultMessage: 'This coverage covers the amount needed to repair or replace property or parts with Original Equipment Manufacturer parts.'
    },
    umpdTooltip: {
        id: 'wmic.policy-change.component.edit-uninsured.Property Damage Tooltip',
        defaultMessage: 'This coverage is available on all vehicles and provides up to $20,000 towards the lesser of either repairs to or the actual cash value of your vehicle for damages, subject to a deductible, caused by an uninsured vehicle.'
    },
    umdwTooltip: {
        id: 'wmic.policy-change.component.edit-uninsured.Collision Deductible Waiver Tooltip',
        defaultMessage: 'Unless a waiver is signed, your uninsured motorists collision deductible waiver coverage applies to all insured vehicles carrying collision coverage. This coverage pays your collision deductible if your vehicle is damaged by direct physical contact with an uninsured motorist, where the driver or vehicle owner is identified and determined to be either fully or partially legally liable.'
    },
    vmrpTooltip: {
        id: 'wmic.policy-change.component.edit-uninsured.Vehicle Manufacturer Replacement Parts Tooltip',
        defaultMessage: '(Available on vehicles insured for Comprehensive and Collision Coverage) This endorsement is available for an additional premium to provide coverage for the amount to repair or replace property or parts with those made for or by the Original Equipment Manufacturer. To be eligible for this optional coverage, the vehicle must be less than 11 years of age and carry both collision and comprehensive coverage.'
    },
    tooltipAria: {
        id: 'wmic.policy-change.component.edit-uninsured.Tooltip Aria',
        defaultMessage: 'Click to expand this tip about {title}'
    },
    hasInitialCoverage: {
        id: 'wmic.policy-change.component.edit-uninsured.Has initial coverage',
        defaultMessage: 'This vehicle currently has this coverage'
    },
    umpdhasInitialCoverageDivAria: {
        id: 'wmic.policy-change.component.edit-uninsured.Has initial coverage for uninsured motorist property damage',
        defaultMessage: 'This vehicle currently has uninsured motorist property damage'
    },
    umdwhasInitialCoverageDivAria: {
        id: 'wmic.policy-change.component.edit-uninsured.Has initial coverage for uninsured motorist collision deductible waiver',
        defaultMessage: 'This vehicle currently has uninsured motorist collision deductible waiver'
    },
    vmrphasInitialCoverageDivAria: {
        id: 'wmic.policy-change.component.edit-uninsured.Has initial coverage for vehicle manufacturer replacement parts',
        defaultMessage: 'This vehicle currently has vehicle manufacturer replacement parts'
    },
    noInitialCoverage: {
        id: 'wmic.policy-change.component.edit-uninsured.Does not have initial coverage',
        defaultMessage: 'This vehicle does not currently have this coverage'
    },
    umpdnoInitialCoverageDivAria: {
        id: 'wmic.policy-change.component.edit-uninsured.Does not have initial coverage for uninsured motorist property damage',
        defaultMessage: 'This vehicle does not currently have uninsured motorist property damage'
    },
    umdwnoInitialCoverageDivAria: {
        id: 'wmic.policy-change.component.edit-uninsured.Does not have initial coverage for uninsured motorist collision deductible waiver',
        defaultMessage: 'This vehicle does not currently have uninsured motorist collision deductible waiver'
    },
    vmrpnoInitialCoverageDivAria: {
        id: 'wmic.policy-change.component.edit-uninsured.Does not have initial coverage for vehicle manufacturer replacement parts',
        defaultMessage: 'This vehicle does not currently have vehicle manufacturer replacement parts'
    },
    confirmModalTitle: {
        id: 'wmic.policy-change.component.edit-uninsured.Confirm policy changes',
        defaultMessage: 'Confirm policy changes'
    },
    confirmModalBody: {
        id: 'wmic.policy-change.component.edit-uninsured.Please confirm',
        defaultMessage: '&lt;p&gt;Please confirm the following changes to your policy:&lt;/p&gt;&lt;br&gt;'
    },
    confirmationLabel: {
        id: 'wmic.policy-change.component.edit-uninsured.Submit',
        defaultMessage: 'Submit'
    },
    submitButtonTracking: {
        id: 'wmic.policy-change.component.edit-uninsured.Policy Change - Submit - Adjust Coverages or Deductibles',
        defaultMessage: 'Policy Change - Submit - Adjust Coverages or Deductibles (Uninsured)'
    },
    continue: {
        id: 'wmic.policy-change.component.edit-uninsured.Continue',
        defaultMessage: 'Continue'
    },
    cancel: {
        id: 'wmic.policy-change.component.edit-uninsured.Cancel',
        defaultMessage: 'Cancel'
    }
});
