import React, { useEffect, useContext, useMemo, useCallback } from 'react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAccordionValidation } from 'wmic-pe-portals-wizard-components-ui';
import _ from 'lodash';
import {
    WMICJurisdictionUtil,
    JURISDICTIONS,
    WMICPhoneValidationUtil,
    WMICDriverUtil
} from 'wmic-pe-portals-utils-js';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import { Accordion } from '@jutro/legacy/components';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import WMICDriverPersonalInfoComponent
    from '../WMICDriverPersonalInfo/WMICDriverPersonalInfoComponent';
import WMICLicenseInfoComponent
    from '../WMICLicenseInfo/WMICLicenseInfoComponent';
import WMICMVRComponent
    from '../WMICMVRComponent/WMICMVRComponent';
import WMICDriverPolicyHistoryComponent
    from '../WMICDriverPolicyHistory/WMICDriverPolicyHistoryComponent';
import WMICCommercialDriverComponent
    from '../WMICDriverCommercialDriver/WMICCommercialDriverComponent';
import WMICDriveChangeTelematicsComponent
    from '../WMICDriveChangeTelematics/WMICDriveChangeTelematicsComponent';
import metadata from './WMICDriverDetailView.metadata.json5';
import messages from './WMICDriverDetailView.messages';

function DriverDetailView (props) {
    const { value: selectedDriverVM, updateModel, isEditing, readOnly, showErrors, onValidate, jobVM } = props;
    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const { registerComponentValidation } = useValidation('paDriversDetailsPage');
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);

    const phoneValidationChecksPassed = useCallback(() => {
        const driverPerson = _.get(selectedDriverVM, 'person.value');

        if (!driverPerson) {
            return false;
        }

        if (driverPerson.cellNumber && !WMICPhoneValidationUtil.validatePhone(driverPerson.cellNumber, 10)) {
            return false;
        }

        if (driverPerson.homeNumber && !WMICPhoneValidationUtil.validatePhone(driverPerson.homeNumber, 10)) {
            return false;
        }

        if (driverPerson.workNumber && !WMICPhoneValidationUtil.validatePhone(driverPerson.workNumber, 10)) {
            return false;
        }

        return true;
    }, [selectedDriverVM]);

    const isDriverClassHistoryValid = useCallback(() => {
        if ((selectedDriverVM.assignment_WMIC.value && ['Unassigned', 'Restricted', 'Excluded'].includes(selectedDriverVM.assignment_WMIC.value.code))
            ||
            (selectedDriverVM.licenseCountry_WMIC && selectedDriverVM.licenseCountry_WMIC.value && !['CA', 'US'].includes(selectedDriverVM.licenseCountry_WMIC.value.code))) {
            return true;
        }

        return !_.isEmpty(selectedDriverVM.driverClassHistory_WMIC.value);
    }, [selectedDriverVM]);

    const isDriverValid = useCallback(() => phoneValidationChecksPassed() && isDriverClassHistoryValid() && selectedDriverVM.aspects.subtreeValid, [selectedDriverVM, phoneValidationChecksPassed, isDriverClassHistoryValid]);

    const isUnassignedDriverValid = useCallback(() => phoneValidationChecksPassed() && selectedDriverVM.aspects.subtreeValid, [selectedDriverVM, phoneValidationChecksPassed]);

    const isMVRValid = useCallback(() => {
        let mvrValid = false;

        if (selectedDriverVM.orderMVR_WMIC.value) {
            if (selectedDriverVM.assignment_WMIC.value
                && selectedDriverVM.assignment_WMIC.value.code === 'Assigned'
                && WMICDriverUtil.isDomesticDriverLicense(selectedDriverVM)) {

                mvrValid = !!(selectedDriverVM.mvrReports_WMIC
                    && selectedDriverVM.mvrReports_WMIC.value
                    && selectedDriverVM.mvrReports_WMIC.value.length >= 1) || 
                    !_.isNil(selectedDriverVM.orderedMVRStatus_Ext.value?.code)
            } else {
                mvrValid = true;
            }
        } else {
            mvrValid = true;
        }

        return mvrValid;
    }, [selectedDriverVM]);

    const validateDriverForm = useCallback(() => isDriverValid() &&
        isUnassignedDriverValid() &&
        isMVRValid(), [isDriverValid, isMVRValid, isUnassignedDriverValid]);

    useEffect(() => {
        registerComponentValidation(validateDriverForm);
    }, [registerComponentValidation, validateDriverForm]);

    const getMVRTitle = useMemo(() => WMICJurisdictionUtil.isBaseState(jobVM.baseData, JURISDICTIONS.QUEBEC) ? messages.mvrQCTabHeading : messages.mvrTabHeading, [jobVM.baseData]);

    const commonDriverAccordionProps = {
        showErrors,
        jobVM,
        updateDriver: updateModel,
        isEditMode: isEditing,
        isReadOnlyUser: readOnly,
        driverVM: selectedDriverVM,
        baseData: _.get(jobVM, 'baseData'),
        onValidate: onValidateAccordion,
    }

    const overrideProps = {
        personalInfoAccordion: {
            ...commonDriverAccordionProps,
            isValid: isAccordionValid('personalInfoAccordionComponent')
        },
        personalInfoAccordionComponent: {
            accountHolder: _.get(jobVM, 'baseData.accountHolder.value'),
            ...commonDriverAccordionProps
        },
        licenseInfoAccordion: {
            ...commonDriverAccordionProps,
            isValid: isAccordionValid('licenseInfoAccordionComponent')
        },
        licenseInfoAccordionComponent: {
            ...commonDriverAccordionProps
        },
        mvrAccordion: {
            ...commonDriverAccordionProps,
            isValid: isAccordionValid('mvrAccordionComponent'),
            title: translator(getMVRTitle)
        },
        mvrAccordionComponent: {
            ...commonDriverAccordionProps,
            authHeader
        },
        policyHistoryAccordion: {
            ...commonDriverAccordionProps,
            isValid: isAccordionValid('policyHistoryAccordionComponent')
        },
        policyHistoryAccordionComponent: {
            ...commonDriverAccordionProps,
            authHeader
        },
        commercialDriversAccordion: {
            ...commonDriverAccordionProps,
            isValid: isAccordionValid('commercialDriverAccordionComponent')
        },
        commercialDriverAccordionComponent: {
            ...commonDriverAccordionProps
        },
        driveChangeTelematicsAccordion: {
            ...commonDriverAccordionProps,
            isValid: isAccordionValid('driveChangeTelematicsAccordionComponent'),
            visible: _.get(jobVM, 'baseData.isDriveChangeAvailable_WMIC.value', false)
        },
        driveChangeTelematicsAccordionComponent: {
            ...commonDriverAccordionProps,
            authHeader
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICDriverPersonalInfoComponent,
            WMICLicenseInfoComponent,
            WMICMVRComponent,
            WMICDriverPolicyHistoryComponent,
            WMICCommercialDriverComponent,
            WMICDriveChangeTelematicsComponent,
            Accordion
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={selectedDriverVM}
            overrideProps={overrideProps}
            onModelChange={updateModel}
            onValidationChange={onValidateAccordion}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    )
}

export default DriverDetailView;
