import React, { useContext, useState, useEffect } from 'react'
import _ from 'lodash'
import { ViewModelForm, ViewModelServiceContext, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import { CONSTANTS, WMICAddressDetailsUtil } from 'wmic-pe-portals-utils-js';
import { useValidation } from '@xengage/gw-portals-validation-react'


import metadata from './WMICPUPLocationExposureForm.metadata.json5';
import messages from '../WMICPUPLocationExposures.messages.js'

function WMICPUPLocationExposureForm(props) {
    const {
        id,
        exposureVM,
        setExposureVM,
        accountAddressOptions,
        setAccountAddressOptions,
        cancelExposure,
        saveExposure,
        isEditing,
        isParentUnderEditing,
        onValidate,
        showErrors,
        jobNumber
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const [currentAddress, setCurrentAddress] = useState();
    const { refreshData } = useDataRefresh();
    const { isComponentValid, onValidate: setComponentValidation } = useValidation(id);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, id]);

    const addLocation = () => {
        const address = viewModelService.create(
            { country: 'CA' },
            'pc',
            'wmic.edge.ca.capabilities.address.dto.AddressDTO'
        );
        setCurrentAddress(address);
    };

    const cancelLocation = () => {
        setCurrentAddress(undefined)
    };

    const updateExposure = (exposure) => {
        setExposureVM(exposure);
        refreshData();
    };

    const saveLocation = () => {
        const addressNumber = accountAddressOptions.length + 1;

        const addressDisplayName = `${addressNumber}: ${WMICAddressDetailsUtil.formatAddress(currentAddress)}`;

        const tempPublicID = `tempPublicId:${addressNumber}`;
        _.set(currentAddress, 'publicID.value', tempPublicID);
        _.set(currentAddress, 'displayName.value', addressDisplayName);

        _.set(currentAddress, 'value.name', addressDisplayName);
        _.set(currentAddress, 'value.code', tempPublicID);

        accountAddressOptions.push(currentAddress.value);
        _.set(exposureVM, 'address.value', currentAddress.value);
        _.set(exposureVM, 'accountLocationPublicID.value', tempPublicID);
        _.set(exposureVM, 'addressDisplayName.value', addressDisplayName);

        setExposureVM(exposureVM);
        setAccountAddressOptions(accountAddressOptions);
        setCurrentAddress(undefined);
    }

    const resolvers = {
        resolveComponentMap: {
            WMICAddressDetails
        },
        resolveCallbackMap: {
            addLocation
        }
    }
    const overrideProps = {
        '@field': {
            parentNode: exposureVM,
            readOnly: !isEditing || !isParentUnderEditing
        },
        locationForm: {
            addressVM: currentAddress,
            onSave: saveLocation,
            onCancel: cancelLocation,
            visible: !_.isUndefined(currentAddress),
            onValidate: setComponentValidation,
            showErrors,
            addressType: CONSTANTS.ADDRESS_TYPE.POLICY_LOCATION,
            jobNumber
        },
        locationExposureContainerButtons: {
            onCancel: cancelExposure,
            onSave: saveExposure,
            disableCancel: !_.isUndefined(currentAddress),
            disableSave: !_.isUndefined(currentAddress),
            isEditing: isEditing
        },
        accountLocationPublicID: {
            availableValues: accountAddressOptions
        },
        addAddressButton: {
            disabled: !_.isUndefined(currentAddress) || !isEditing
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={exposureVM}
            onModelChange={updateExposure}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

export default WMICPUPLocationExposureForm;