import React, { useCallback, useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { CONSTANTS, MODAL_CONSTANTS } from 'wmic-pe-portals-utils-js';
import { useWizardModals } from "wmic-pe-portals-wizard-components-ui";
import { PolicyUtilService, LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import metadata from './WMICAddWawanesaPolicyComponent.metadata.json5';
import messages from './WMICAddWawanesaPolicyComponent.messages';
import styles from './WMICAddWawanesaPolicyComponent.module.scss';

const PERSONALAUTOLINE_CODE = "PersonalAutoLine";

function WMICAddWawanesaPolicyComponent(props) {
    const { id, policy, onValidate, savePolicy, cancelPolicy, checkWawanesaPolicyExists, quoteId, showErrors: pShowErrors } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const translator = useContext(TranslatorContext);
    const { showError } = useWizardModals();
    const { authHeader } = useAuthentication();
    const { refreshData } = useDataRefresh();

    const [currentPolicy, updateCurrentPolicy] = useState(policy);
    const [showErrors, updateShowErrors] = useState(false);
    const [isPMSPolicyNumber, updateIsPMSPolicyNumber] = useState(false);
    const [invalidPolicyNumberError, updateInvalidPolicyNumberError] = useState(false);
    const [searchFailedError, updateSearchFailedError] = useState(false);
    const [isAutoProductType, updateIsAutoProductType] = useState(false);
    const [availableRiskTypes, updateAvailableRiskTypes] = useState([]);
    const [originalPolicyNumber, setOriginalPolicyNumber] = useState(_.get(policy, "pmsOrPCPolicyNumber.value"));

    const checkIsAutoProduct = (code) => code === PERSONALAUTOLINE_CODE;

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        return () => onValidate ? onValidate(true, id) : undefined;
    }, [id, onValidate, isComponentValid]);

    const checkPolicyExists = useCallback(policyNumber => {
        updateInvalidPolicyNumberError(false);
        if (checkWawanesaPolicyExists(policyNumber)) {
            // If we are editing existing policy, and the policy number hasn't changed,
            // this policy number already exists, allow the user to continue
            if (originalPolicyNumber && originalPolicyNumber === policyNumber) {
                return false;
            }
            // There is already a policy (PMS or PC) with this policy number
            // Do not allow the user to add this policy again
            showError({
                message: translator(messages.policyAlreadyAdded, { policyNumber }),
                status: MODAL_CONSTANTS.STATUS.ERROR,
                icon: MODAL_CONSTANTS.ICON.ERROR,
            });
            return true;
        }
        return false;
    }, [checkWawanesaPolicyExists, originalPolicyNumber, showError, translator]);

    const isValidPMSPolicyNumber = policyNumber => policyNumber && /^\d{7}$/.test(policyNumber.trim());

    const isValidPolicyNumber = policyNumber => policyNumber && /^\d{7,}$/.test(policyNumber.trim());

    const handleSavePolicy = useCallback(() => {
        const policyNumber = policy?.pmsOrPCPolicyNumber?.value;
        if (!checkPolicyExists(policyNumber)) {
            if (isValidPolicyNumber(policyNumber)) {
                const isPMSPolicy = isValidPMSPolicyNumber(policyNumber);
                _.set(policy, 'pmsPolicyNumber.value', isPMSPolicy ? policyNumber : null);
                _.set(policy, 'policyNumber.value', !isPMSPolicy ? policyNumber : null);
                savePolicy(currentPolicy);
            } else {
                updateInvalidPolicyNumberError(true);
                updateShowErrors(true);
            }
        }
    }, [checkPolicyExists, currentPolicy, policy, savePolicy]);

    const updatePolicy = useCallback((data) => {
        refreshData();
        updateCurrentPolicy(data);
    }, [refreshData]);

    const setAvailableRiskTypes = useCallback((riskTypesFound) => {
        const riskTypesList = [];

        _.each(riskTypesFound, (option, index) => {
            riskTypesList.push({code: option, name: option});
        });

        const defaultOptionLabel = translator(riskTypesList.length <= 1 ? messages.none : messages.pleaseSelect);

        const containsNoneOption = _.find(riskTypesList, (item) => { return item.code === "" });
        if (!containsNoneOption) {
            riskTypesList.unshift(
                {
                    code: "",
                    name: defaultOptionLabel
                }
            );
        } else {
            containsNoneOption.name = defaultOptionLabel;
        }
        updateAvailableRiskTypes(riskTypesList)
    }, [translator]);

    const getRiskValuesByProduct = useCallback(
        (productType, isPCPolicy) => PolicyUtilService.getRiskValuesByProduct(productType, isPCPolicy, authHeader),
        [authHeader]
    );

    const policyNumberOnSearch = useCallback(() => {
        updateSearchFailedError(false);
        const policyNumber = _.get(policy, "pmsOrPCPolicyNumber.value");
        if (!checkPolicyExists(policyNumber)) {
            let invalidSearch = false;
            let isPMSPolicy = false;
            if (isValidPMSPolicyNumber(policyNumber)) {
                isPMSPolicy = true;
            } else if (isValidPolicyNumber(policyNumber)) {
                const retrieveOtherInforceWawanesaPolicyPromise = LoadSaveService.retrieveOtherInforceWawanesaPolicy(policyNumber, quoteId, authHeader);
                retrieveOtherInforceWawanesaPolicyPromise.then((response) => {
                    if (response === undefined || _.isEmpty(response)) {
                        updateSearchFailedError(true);
                        updateShowErrors(true);
                    } else {
                        getRiskValuesByProduct(response.productType, !isPMSPolicy)
                            .then((riskTypesFound) => {
                                setAvailableRiskTypes(riskTypesFound);
                                _.set(policy, "productType.value", response.productType);
                                _.set(policy, "riskType.value", response.riskType);
                                updatePolicy(policy);
                            });
                    }
                })
                .catch((error) => {
                    if (error.message === CONSTANTS.POLICY_NUMBER_MATCHES_CURRENT_POLICY) {
                        showError({
                            message: translator(messages.policyMatchesCurrentPolicy),
                            status: 'error',
                        });
                    } else {
                        updateSearchFailedError(true);
                        updateShowErrors(true);
                    }
                })
            } else {
                invalidSearch = true;
            }
            updateInvalidPolicyNumberError(invalidSearch);
            updateIsPMSPolicyNumber(isPMSPolicy);
            if (policyNumber) {
                const productType = _.get(policy, "productType.value");
                if(productType) {
                    getRiskValuesByProduct(productType.code, !isPMSPolicy).then((riskTypesFound) => {
                        setAvailableRiskTypes(riskTypesFound);
                    });
                }
            }
        }
    }, [authHeader, checkPolicyExists, getRiskValuesByProduct, policy, quoteId, setAvailableRiskTypes, updatePolicy, showError, translator]);

    const onProductTypeChange = useCallback((value) => {
        let isAutoProduct = false;
        _.set(policy, "productType.value", value);
        if (value) {
            getRiskValuesByProduct(value, isPMSPolicyNumber).then((riskTypesFound) => {
                setAvailableRiskTypes(riskTypesFound);
            });

            isAutoProduct = checkIsAutoProduct(value);
        }
        _.set(policy, "riskType.value", "");
        _.unset(policy.value, "multiVehicleDiscountType");
        updatePolicy(policy);
        updateIsAutoProductType(isAutoProduct);
    }, [getRiskValuesByProduct, isPMSPolicyNumber, policy, setAvailableRiskTypes, updatePolicy]);

    const getPolicyNumberErrorMessage = () => {
        if (invalidPolicyNumberError) {
            return [translator(messages.invalidPolicyNumberError)];
        }
        if (searchFailedError) {
            return [translator(messages.policyNotFoundError)];
        }
        return [];
    };

    useEffect(() => {
        const policyNumber = _.get(policy, 'pmsOrPCPolicyNumber.value');
        const isPMSPolicy = isValidPMSPolicyNumber(policyNumber);
        updateIsPMSPolicyNumber(isPMSPolicy);
        if (policyNumber) {
            const productType = _.get(policy, 'productType.value');
            if (productType) {
                getRiskValuesByProduct(productType.code, !isPMSPolicy).then(riskTypesFound => {
                    setAvailableRiskTypes(riskTypesFound);
                });
                const isAutoProduct = checkIsAutoProduct(productType.code);
                updateIsAutoProductType(isAutoProduct);
            }
        }
    }, []);

    const overrideProps = {
        '@field': {
            parentNode: policy,
        },
        pmsOrPCPolicyNumber: {
            validationMessages: getPolicyNumberErrorMessage(),
        },
        productType: {
            disabled: !isPMSPolicyNumber,
            onValueChange: onProductTypeChange
        },
        riskType: {
            visible: !isPMSPolicyNumber,
            disabled: !isPMSPolicyNumber
        },
        riskTypeDropdownselect: {
            visible: isPMSPolicyNumber,
            availableValues: availableRiskTypes
        },
        multiVehicleDiscountType: {
            visible: isPMSPolicyNumber && isAutoProductType
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleSavePolicy,
            cancelPolicy,
            policyNumberOnSearch
        }
    };

    return (
        <ViewModelForm
            model={policy}
            onModelChange={updatePolicy}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors || pShowErrors}
        />
    );
}

export default WMICAddWawanesaPolicyComponent;
