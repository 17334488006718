import { defineMessages } from 'react-intl';

export default defineMessages({
    policies: {
        id: 'portal.views.custom-footer.policies',
        defaultMessage: 'Policies'
    },
    aboutWawanesa: {
        id: 'portal.views.custom-footer.aboutWawanesa',
        defaultMessage: 'About Wawanesa'
    },
    privacyPolicy: {
        id: 'portal.views.custom-footer.privacyPolicy',
        defaultMessage: 'Privacy Policy'
    },
    privacyPolicyTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.privacyPolicy',
        defaultMessage: 'Footer - Privacy Policy'
    },
    privacyNotice: {
        id: 'portal.views.custom-footer.privacyNotice',
        defaultMessage: 'Privacy Notice'
    },
    privacyNoticeTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.privacyNotice',
        defaultMessage: 'Footer - Privacy Notice'
    },
    termsOfUse: {
        id: 'portal.views.custom-footer.termsOfUse',
        defaultMessage: 'Terms of Use'
    },
    termsOfUseTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.termsOfUse',
        defaultMessage: 'Footer - Terms of Use'
    },
    onlinePaymentTerms: {
        id: 'portal.views.custom-footer.onlinePaymentTerms',
        defaultMessage: 'Online Payment Terms'
    },
    onlinePaymentTermsTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.onlinePaymentTerms',
        defaultMessage: 'Footer - Online Payment Terms'
    },
    accessibility: {
        id: 'portal.views.custom-footer.accessibility',
        defaultMessage: 'Accessibility'
    },
    accessibilityTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.accessibility',
        defaultMessage: 'Footer - Accessibility'
    },
    californiaCustomers: {
        id: 'portal.views.custom-footer.californiaCustomers',
        defaultMessage: 'California Members'
    },
    california: {
        id: 'portal.views.custom-footer.california',
        defaultMessage: 'California'
    },
    requestAnAutoQuote: {
        id: 'portal.views.custom-footer.requestAnAutoQuote',
        defaultMessage: 'Request an Auto Quote'
    },
    requestAnAutoQuoteTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.requestAnAutoQuote',
        defaultMessage: '{region} - Request An Auto Quote'
    },
    requestACondominiumQuote: {
        id: 'portal.views.custom-footer.requestACondominiumQuote',
        defaultMessage: 'Request a Condominium Quote'
    },
    requestACondominiumQuoteTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.requestACondominiumQuote',
        defaultMessage: '{region} - Request a Condominium Quote'
    },
    requestARentersQuote: {
        id: 'portal.views.custom-footer.requestARentersQuote',
        defaultMessage: 'Request a Renters Quote'
    },
    requestARentersQuoteTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.requestARentersQuote',
        defaultMessage: '{region} - Request a Renters Quote'
    },
    requestAHomeownersQuote: {
        id: 'portal.views.custom-footer.requestAHomeownersQuote',
        defaultMessage: 'Request a Homeowners Quote'
    },
    requestAHomeownersQuoteTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.requestAHomeownersQuote',
        defaultMessage: '{region} - Request a Homeowners Quote'
    },
    changeYourPolicy: {
        id: 'portal.views.custom-footer.changeYourPolicy',
        defaultMessage: 'Change Your Policy'
    },
    submitAnAutoClaim: {
        id: 'portal.views.custom-footer.submitAnAutoClaim',
        defaultMessage: 'Submit an Auto Claim'
    },
    submitAnAutoClaimTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.submitAnAutoClaim',
        defaultMessage: '{region} - Submit an Auto Claim'
    },
    submitAResidentialClaim: {
        id: 'portal.views.custom-footer.submitAResidentialClaim',
        defaultMessage: 'Submit a Residential Claim'
    },
    submitAResidentialClaimTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.submitAResidentialClaim',
        defaultMessage: '{region} - Submit a Residential Claim'
    },
    oregonCustomers: {
        id: 'portal.views.custom-footer.oregonCustomers',
        defaultMessage: 'Oregon Members'
    },
    oregon: {
        id: 'portal.views.custom-footer.oregon',
        defaultMessage: 'Oregon'
    },
    copyright: {
        id: 'portal.views.custom-footer.copyright',
        defaultMessage: 'Copyright 1996-{year} Wawanesa Insurance'
    },
    wawanesaSubscript: {
        id: 'portal.views.custom-footer.wawanesaSubscript',
        defaultMessage: '"Wawanesa Insurance" is a trademark of Wawanesa General Insurance Company'
    },
    wawanesaComUs: {
        id: 'portal.views.custom-footer.wawanesaComUs',
        defaultMessage: 'wawanesa.com/us'
    },
    newsBoard: {
        id: 'portal.views.custom-footer.newsBoard',
        defaultMessage: 'News Board'
    },
    amBestImgAltText: {
        id: 'portal.views.custom-footer.amBestImgAltText',
        defaultMessage: 'AM Best Financial Strength Rating: A+ Superior'
    },
    contactUs: {
        id: 'portal.views.custom-footer.contactUs',
        defaultMessage: 'Contact Us'
    },
    recaptchaPolicy: {
        id: 'portal.views.custom-footer.recaptchaPolicyMessage',
        defaultMessage: 'This site is protected by reCAPTCHA and the Google &lt;a href="https://policies.google.com/privacy"&gt;Privacy Policy&lt;/a&gt; and &lt;a href="https://policies.google.com/terms"&gt;Terms of Service&lt;/a&gt; apply.'
    },
    trademarkInfo: {
        id: 'portal.views.custom-footer.enrollmentTrademarkInfo',
        defaultMessage: '"Wawanesa Insurance" is a trademark of Wawanesa General Insurance Company'
    },
    enrollmentCopyright: {
        id: 'portal.views.custom-footer.enrollmentCopyright',
        defaultMessage: 'Copyright 1996 - {currentYear}'
    },
    fileAClaimOnline: {
        id: 'portal.views.custom-footer.fileAClaimOnline',
        defaultMessage: 'File a Claim'
    },
    fileAClaimOnlineTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.fileAClaimOnline',
        defaultMessage: '{region} - File a Claim'
    },
    viewPreferredAutoshops: {
        id: 'portal.views.custom-footer.viewPreferredAutoshops',
        defaultMessage: 'Preferred Auto Shops'
    },
    viewPreferredAutoshopsTrackButtonIdentifier: {
        id: 'portal.views.custom-footer.tracking.viewPreferredAutoshops',
        defaultMessage: '{region} - Preferred Auto Shops'
    },
});
