import { defineMessages } from 'react-intl';

export default defineMessages({
    noPriorLossesMsg: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.noPriorLosses',
        defaultMessage: 'No losses have been added'
    },
    driverName: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.driverName',
        defaultMessage: 'Driver'
    },
    policyNumber: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.policyNumber',
        defaultMessage: 'Policy #'
    },
    claimNumber: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.claimNumber',
        defaultMessage: 'Claim Number'
    },
    atFault: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.atFault',
        defaultMessage: 'At Fault'
    },
    lossDate: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.lossDate',
        defaultMessage: 'Date of Loss'
    },
    no: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.no',
        defaultMessage: 'No'
    },
    yes: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.yes',
        defaultMessage: 'Yes'
    },
    reportedDriver: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.reportedDriver',
        defaultMessage: 'Reported Driver'
    },
    vehicleChargeable: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.Chargeable',
        defaultMessage: 'Chargeable?'
    },
    vehicleUseForRating: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.UseForRating',
        defaultMessage: 'Use for rating?'
    },
    vehicleRatingOverrideApplied: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.RatingOverrideApplied',
        defaultMessage: 'Override Applied?'
    },
    vehicleRatingReason: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.RatingReason',
        defaultMessage: 'Reason'
    },
    FCSASuccess: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.FCSASuccess',
        defaultMessage: 'Success'
    },
    FCSANotRequested: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.FCSANotRequested',
        defaultMessage: 'Not Requested'
    },
    FCSAError: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.FCSAError',
        defaultMessage: 'Error'
    },
    FCSADriverNameError: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.FCSADriverNameError',
        defaultMessage: '{driverName} on {date} - An error occurred, please try again.'
    },
    FCSADriverNameSuccess: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.FCSADriverNameSuccess',
        defaultMessage: '{driverName} on {date}'
    },
    FCSALossHistoryQC: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.FCSALossHistoryQC',
        defaultMessage: 'FCSA: Loss History For Quebec Drivers'
    },
    FCSAProvided: {
        id: 'wmic-pe-capability-policy-common-react.WMICPAPolicyLossHistory.FCSAProvided',
        defaultMessage: 'Manually add claims information? (FCSA Provided)'
    }
});