import React, {
    useCallback,
    useContext,
    useMemo,
    useState
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals, useDocumentTitle } from 'wmic-pe-portals-wizard-components-ui';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICUserAccessUtil, WMICValidationUtil } from 'wmic-pe-portals-utils-js';
import { WMICPolicyDetailsComponent } from 'wmic-pe-capability-gateway-common-react';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';

function WMICHOPolicyRenewalPolicyDetailsPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData
    } = props;

    const { RenewalService } = useDependencies('RenewalService');
    const { setWizardLoading } = useWizardModals();
    const translator = useContext(TranslatorContext);
    const { authHeader, authUserData } = useAuthentication();
    const [showErrors, setShowErrors] = useState(false);

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        initialValidation,
    } = useValidation('WMICHOPolicyDetailsPage');

    useDocumentTitle(translator(commonMessages.policyDetailsTitle), renewalVM);

    const canEditRenewal = useMemo(() => WMICUserAccessUtil.canEditRenewal(authUserData.permission_Ext), [authUserData.permission_Ext]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }
        try {
            setWizardLoading(true, translator(commonMessages.savingTransactionDetails));

            const newRenewalVM = _.cloneDeep(renewalVM.value);

            renewalVM.value = await RenewalService.saveRenewal(
                [newRenewalVM],
                authHeader
            );
            updateWizardData(renewalVM);

            return renewalVM;
        } finally {
            setWizardLoading(false);
        }
    }, [isComponentValid, setWizardLoading, translator, renewalVM, RenewalService, authHeader, updateWizardData]);

    return (
        <WMICWizardChangeOrRenewalPage
            showPrevious={false}
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
            showRequired
        >
            <WMICPolicyDetailsComponent
                jobVM={renewalVM}
                updateWizardData={updateWizardData}
                onValidate={setComponentValidation}
                isReadOnly={!canEditRenewal}
                authHeader={authHeader}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICHOPolicyRenewalPolicyDetailsPage.propTypes = wizardProps;

export default withViewModelService(WMICHOPolicyRenewalPolicyDetailsPage);
