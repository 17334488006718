import { defineMessages } from 'react-intl';

export default defineMessages({
    other: {
        id: 'quoteandbind.views.payment-details.other',
        defaultMessage: 'other'
    },
    payer: {
        id: 'quoteandbind.views.payment-details.Who\'s making the payment',
        defaultMessage: 'Who\'s making the payment?'
    },
    selectPayer: {
        id: 'quoteandbind.controllers.PaymentDetailsCtrl.Please select who\'s making the payment',
        defaultMessage: "Please select who's making the payment"
    },
    emailUpdateHint: {
        id: 'quoteandbind.views.payment-details.This email can be updated in Your Info',
        defaultMessage: 'This email can be updated in Your Info.'
    },
    withdrawalDate: {
        id: 'platform.paymentdetails.bank-account-details.Withdrawal Date of Month',
        defaultMessage: 'Which day of the month would you prefer your payment to be withdrawn?'
    },
    withdrawalMessage: {
        id: 'platform.paymentdetails.bank-account-details.First withdrawal next month will be double',
        defaultMessage: 'Based on the Withdrawal Date of Month selected, your first invoice will be due on {dueDate} and will include two installment amounts.'
    },
    email: {
        id: 'platform.paymentdetails.bank-account-details.Email',
        defaultMessage: 'Email'
    }
});