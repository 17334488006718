import React, { useCallback, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICRichTextUtil, CONSTANTS } from 'wmic-portals-utils-js';

import messages from './WMICLogoutConfirmationPage.messages';
import styles from './WMICLogoutConfirmationPage.module.scss';

const WMICLogoutConfirmationPage = () => {
    const translator = useTranslator();
    const history = useHistory();

    const onButtonClick = useCallback(() => {
        return history.push('/');
    }, []);

    const logoutReason = localStorage.getItem('logout_reason');
    localStorage.removeItem('logout_reason');
    localStorage.removeItem(CONSTANTS.AMP_LOCAL_STORAGE.USER_lOGGEDIN.NAME);
    
    return (
        <Fragment>
            <div className="ww-content-limit ww-page-header">
                <div className="ww-card--100w">
                    <h1>{translator(messages.logoutConfirmationPageTitle)}</h1>
                </div>
            </div>

            <div className="ww-content-limit ww-main-content" role="main">
                <div className={`${styles.wwCard66w} ww-card-vertical-root`}>
                    <div className="ww-card ww-card-footer">
                        <div className="ww-card__content">
                            <div>
                                {logoutReason === CONSTANTS.AMP_LOCAL_STORAGE.LOGOUT_REASON.LOGOUT ? <p>{translator(messages.logoutConfirmationPageDescription)}</p> : <p>{translator(messages.logoutConfirmationInactivityDes)}</p>}
                            </div>
                            <div>
                                <WMICButton type="primary" onClick={onButtonClick}>
                                    {translator(messages.logoutConfirmationPageButtonText)}
                                </WMICButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

WMICLogoutConfirmationPage.propTypes = {};

export default WMICLogoutConfirmationPage;