import { defineMessages } from 'react-intl';

export default defineMessages({
    cancellationDetailsTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetails.WMICCancellationDetailsPage.Title',
        defaultMessage: 'Cancellation Details',
    },
    cancelPolicyHeader: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.cancelPolicyHeader',
        defaultMessage: 'Cancel Policy',
    },
    editAccount: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.editAccount',
        defaultMessage: 'Edit account'
    },
    withdrawCancellationButton: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.withdrawCancellationButton',
        defaultMessage: 'Withdraw'
    },
    submitCancellationButton: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.submitCancellationButton',
        defaultMessage: 'Submit'
    },
    underwritingIssues: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.cancellationUnderwritingIssuesCardTitle',
        defaultMessage: 'Underwriting Issues'
    },
    underwriterMustReview: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.uwMustReviewTitle',
        defaultMessage: 'An underwriter must review the following:'
    },
    noOutstandingUWIssues: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.noOutstandingUWIssuesTitle',
        defaultMessage: 'There are no outstanding underwriting issues'
    },
    personalAuto: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.personalAuto',
        defaultMessage: 'Automobile Submission ({jobNumber})'
    },
    personalUmbrella: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.personalUmbrella',
        defaultMessage: 'Personal Umbrella Submission ({jobNumber})'
    },
    homeowners: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.homeowners',
        defaultMessage: 'Personal Property Submission ({jobNumber})'
    },
    policyCancelDraftBody: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.policyCancelDraftBody',
        defaultMessage: 'The policy cancellation was successfully started.'
    },
    policyCancelQuotedUWBody: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.policyCancelQuotedUWBody',
        defaultMessage: 'You cannot complete this Policy Cancellation until these issues have been resolved.'
    },
    policyCancelWithdrawnBody: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.policyCancelWithdrawnBody',
        defaultMessage: 'This policy cancellation has been withdrawn.'
    },
    policyCancelQuotedBody: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.policyCancelReadyToSubmitBody',
        defaultMessage: 'This cancellation was successfully quoted. You can either Withdraw or Submit the cancellation.'
    },
    withdrawPolicyCancelBody: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.withdrawPolicyCancelBody',
        defaultMessage: 'Are you sure you want to withdraw this policy cancellation?'
    },
    policyCancelDraftHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.policyCancelDraftHeading',
        defaultMessage: 'Draft'
    },
    policyCancelQuotedUWHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.policyCancelQuotedUWHeading',
        defaultMessage: 'Underwriting issues have been raised for this cancellation'
    },
    policyCancelWithdrawnHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.policyCancelWithdrawnHeading',
        defaultMessage: 'This policy cancellation has been withdrawn.'
    },
    policyCancelQuotedHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.policyCancelReadyToSubmitHeading',
        defaultMessage: 'Ready to Submit'
    },
    withdrawPolicyCancelHeading: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.withdrawPolicyCancelHeading',
        defaultMessage: 'Withdraw'
    },
    underwriterApprovalPending: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Quoted: Underwriter Approval Pending',
        defaultMessage: 'Quoted: Underwriter Approval Pending'
    },
    refund: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Refund',
        defaultMessage: 'Refund'
    },
    shortRatedPremium: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Short rated premium',
        defaultMessage: 'Short rated premium'
    },
    proRatedPremium: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Pro rated premium',
        defaultMessage: 'Pro rated premium'
    },
    amountReceived: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Amount received',
        defaultMessage: 'Amount received'
    },
    difference: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Difference',
        defaultMessage: 'Difference'
    },
    cancellationSummary: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Summary',
        defaultMessage: 'Summary'
    },
    policyNumber: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Policy Number',
        defaultMessage: 'Policy Number'
    },
    policyTerm: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Policy Term',
        defaultMessage: 'Policy Term'
    },
    policyCancellationStatus: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Policy Cancellation Status',
        defaultMessage: 'Policy Cancellation Status'
    },
    policyCancellationEffectiveDate: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Policy cancellation effective date',
        defaultMessage: 'Policy cancellation effective date'
    },
    withdrawCancellation: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Withdraw Cancellation',
        defaultMessage: 'Withdraw Cancellation'
    },
    withdrawCancellationMessage: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Are you sure you want to withdraw this cancellation?',
        defaultMessage: 'Are you sure you want to withdraw this cancellation?'
    },
    submitCancellation: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Submit Cancellation',
        defaultMessage: 'Submit Cancellation'
    },
    bindCancellationMessage: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.The cancellation will be bound. Would you like to continue?',
        defaultMessage: 'The cancellation will be bound. Would you like to continue?'
    },
    quoteCancellationMessage: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.The cancellation will be quoted. Would you like to continue?',
        defaultMessage: 'The cancellation will be quoted. Would you like to continue?'
    },
    editQuoteOrRefer: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.You can either edit the quote with the customers permission, or refer it to the underwriter',
        defaultMessage: 'You can either edit the quote with the customers permission, or refer it to the underwriter'
    },
    notesToUnderwriter: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Notes to the underwriter',
        defaultMessage: 'Notes to the underwriter'
    },
    referToUnderwriter: {
        id: 'wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Refer to Underwriter',
        defaultMessage: 'Refer to Underwriter'
    },
    notesPlaceholder: {
        id: "wmic-pe-capability-gateway-react.WMICCancellationDetailsPage.Notes written here will be seen by the underwriter",
        defaultMessage: "Notes written here will be seen by the underwriter..."
    }
});
