import { defineMessages } from 'react-intl';

export default defineMessages({
    insuranceHistoryTitle: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pa-react.pages.WMICPAInsuranceHistory.WMICPAInsuranceHistoryPage.Title',
        defaultMessage: 'Insurance History'
    },
    policies: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.policies',
        defaultMessage: 'Policies'
    },
    lossHistory: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.lossHistory',
        defaultMessage: 'Loss History'
    },
    creditConsent: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.creditConsent',
        defaultMessage: 'Credit Consent'
    },
    broker: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.broker',
        defaultMessage: 'Broker'
    },
    saveInsuranceHistory: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.saveInsuranceHistory',
        defaultMessage: 'Save Insurance History'
    },
    savingInsuranceHistory: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.savingInsuranceHistory',
        defaultMessage: 'Saving Insurance History'
    },
});
