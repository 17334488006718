/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import appConfig from 'app-config';
import PropTypes from 'prop-types';

import { TranslatorContext } from '@jutro/locale';
import { Flex } from '@jutro/layout';

import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { MockUpUtil } from '@xengage/gw-portals-util-js';

import { CONSTANTS, WMICValidationUtil, WMICVariousUtil, FlowStepId } from 'wmic-pe-portals-utils-js';
import { WMICScrollToError, WMICDropdownMenuButton } from 'wmic-pe-components-platform-react';
import { useWizardModals, useDocumentTitle, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui'
import { WMICGLCrimeCoverages } from 'wmic-pe-capability-gateway-quoteandbind-gl-react';
import { WMICImCoverages } from 'wmic-pe-capability-gateway-quoteandbind-im-react';
import { WMICCPAdditionalInterestDetailView } from "wmic-pe-capability-gateway-quoteandbind-cp-react"

import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICGLAdditionalCoveragesPage.metadata.json5';
import messages from './WMICGLAdditionalCoveragesPage.messages';
import styles from './WMICGLAdditionalCoveragesPage.module.scss';

const MOCK_DATA_TO_REMOVE = [
    'bindData.contactPhone',
    'bindData.contactEmail'
];

const structureCustomQuote = (submissionVM, affectedQuote, clauses) =>
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        quote: affectedQuote,
        quoteID: submissionVM.quoteID.value,
        sessionUUID: submissionVM.sessionUUID.value,
        periodStart: submissionVM.baseData.periodStartDate.value,
        periodEnd: submissionVM.baseData.periodEndDate.value,
        coverages: clauses
    })
;

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        // eslint-disable-next-line max-len
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

function WMICGLAdditionalCoveragesPage(props) {
    const { wizardSnapshot, wizardData: submissionVM, updateWizardData, underwritingIssues } = props;
    const translator = useContext(TranslatorContext);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState(false); // we toggle this when we want to scroll to the first error on the page

    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { setWizardLoading, showConfirm } = useWizardModals();
    const { authHeader } = useAuthentication();
    const { onValidate,
        disregardFieldValidation,
        initialValidation,
        registerInitialComponentValidation,
        isComponentValid
    } = useValidation("cpPropertyPage");

    const showQuoteStartDate = appConfig.showQuoteStartDateInHeader;
    const viewModelService = useContext(ViewModelServiceContext);
    const [initialSubmissionVM, setInitialSubmissionVM] = useState(undefined);
    const [showErrorsAddlInt, setShowErrorsAddlInt] = useState(false)

    useDocumentTitle(translator(messages.wizard), submissionVM);

    const validateTableForm = useCallback(() =>
        WMICValidationUtil.validatePageEntities(submissionVM, [
            "lobData.inlandMarine.machineryEquipAddlInterests",
            "lobData.inlandMarine.toolsAddlInterests"
        ]) && !WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.CP_ADDITIONALCOVERAGES)
    , [submissionVM]);

    useEffect(() => {
        registerInitialComponentValidation(validateTableForm);
    }, [registerInitialComponentValidation, validateTableForm]);

    useEffect(() => {
        const offerings = _.get(submissionVM, 'quoteData.offeredQuotes.value');
        const draftOffers = _.filter(offerings, ['status', 'Draft']);
        const draftBranchCodes = _.map(draftOffers, 'branchCode');
        setStaleQuoteBranchCode(draftBranchCodes);
        // Store the initial SubmissionVM when component is mounted
        setInitialSubmissionVM(viewModelService.clone(wizardSnapshot));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.CP_ADDITIONALCOVERAGES;
    }, [submissionVM.value]);

    // reset show errors to false whenever the component is back to a valid state or we stop editing
    useEffect(() => {
        if (isComponentValid) {
            setShowErrors(false);
        }
    }, [isComponentValid]);

    const getRemovedFieldsForLob = useCallback((lobName, lobPath, newSubmissionVM) => {

        let allRemovedFields = [];
        switch(lobName) {
            case 'inlandMarine':
                const removedFieldsFromLineCoverages = ClausesUtil.getRemovedClausesID(
                    submissionVM,
                    newSubmissionVM,
                    `${lobPath}.coverages.lineCoverages`
                );

                const removedFieldsFromToolsCoverages = ClausesUtil.getRemovedClausesID(
                    submissionVM,
                    newSubmissionVM,
                    `${lobPath}.coverages.toolsCoverages`
                );

                const removedFieldsFromEquipmentCoverages = ClausesUtil.getRemovedClausesID(
                    submissionVM,
                    newSubmissionVM,
                    `${lobPath}.coverages.equipmentCoverages`
                )

                allRemovedFields = [
                    ...removedFieldsFromLineCoverages,
                    ...removedFieldsFromToolsCoverages,
                    ...removedFieldsFromEquipmentCoverages
                ];

                break;
            case 'generalLiability':

                const removedFieldsFromCrimeLineCoverages = ClausesUtil.getRemovedClausesID(
                    submissionVM,
                    newSubmissionVM,
                    `${lobPath}.coverages.commercialCrimeCoverages`
                );

                allRemovedFields = [
                    ...removedFieldsFromCrimeLineCoverages,
                ];

                break;
            default:
                break;
        }

        return allRemovedFields;
    }, [submissionVM]);

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName, true);

            return CustomQuoteService.updateCustomQuoteCoveragesAndQuoteData(customQuote, submissionVM.value, authHeader).then(
                (response) => {
                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    newSubmissionVM.value = response;

                    const allRemovedFields = getRemovedFieldsForLob(lobName, lobPath, newSubmissionVM);

                    disregardFieldValidation(allRemovedFields);
                    updateWizardData(newSubmissionVM);
                }
            );
        },
        [
            submissionVM,
            CustomQuoteService,
            authHeader,
            viewModelService,
            disregardFieldValidation,
            updateWizardData,
            getRemovedFieldsForLob
        ]
    );

    const syncCoverages = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            return onClauseChange(basePath, lobPath, quotePath);
        },
        [onClauseChange]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(submissionVM, value, changedPath));
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissionVM]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath, lobPath, quotePath) => {
            changeSubmission(value, changedPath);
            return syncCoverages(value, changedPath, lobPath, quotePath);
        },
        [changeSubmission, syncCoverages]
    );

    const resetQuote = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(initialSubmissionVM, lobPath, quotePath, lobName);

            return CustomQuoteService.forceUpdateCustomQuoteCoveragesAndQuoteData(customQuote, initialSubmissionVM.value, authHeader).then(
                (response) => {
                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    newSubmissionVM.value = response;
                    updateWizardData(newSubmissionVM);
                }
            );
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [initialSubmissionVM, submissionVM, CustomQuoteService, authHeader]
    );

    const recalculate = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, quotePath, lobName);

            return CustomQuoteService.updateCustomQuoteAndQuoteData(customQuote, authHeader).then(
                (response) => {
                    const newSubmissionVM = viewModelService.clone(submissionVM);
                    newSubmissionVM.value = response;
                    updateWizardData(newSubmissionVM);
                    return response;
                }
            );
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissionVM, CustomQuoteService, authHeader]
    );

    const handlePrint = useCallback(() => {
        window.print();
    }, []);

    const renderAddAIButton = useCallback(({ isEditing, onClick }) =>
        (<Flex className="gw-mb-6" justifyContent="left">
            <WMICDropdownMenuButton
                id="addNewAdditionalInsuredButton"
                type="secondary"
                size="medium"
                buttonText={translator(messages.addAdditionalInterest)}
                alignRight
                onItemClick={onClick}
                items={
                    [
                        { id: 'newPerson', text: translator(messages.person), code: _.lowerCase(CONSTANTS.Person), icon: 'mi-person' },
                        { id: 'newCompany', text: translator(messages.company), code: _.lowerCase(CONSTANTS.Company), icon: 'mi-home' },
                    ]
                }
                disabled={isEditing}
            />
        </Flex>)
    , []);

    const toCreateClicked = useCallback((contactType) => {
        const additionalInterestAddress = {
            country: CONSTANTS.COUNTRY.CA,
            city: '',
            state: '',
            postalCode: '',
        };
        const newVM = viewModelService.create(
            {
                'contactType': contactType,
                'locationAddress': additionalInterestAddress,
                'isEditable': true,
                'subType': CONSTANTS.POLICY_ADDL_INTEREST,
                'detailType': CONSTANTS.CPIM_MCEQUIP_PART_ADDL_INTEREST
            },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.commercialproperty.coverables.dto.CPAdditionalInterest_WMICDTO',
            submissionVM.aspects.context()
        );
        submissionVM.value.lobData.inlandMarine.machineryEquipAddlInterests.push(newVM.value)

        return newVM
    }, [viewModelService, submissionVM.aspects, submissionVM.value.lobData.inlandMarine.machineryEquipAddlInterests]);

    const saveSubmission = useCallback(async (newSubmission) => {
        setWizardLoading(true);

        return LoadSaveService.updateDraftSubmission(
            newSubmission,
            authHeader
        ).then((result) => {
            submissionVM.value = result;
            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM)) {
                WMICVariousUtil.scrollToTop();
            }

            return true;
        }).finally(() => {
            setWizardLoading(false);
        });
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [LoadSaveService, authHeader, setWizardLoading, submissionVM]);

    const onSaveClickedAddtionalInterest = useCallback(async (additionalInterestVM, index) => {
        if (additionalInterestVM.aspects.valid && additionalInterestVM.aspects.subtreeValid) {
            const additionalInsureds = _.get(submissionVM, 'lobData.inlandMarine.machineryEquipAddlInterests.value')
            if (additionalInterestVM.publicID.value != null) {
                additionalInsureds[index] = additionalInterestVM.value
            }
            return saveSubmission(submissionVM.value).then((result) => {
                setShowErrorsAddlInt(false)
                return result
            })
        } else {
            setShowErrorsAddlInt(true)
        }

    }, [saveSubmission, submissionVM]);

    const getName = useCallback((additionalInterest) => {
        if (additionalInterest.contactType.value.code === _.lowerCase(CONSTANTS.Person)) {
            const firstName = additionalInterest.firstName.value
            const lastName = additionalInterest.lastName.value
            return `${firstName} ${lastName}`
        }
        if (additionalInterest.contactType.value.code === _.lowerCase(CONSTANTS.Company)) {
            const company = additionalInterest.companyName.value
            return `${company}`
        }
    }, []);

    const onDeleteAdditionalInterest = useCallback(async (additionalInterestVM, index) => {
        const name = getName(additionalInterestVM)
        const response = await showConfirm({
            title: translator(messages.removeAdditionalInterestShort),
            message: translator(messages.removeAdditionalInterestLong, { displayName: name })
        })
        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const newSubmission = _.cloneDeep(submissionVM.value);
            const additionalInterests = newSubmission.lobData.inlandMarine.machineryEquipAddlInterests
            additionalInterests.splice(index, 1)
            return saveSubmission(newSubmission)
        }
    }, [getName, saveSubmission, showConfirm, submissionVM.value, translator]);

    const toCreateAITools = useCallback((contactType) => {
        const additionalInterestAddress = {
            country: CONSTANTS.COUNTRY.CA,
            city: '',
            state: '',
            postalCode: '',
        };
        const newVM = viewModelService.create(
            {
                'contactType': contactType,
                'locationAddress': additionalInterestAddress,
                'isEditable': true,
                'subType': CONSTANTS.POLICY_ADDL_INTEREST,
                'detailType': CONSTANTS.CPIM_TOOLS_PART_ADDL_INTEREST
            },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.commercialproperty.coverables.dto.CPAdditionalInterest_WMICDTO',
            submissionVM.aspects.context()
        );
        submissionVM.value.lobData.inlandMarine.toolsAddlInterests.push(newVM.value)

        return newVM
    }, [viewModelService, submissionVM.aspects, submissionVM.value.lobData.inlandMarine.toolsAddlInterests]);

    const onSaveClickedAITools = useCallback(async (additionalInterestVM, index) => {
        if (additionalInterestVM.aspects.valid && additionalInterestVM.aspects.subtreeValid) {
            const additionalInsureds = _.get(submissionVM, 'lobData.inlandMarine.toolsAddlInterests.value')
            if (additionalInterestVM.publicID.value != null) {
                additionalInsureds[index] = additionalInterestVM.value
            }
            return saveSubmission(submissionVM.value).then((result) => {
                setShowErrorsAddlInt(false)
                return result
            })
        } else {
            setShowErrorsAddlInt(true)
        }

    }, [saveSubmission, submissionVM]);

    const onDeleteAITools = useCallback(async (additionalInterestVM, index) => {
        const name = getName(additionalInterestVM)
        const response = await showConfirm({
            title: translator(messages.removeAdditionalInterestShort),
            message: translator(messages.removeAdditionalInterestLong, { displayName: name })
        })
        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const newSubmission = _.cloneDeep(submissionVM.value);
            const additionalInterests = newSubmission.lobData.inlandMarine.toolsAddlInterests
            additionalInterests.splice(index, 1)
            return saveSubmission(newSubmission)
        }
    }, [getName, saveSubmission, showConfirm, submissionVM.value, translator]);

    const buyNow = useCallback(
        async (lobPath, quotePath) => {
            const lobOffering = _.get(submissionVM, `${lobPath}.value`);
            const quoteOffering = _.get(submissionVM, `${quotePath}.value`);
            const quoteID = _.get(submissionVM, 'quoteID.value');
            const sessionUUID = _.get(submissionVM, 'sessionUUID.value');

            await CustomQuoteService.setSelectedVersionOnSubmission(
                quoteID,
                lobOffering.branchName,
                sessionUUID,
                authHeader
            );

            // set chosen quote
            _.set(submissionVM, 'bindData.chosenQuote.value', quoteOffering.publicID);
            submissionVM.value = MockUpUtil.cleanUpMockedProperties(
                submissionVM.value,
                'quote.cp',
                ...MOCK_DATA_TO_REMOVE
            );
            updateWizardData(submissionVM);
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submissionVM, CustomQuoteService, authHeader]
    );

    const getCustomTerms = () => null;

    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);

    const commonOverrides = {
        'quoteID': submissionVM.quoteID.value,
        'changeSubmissionAndSync': changeSubmissionAndSync,
        'changeSubmission': changeSubmission,
        'onClauseChange': onClauseChange,
        'syncCoverages': syncCoverages,
        'recalculate': recalculate,
        'resetQuote': resetQuote,
        'onStaleQuoteBranchCode': onStaleQuoteBranchCode,
        'underwritingIssues': underwritingIssues,
        'showErrors': showErrors,
        'onValidate': onValidate,
        'getCustomTerms': getCustomTerms,
        'buyNow' : buyNow,
    }

    const overrideProps = {
        startDate: {
            visible: showQuoteStartDate
        },
        print: {
            visible: !showQuoteStartDate
        },
        crimeCoverages: {
            ...commonOverrides,
            jobVM: submissionVM,
        },
        inlandMarineCoverages: {
            ...commonOverrides,
            jobVM: submissionVM,
        },
        inlandMarineAdditionalInterestListView: {
            clickable: true,
            startOpen: false,
            readOnly: false,
            editEnabled: true,
            hideBorder: true,
            value: _.get(submissionVM, `lobData.inlandMarine.machineryEquipAddlInterests.children`, []),
            VMData: [
                {
                    headerText: translator(messages.name),
                    getData: getName
                },
                {
                    headerText: translator(messages.interestType),
                    path: 'interestType'
                },
            ],
            detailViewComponent: WMICCPAdditionalInterestDetailView,
            detailViewComponentProps: {
                jobVM: submissionVM,
                showErrorsAddlInt,
                updateWizardData,
            },
            onValidate,
            renderAddButton: renderAddAIButton,
            toCreate: toCreateClicked,
            toUndoCreate: () => {
                const additionalInterests = _.get(submissionVM, `lobData.inlandMarine.machineryEquipAddlInterests.value`)
                additionalInterests.splice(additionalInterests.length - 1, 1);
                updateWizardData(submissionVM);
                setShowErrorsAddlInt(false)
            },
            onDelete: onDeleteAdditionalInterest,
            onSave: onSaveClickedAddtionalInterest,
        },
        inlandMarineAdditionalInterestToolsListView: {
            clickable: true,
            startOpen: false,
            readOnly: false,
            editEnabled: true,
            hideBorder: true,
            value: _.get(submissionVM, `lobData.inlandMarine.toolsAddlInterests.children`, []),
            VMData: [
                {
                    headerText: translator(messages.name),
                    getData: getName
                },
                {
                    headerText: translator(messages.interestType),
                    path: 'interestType'
                },
            ],
            detailViewComponent: WMICCPAdditionalInterestDetailView,
            detailViewComponentProps: {
                jobVM: submissionVM,
                showErrorsAddlInt,
                updateWizardData,
            },
            onValidate,
            renderAddButton: renderAddAIButton,
            toCreate: toCreateAITools,
            toUndoCreate: () => {
                const additionalInterests = _.get(submissionVM, `lobData.inlandMarine.toolsAddlInterests.value`)
                additionalInterests.splice(additionalInterests.length - 1, 1);
                updateWizardData(submissionVM);
                setShowErrorsAddlInt(false)
            },
            onDelete: onDeleteAITools,
            onSave: onSaveClickedAITools,
        },
    };

    const onNext = useCallback(async () => {
        try {

            if (!isComponentValid) {
                setShowErrors(true);
                setScrollToError(Date.now());
                return false;
            }

            setWizardLoading(true);
            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.unset(newSubmissionVM.value, 'bindData');

            submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(newSubmissionVM.value, authHeader)

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM)) {
                WMICVariousUtil.scrollToTop();
                return false;
            }

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [LoadSaveService, authHeader, isComponentValid, setWizardLoading, submissionVM, viewModelService]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onBuyNow: (lobPath, quotePath) => buyNow(lobPath, quotePath).then(onNext),
            onStaleQuoteBranchCode,
            onRecalculate: recalculate,
            onResetQuote: resetQuote,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onChangeSubmission: changeSubmission,
            onSyncCoverages: syncCoverages,
            onPrint: handlePrint
        },
        resolveComponentMap: {
            WMICGLCrimeCoverages,
            WMICImCoverages
        }
    };

    return (
        <WMICWizardSubmissionPage
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={commonMessages.saveAndExit}
            onNext={onNext}
            flowStepId={FlowStepId.CP_ADDITIONALCOVERAGES}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICGLAdditionalCoveragesPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default WMICGLAdditionalCoveragesPage;
