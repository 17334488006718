import React, { useCallback, useEffect, useContext, useState , useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';
import { useModal, Loader } from '@jutro/components';
import { ViewModelUtil } from '@xengage/gw-portals-viewmodel-js';

import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICScrollToError } from "wmic-pe-components-platform-react";

import { WMICConstantsUtil, WMICRPCUtil } from 'wmic-pe-portals-utils-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import { wizardStepProps } from './prop-types/wizardPropTypes';

import useWizardSteps from './hooks/useWizardSteps';

import useWizardData from './hooks/useWizardData';
import useWizardErrors from './hooks/useWizardErrors';
import { WizardContext } from './WizardContext';

import WizardRoutes from './WizardRoutes';
import messages from './Wizard.messages'
import { usePrevious } from "@jutro/platform";
import { LoadSaveService } from "gw-capability-gateway-quoteandbind";

const checkIsDataValid = (data, extractVMObject) => {
    let submissionVM = data;

    if(!ViewModelUtil.isViewModelNode(submissionVM)) {
        submissionVM = extractVMObject(submissionVM)
    }

    return (submissionVM && submissionVM.aspects.subtreeValid && submissionVM.aspects.valid);
};

const checkDataValidityDuringTransition = (wizardData, wizardSnapshot, modalMessageProps,
    cloneData, updateWizardData, extractVMObject, checkValidity, showConfirm, currentStep) => checkValidity(wizardData, extractVMObject, currentStep)
        ? wizardData
        : showConfirm(modalMessageProps).then(
            (results) => {
                if (results === 'cancel' || results === 'close') {
                    return _.stubFalse();
                }

                updateWizardData(cloneData(wizardSnapshot));

                return wizardSnapshot;
            }, _.stubFalse
        );

const checkContinue = (result) => {
    if (result === false) {
        return Promise.reject(new Error(false));
    }

    return Promise.resolve(result);
};

const handleOnPrevious = (wizardProps) => {
    const {
        wizardData, wizardSnapshot, onPreviousModalProps,
        cloneData, updateWizardData, extractVMObject, checkValidity, showConfirm, currentStep
    } = wizardProps;

    return checkDataValidityDuringTransition(wizardData, wizardSnapshot, onPreviousModalProps,
        cloneData, updateWizardData, extractVMObject, checkValidity, showConfirm, currentStep);
};

/**
 * Use this component to render a wizard.
 * This component is the main wizard container.
 * It renders routes for the specified steps. Each step should reference a component that either
 * wraps or extends `<WizardPage>`. Each step component will receive metadata and navigation paths
 * to be used in page rendering. See `<WizardPage>` documentation for more details.
 *
 * @function Wizard
 * @memberof module:gw-portals-wizard-react
 *
 * @override
 *
 * @prop {String|Label} [wizardTitle] - the title for this wizard
 * @prop {Array.<WizardStepProps>} initialSteps -
 *      the steps that will compose this wizard
 *      (once initialized, they cannot be changed from the props)
 * @prop {Object} [initialData] -
 *      the data to pass to the wizard
 *      (once initialized, it cannot be changed from props)
 * @prop {{pathname: String, state: Object}} location - the React Router location
 * @prop {Object} history - the React Router history
 * @prop {{url: String}} match - The React Router match
 * @prop {Object} [wizardStepToFieldMapping] - Steps to match errors to the specific wizard step (e.g. backend validation)
 * @prop {Boolean} [skipCompletedSteps] -
 *      whether the wizard should begin while skipping the completed steps
 *      (once initialized, this cannot be changed from the props)
 * @prop {Function} [onFinish] -
 *      Callback that will be called when the wizard is finished.
 *      This will be called with one parameter containing all the wizard data
 * @prop {Function} [onCancel] -
 *      Callback that will be called when the wizard is canceled.
 *      This will be called with one parameter containing all the wizard data
 * @prop {Function} [onPrevious] - Callback that will be called when the previous button is pushed
 * @prop {
 *      {
 *          title: Label,
 *          message: Label,
 *          status: String,
 *          icon: String,
 *          confirmButtonText: String,
 *          cancelButtonText: String
 *      }
 * } [onPreviousModalProps] - Properties to be passed to the previous modal
 * @prop {Function} [onKnockOut] -
 *      Callback that will be called when the wizard is interrupted because of a knock-out error.
 *      This will be called with one parameter containing all the wizard data
 * @prop {String} [basePath] -
 *      Where this wizard is based. If not passed the current path of the page will be used.
 *      This is particularly useful when nesting one wizard inside another
 * @prop {Function} [extractVMObject] -
 *      Function to find and extract the VM object from inside a normal object
 *      This will be passed when we the data is sent as a VM object enclosed inside a normal object (like in Policy Change)
 *      {
 *          submissionVM: initialSubmission,
 *          selectedPages: {
 *              address: false,
 *              vehicles: false,
 *              drivers: false,
 *              coverages: false
 *          }
 *      }
 * @prop {Function} [checkValidity] -
 *      Function to check the validity of the VM object when moving to the previous screen.
 *      This has a default implementation, but can also be passed to the Wizard for custom implementations
 *
 * @prop {Boolean} [maintainFurtherStepsVisitedSubmitted] -
 *      Whether to invalidate subsequent steps on update
 *      This allows for creating a wizard where the data is preloaded (e.g. compatarive raters)
 *      and any update to the current data isn't detrimental to future flow
 *
 * @example
 *  const steps = [
 *      {
 *          title: 'one',
 *          path: 'step1',
 *          component: PageOne
 *      },
 *      {
 *          title: 'two',
 *          path: 'step2',
 *          component: PageTwo
 *      },
 *      {
 *          title: 'three',
 *          path: 'step3',
 *          component: PageThree
 *      }
 *  ];
 *
 *  <Wizard
 *      location={}
 *      history={}
 *      match={}
 *      basePath="/wizard/90210"
 *      initialSteps={steps}
 *  />
 *
 * @returns {ReactElement}
 */
function Wizard(props) {
   
    const {
        wizardTitle,
        initialSteps,
        initialData,
        skipCompletedSteps,
        onFinish,
        onCancel,
        onPrevious,
        onPreviousModalProps,
        onKnockOut,
        wizardStepToFieldMapping,
        location,
        history,
        match,
        basePath = match.url,
        extractVMObject,
        checkValidity,
        maintainFurtherStepsVisitedSubmitted,
        setIsCustomStepsInvalidated,
    } = props;

    const {
        steps,
        currentStepIndex,
        currentStep,
        goNext,
        goPrevious,
        jumpTo,
        markStepSubmitted,
        changeNextSteps,
        isSkipping,
        stopSkipping,
        startSkipping,
        clearVisitedStepsAfterCurrent,
        isAllowedToNavigateOnError,
        stopErrorNavigation,
        startErrorNavigation,
    } = useWizardSteps(initialSteps, maintainFurtherStepsVisitedSubmitted, skipCompletedSteps);
    const { showConfirm } = useModal();

    const {
        data, updateData, snapshot, updateSnapshot, cloneData
    } = useWizardData(initialData);
    const submittedStepIds = _.filter(steps, 'submitted').map((step) => step.id);
    const {
        acknowledgeError,
        reportErrors,
        stepsWithErrors,
        hasNewErrors,
        underwritingIssues,
        knockOutErrors
    } = useWizardErrors(wizardStepToFieldMapping, submittedStepIds);

    const { authHeader } = useAuthentication();
    const { setWizardLoading } = useWizardModals();
    const translator = useContext(TranslatorContext);

    const [toggle, flip] = useState(false);
    const [isUwIssuesInitialized, updateIsUwIssuesInitialized] = useState(false);
    const [wizardScrollToError, setWizardScrollToError] = useState();
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const unblockNavigation = useRef();
    const [lastNavigationCancel, setLastNavigationCancel] = useState(new Date()); 

    useEffect(() => {
        if (!isUwIssuesInitialized) {
            // errorsAndWarnings is not set because updateWizardData
            //  is NOT called right after retrieving data from the API
            if (underwritingIssues.length === 0) {
                const errorsPath = ViewModelUtil.isViewModelNode(data)
                    ? 'errorsAndWarnings.value'
                    : 'errorsAndWarnings';
                const errorsAndWarnings = _.get(data, errorsPath);

                reportErrors(errorsAndWarnings);
            }

            updateIsUwIssuesInitialized(true);
        }
    }, [data, isUwIssuesInitialized, reportErrors, underwritingIssues.length]);

    const errorsForStep =
        // the trick here is that IFF we have a target step (e.g. the quote step) and we have gotten there with ANY errors, we just
        // need to tell the wizard that we have some errors so that it will not navigate forward
        (maintainFurtherStepsVisitedSubmitted.targetStep || -1) === currentStepIndex && !_.isUndefined(stepsWithErrors)
            ? stepsWithErrors[Object.keys(stepsWithErrors)[0]]
            : stepsWithErrors[currentStep.id] || [];

    const startDate = _.get(data, 'baseData.periodStartDate.value') ?? _.get(data, 'policyVM.latestPeriod.rateAsOfDate.value');
    const prevStartDate = usePrevious({startDate});

    useEffect(() => {
        if(startDate?.day === undefined || startDate?.month === undefined || startDate?.year === undefined) {
            return;
        }

        setIsLoading(true);

        if(isInitialized) {
            setWizardLoading(true, translator(messages.loading));
        }

        const lob = _.get(data, 'lob.value.code');
        const constantInitialization = WMICConstantsUtil.initialize(startDate, authHeader);
        const rpcDateInitialization = WMICRPCUtil.initializeDates(authHeader);
        const rpcInitialization = WMICRPCUtil.initialize(startDate, lob, authHeader);

        Promise.all([constantInitialization, rpcDateInitialization, rpcInitialization])
            .then(() => {
                if (prevStartDate && prevStartDate != startDate) {
                    return LoadSaveService.effectiveDateOnChange_Ext(
                        data.value,
                        authHeader
                    ).then((res) => {
                        data.value = res;
                    });
                }
            })
            .then(() => {
                setWizardLoading(false);
                setIsLoading(false);
                setIsInitialized(true);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate?.day, startDate?.month, startDate?.year]);

    const updateWizardData = useCallback(
        (newData) => {
            clearVisitedStepsAfterCurrent();

            const errorsPath = ViewModelUtil.isViewModelNode(newData)
                ? 'errorsAndWarnings.value'
                : 'errorsAndWarnings';
            const errorsAndWarnings = _.get(newData, errorsPath);

            flip(!toggle);
            updateData(newData);

            return reportErrors(errorsAndWarnings);
        },
        [toggle, updateData, clearVisitedStepsAfterCurrent, reportErrors]
    );

    const updateWizardSnapshot = useCallback(
        (newData) => {
            updateSnapshot(cloneData(newData));

            return updateWizardData(newData);
        },
        [cloneData, updateSnapshot, updateWizardData]
    );

    const finishCallback = useCallback(
        (params) => onFinish({
            steps,
            currentStepIndex,
            wizardData: data,
            params
        }),
        [steps, currentStepIndex, data, onFinish]
    );

    const previousCallback = useCallback(
        (param) => Promise.resolve(
                onPrevious({
                    wizardSnapshot: snapshot,
                    wizardData: data,
                    onPreviousModalProps,
                    param,
                    cloneData,
                    updateWizardData,
                    extractVMObject,
                    checkValidity,
                    showConfirm,
                    currentStep
                })
            )
                .then(checkContinue)
                .then(goPrevious)
                .catch((error) => {
                    if (_.get(error, 'message') === 'false') {
                        // do nothing as we don't want to proceed
                        return;
                    }

                    throw error;
                }),
        [onPrevious, snapshot, data, onPreviousModalProps, cloneData, updateWizardData, extractVMObject, checkValidity, showConfirm, goPrevious, currentStep]
    );

    const onPageJump = useCallback(
        ({
            wizardData, wizardSnapshot, modalMessages, index
        }) => {
            if (checkValidity(wizardData, extractVMObject, currentStep)) {
                const pageTransitionPromise = Promise.resolve(
                    checkDataValidityDuringTransition(wizardData, wizardSnapshot, modalMessages,
                        cloneData, updateWizardData, extractVMObject, checkValidity, showConfirm, currentStep)
                );

                pageTransitionPromise
                    .then((result) => {
                        if (result !== false) {
                            jumpTo(index);
                        }
                    })
                    .catch((error) => {
                        if (_.get(error, 'message') === 'false') {
                            // do nothing as we don't want to proceed
                            return;
                        }

                        throw error;
                    });
             } else {
                setWizardScrollToError(Date.now())
            }
         },
        [checkValidity, cloneData, extractVMObject, jumpTo, showConfirm, updateWizardData, currentStep]
    );

    const cancelCallback = useCallback(
        async param => {
            // the current wizard is very permissive and allows the
            // `onCancel` to be anything. This means that the wizard does
            // not get to know if the callback was successful or failed.
            if (typeof onCancel === 'function') {
                // Given the current implementation of all the `onCancel` functions
                // in the project, we have to be optimistic and assume they will do
                // the "right thing", meaning they will decide wether or not to
                // navigate away from the page. This also means we have no control
                // over accidental navigations that should be prevented
                if (unblockNavigation.current) {
                    unblockNavigation.current();
                }

                const onCancelResult = await onCancel({
                    steps,
                    currentStepIndex,
                    wizardSnapshot: snapshot,
                    wizardData: data,
                    param,
                });
                
                if (onCancelResult === false || onCancelResult === undefined) {
                    setLastNavigationCancel(new Date());
                }

                if (_.isBoolean(onCancelResult)) {
                    return onCancelResult;
                }

                if (onCancelResult === null) {
                    // we do accept null and assume user developer has handled
                    // the transition (current behavior for all the `onCancel`
                    // passed as props)
                    return true;
                }

                return onCancelResult;
            }

            // we can consider handling specific objects for which the behavior is more restrictive
            return undefined;
        },
        [onCancel, steps, currentStepIndex, snapshot, data, unblockNavigation]
    );

    useEffect(() => {
        const unblock = history.block((...navigationInfo) => {
            const [destLocation] = navigationInfo;
            const {
                search,
                state,
                pathname: desiredDestinationPath,
            } = destLocation;
            const isWizardPath = desiredDestinationPath.startsWith(basePath);

            const continueNavigation = () => {
                unblock();
                history.push(desiredDestinationPath);
            };

            // if triggered by the wizard, we ignore it
            if (isWizardPath) {
                continueNavigation();

                return true;
            }

            cancelCallback(desiredDestinationPath).then(result => {
                if (result === false || result === undefined) {
                    // user developer does not want us to allow the transition transition
                    // therefore we do not invoke a new history push
                    return;
                }

                if (result === true) {
                    // user developer wants us to handle the navigation
                    unblock();
                    history.push({
                        pathname: desiredDestinationPath,
                        search,
                        state,
                    });
                }
            });

            // always block the transiton an defer to the promptMessageHandler
            // when to continue the navigation
            return false;
        });

        unblockNavigation.current = unblock;

        return unblock;
        // the lastNavigationCancel dependency is used to store the time the
        // cancelCallback was last called and is essential for triggering the useEffect
    }, [history, cancelCallback, basePath, lastNavigationCancel]);

    if (hasNewErrors && isAllowedToNavigateOnError) {
        const errorStepIds = Object.keys(stepsWithErrors);
        const stepIndex = _.findIndex(steps, (step) => errorStepIds.includes(step.id));

        if (stepIndex !== -1 && stepIndex < currentStepIndex) {
            jumpTo(stepIndex);
            stopSkipping();
        }
    }

    if (!_.isEmpty(knockOutErrors)) {
        onKnockOut({
            steps,
            knockOutErrors,
            wizardData: data
        });
    }

    /*
     * current step has authority over the URL.
     *
     * If current step is not matching the URL,
     * we adjust by sending a redirect to the step URL
     */
    const expectedPath = `${basePath}${currentStep.path}`;

    if (!location.pathname.startsWith(expectedPath)) {
        const nextLocation = {
            pathname: expectedPath,
            state: location.state
        };

        return <Redirect to={nextLocation} />;
    }

    const wizardProps = {
        // wizard details
        wizardTitle,
        // steps props
        steps,
        changeNextSteps,
        currentStepIndex,
        currentStep,
        // transitions props
        isSkipping,
        stopSkipping,
        startSkipping,
        goNext,
        goPrevious: previousCallback,
        markStepSubmitted,
        jumpTo,
        finish: finishCallback,
        cancel: cancelCallback,
        onPageJump,
        // wizard data props
        wizardData: data,
        updateWizardData,
        updateWizardSnapshot,
        wizardSnapshot: snapshot,
        // errors props
        hasNewErrors,
        errorsForStep,
        stepsWithErrors,
        underwritingIssues,
        acknowledgeError,
        reportErrors,
        extractVMObject,
        checkValidity,
	    showConfirm,
        maintainFurtherStepsVisitedSubmitted,
        isAllowedToNavigateOnError,
        stopErrorNavigation,
        startErrorNavigation,
        setIsCustomStepsInvalidated,
    };

    if (isLoading) {
        return <Loader loaded={!isLoading} />;
    }

    // we pass the props to both the component
    // and the context to allow nested components to reuse them
    // without prop-drilling
    return (
        <WizardContext.Provider value={wizardProps}>
            <WMICScrollToError counter={wizardScrollToError} />
            <WizardRoutes basePath={basePath} {...wizardProps} />
        </WizardContext.Provider>
    );
}

Wizard.propTypes = {
    /** the title for this wizard */
    wizardTitle: PropTypes.oneOfType([
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired
        }),
        PropTypes.string
    ]),
    /**
     * the steps that will compose this wizard
     * (once initialized, they cannot be changed from the props)
     */
    initialSteps: PropTypes.arrayOf(PropTypes.shape(wizardStepProps)).isRequired,
    /**
     * the data to pass to the wizard
     * (once initialized, it cannot be changed from props)
     */
    initialData: PropTypes.shape({}),
    /**
     * the React Router location
     */
    location: PropTypes.shape({
        pathname: PropTypes.string,
        state: PropTypes.shape({})
    }).isRequired,
    /**
     * the React Router history
     */
    history: PropTypes.shape({}).isRequired,
    /**
     * The React Router match
     */
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    /**
     * Steps to match errors to the specific wizard step (e.g. backend validation)
     */
    wizardStepToFieldMapping: PropTypes.shape({}),
    /**
     * whether the wizard should begin while skipping the completed steps
     * (once initialized, this cannot be changed from the props)
     */
    skipCompletedSteps: PropTypes.bool,
    /**
     * Callback that will be called when the wizard is finished.
     * This will be called with one parameter containing all the wizard data
     */
    onFinish: PropTypes.func,
    /**
     * Callback that will be called when the wizard is canceled.
     * This will be called with one parameter containing all the wizard data
     */
    onCancel: PropTypes.func,
    onPrevious: PropTypes.func,
    onPreviousModalProps: PropTypes.shape({
        title: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        message: PropTypes.shape({
            id: PropTypes.string,
            defaultMessage: PropTypes.string
        }),
        status: PropTypes.string,
        icon: PropTypes.string,
        confirmButtonText: PropTypes.shape({}),
        cancelButtonText: PropTypes.shape({})
    }),
    /**
     * Callback that will be called when the wizard is interrupted because of
     * a knock-out error.
     * This will be called with one parameter containing all the wizard data
     */
    onKnockOut: PropTypes.func,
    /**
     * Where this wizard is based
     * If not passed the current path of the page will be used.
     * This is particularly useful when nesting one wizard inside another
     */
    basePath: PropTypes.string,
    /**
     * Function to find and extract the VM object from inside a normal object
     * This will be passed when we the data is sent as a VM object enclosed inside a normal object (like in Policy Change)
     *  {
     *      submissionVM: initialSubmission,
     *      selectedPages: {
     *          address: false,
     *          vehicles: false,
     *          drivers: false,
     *          coverages: false
     *      }
     *  }
     */
    extractVMObject: PropTypes.func,
    /**
     * Function to check the validity of the VM object when moving to the previous screen
     * This has a default implementation, but can also be passed to the Wizard for custom implementations
     */
    checkValidity: PropTypes.func,

    /** When set this will keep subsequent steps from being visibily invalidated.  The actual step becomes invalid (unvisited)
     * but we give the illusion that it has been visited so we can jump to other steps
     */
    maintainFurtherStepsVisitedSubmitted: PropTypes.shape({
        flag: PropTypes.bool,
        targetStep: PropTypes.number
    }),

    /**
     * Function responsible for updating a state in an implemented wizard using Wizard component
     */
    setIsCustomStepsInvalidated: PropTypes.func,
};

Wizard.defaultProps = {
    wizardTitle: null,
    initialData: null,
    skipCompletedSteps: false,
    maintainFurtherStepsVisitedSubmitted: {flag: true, targetStep: undefined },
    onCancel: _.noop,
    onPrevious: handleOnPrevious,
    onPreviousModalProps: {
        title: commonMessages.wantToJump,
        message: commonMessages.wantToJumpMessage,
        status: 'warning',
        icon: 'mi-error-outline',
        confirmButtonText: commonMessages.yes,
        cancelButtonText: commonMessages.close
    },
    onFinish: _.noop,
    wizardStepToFieldMapping: {},
    basePath: undefined,
    onKnockOut: _.noop,
    extractVMObject: _.noop,
    checkValidity: checkIsDataValid,
    setIsCustomStepsInvalidated: _.noop,
};

export default withRouter(Wizard);