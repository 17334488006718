import { defineMessages } from 'react-intl';

export default defineMessages({
    MVRRequired: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.mvr-required',
        defaultMessage: 'MVR required?'
    },
    MVRReasonNotOrdered: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.reason-for-not-mvr',
        defaultMessage: 'Reason for not receiving the MVR?'
    },
    MVRDescription: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.description',
        defaultMessage: 'Description'
    },
    MVRPrimaryResidence: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.primary-residence-address',
        defaultMessage: 'MVR address matches Primary Residence address?'
    },
    MVRReasonNotMatch: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.reason-address-not-match',
        defaultMessage: 'Reason MVR address does not match?'
    },
    MVRDescribeOther: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.describe-other',
        defaultMessage: 'Describe other'
    },
    MVRViolationSuspensions: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.violations-suspensions',
        defaultMessage: 'MVR and Violations/Suspensions'
    },
    MVRMinimumMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.mvr-minimum',
        defaultMessage: '&lt;span&gt;Add MVR details. A minimum of one record is required &lt;b&gt;to quote and bind.&lt;/b&gt;&lt;/span&gt;'
    },
    MVRManualAndCGIMinimumMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.mvr-manual-and-cgi-minimum',
        defaultMessage: '&lt;span&gt;Manual MVR entry is required for quote. Integrated MVR will be required to bind.&lt;/span&gt;'
    },
    MVRMoreAccurateQuote: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.more-accurate-quote',
        defaultMessage: 'To obtain a more accurate quote, add any violations or suspensions.  MVR is required to bind.'
    },
    MVROrderedMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.mvr-ordered-message',
        defaultMessage: "MVR order will be submitted when driver's information is saved."
    },
    MVRWaitingMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.mvr-waiting-message',
        defaultMessage: "MVR has been requested."
    },
    MVRDate: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.date',
        defaultMessage: 'Date'
    },
    MVRType: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.type',
        defaultMessage: 'Type'
    },
    MVRPredictorScore: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.predictor-score',
        defaultMessage: 'Predictor Score'
    },
    MVRViolationsSuspensions: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.violations-suspensions-table',
        defaultMessage: 'Violations / Suspensions'
    },
    MVRReportSource: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.report-source',
        defaultMessage: 'Report Source'
    },
    MVRAdd: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.add',
        defaultMessage: 'Add Manual Entry'
    },
    MVROrder: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.order-mvr',
        defaultMessage: 'Order MVR'
    },
    No: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.no',
        defaultMessage: 'No'
    },
    Yes: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.yes',
        defaultMessage: 'Yes'
    },

    RemovingMVRConfirm: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.RemovingMVRConfirm',
        defaultMessage: 'Are you sure you want to remove the MVR from the list?'
    },
    RemovingMVR: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.RemovingMVR',
        defaultMessage: 'Remove MVR'
    },
    yesIncidentLength: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.mvr.yesIncidentLength',
        defaultMessage: 'Yes ({ incidents })'
    },
    ChangeMVROrderNoConfirmationMsg: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.ChangeMVROrderNoConfirmationMsg',
        defaultMessage:'Changing the answer to "No" will remove all information for this report. Do you want to continue?'
    },
    ChangeMVROrderNo: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.ChangeMVROrderNo',
        defaultMessage:'MVR Ordered'
    },
    DuplicateMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.DuplicateConviction',
        defaultMessage: 'Duplicate conviction {desc}, {date}'
    },
    MVRNotRequired: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.MVRNotRequired',
        defaultMessage: '&lt;span&gt;MVR is &lt;b&gt;not required&lt;/b&gt; for Foreign License or International Permit;&lt;/span&gt;'
    },
    MVRNotAvailableMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.MVRNotAvailable',
        defaultMessage: 'MVR orders or entries will be available 30 days after the previous MVR was received.'
    },
    MVRErrorMessage: {
        id: 'wmic-pe-capability-gateway-common-pa-react.pa-drivers-details.MVRErrorStatus',
        defaultMessage: 'An error occurred during your request. Try again shortly and reach out for assistance if the problem persists.'
    },
})