import { defineMessages } from 'react-intl';

export default defineMessages({
    excluded: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.PA_28A_ReducAmtForNamedCov',
        defaultMessage: '28A - Excluded &lt;b&gt;{name}&lt;/b&gt;'
    },
    article6Excluded: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.Article6_Excluded_Driver',
        defaultMessage: 'Article6 - Excluded &lt;b&gt;{name}&lt;/b&gt;'
    },
    inUse: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.In Use',
        defaultMessage: 'In Use'
    },
    notInUse: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.Not In Use',
        defaultMessage: 'Not In Use'
    },
    accidentWaiverPurchaseDate: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.Accident Waiver Purchase Date',
        defaultMessage: "Accident Waiver Purchase Date"
    }
})
