/* eslint-disable no-secrets/no-secrets */
import React, {
    useContext, useCallback, useEffect, useState, useMemo
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWizardModals, useDocumentTitle, WMICWizardSubmissionPage  } from 'wmic-pe-portals-wizard-components-ui';
import { CONSTANTS, HOConstants, WMICUserAccessUtil, WMICValidationUtil, WMICVariousUtil, WMICLogger, MODAL_CONSTANTS, FlowStepId } from 'wmic-pe-portals-utils-js';
import { WMICHOAdditionalInterests, WMICHOWatercraftDetails, DwellingDetailView, WatercraftDetailView } from 'wmic-pe-capability-gateway-common-ho-react';
import WMICHORiskUtil from 'wmic-pe-capability-gateway-common-ho-react/utils/WMICHORiskUtil';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import { messages as quoteAndBindCommonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import { messages as gwCommonMessages } from '@xengage/gw-platform-translations';

import * as WMICHOYourHomeUtil from 'wmic-pe-capability-gateway-quoteandbind-ho-react/util/WMICHOYourHomeUtil'
import defaultRisk from 'wmic-pe-capability-gateway-quoteandbind-ho-react/util/defaultRisk.json5';
import {WMICErrorHandler} from "wmic-pe-capability-quoteandbind-common-react";
import metadata from './WMICHOYourHomePage.metadata.json5';
import messages from './WMICHOYourHomePage.messages';

const DWELLINGS_PATH = 'lobData.homeowners.coverables.dwellings';
const WATERCRAFT_PATH = 'lobData.homeowners.coverables.watercrafts';
const YOURHOMEMAP = `yourHome.${CONSTANTS.METADATAMAP}`;
const RATINGMAP = `rating.${CONSTANTS.METADATAMAP}`;
const ADLINTMAP = `additionalInterests.${CONSTANTS.METADATAMAP}`;

function recalculatePrimaryDwelling(submission, dwelling) {
    const dwellings = _.get(submission, 'lobData.homeowners.coverables.dwellings');
    const isPrimary = _.get(dwelling, 'yourHome.primaryDwelling_EXT');

    if (dwelling && isPrimary) {
        dwellings.forEach((item) => {
            if(!_.isEqual(item, dwelling)) {
                _.set(item, 'yourHome.primaryDwelling_EXT', false);
            }
        })
    }
}

function PEHOYourHomePage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const {
        registerInitialComponentValidation,
        initialValidation,
        onValidate,
        isComponentValid
    } = useValidation('PEHOYourHomePage');
    const { authHeader, authUserData } = useAuthentication();
    const { setWizardLoading, showConfirm } = useWizardModals();
    const { maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated } = useContext(WizardContext);
    const isBMSi = _.get(submissionVM, "isFromBMS_WMIC.value");

    useDocumentTitle(translator(messages.risksTitle), submissionVM);

    // PAGE STATE
    const [isPageInitialized, setPageInitialized] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState()
    const [isEditingDwelling, setIsEditingDwelling] = useState(false);
    const [selectedDwellingIndex, setSelectedDwellingIndex] = useState();

    // initialise the data for the page
    useEffect(() => {
        registerInitialComponentValidation(() =>
            WMICHOYourHomeUtil.presentDwellingRiskList(submissionVM)
                && !WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.HO_RISKS)
        );

        if (WMICHOYourHomeUtil.presentDwellingRiskList(submissionVM)) {
            setSelectedDwellingIndex(0);
        }
        setPageInitialized(true);
        // The above action only need to run once when the page is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const canEditSubmission = useMemo(() => WMICUserAccessUtil.canEditSubmission(authUserData.permission_Ext), [authUserData.permission_Ext]);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.HO_RISKS;
    }, [submissionVM.value]);

    const onEditDwelling = () => {
        setIsEditingDwelling(true);
        _.set(submissionVM, 'isEditingPage.value', true);
        updateWizardData(submissionVM);
    }

    const toCreateDwelling = useCallback( async () => {
        if (isBMSi && maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
        }

        const skeleton = await LoadSaveService.getSkeletonStructure(
            submissionVM.value,
            authHeader
        );

        const _dwellingRiskViewVM = viewModelService.create(
            defaultRisk,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.dwelling.DwellingDTO_WMIC',
            submissionVM.aspects.context()
        );
        // copy in skeletons for the Radiant data meta maps
        _.set(_dwellingRiskViewVM, YOURHOMEMAP, _.get(skeleton, `${DWELLINGS_PATH}[0].${YOURHOMEMAP}`));
        _.set(_dwellingRiskViewVM, RATINGMAP, _.get(skeleton, `${DWELLINGS_PATH}[0].${RATINGMAP}`));
        _.set(_dwellingRiskViewVM, ADLINTMAP, _.get(skeleton, `${DWELLINGS_PATH}[0].${ADLINTMAP}`));

        if (!WMICHOYourHomeUtil.presentDwellingRiskList(submissionVM)) {
            _.set(_dwellingRiskViewVM, 'yourHome.primaryDwelling_EXT.value', true);
        }

        _dwellingRiskViewVM.value.isUnderEditing = true;
        _dwellingRiskViewVM.value.isAddingLocation = false;
        _dwellingRiskViewVM.value.isAddingAdditionalInterest = false;
        _dwellingRiskViewVM.value.isAddingPetOnPremise = false;
        onEditDwelling();
        setShowErrors(false);
        submissionVM.lobData.homeowners.coverables.dwellings.value.push(_dwellingRiskViewVM.value);

        return _dwellingRiskViewVM;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LoadSaveService, authHeader, isBMSi, maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated, submissionVM, viewModelService]);


    // PAGE HELPER FUNCTIONS
    // ADD NEW RISKx

    const toCreateDwellingWithModal = useCallback(() => showConfirm({
        title: quoteAndBindCommonMessages.revalidationRequiredTitle,
        message: quoteAndBindCommonMessages.revalidationRequiredBody,
        status: MODAL_CONSTANTS.STATUS.WARNING,
        icon: MODAL_CONSTANTS.ICON.ERROR,
        confirmButtonText: gwCommonMessages.yes,
        cancelButtonText: gwCommonMessages.cancelModel
    }).then(results => {
        if (
            results === CONSTANTS.MODAL_RESULT.CANCEL ||
            results === CONSTANTS.MODAL_RESULT.CLOSE
        ) {
            return false;
        }

        return toCreateDwelling();
    }), [showConfirm, toCreateDwelling]);


    const saveSubmission = useCallback(() => {
        setWizardLoading(true, translator(messages.savingRisk));

        return LoadSaveService.saveWithNoValidationRuleCheck(
            submissionVM.value,
            authHeader
        ).then((result) => {
            submissionVM.value = result;
            updateWizardData(submissionVM);
            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.HO_RISKS)) {
                WMICVariousUtil.scrollToTop();
            }
            return true;
        }).catch((error) => {
            WMICLogger.error('Save risk failed', error);
            return false;
        }).finally(() => {
            setIsEditingDwelling(false);
            setWizardLoading(false);
        });
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LoadSaveService, authHeader, setWizardLoading, submissionVM, translator]);

    const onDeleteDwelling = useCallback((item, index) => showConfirm({
            title: messages.removeRiskTitle,
            message: messages.removeRiskMessage,
        })
            .then((response) => {
                if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                    const dwellings = _.get(submissionVM.value, DWELLINGS_PATH);

                    if (item && (
                        (item.yourHome && item.yourHome.residenceType === HOConstants.residenceTypeLaneway)
                        || (item.rating && (item.rating.hoPolicyType === HOConstants.revenuePropertyPolicyType
                            || item.rating.hoPolicyType === HOConstants.homeownerPolicyType)))) {
                        const associatedDwellingDeleted = _.find(dwellings,
                            (dwelling) => dwelling.associatedDwellingPublicID === item.publicID);
                        if (associatedDwellingDeleted) {
                            associatedDwellingDeleted.associatedDwellingPublicID = undefined;
                        }
                    }

                    dwellings.splice(index, 1);
                    _.set(submissionVM.value, DWELLINGS_PATH, dwellings);
                    setIsEditingDwelling(false);
                    return saveSubmission();
                }
                return false;
            }), [saveSubmission, showConfirm, submissionVM]);

    const onSaveDwelling = useCallback((dwellingVM, index) => {
        const dwellingPath = `${DWELLINGS_PATH}.${index}`;
        recalculatePrimaryDwelling(submissionVM.value, dwellingVM.value);
        _.set(submissionVM.value, `${dwellingPath}.value.isUnderEditing`, false);
        setIsEditingDwelling(false);
        return saveSubmission();
    }, [saveSubmission, submissionVM]);

    const toCreateWatercraft = useCallback(() => {
        if (isBMSi && maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
        }
        updateWizardData(submissionVM);

        const _watercraftRisk = {
            watercraftType: HOConstants.boatEquipmentTrailer,
            watercraftComponents: [],
            additionalInterests: [],
        };
        const _watercraftRiskViewVM = viewModelService.create(
            _watercraftRisk,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.watercraft.WatercraftRiskDTO_WMIC',
            submissionVM.aspects.context()
        );
        // _.set(_watercraftRiskviewVM, ADLINTMAP, _.get(skeletons, `${DWELLINGS_PATH}[0].${ADLINTMAP}`)); todo: needed when we fix the POC
        _.set(_watercraftRiskViewVM, ADLINTMAP, null);
        _watercraftRiskViewVM.value.isUnderEditing = true;
        _watercraftRiskViewVM.value.isAddingAdditionalInterest = false;
        _watercraftRiskViewVM.value.isAddingBoatMotor = false;

        submissionVM.lobData.homeowners.coverables.watercrafts.value.push(_watercraftRiskViewVM.value);
        return _watercraftRiskViewVM;
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBMSi, maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated, submissionVM, viewModelService]);

    const toCreateWatercraftWithModal = useCallback(() => showConfirm({
        title: quoteAndBindCommonMessages.revalidationRequiredTitle,
        message: quoteAndBindCommonMessages.revalidationRequiredBody,
        status: MODAL_CONSTANTS.STATUS.WARNING,
        icon: MODAL_CONSTANTS.ICON.ERROR,
        confirmButtonText: gwCommonMessages.yes,
        cancelButtonText: gwCommonMessages.cancelModel
    }).then(results => {
        if (
            results === CONSTANTS.MODAL_RESULT.CANCEL ||
            results === CONSTANTS.MODAL_RESULT.CLOSE
        ) {
            return false;
        }

        return toCreateWatercraft()
    }), [showConfirm, toCreateWatercraft]);

    
    const onDeleteWatercraft = useCallback((item, index) => showConfirm({
            title: messages.removeRiskTitle,
            message: messages.removeRiskMessage,
        })
            .then((response) => {
                if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                    const watercrafts = _.get(submissionVM.value, WATERCRAFT_PATH);
                    watercrafts.splice(index, 1);
                    return saveSubmission();
                }
                return false;
            }), [saveSubmission, showConfirm, submissionVM]);

    const onSaveWatercraft = useCallback((watercraftVM, index) => {
        const watercraftPath = `${WATERCRAFT_PATH}.${index}`;
        _.set(submissionVM.value, `${watercraftPath}.value.isUnderEditing`, false);
        return saveSubmission();
    }, [saveSubmission, submissionVM]);

    const onCancelDwelling = () => {
        setIsEditingDwelling(false);
        _.set(submissionVM, 'isEditingPage.value', false);
        updateWizardData(submissionVM);
    }

    // WIZARD FUNCTIONS

    const onNext = useCallback(async () => {
        if (!isComponentValid || isEditingDwelling || !WMICHOYourHomeUtil.presentDwellingRiskList(submissionVM)) {
            setShowErrors(true);
            setScrollToError(Date.now());
            return false;
        }

        try {
            setWizardLoading(true);

            const newSubmissionVM = viewModelService.clone(submissionVM);
            _.unset(newSubmissionVM.value, 'bindData');

            submissionVM.value = await LoadSaveService.updateDraftSubmission(
                newSubmissionVM.value,
                authHeader
            );

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.HO_RISKS)) {
                WMICVariousUtil.scrollToTop();
                return false;
            }

            return submissionVM;
        } catch (error) {
            WMICErrorHandler.processAsModal(error);

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }

    }, [LoadSaveService, authHeader, isComponentValid, isEditingDwelling, setWizardLoading, submissionVM, viewModelService]);

    const overrideProps = {
        inlineNotification: {
            visible: WMICHOYourHomeUtil.presentWatercraftRiskList(submissionVM) && !WMICHOYourHomeUtil.presentDwellingRiskList(submissionVM)
        },
        trailerMonetaryValue: {
            parent: submissionVM.trailer
        },

        // DWELLING

        dwellingRequiredText: {
            visible: !WMICHOYourHomeUtil.presentDwellingRiskList(submissionVM),
            message: translator(messages.dwellingRequired)
        },
        dwellingListView: {
            clickable: true,
            readOnly: !canEditSubmission,
            VMData: [
                {
                    headerText: translator(messages.dwellingHeader),
                    path: 'yourHome.dwellingUsage',
                },
                {
                    headerText: translator(messages.riskHeader),
                    path: 'yourHome.dwellingNumber_EXT',
                    getData: (item, path) => WMICHOYourHomeUtil.renderDwellingRiskNumberCell(item, path, submissionVM)
                },
                {
                    headerText: translator(messages.addressHeader),
                    path: 'yourHome.locationAddress.displayName',
                    getData: (item) => WMICHOYourHomeUtil.renderDwellingAddressCell(item, translator)
                },
                {
                    headerText: translator(messages.riskTypeHeader),
                    path: 'rating.hoPolicyType'
                }
            ],
            detailViewComponent: DwellingDetailView,
            onValidate,
            toCreate: isBMSi ? toCreateDwellingWithModal : toCreateDwelling,
            showErrors,
            toUndoCreate: () => {
                const dwellings = _.get(submissionVM.value, DWELLINGS_PATH);
                dwellings.splice(dwellings.length-1, 1);
                _.set(submissionVM.value, DWELLINGS_PATH, dwellings);
                updateWizardData(submissionVM);
            },
            onSave: onSaveDwelling,
            onDelete: onDeleteDwelling,
            toEdit: onEditDwelling,
            onCancel: onCancelDwelling,
        },
        watercraftListView: {
            clickable: true,
            readOnly: !canEditSubmission,
            startOpen: false,
            VMData: [
                {
                    headerText: translator(messages.riskHeader),
                    path: 'riskNumber'
                },
                {
                    headerText: translator(messages.riskDescription),
                    path: 'description',
                    getData: WMICHORiskUtil.getWaterCraftDescription
                },
                {
                    headerText: translator(messages.riskTypeHeader),
                    path: 'watercraftType'
                },
            ],
            detailViewComponent: WatercraftDetailView,
            onValidate,
            showErrors,
            toCreate: isBMSi ? toCreateWatercraftWithModal : toCreateWatercraft,
            toUndoCreate: () => {
                const watercrafts = _.get(submissionVM.value, WATERCRAFT_PATH);
                watercrafts.splice(watercrafts.length-1, 1);
                _.set(submissionVM.value, WATERCRAFT_PATH, watercrafts);
                updateWizardData(submissionVM);
            },
            onSave: onSaveWatercraft,
            onDelete: onDeleteWatercraft
        }
    };

    const resolvers = {
        resolveCallbackMap: {},
        resolveComponentMap: {
            WMICHOAdditionalInterests,
            WMICHOWatercraftDetails,
        }
    };

    if (!isPageInitialized) {
        return null;
    }

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            skipWhen={initialValidation}
            registerInitialComponentValidation={initialValidation}
            cancelLabel={translator(quoteAndBindCommonMessages.saveAndExit)}
            showRequired
            flowStepId={FlowStepId.HO_RISKS}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    );
}

PEHOYourHomePage.propTypes = wizardProps;
export default PEHOYourHomePage;
