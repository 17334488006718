import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetailsHeader: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Policy Details',
        defaultMessage: 'Policy Details'
    },
    defaultAnswers: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.CreateDefaultAnswers',
        defaultMessage: 'Default Answers'
    },
    effDate: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Effective Date',
        defaultMessage: 'Effective Date'
    },
    incDate: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Original Inception Date',
        defaultMessage: 'Loyalty Date'
    },
    expDate: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Expiration Date',
        defaultMessage: 'Expiration Date'
    },
    writeDate: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Written Date',
        defaultMessage: 'Written Date'
    },
    rateDate: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Rate as of Date',
        defaultMessage: 'Rate as of Date'
    },
    taxExempt: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Tax Exemption',
        defaultMessage: 'Tax Exemption'
    },
    exemptReason: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Tax Exemption Reason',
        defaultMessage: 'Tax Exemption Reason'
    },
    brokerCode: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Broker Code',
        defaultMessage: 'Broker Code'
    },
    brokerID: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Broker Client ID',
        defaultMessage: 'Broker Client ID'
    },
    portfolioTransfer:{
        id:'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Portfolio Transfer',
        defaultMessage:'Portfolio Transfer'
    },
    pniConsent: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.PNI Credit Consent Received',
        defaultMessage: 'Primary Named Insured Credit Consent Received'
    },
    credDecline: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Credit Declined Date',
        defaultMessage: 'Date Declined or Withdrawn'
    },
    withdrawConsent: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Credit Information Withdrawal of Consent',
        defaultMessage: 'Withdrawal of Consent Form Received?'
    },
    credConsent: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Credit Consent Date',
        defaultMessage: 'Credit Consent Date'
    },
    piConsent: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Personal Information and Consent Form',
        defaultMessage: 'Consent Form Received?'
    },
    pniDOB: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Primary Named Insured Date of Birth',
        defaultMessage: 'Primary Named Insured Date of Birth'
    },
    aniDOB: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Additional Named Insured Date of Birth',
        defaultMessage: '&lt;span&gt;The &lt;b&gt;Date of Birth&lt;/b&gt; for Additional Named Insured(s) is required&lt;/span&gt;'
    },
    aniTitle: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Additional Named Insured Opt Title',
        defaultMessage: 'Additional Named Insured (Optional)'
    },
    aniTitlePolicyChange: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Insureds',
        defaultMessage: 'Insureds'
    },
    regOwner: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.All Registered owners of the vehicles must be Named Insureds',
        defaultMessage: 'All Registered owners of the vehicles must be Named Insureds'
    },
    loading: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.loading',
        defaultMessage: 'Loading...'
    },
    prefLanguage: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Policy documents preferred language',
        defaultMessage: 'Preferred Language'
    },
    policyInformation: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Policy Information',
        defaultMessage: 'Policy Information'
    },
    primaryBusinessOperation: {
        id: "wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.PrimaryBusinessOperation",
        defaultMessage: "Primary Business Operation"
    },
    industryCode: {
        id: "wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.IndustryCode",
        defaultMessage: "Industry Code"
    },
    industryCodeSearch: {
        id: "wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.IndustryCodeSearch",
        defaultMessage: "Search",
    },
    industryCodeSupported:{
        id: "wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.IndustryCodeSupported",
        defaultMessage: "Supported"
    },
    industryCodeUnsupported: {
        id: "wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.IndustryCodeUnsupported",
        defaultMessage: "Unsupported"
    },
    classification: {
        id: "wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Classification",
        defaultMessage: "Classification"
    },
    coverageSelection: {
        id: "wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Coverage Selection",
        defaultMessage: "Coverage Selection"
    },
    coverage: {
        id: "wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.coverage",
        defaultMessage: "Choose one of the coverages listed"
    },
    commercialPackageCoverage: {
        id: "wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Commercial Package",
        defaultMessage: "Commercial Package - property, liability, enhancements and additional coverages"
    },
    commercialGeneralLiabilityCoverage: {
        id: "wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Commercial General Liability",
        defaultMessage: "Commercial General Liability Only - liability coverage only (no property or other coverages)"
    },
    policyLimitsTitle:{
        id: "wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Policy Limits",
        defaultMessage: "Policy Limits"
    },
    businessAndOperation: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Business and Operation',
        defaultMessage: 'Business and Operation'
    },
    organizationType: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Organization type',
        defaultMessage: 'Organization type'
    },
    other: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Other',
        defaultMessage: 'Other'
    },
    yearBusinessStarted: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Year Business Started',
        defaultMessage: 'Year business started'
    },
    descriptionOfBusinessOperation: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Description of business and operation',
        defaultMessage: 'Description of business and operation'
    },
    tradeName: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Trade Name',
        defaultMessage: 'Trade Name (Optional)'
    },
    checkDrivers: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.CheckDrivers',
        defaultMessage: 'Changes to the Named Insureds will result in changes to Drivers or Registered Vehicle owner details. Please review Driver and Vehicle information.'
    },
    alerts: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Revalidation Required',
        defaultMessage: "Revalidation Required"
    },
    changeEffectiveDateWarning: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.changeEffectiveDateWarning',
        defaultMessage: "You have changed the effective date of this transaction. Please note this will require you to go through all of the pages in the workflow and validate the information."
    },
    ok: {
        id: 'wmic-pe-capability-gateway-common-react.WMICPolicyDetailsComponent.Ok',
        defaultMessage: "Ok"
    },
});


