import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { LanguageContext, LocaleContext } from '@jutro/locale';
import { BurgerMenuItem } from '@jutro/router';
import { WMICLink } from 'wmic-pe-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICUserService } from 'wmic-pe-capability-gateway';
import { ServiceManager } from '@jutro/legacy/services';
import { WMICLogger } from "wmic-pe-portals-utils-js";
import constants from '../../constants';

const WMICLanguageSwitcher = ({ inSideNav, ...props }) => {
    const LocaleService = ServiceManager.getService('locale-service');
    const { authHeader } = useAuthentication();
    const [selectedLanguage, setSelectedLanguage] = useState(LocaleService.getStoredLanguage());
    const languageContext = useContext(LanguageContext);
    const localeContext = useContext(LocaleContext);
    const { LOCALE_KEY_ENGLISH, LOCALE_KEY_FRENCH, DISPLAY_NAME_FRENCH, DISPLAY_NAME_ENGLISH } = constants;
    const langDisplayNameMap = {
        [LOCALE_KEY_ENGLISH]: DISPLAY_NAME_FRENCH,
        [LOCALE_KEY_FRENCH]: DISPLAY_NAME_ENGLISH
    };

    const changeLanguage = useCallback(() => {
        const targetLanguage = selectedLanguage === LOCALE_KEY_ENGLISH ? LOCALE_KEY_FRENCH : LOCALE_KEY_ENGLISH;
        setSelectedLanguage(targetLanguage);
        LocaleService.saveLanguage(targetLanguage);
        LocaleService.saveLocale(targetLanguage);
        WMICUserService.updateUserPreferredLanguage(LocaleService.localeToPCNotation(targetLanguage), authHeader).then(() => {
            window.location.reload();
            if (languageContext?.languageOnChangeCallback) {
                languageContext.languageOnChangeCallback(targetLanguage);
            }
            if (localeContext?.localeOnChangeCallback) {
                localeContext.localeOnChangeCallback(targetLanguage);
            }
        }).catch((error) => {
            WMICLogger.error(`request=updateUserPreferredLanguage, error=${error}`);
        });
    }, [LOCALE_KEY_ENGLISH, LOCALE_KEY_FRENCH, authHeader, languageContext, selectedLanguage, LocaleService]);

    return inSideNav
        ? <BurgerMenuItem {...props} onClick={changeLanguage}>{langDisplayNameMap[selectedLanguage]}</BurgerMenuItem>
        : <WMICLink {...props} onClick={changeLanguage}>{langDisplayNameMap[selectedLanguage]}</WMICLink>
}

WMICLanguageSwitcher.propTypes = {
    inSideNav: PropTypes.bool
};

WMICLanguageSwitcher.defaultProps = {
    inSideNav: false
}

export default WMICLanguageSwitcher;
