import { defineMessages } from 'react-intl';

export default defineMessages({
    receiptTitle: {
        id: "wmic-pe-capability-quoteandbind-common-react.pages.WMICReceiptPage.WMICReceiptPage.Title",
        defaultMessage: "Receipt"
    },
    successNotification: {
        id: "quoteandbind.views.payment-receipt.ProcessedSuccessfully",
        defaultMessage: "The payment was processed successfully"
    },
    inProgressTitleSubHeading: {
        id: 'quoteandbind.views.payment-receipt.ViewBoundPolicyMessage',
        defaultMessage: 'You will be able to view the policy once complete.'
    },
    thankYouForWaiting: {
        id: 'quoteandbind.views.payment-receipt.ThankYouForWaiting',
        defaultMessage: 'Thank you for waiting. You can now view the policy.'
    },    
    viewPolicy: {
        id: 'quoteandbind.views.payment-receipt.ViewPolicy',
        defaultMessage: 'View Policy'
    },    
    bindErrorTitleSubHeading: {
        id: 'quoteandbind.views.payment-receipt.ThereMayBeAnIssue',
        defaultMessage: '&lt;span&gt;There may be an issue with this policy. Please &lt;b&gt;contact an underwriter&lt;/b&gt;.&lt;/span&gt;'
    },
    policyCreatedTitle: {
        id: 'quoteandbind.views.payment-receipt.PolicyCreatedTitle',
        defaultMessage: 'Policy #{policyNumber} Created'
    },    
    date: {
        id: 'quoteandbind.views.payment-receipt.Date',
        defaultMessage: 'Date/Time (CST)'
    },
    amount: {
        id: 'quoteandbind.views.payment-receipt.Amount',
        defaultMessage: 'Amount'
    },    
    refNumber: {
        id: 'quoteandbind.views.payment-receipt.RefNumber',
        defaultMessage: 'Ref Number'
    },    
    authCode: {
        id: 'quoteandbind.views.payment-receipt.AuthCode',
        defaultMessage: 'Authorization Code'
    },
    paymentMethod: {
        id: 'quoteandbind.views.payment-receipt.PaymentMethod',
        defaultMessage: 'Payment method'
    },    
    cardholder: {
        id: 'quoteandbind.views.payment-receipt.Cardholder',
        defaultMessage: 'Card Holder'
    },
    emailAddress: {
        id: 'quoteandbind.views.payment-receipt.EmailAddress',
        defaultMessage: 'Email Address'
    },    
    policyCreationInProgress: {
        id: 'quoteandbind.views.payment-receipt.PolicyCreationInProgress',
        defaultMessage: 'Policy Creation In Progress. Please Wait.'
    },    
    policyNumber: {
        id: 'quoteandbind.views.payment-receipt.PolicyNumber',
        defaultMessage: 'Policy Number'
    },
    paymentReceived: {
        id: 'quoteandbind.views.payment-receipt.PaymentReceived',
        defaultMessage: 'Payment Received'
    },    
    paymentDetails: {
        id: 'quoteandbind.views.payment-receipt.PaymentDetails',
        defaultMessage: 'Payment Details'
    },    
    printReceipt: {
        id: 'quoteandbind.views.payment-receipt.PrintReceipt',
        defaultMessage: 'Print Receipt'
    },
});
