import { defineMessages } from 'react-intl';

export default defineMessages({
    excluded: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.PA_28A_ReducAmtForNamedCov',
        defaultMessage: '28A - Excluded &lt;b&gt;{name}&lt;/b&gt;'
    },
    article6Excluded: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.Article6_Excluded_Driver',
        defaultMessage: 'Article6 - Excluded &lt;b&gt;{name}&lt;/b&gt;'
    }
})
