import { defineMessages } from 'react-intl';

export default defineMessages({
    coveragesTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-ho-react.pages.WMICHOPolicyRenewalCoverages.WMICHOPolicyRenewalCoveragesPage.Title',
        defaultMessage: 'Coverages'
    },
    coverages: {
        id: 'policyRenewal.common.views.coverages.Coverages',
        defaultMessage: 'Coverages'
    },
    mainCoverages: {
        id: 'policyRenewal.common.views.coverages.Main Coverages Premium',
        defaultMessage: 'Main Coverages Premium'
    },
    additionalCoverages: {
        id: 'policyRenewal.views.selectAction.cart.Additional Coverages',
        defaultMessage: 'Additional Coverages'
    },
    schedules: {
        id: 'policyRenewal.views.selectAction.cart.Schedules',
        defaultMessage: 'Schedules'
    }
});
