import { defineMessages } from 'react-intl';

export default defineMessages({
    yes: {
        id: 'wmic.policy-change.component.WMICChangeRoadsideAssistanceToggle.yes',
        defaultMessage: 'Yes'
    },
    no: {
        id: 'wmic.policy-change.component.WMICChangeRoadsideAssistanceToggle.no',
        defaultMessage: 'No'
    },
    doesNotCurrentlyHaveThisCoverage: {
        id: 'wmic.policy-change.component.WMICChangeRoadsideAssistanceToggle.doesNotCurrentlyHaveThisCoverage',
        defaultMessage: 'This vehicle does not currently have this coverage'
    },
    doesNotCurrentlyHaveThisCoverageAriaLabel: {
        id: 'wmic.policy-change.component.WMICChangeRoadsideAssistanceToggle.This vehicle does not currently have roadside assistance coverage',
        defaultMessage: 'This vehicle does not currently have roadside assistance coverage'
    },
    vehicleHasThisCoverage: {
        id: 'wmic.policy-change.component.WMICChangeRoadsideAssistanceToggle.vehicleHasThisCoverage',
        defaultMessage: 'This vehicle currently has this coverage'
    },
    vehicleHasThisCoverageAriaLabel: {
        id: 'wmic.policy-change.component.WMICChangeRoadsideAssistanceToggle.This vehicle currently has roadside assistance coverage',
        defaultMessage: 'This vehicle currently has roadside assistance coverage'
    },
    toggleYesApplyThisCoverageAria: {
        id: 'wmic.policy-change.component.WMICChangeRoadsideAssistanceToggle.yesApplyThisCoverageAria',
        defaultMessage: 'Yes, apply roadside assistance for this {vehicle}'
    },
    toggleNoDoNotApplyThisCoverageAria: {
        id: 'wmic.policy-change.component.WMICChangeRoadsideAssistanceToggle.noDoNotApplyThisCovrageAria',
        defaultMessage: 'No, do not apply roadside assistance for this {vehicle}'
    }
})