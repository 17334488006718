import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import _ from 'lodash'
import PropTypes from 'prop-types';

import {
    WMICRPCUtil,
    PAConstants,
    JobType,
    ACTION_TYPES,
    JURISDICTIONS,
    WMICVehicleUtil
} from 'wmic-pe-portals-utils-js';
import { WMICVinSearchComponent } from 'wmic-pe-capability-gateway-common-pa-react'

import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'

import { TranslatorContext } from '@jutro/locale';

import metadata from './WMICVehicleInfoComponent.metadata.json5';
import messages from './WMICVehicleInfoComponent.messages.js'

function WMICVehicleInfoComponent(props) {
    const {
        id,
        vehicleVM,
        updateVehicle,
        jobVM,
        onValidate,
        isEditMode,
        showErrors,
        isReadOnlyUser,
        authHeader,
        vehicleAction,
        reselectVehicle,
        updateReselectVehicle,
        vehicleSearchRef
    } = props;

    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const ViewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);

    const [showVehicleSearch, updateShowVehicleSearch] = useState(false);
    const [initialVinSearch, updateInitialVinSearch] = useState();
    const [availableVICCMotorcycleBodyTypes, updateAvailableVICCMotorcycleBodyTypes] = useState([]);

    const VEHICLE_TYPE_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('VehicleType');
    const VINOVERRIDE_REASON_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('VinOverrideReason_WMIC');
    const VICC_BODY_TYPE_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('VICCBodyType_WMIC');

    const isVehicleType = (typeCode) => _.get(vehicleVM, 'vehicleType.value.code') === typeCode;
    const isVehicleTypeMotorhome = () => isVehicleType(PAConstants.motorhomeVehicleType);
    const isVehicleTypeTrailer = () => isVehicleType(PAConstants.trailerVehicleType);
    const isVehicleTypeAuto = () => isVehicleType(PAConstants.personalAutoVehicleType);
    const isVehicleTypeMotorCycle = () => isVehicleType(PAConstants.motorcycleVehicleType);
    const isRPCEffective = (rpcNumber) => WMICRPCUtil.getIsRPCEffective(jobVM.baseData.baseState.value.code, jobVM.baseData.rateAsOfDate.value, rpcNumber);
    const isVehicleTypeAutoMotorCyleOrMotorHome = () => isVehicleTypeAuto() || isVehicleTypeMotorCycle() || isVehicleTypeMotorhome();
    const isPolicyChange = () => jobVM.baseData.jobType.value.code === JobType.POLICY_CHANGE;
    const isPolicyChangeEdit = () => isPolicyChange() && vehicleAction === ACTION_TYPES.EDIT;
    const isPolicyRenewal = () => jobVM.baseData.jobType.value.code === JobType.RENEWAL;
    const isPolicyRenewalEdit = () => isPolicyRenewal() && vehicleAction === ACTION_TYPES.EDIT;
    const isCommercialVehicle = () => _.get(vehicleVM, 'businessSegment_WMIC.value.code') === PAConstants.vehicleBusinessSegmentCommercial;

    const getBusinessSegmentForVINSearch = () => isCommercialVehicle()
        ? PAConstants.vehicleBusinessSegmentCommercial
        : PAConstants.vehicleBusinessSegmentPersonal;

    const componentValidMap = useMemo(() => ({}), []);

    const componentValidation = useCallback((valid, componentId) => {
        componentValidMap[componentId] = valid;
        if (_.get(vehicleVM, 'vinOverride_WMIC.value') || vehicleAction === ACTION_TYPES.NONE) {
            onValidate(componentValidMap.vehicleInfoComponent, id);
        } else {
            onValidate(
                showVehicleSearch
                    ? componentValidMap.vehicleInfoComponent &&
                          componentValidMap.vinSearchComponent
                    : componentValidMap.vehicleInfoComponent,
                id
            );
        }
    }, [componentValidMap, vehicleVM, vehicleAction, onValidate, id, showVehicleSearch]);

    const resetDetailsTab = () => {
        updateShowVehicleSearch(true);
        updateAvailableVICCMotorcycleBodyTypes([]);

        if (vehicleVM) {
            if (vehicleVM.vehicleType && !vehicleVM.vehicleType.value) {
                _.set(vehicleVM, 'vehicleType.value', VEHICLE_TYPE_TYPELIST.getCode(PAConstants.personalAutoVehicleType));
            }
            if (!vehicleVM.publicID || !vehicleVM.publicID.value) {
                if (isVehicleTypeTrailer() || isVehicleTypeMotorhome()) {
                    updateShowVehicleSearch(false);
                    _.set(vehicleVM, 'vinOverride_WMIC.value', true);
                    _.set(vehicleVM, 'searchCompleted.value', true);
                    _.set(vehicleVM, 'vinOverrideReason_WMIC.value', VINOVERRIDE_REASON_TYPELIST.getCode('NotOnVICC'));
                }
            } else {
                updateShowVehicleSearch(false);
            }
            updateVehicle(vehicleVM);
        }
    };

    const populateAvailableVICCMotorcycleBodyTypes = () => {
        const vehicleType = _.get(vehicleVM, 'vehicleType.value.code');
        const businessSegment = _.get(vehicleVM, 'businessSegment_WMIC.value.code');
        const garageLocationState = _.get(vehicleVM, 'garageLocation.state.value.code');
        const rpc1392Effective = jobVM.baseData.baseState && jobVM.baseData.rateAsOfDate ? WMICRPCUtil.getIsRPCEffectiveForVehType(jobVM.baseData.baseState.value.code, jobVM.baseData.rateAsOfDate.value, vehicleType, '1392') : false;

        const unavailableBodyTypes = {};

        switch (vehicleType) {
            case PAConstants.motorcycleVehicleType:
                if (businessSegment === PAConstants.vehicleBusinessSegmentCommercial || ![JURISDICTIONS.ONTARIO, JURISDICTIONS.NOVA_SCOTIA,
                    JURISDICTIONS.NEW_BRUNSWICK, JURISDICTIONS.PRINCE_EDWARD_ISLAND].includes(garageLocationState)) {
                    unavailableBodyTypes.mc_sp = true;
                }

                if (!rpc1392Effective) {
                    unavailableBodyTypes.mc_en = true;
                    unavailableBodyTypes.mc_es = true;
                    unavailableBodyTypes.mc_ss1 = true;
                    unavailableBodyTypes.mc_nr = true;
                    unavailableBodyTypes.mc_sh = true;
                } else {
                    unavailableBodyTypes.mc_ss = true;
                }

                break;
            case 'auto':
                if (businessSegment === PAConstants.vehicleBusinessSegmentPersonal
                    && garageLocationState === JURISDICTIONS.ONTARIO) {
                    unavailableBodyTypes.pp_2d_p3 = true;
                    unavailableBodyTypes.pp_4d_p5 = true;
                }
                break;
            default:
                break;
        }

        const availableVICCValues = VICC_BODY_TYPE_TYPELIST
            .getCodesForCategory({
                'typelist': { 'name': 'VehicleType' },
                'code': vehicleType,
            }).filter((item) => !Object.keys(unavailableBodyTypes).includes(item.code)).map((item) => {
                const avalibaleVICCJoinKey = {
                    code: item.code,
                    name: translator({ id: item.name })
                }
                return avalibaleVICCJoinKey;
            })

        updateAvailableVICCMotorcycleBodyTypes(availableVICCValues);
    };

    useEffect(() => {
        if (onValidate) {
            componentValidation(isComponentValid, id);
        }
    }, [componentValidation, id, isComponentValid, onValidate]);

    useEffect(() => {
        if (vehicleAction === ACTION_TYPES.ADD) {
            updateInitialVinSearch(ViewModelService.create(
                {},
                'pc',
                'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.VinSearchDTO',
                jobVM.aspects.context()
            ));
        }
    }, [ViewModelService, jobVM.aspects, vehicleAction, vehicleVM]);

    useEffect(() => {
        if (vehicleAction === ACTION_TYPES.ADD) {
            resetDetailsTab();
            populateAvailableVICCMotorcycleBodyTypes();
        }
        if (vehicleAction === ACTION_TYPES.NONE) {
            updateShowVehicleSearch(false);
        }
        if (vehicleAction === ACTION_TYPES.EDIT) {
            populateAvailableVICCMotorcycleBodyTypes();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicleAction]);

    const vinOverrideReasonChanged = (value, path) => {
        if (!isReadOnlyUser) {
            _.set(vehicleVM, path, value);
            _.set(vehicleVM, 'vinOverrideOtherDesc_WMIC.value', undefined);
            updateVehicle(vehicleVM);
        }
    };

    const trailerSubTypeChanged = (value, path) => {
        _.set(vehicleVM, path, value);
        if (!isReadOnlyUser && _.get(vehicleVM, 'commercialVehicle_WMIC.trailerHaulingType.value', false)) {
            _.set(vehicleVM, 'commercialVehicle_WMIC.trailerHaulingType.value', undefined);
        }
        updateVehicle(vehicleVM);
    };

    const clearInspection = (value, path) => {
        _.set(vehicleVM, path, value);
        if (value != null && !value) {
            _.set(vehicleVM, 'vehicleInspectionComplete_WMIC', null);
            _.set(vehicleVM, 'vehicleInspectionDate_WMIC', null);
        }
        updateVehicle(vehicleVM);
    };

    const isAntiTheftDeviceEquippedChanged = (value, path) => {
        _.set(vehicleVM, path, value);
        WMICVehicleUtil.resetAntiTheftFields(vehicleVM);
        updateVehicle(vehicleVM);
    };

    const cannotChangeVIN = () => (isPolicyChangeEdit() || isPolicyRenewalEdit()) && !_.get(vehicleVM, 'vin.value', '').includes('?')
        && !!(vehicleVM.publicID || vehicleVM.publicID.value);

    const repopulateVinSearch = () => {
        const newVinSearch = ViewModelService.create(
            {
                make: vehicleVM.make.value,
                model: vehicleVM.model.value,
                year: vehicleVM.year.value,
                vin: vehicleVM.vin.value,
                vehicleType: vehicleVM.vehicleType.value.code,
                ratingJurisdiction: jobVM.baseData.baseState.value.code,
                baseState: jobVM.baseData.baseState.value.code,
                effectiveDate: jobVM.baseData.periodStartDate.value,
                rateAsOfDate: jobVM.baseData.rateAsOfDate.value,
                transactionEffectiveDate: jobVM.baseData.transactionEffectiveDate.value,
                businessSegment: getBusinessSegmentForVINSearch()
            },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.VinSearchDTO',
            jobVM.aspects.context()
        );
        updateInitialVinSearch(newVinSearch);
    };

    const vinOverrideChange = (value, path) => {
        if (!isReadOnlyUser) {
            _.set(vehicleVM, path, value);
            if (value === false) {
                _.set(vehicleVM, 'make.value', '');
                _.set(vehicleVM, 'model.value', '');
                _.set(vehicleVM, 'bodyStyle_WMIC.value', null);
                _.set(vehicleVM, 'vinOverrideReason_WMIC.value', undefined);
                _.set(vehicleVM, 'vinOverrideOtherDesc_WMIC.value', undefined);
                _.set(vehicleVM, 'vinSearchAttempted_WMIC.value', false);
                _.set(vehicleVM, 'engineStrokeType_WMIC.value', null);
                if (_.get(vehicleVM, 'commercialVehicle_WMIC.value')) {
                    _.set(vehicleVM, 'commercialVehicle_WMIC.bodyType.value', undefined);
                    _.set(vehicleVM, 'commercialVehicle_WMIC.otherBodyTypeDesc.value', undefined);
                }
            }
            updateShowVehicleSearch(!value);
            _.set(vehicleVM, 'searchCompleted.value', value);
            updateVehicle(vehicleVM);

            repopulateVinSearch();
        }
    };

    const editVehicle = () => {
        _.set(vehicleVM, 'searchCompleted.value', false);

        repopulateVinSearch();
        updateVehicle(vehicleVM);
        updateShowVehicleSearch(true);
    };

    const overrideProps = {
        '@field': {
            parentNode: vehicleVM,
            readOnly: !isEditMode || isReadOnlyUser
        },
        vinSearchComponent: {
            initialVinSearch,
            onValidate: componentValidation,
            showErrors,
            isEditMode,
            isReadOnlyUser,
            jobVM,
            authHeader,
            vehicleVM,
            updateShowVehicleSearch,
            updateVehicle,
            updateInitialVinSearch,
            cannotChangeVIN,
            reselectVehicle,
            updateReselectVehicle,
            getBusinessSegmentForVINSearch,
            repopulateVinSearch,
            vehicleSearchRef
        },
        vinSearchContainer: {
            visible: showVehicleSearch || reselectVehicle
        },
        noVinSearchContainer: {
            visible: !showVehicleSearch && !reselectVehicle
        },
        editVehicleContainer: {
            visible: !isReadOnlyUser && isEditMode && !_.get(vehicleVM, 'vinOverride_WMIC.value') && !cannotChangeVIN(),
        },
        editVinButton: {
            onClick: editVehicle
        },
        trailerTitle: {
            title: translator({ id: vehicleVM.vehicleType.value.name }),
            visible: isVehicleTypeTrailer()
        },
        notTrailerTitle: {
            title: translator(messages.notTrailerTitle, { type: translator({ id: vehicleVM.vehicleType.value.name }) }),
            visible: !isVehicleTypeTrailer()
        },
        vehicleTypeInfo: {
            title: translator(messages.vehicleTypeInfo, { type: translator({ id: vehicleVM.vehicleType.value.name }) })
        },
        vinOverride: {
            onValueChange: vinOverrideChange,
            visible: !(isVehicleTypeTrailer() || isVehicleTypeMotorhome()),
            readOnly: !isEditMode || isPolicyChangeEdit() || isPolicyRenewalEdit()
        },
        isVehicleTypeAutoMotorCyleOrMotorHomeContainer: {
            visible: isVehicleTypeAutoMotorCyleOrMotorHome() && isRPCEffective('1265')
        },
        vehicleInspectionRequired: {
            onValueChange: clearInspection,
            readOnly: _.get(jobVM, 'baseData.baseState.value.code') == JURISDICTIONS.ALBERTA
        },
        trailerSubType: {
            onValueChange: trailerSubTypeChanged
        },
        antiTheftDeviceEquipped: {
            onValueChange: isAntiTheftDeviceEquippedChanged
        },
        antiTheftDeviceInstallDate: {
            maxDate: _.get(jobVM, 'baseData.transactionEffectiveDate.value')
        },
        vinOverrideReason: {
            onValueChange: vinOverrideReasonChanged
        },
        bodyStyleReadonly: {
            readOnly: true,
            visible: !_.get(vehicleVM, 'vinOverride_WMIC.value'),
            value: vehicleVM.bodyStyle_WMIC.value ? translator({ id: vehicleVM.bodyStyle_WMIC.value }) : ''
        },
        bodyStyle: {
            visible: _.get(vehicleVM, 'vinOverride_WMIC.value')
        },
        vICCMotorcycleCBodyType: {
            readOnly: !_.get(vehicleVM, 'vinOverride_WMIC.value') || !isEditMode || isReadOnlyUser,
            availableValues: availableVICCMotorcycleBodyTypes
        },
        engineStrokeType: {
            readOnly: !_.get(vehicleVM, 'vinOverride_WMIC.value') || !isEditMode || isReadOnlyUser
        },
        cubicCapacity: {
            readOnly: !_.get(vehicleVM, 'vinOverride_WMIC.value') || !isEditMode || isReadOnlyUser
        },
        vehicleMsrp: {
            readOnly: !isEditMode || isReadOnlyUser || !_.get(vehicleVM, 'vinOverride_WMIC.value')
        },
        specialVehicleType: {
            readOnly: !_.get(vehicleVM, 'vinOverride_WMIC.value') || !isEditMode || isReadOnlyUser
        },
        validVINWarning: {
            visible: !_.get(vehicleVM, 'vinOverride_WMIC.value') && _.get(vehicleVM, 'vin.value', '')?.includes('?')
        },
        cannotChangeVinContainer: {
            visible: isEditMode && cannotChangeVIN()
        },
        year: {
            readOnly: !_.get(vehicleVM, 'vinOverride_WMIC.value') || cannotChangeVIN() || !isEditMode || isReadOnlyUser
        },
        make: {
            readOnly: !_.get(vehicleVM, 'vinOverride_WMIC.value') || cannotChangeVIN() || !isEditMode || isReadOnlyUser
        },
        model: {
            readOnly: !_.get(vehicleVM, 'vinOverride_WMIC.value') || cannotChangeVIN() || !isEditMode || isReadOnlyUser
        },
        vin: {
            readOnly: !_.get(vehicleVM, 'vinOverride_WMIC.value') || cannotChangeVIN() || !isEditMode || isReadOnlyUser
        },
        autonomousEmergencyBrakingSys: {
            readOnly: _.get(vehicleVM, 'autonomousBrakingDiscountDisabled_Ext.value') || !isEditMode || isReadOnlyUser
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICVinSearchComponent
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={vehicleVM}
            overrideProps={overrideProps}
            onModelChange={updateVehicle}
            onValidationChange={setComponentValidation}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

WMICVehicleInfoComponent.propTypes = {
    id: PropTypes.string.isRequired,
    vehicleVM: PropTypes.shape({}).isRequired,
    jobVM: PropTypes.shape({}).isRequired,
    updateVehicle: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
    isReadOnlyUser: PropTypes.bool.isRequired,
    vehicleAction: PropTypes.string.isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired
};

export default WMICVehicleInfoComponent;
