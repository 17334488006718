import React, { useCallback, useState, useEffect, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { WMICButton } from 'wmic-components-platform-react';
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { WMICPageHeader, WMICPageFooter } from 'wmic-components-amp-common-react';
import htmlParser from 'html-react-parser';

import messages from './WMICTechnicalErrorPage.messages';
import styles from './WMICTechnicalErrorPage.module.scss';

const WMICTechnicalErrorPage = () => {
    const translator = useTranslator();
    const history = useHistory();
    const { error } = useParams();
    const [isDocumentError] = useState(error !== undefined && error === 'documentDownload');
    const [isMaintenance] = useState(error !== undefined && error === 'maintenance');
    const [isIssueWithAccount] = useState(error !== undefined && error === 'issueWithAccount');

    const onButtonClick = useCallback(() => {
        return history.push('/');
    }, [history]);

    // One of the recommended solutions for working with SPAs is to set focus on a container element which
    // will help read out the content within the container.
    // See https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const tags = document.getElementsByTagName('main');
        if (tags.length === 1) {
            tags[0].focus();
        }
    }, [])

    return (
        <Fragment>
            <div className="ww-content-limit ww-page-header">
                <div className="ww-card--100w">
                    {(isIssueWithAccount) && (<h1>{translator(messages.issueWithAccountHeader)}</h1>)}
                    {(!isIssueWithAccount) && (<h1>{translator(messages.weAreSorry)}</h1>)}
                </div>
            </div>

            <div className="ww-content-limit ww-main-content">
                <div className={`${styles.wwCard66w} ww-card-vertical-root`}>
                    { (!isDocumentError && !isMaintenance && !isIssueWithAccount)
                        && (
                            <div className="ww-card ww-card-footer" id="policyDetails">
                                <div className="ww-card__content">
                                    <div className="gw-error-contact">
                                        <div>{WMICRichTextUtil.translateRichText(translator(messages.technicalDifficulty))}</div>
                                        <div className="my-5">{translator(messages.thankYouMessage)}</div>
                                    </div>
                                    <div className="my-5">
                                        <nav>
                                            <WMICButton type="primary" onClick={onButtonClick}>
                                                {translator(messages.backToHome)}
                                            </WMICButton>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {isDocumentError
                        && (
                            <div className="ww-card ww-card-footer" id="documentDownload">
                                <div className="ww-card__content">
                                    <div className="gw-error-contact">
                                        <p>{translator(messages.cannotAccessDocument)}</p>
                                        <p>{WMICRichTextUtil.translateRichText(translator(messages.tryAgainDocument))}</p>
                                    </div>
                                    <br />
                                    <div>
                                        <nav>
                                            <WMICButton type="primary" onClick={onButtonClick}>
                                                {translator(messages.backToHome)}
                                            </WMICButton>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {isMaintenance
                        && (
                            <div className="ww-card ww-card-footer" id="maintenance">
                                <div className="ww-card__content">
                                    <div className="gw-error-contact">
                                        <div className="mb-3">{WMICRichTextUtil.translateRichText(translator(messages.unavailableDueToMaintenance))}</div>
                                        <div className="my-5">{translator(messages.thankYouMessage)}</div>
                                    </div>
                                    <div>
                                        <p>{translator(messages.thankYou)}</p>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        )
                    }
                    {isIssueWithAccount
                        && (
                            <div className="ww-card ww-card-footer" id="maintenance">
                                <div className="ww-card__content">
                                    <div className="gw-error-contact">
                                        <p>
                                            {htmlParser(translator(messages.issueWithAccount))}
                                        </p>
                                        <br />
                                        <p>{WMICRichTextUtil.translateRichText(translator(messages.issueWithAccountContactUs))}</p>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </Fragment>
    );
};

WMICTechnicalErrorPage.propTypes = {};

export default WMICTechnicalErrorPage;