import React, { useState, useCallback, useContext, useMemo } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { LOBConstants, ICON_NAMES, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { useDocumentTitle } from 'wmic-pe-portals-wizard-components-ui';
import { TranslatorContext } from '@jutro/locale';
import { Tab } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import metadata from './WMICLandingPage.metadata.json5';
import WMICDataTableTab from './components/WMICDataTableTab/WMICDataTableTab';
import styles from './WMICLandingPage.module.scss';
import messages from './WMICLanding.messages.js';
import tableData, { readOnlyUserTableData } from './WMICLandingPageTableData';

function WMICLandingPage() {
    const history = useHistory();
    const { authUserData } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const [searchByValue, setSearchByValue] = useState('all');
    const isReadRole = useMemo(() => WMICUserAccessUtil.isReadOnlyUser(authUserData.permission_Ext), [authUserData.permission_Ext]);
    const canCreateSubmission = useMemo(() => WMICUserAccessUtil.canCreateSubmission(authUserData.permission_Ext), [authUserData.permission_Ext]);

    const goToNewQuote = useCallback((lob) =>
        history.push('/wmic-new-quote-account-search', { productCode: lob }), [history]);

    const tabList = !isReadRole ? tableData : readOnlyUserTableData;
    const renderTabs = () => tabList.map((tableDataItem, idx) =>
        <Tab id={`wmixLandingPageTab${idx}`} heading={translator(tableDataItem.tabHeading)} key={idx} className={styles.tabButton}>
            <WMICDataTableTab
                producerCodeValue={searchByValue}
                handleSearchByValueChange={setSearchByValue}
                displayColumns={tableDataItem.displayColumns(translator)}
                loadDataAsync={tableDataItem.loadDataAsyncSubmission}
                hideFilters={tableDataItem.hideFilters}
                tabHeading={tableDataItem.tabHeading}
            />
        </Tab>
    )

    const override = {
        wmicLandingPageTabs: {
            content: renderTabs()
        },
        accessRestrictsSavingChangesMessage: {
            visible: isReadRole
        },
        newSubmissionButton: {
            visible: canCreateSubmission,
            onItemClick: goToNewQuote,
            items: [
                { id: 'newSubmissionPA', text: translator(messages.autoMobileLink), icon: ICON_NAMES.ICON_CAR, code: LOBConstants.PA },
                { id: 'newSubmissionPP', text: translator(messages.personalPropertyLink), icon: ICON_NAMES.ICON_BUILDING, code: LOBConstants.PP },
                { id: 'newSubmissionPUP', text: translator(messages.personalUmbrellaLink), icon: ICON_NAMES.ICON_UMBRELLA, code: LOBConstants.PUP },
                { id: 'newSubmissionCP', text: translator(messages.commercialSmallBusinessLink), icon: ICON_NAMES.ICON_COMMERCIAL, code: LOBConstants.CP },
            ]
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            WMICDataTableTab
        }
    };

    useDocumentTitle('Policies');

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={override}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default withRouter(WMICLandingPage);
