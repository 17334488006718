/* eslint-disable max-len */
import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from 'wmic-digital-auth-react';
import { useTranslator } from '@jutro/locale';
import { publish, JUTRO_TOPICS } from "@jutro/events";
import { CONSTANTS, WMICRichTextUtil } from 'wmic-portals-utils-js';
import { WMICClickableIconTooltipComponent } from 'gw-capability-policy-common-react';
import {
    WMICControllerUtil,
    WMICPolicyChangeUtilsService,
    EditUninsuredModel,
    VehicleModel,
    WMICPolicyChangeStateService
} from 'wmic-capability-policychange';
import { WMICButton } from 'wmic-components-platform-react';
import { useModal } from '@jutro/components';
import { WMICModal } from 'gw-capability-policy-react';
import metadata from './WMICEditUninsuredComponent.metadata.json5';
import messages from './WMICEditUninsuredComponent.messages';
import styles from './WMICEditUninsuredComponent.module.scss';

function WMICEditUninsuredComponent(props) {
    const modalApi = useModal();
    const {
        policyVM,
        match,
        history,
        loadingState,
        setLoadingState
    } = props;
    const translator = useTranslator();
    const [hasChanged, updateHasChanged] = useState(false);
    const [vehiclesModel, updateVehiclesModel] = useState();

    const { authHeader, userInfo, authUserData } = useAuthentication();

    const policyNumber = _.get(match, 'params.policyNumber');
    const termNumber = _.get(match, 'params.termNumber');
    const policyType = _.get(match, 'params.policyType');
    const accountHolder = _.get(policyVM, 'currentPeriod.contacts')
        .filter((contact) => { return contact.contact.accountHolder === true; })[0];

    // To resolve issues where screen readers don't announce content on page load for SPAs
    // Reference: https://www.gatsbyjs.com/blog/2019-07-11-user-testing-accessible-client-routing/
    useEffect(() => {
        const headerTag = document.getElementById('editUninsuredHeader');
        if (headerTag && !loadingState) {
            headerTag.focus({preventScroll: true});
        }
    }, [loadingState])

    const getEditUninsuredTitle = () => {
        const title = translator(messages.editUninsuredTitle);

        return (
            <Fragment>
                <i className={`fa fa-file-text ${styles.titleIconBlue}`} />
                <h2 className={styles['wmic-policy-title']}>{title}</h2>
            </Fragment>
        );
    };

    const getVehiclesModel = (vehicles) => {
        const model = vehicles.map((vehicle) => {
            const collision = vehicle.coverages.find((cov) => cov.patternCode_WMIC === EditUninsuredModel.coverageInfo.collisionDeductibleWaiver);
            let propDamage = vehicle.coverages.find((cov) => cov.patternCode_WMIC === EditUninsuredModel.coverageInfo.propertyDamage);
            const vehicleManufacturerReplacementParts = vehicle.coverages.find((cov) => cov.patternCode_WMIC === EditUninsuredModel.coverageInfo.vehicleManufacturerReplacementParts);

            if (!propDamage) {
                propDamage = {
                    limit: false
                };
            }
            return {
                vehicle: {
                    year: vehicle.year,
                    make: vehicle.make,
                    model: vehicle.model,
                    vin: vehicle.vin,
                    vehicleNumber: vehicle.vehicleNumber
                },
                coverages: {
                    propertyDamage: propDamage,
                    collisionDeductibleWaiver: collision,
                    vehicleManufacturerReplacementParts: vehicleManufacturerReplacementParts
                },
                initialPropertyDamage: {
                    value: !!propDamage.limit
                },
                initialCollisionDeductibleWaiver: {
                    value: !!collision
                },
                initialVehicleManufacturerReplacementParts: {
                    value: !!vehicleManufacturerReplacementParts
                },
                hasPropertyDamage: {
                    value: !!propDamage.limit
                },
                hasCollisionDeductibleWaiver: {
                    value: !!collision
                },
                hasVehicleManufacturerReplacementParts: {
                    value: !!vehicleManufacturerReplacementParts
                }
            };
        });
        return model;
    };

    const isCollisionDedWaiverVisible = () => {
        return (_.get(policyVM, 'currentPeriod.policyAddress.country') === CONSTANTS.COUNTRY.US
            && _.get(policyVM, 'currentPeriod.policyAddress.state') === CONSTANTS.STATE.CA);
    };

    const checkForChanges = (item) => {
        return (item.hasCollisionDeductibleWaiver.value !== item.initialCollisionDeductibleWaiver.value
            || item.hasPropertyDamage.value !== item.initialPropertyDamage.value
            || item.hasVehicleManufacturerReplacementParts.value !== item.initialVehicleManufacturerReplacementParts.value);
    };

    const onPropertyDamageChange = useCallback((value, index) => {
        const vehModel = vehiclesModel;
        vehModel[index].hasPropertyDamage.value = value;
        let changed = false;
        vehModel.forEach((vehicle) => {
            if (checkForChanges(vehicle)) {
                changed = true;
            }
        });
        updateHasChanged(changed);
        updateVehiclesModel([...vehModel]);
    }, [vehiclesModel]);

    const onCollisionDeductibleWaiverChange = useCallback((value, index) => {
        const vehModel = vehiclesModel;
        vehModel[index].hasCollisionDeductibleWaiver.value = value;
        let changed = false;
        vehModel.forEach((vehicle) => {
            if (checkForChanges(vehicle)) {
                changed = true;
            }
        });
        updateHasChanged(changed);
        updateVehiclesModel([...vehModel]);
    }, [vehiclesModel]);

    const onVehManRepPartsChange = useCallback((value, index) => {
        const vehModel = vehiclesModel;
        vehModel[index].hasVehicleManufacturerReplacementParts.value = value;
        let changed = false;
        vehModel.forEach((vehicle) => {
            if (checkForChanges(vehicle)) {
                changed = true;
            }
        });
        updateHasChanged(changed);
        updateVehiclesModel([...vehModel]);
    }, [vehiclesModel]);

    const onCancel = useCallback(() => {
        return history.push(`/account-policy-change/${policyNumber}/${termNumber}/${policyType}/select-type`);
    }, [history, policyNumber, policyType, termNumber]);

    const parseToModel = (model) => {
        return {
            changeType: 'edit-uninsured',
            objectType: 'EditUninsuredModel',
            changeModel: model
        };
    };

    const onSubmit = useCallback(() => {
        const changes = vehiclesModel.filter((item) => {
            return (checkForChanges(item));
        }).map((item) => {
            const model = new EditUninsuredModel({});
            model.vehicle = new VehicleModel(item.vehicle);
            if (item.hasPropertyDamage.value !== item.initialPropertyDamage.value) {
                model.propertyDamage = item.hasPropertyDamage.value ? 'Add' : 'Remove';
            }
            if (item.hasCollisionDeductibleWaiver.value !== item.initialCollisionDeductibleWaiver.value) {
                model.collisionDeductibleWaiver = item.hasCollisionDeductibleWaiver.value ? 'Add' : 'Remove';
            }
            if (item.hasVehicleManufacturerReplacementParts.value !== item.initialVehicleManufacturerReplacementParts.value) {
                model.vehicleManufacturerReplacementParts = item.hasVehicleManufacturerReplacementParts.value ? 'Add' : 'Remove';
            }
            model.country = _.get(policyVM, 'currentPeriod.policyAddress.country');
            model.state = _.get(policyVM, 'currentPeriod.policyAddress.state');
            return model;
        });

        const title = WMICRichTextUtil.safeUnescape(translator(messages.confirmModalBody));
        const modalBody = WMICPolicyChangeUtilsService.getModifiedBody(changes, title, translator)
        modalApi.showModal(
            <WMICModal
                id="editUninsuredSubmitModal"
                modalHeader={translator(messages.confirmModalTitle)}
                modalBody={modalBody}
                onConfirmCallback={() => {
                    setLoadingState(true);

                    publish(JUTRO_TOPICS.BUTTON_CLICKED, { trackButtonIdentifier: translator(messages.submitButtonTracking)});

                    WMICPolicyChangeStateService.addChangeRequest('edit-uninsured', 'change', parseToModel(changes));
                    WMICControllerUtil.handleSubmit({
                        history: history,
                        policyNumber: policyNumber,
                        termNumber: termNumber,
                        policyData: policyVM,
                        policyType: policyType,
                        accountInfo: accountHolder,
                        authHeader: authHeader,
                        authUserData: authUserData,
                        userInfo: userInfo
                    },
                    translator);
                }}
                onCancelCallback={() => _.noop()}
                confirmButtonText={translator(messages.confirmationLabel)}
                cancelButtonText={translator(messages.cancel)}
            />
        );
    }, [
        accountHolder,
        authHeader,
        history,
        policyNumber,
        policyType,
        policyVM,
        termNumber,
        translator,
        vehiclesModel
    ]);

    useEffect(() => {
        setLoadingState(true);
        const controllerUtilProps = {
            history: history,
            policyNumber: policyNumber,
            termNumber: termNumber,
            policyType: policyType,
            accountHolder: accountHolder,
            authHeader: authHeader,
            authUserData: authUserData,
            userInfo: userInfo
        };

        WMICControllerUtil.getPolicyData(controllerUtilProps)
            .then((polData) => {
                const vehicles = _.get(polData, 'currentPeriod.lobs.personalAuto.vehicleDTOs');
                const vehsModel = getVehiclesModel(vehicles);
                updateVehiclesModel(vehsModel);
            })
            .finally(() => {
                setLoadingState(false);
            });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatTitle = (title, tooltip) => {
        return (
            <Fragment>
                <h3 className={`${styles.covTitleContent}`}>{title}</h3>
                <WMICClickableIconTooltipComponent 
                    tooltipMessage={tooltip} 
                    tooltipAriaLabel={translator(messages.tooltipAria, {title})}
                    // tooltipMessageAriaLabel={}
                />
            </Fragment>
        );
    };

    const uninsuredMotoristPDOverrides = useMemo(() => {
        if (vehiclesModel) {
            const vehModel = vehiclesModel;
            const vehicleOverrides = vehModel.map((veh, index) => {
                return {
                    [`uninsuredMotoristPDToggle${index}`]: {
                        className: styles.euToggle,
                        defaultValue: veh.initialPropertyDamage.value,
                        value: veh.hasPropertyDamage.value,
                        onValueChange: (value) => onPropertyDamageChange(value, index)
                    },
                    [`umpdVehicleInfo${index}`]: {
                        className: styles.vehicleInfoLabel,
                        content: `${veh.vehicle.year} ${veh.vehicle.make} ${veh.vehicle.model} (${veh.vehicle.vehicleNumber})`
                    },
                    [`umpdHasInitialCoverage${index}`]: {
                        className: styles.initialCovLabel,
                        content: veh.initialPropertyDamage.value ? translator(messages.hasInitialCoverage) : translator(messages.noInitialCoverage)
                    },
                    [`umpdHasInitialCoverageDivAria${index}`]: {
                        content: veh.initialPropertyDamage.value ? translator(messages.umpdhasInitialCoverageDivAria) : translator(messages.umpdnoInitialCoverageDivAria)
                    }
                };
            });

            return {
                uninsuredMotoristPDTitle: {
                    content: formatTitle(translator(messages.uninsuredMotoristPDTitle), translator(messages.umpdTooltip)),
                    className: styles.covTitle
                },
                uninsuredMotoristPDIterable: {
                    className: styles.euIterable,
                    data: vehiclesModel
                },
                ...Object.assign({}, ...vehicleOverrides)
            };
        }
        return undefined;
    }, [onPropertyDamageChange, translator, vehiclesModel]);

    const uninsuredMotoristDWOverrides = useMemo(() => {
        if (isCollisionDedWaiverVisible() && vehiclesModel) {
            const vehModel = vehiclesModel;
            const vehicleOverrides = vehModel.map((veh, index) => {
                return {
                    [`uninsuredMotoristDWToggle${index}`]: {
                        className: styles.euToggle,
                        defaultValue: veh.initialCollisionDeductibleWaiver.value,
                        value: veh.hasCollisionDeductibleWaiver.value,
                        onValueChange: (value) => onCollisionDeductibleWaiverChange(value, index)
                    },
                    [`umdwVehicleInfo${index}`]: {
                        className: styles.vehicleInfoLabel,
                        content: `${veh.vehicle.year} ${veh.vehicle.make} ${veh.vehicle.model} (${veh.vehicle.vehicleNumber})`
                    },
                    [`umdwHasInitialCoverage${index}`]: {
                        className: styles.initialCovLabel,
                        content: veh.initialCollisionDeductibleWaiver.value ? translator(messages.hasInitialCoverage) : translator(messages.noInitialCoverage)
                    },
                    [`umdwHasInitialCoverageDivAria${index}`]: {
                        content: veh.initialCollisionDeductibleWaiver.value ? translator(messages.umdwhasInitialCoverageDivAria) : translator(messages.umdwnoInitialCoverageDivAria)
                    },

                };
            });

            return {
                uninsuredMotoristDWTitle: {
                    content: formatTitle(translator(messages.uninsuredMotoristDWTitle), translator(messages.umdwTooltip)),
                    className: styles.covTitle
                },
                uninsuredMotoristDWIterable: {
                    className: styles.euIterable,
                    data: vehiclesModel
                },
                ...Object.assign({}, ...vehicleOverrides)
            };
        }
        return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onCollisionDeductibleWaiverChange, translator, vehiclesModel]);

    const vehManRepPartsOverrides = useMemo(() => {
        if (vehiclesModel) {
            const vehModel = vehiclesModel;
            const vehicleOverrides = vehModel.map((veh, index) => {
                return {
                    [`vehManufRepPartsToggle${index}`]: {
                        className: styles.euToggle,
                        defaultValue: veh.initialVehicleManufacturerReplacementParts.value,
                        value: veh.hasVehicleManufacturerReplacementParts.value,
                        onValueChange: (value) => onVehManRepPartsChange(value, index)
                    },
                    [`vmrpVehicleInfo${index}`]: {
                        className: styles.vehicleInfoLabel,
                        content: `${veh.vehicle.year} ${veh.vehicle.make} ${veh.vehicle.model} (${veh.vehicle.vehicleNumber})`
                    },
                    [`vmrpHasInitialCoverage${index}`]: {
                        className: styles.initialCovLabel,
                        content: veh.initialVehicleManufacturerReplacementParts.value ? translator(messages.hasInitialCoverage) : translator(messages.noInitialCoverage)
                    },
                    [`vmrpHasInitialCoverageDivAria${index}`]: {
                        content: veh.initialVehicleManufacturerReplacementParts.value ? translator(messages.vmrphasInitialCoverageDivAria) : translator(messages.vmrpnoInitialCoverageDivAria)
                    }
                };
            });

            return {
                vehManufRepPartsTitle: {
                    content: formatTitle(translator(messages.vehManufRepPartsTitle), translator(messages.vmrpTooltip)),
                    className: styles.covTitle
                },
                vehManufRepPartsSubtitle: {
                    content: translator(messages.vehManufRepPartsSubtitle)
                },
                vehManufRepPartsIterable: {
                    className: styles.euIterable,
                    data: vehiclesModel
                },
                ...Object.assign({}, ...vehicleOverrides)
            };
        }
        return undefined;
    }, [onVehManRepPartsChange, translator, vehiclesModel]);

    const overrideProps = {
        editUninsuredContainer: {
            visible: !loadingState
        },
        editUninsuredTitle: {
            content: getEditUninsuredTitle()
        },
        ...uninsuredMotoristPDOverrides,
        uninsuredMotoristDWContainer: {
            visible: isCollisionDedWaiverVisible()
        },
        ...uninsuredMotoristDWOverrides,
        ...vehManRepPartsOverrides,
        cancelButton: {
            content: translator(messages.cancel),
            onClick: () => onCancel()
        },
        submitButton: {
            content: translator(messages.continue),
            disabled: !hasChanged,
            onClick: () => onSubmit()
        },
        uninsuredMotoristPDToggle: {
            componentProps: {
                availableValues: [
                    {
                      "displayName": "Yes",
                      "id": "true"
                    },
                    {
                      "displayName": "No",
                      "id": "false"
                    }
                  ]
            }
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICButton,
            wmicclickableicontooltipcomponent: WMICClickableIconTooltipComponent
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICEditUninsuredComponent.propTypes = {
    policyVM: PropTypes.shape({}).isRequired,
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};

export default WMICEditUninsuredComponent;
