/* eslint-disable no-unused-vars */
import React, {useCallback, useContext, useState, useEffect} from 'react';
import _ from 'lodash';
import {TranslatorContext} from '@jutro/locale';
import { DropdownSelectField, InputField } from '@jutro/legacy/components';
import {useWizardModals} from 'wmic-pe-portals-wizard-components-ui';
import {useAuthentication} from '@xengage/gw-digital-auth-react';
import {ViewModelForm, ViewModelServiceContext} from '@xengage/gw-portals-viewmodel-react';
import {SearchService} from 'wmic-pe-capability-gateway';
import {DisplayColumn} from '@jutro/legacy/datatable';
import {WMICButton} from 'wmic-pe-components-platform-react';
import {SelectProducerCode} from 'gw-gateway-common-react';
import { CONSTANTS, WMICLogger, WMICAddressDetailsUtil } from 'wmic-pe-portals-utils-js';
import cx from 'classnames';
import {messages as commonMessages} from '@xengage/gw-platform-translations';
import useAsyncFiltering from '../../../wmic-pe-capability-gateway-react/LandingPage/hooks/useAsyncFiltering';

import messages from './WMICAdlIntGoldSearch.messages';
import metadata from './WMICAdlIntGoldSearch.metadata.json5';
import styles from './WMICAdlIntGoldSearch.module.scss';


function WMICAdlIntGoldSearch(props) {
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const {authHeader} = useAuthentication();
    const { showError } = useWizardModals();

    const [searchResults, setSearchResults] = useState([]);
    const [searchKeyword, setKeyword] = useState('');
    const [searchProvince, setSearchProvince] = useState('');
    const [searchCity, setSearchCity] = useState('');
    const [searchPostalCode, setSearchPostalCode] = useState('');
    const [goldContactSearchVM, setGoldContactSearchVM] = useState({});
    const [countryList, setCountryList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [tableVisible, setTableVisible] = useState(false);
    const [tooManyResultsErrorTextVisible, setTooManyResultsErrorTextVisible] = useState(false);
    const [postalCodeInvalid, setPostalCodeInvalid] = useState(false);

    const {
        additionalInterestVM,
        onGoldContactSelected,
        onGoldListSearched,
        onGoldListSearching,
        jobNumber,
        lob
    } = props;

    useEffect(() => {
        let selectedAIType = CONSTANTS.ADDITIONAL_INTEREST_TYPES.MORTGAGEE;
        if (additionalInterestVM.additionalInterestType && additionalInterestVM.additionalInterestType.value?.code) {
            selectedAIType = additionalInterestVM.additionalInterestType.value.code;
        }
        const goldContactSearchVMData = viewModelService.create(
            {
                address: {country: CONSTANTS.COUNTRY.CA},
                additionalInterestType: selectedAIType,
                pageSize: CONSTANTS.GOLD_LIST_PAGE_SIZE,
                page: 1,
                name: searchKeyword
            },
            'pc',
            'wmic.edge.ca.capabilities.gateway.account.search.dto.AdditionalInterestCompanyGoldListSearchCriteriaDTO'
        );
        const goldContactSearchData = _.get(goldContactSearchVMData, 'address');
        setGoldContactSearchVM(goldContactSearchData);
        setCountryList(_.get(goldContactSearchVMData, 'address.country.aspects.availableValues'));
        setProvinceList(_.get(goldContactSearchVMData, 'address.state.aspects.availableValues'));
    }, []);

    const getContactName = (items, index, {path: property}) => {
        return <span className={styles.wmicWordWrapDataTableCell}>{items.companyName}</span>;
    };

    const getAddress = (items, index, {path: property}) => {
        return (
            <div className={styles.wmicWordWrapDataTableCell}>
                <span>{items.address.addressLine1}</span><br/>
                { items.address.addressLine2 && <> <span>{items.address.addressLine2}</span><br/> </> }
                <span>{items.address.city}, {items.address.state}</span>
            </div>
        )
    };

    const getPostalCode = (items, index, {path: property}) => {
        return <span>{items.address && items.address.postalCode}</span>;
    };

    const onSelect = (value, index) => {
        onGoldContactSelected(value);
    };

    const selectButton = (items, index, {path: property}) => {
        return (
            <WMICButton
                size="medium"
                type="secondary-filled"
                className={styles.selectButton}
                onClick={() => onSelect(items, index)}
            >
                {translator(messages.selectMessage)}
            </WMICButton>
        );
    };

    const handleSearchValueChange = ((keyword) => {
        setKeyword(keyword);
    });

    const handleProvinceValueChange = ((province) => {
        setSearchProvince(province);
    });

    const handleCityValueChange = ((city) => {
        setSearchCity(city);
    });

    const handlePostalCodeValueChange = ((postalCode) => {
        setSearchPostalCode(postalCode);
        if (WMICAddressDetailsUtil.validatePostalCode(postalCode)) {
            setPostalCodeInvalid(false);
        }
    });

    const loadDataAsyncSubmission = useCallback((page, pageSize, queryParams, _authHeader, additionalFilters) => {
        if (searchKeyword.length === 0) {
            // if searchKeyword is empty, we don't call the server.
            // this case happens on component initialization.
            return new Promise(((resolve) => {
                resolve({rows: [], numberOfRows: 0});
            }));
        }

        let selectedAIType = CONSTANTS.ADDITIONAL_INTEREST_TYPES.MORTGAGEE;
        if (additionalInterestVM.additionalInterestType && additionalInterestVM.additionalInterestType.value?.code) {
            selectedAIType = additionalInterestVM.additionalInterestType.value.code;
        }

        const addressFilter = {country: CONSTANTS.COUNTRY.CA};
        const { state, city } = additionalFilters || {};
        addressFilter.state = searchProvince !== '' && searchProvince !== undefined ? searchProvince : state;
        addressFilter.city = searchCity !== '' ? searchCity : city;
        addressFilter.postalCode = searchPostalCode.trim();

        const goldMock = viewModelService.create(
            {
                address: addressFilter,
                additionalInterestType: selectedAIType,
                pageSize: pageSize,
                page: page + 1,
                name: searchKeyword,
                jobNumber: jobNumber,
                lob: lob
            },
            'pc',
            'wmic.edge.ca.capabilities.gateway.account.search.dto.AdditionalInterestCompanyGoldListSearchCriteriaDTO'
        );

        onGoldListSearching(true);

        return SearchService.additionalInterestCompanyGoldListSearch(goldMock.value, authHeader).then((response) => {
            setTooManyResultsErrorTextVisible(false);
            const simpleResponse = [];
            const {
                searchResultAddresses,
                searchResultTotal
            } = response;

            searchResultAddresses && searchResultAddresses.forEach((submission) => {
                submission.street = submission.address.addressLine1;
                submission.province = submission.address.state;
                submission.country = submission.address.country;
                submission.city = submission.address.city;
                simpleResponse.push(submission);
            });
            setSearchResults(simpleResponse)
            return {
                rows: simpleResponse,
                numberOfRows: searchResultTotal,
            }
        }).catch((error) => {
            const tooManyResultsMessage = translator(messages.tooManyResults);
            if (_.includes(error.baseError, tooManyResultsMessage)) {
                setTooManyResultsErrorTextVisible(true);
            } else {
                WMICLogger.error('Error additional Interest Company GoldListSearch', error);

                showError({
                    title: commonMessages.genericError,
                    message: commonMessages.genericErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                })
            }
            return {
                rows: [],
                numberOfRows: 0
            }
        }).finally(() => {
            onGoldListSearching(false);
        });
    }, [additionalInterestVM.additionalInterestType, authHeader, searchKeyword, searchProvince, searchCity, searchPostalCode, showError, translator, viewModelService]);

    const renderHeaders = () => {
        const displayColumns = [
            {
                id: "name",
                path: "name",
                textAlign: "left",
                columnProportion: 2,
                renderCell: getContactName,
                sortable: false,
                header: {
                    id: 'wmic.quote-request.risk.Name',
                    defaultMessage: translator(messages.nameMessage),
                },
            },
            {
                id: "address",
                path: "address",
                textAlign: "left",
                columnProportion: 4,
                renderCell: getAddress,
                sortable: false,
                header: {
                    id: "wmic.quote-request.risk.Address",
                    defaultMessage: translator(messages.addressMessage),
                },
            },
            {
                id: "postalcode",
                path: "postalcode",
                textAlign: "left",
                renderCell: getPostalCode,
                sortable: false,
                header: {
                    id: "wmic.quote-request.risk.Postal Code",
                    defaultMessage: translator(messages.postalCodeMessage),
                },
            },
            {
                id: "select",
                path: "select",
                textAlign: "left",
                renderCell: selectButton,
                sortable: false,
                header: {
                    id: "wmic.quote-request.risk.Select",
                    defaultMessage: translator(messages.selectMessage),
                },
            }
        ];

        return displayColumns.map((displayColumn) =>
            <DisplayColumn
                {...displayColumn}
                textAlign="left"/>
        )
    };

    const provinceDropdown = (properties) => {
        return (
            <DropdownSelectField
                {...properties}
                availableValues={provinceList.map((province) => ({
                    code: province.code,
                    name: province.description
                }))}
            />
        )
    }

    const {
        data: asyncTableData,
        isLoading: asyncIsLoading,
        config,
        handleConfigChange,
        totalDataCount
    } = useAsyncFiltering(loadDataAsyncSubmission, '', {page: 0, pageSize: CONSTANTS.GOLD_LIST_PAGE_SIZE});

    const performSearch = () => {
        if (!WMICAddressDetailsUtil.validatePostalCode(searchPostalCode)) {
            setPostalCodeInvalid(true);
        } else {
            onGoldListSearched();
            const newConfig = { ...config };
            handleConfigChange(newConfig)
            setTableVisible(true);
        }
    };

    const isSearchButtonDisabled = useCallback(() => {
        return asyncIsLoading || searchKeyword.length === 0;
    }, [searchKeyword, asyncIsLoading]);

    const handleConvertPostalCodeToUppercase = ((event) => {
        setSearchPostalCode(event.target.value.toUpperCase());
    });

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showRequired: true,
            parentNode: goldContactSearchVM,
        },
        goldSearchContactTable: {
            data: asyncTableData,
            config: config,
            onConfigChange: handleConfigChange,
            numberOfRows: totalDataCount,
            content: renderHeaders(),

        },
        wmicTableLoadingText: {
            className: cx(styles.wmicTableLoadingText, asyncIsLoading ? styles.isLoading : null)
        },
        searchInput: {
            value: searchKeyword
        },
        searchFieldContainer : {
            componentMap: {
                provinceDropdown
            },
        },
        provinceSearchDropdown: {
            value: searchProvince
        },
        citySearchInput: {
            value: searchCity
        },
        postalCodeSearchInput: {
            value: searchPostalCode
        },
        searchButton: {
            disabled: isSearchButtonDisabled()
        },
        searchRule : {
            visible: !tableVisible
        },
        wmicTabTableWrapper: {
            visible: tableVisible
        },
        tooManyResults: {
            visible: tooManyResultsErrorTextVisible
        },
        postalCodeInvalidText: {
            visible: postalCodeInvalid
        }
    };

    const resolvers = {
        resolveComponentMap: {
            SelectProducerCode,
            provinceDropdown,
            InputField
        },
        resolveCallbackMap: {
            onPerformSearch: performSearch,
            getContactName: getContactName,
            getAddress: getAddress,
            getPostalCode: getPostalCode,
            handleSearchValueChange: handleSearchValueChange,
            handleProvinceValueChange: handleProvinceValueChange,
            handleCityValueChange: handleCityValueChange,
            handlePostalCodeValueChange: handlePostalCodeValueChange,
            handleConvertPostalCodeToUppercase: handleConvertPostalCodeToUppercase
        },
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={goldContactSearchVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

export default WMICAdlIntGoldSearch;
