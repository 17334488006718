import { JobType } from 'wmic-pe-portals-utils-js';

function canEditJob(permission, jobType) {
    const jobPermission = {
        [JobType.SUBMISSION]: !!permission?.canEditSubmission,
        [JobType.POLICY_CHANGE]: !!permission?.canEditPolicyChange,
        [JobType.RENEWAL]: !!permission?.canEditRenewal
    };
    return jobPermission[jobType] ?? false;
}

export default {
    canBindPolicyChange: (permission) => !!permission?.canBindPolicyChange,
    canBindSubmission: (permission) => !!permission?.canBindSubmission,
    canCreateCancellation: (permission) => !!permission?.canCreateCancellation,
    canCreateDocument: (permission) => !!permission?.canCreateDocument,
    canCreateSubmission: (permission) => !!permission?.canCreateSubmission,
    canEditAccount: (permission) => !!permission?.canEditAccount,
    canEditJob: (permission, jobType) => canEditJob(permission, jobType),
    canEditPaymentPlan: (permission) => !!permission?.canEditPaymentPlan,
    canEditPolicyChange: (permission) => !!permission?.canEditPolicyChange,
    canEditRenewal: (permission) => !!permission?.canEditRenewal,
    canEditSubmission: (permission) => !!permission?.canEditSubmission,
    canQuote: (permission) => !!permission?.canQuote,
    canWithdrawTransaction: (permission) => !!permission?.canWithdrawTransaction,
    isReadOnlyUser: (permission) => !!permission?.isReadOnlyUser ?? true
};
