/* eslint-disable no-secrets/no-secrets */
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import _ from 'lodash';

import { TranslatorContext } from '@jutro/locale';

import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import {WMICValidationUtil, WMICLogger, FlowStepId, WMICVariousUtil} from 'wmic-pe-portals-utils-js';
import { useWizardModals, useDocumentTitle, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICHOConstructionDetailView, presentConstruction, WMICKitchenBathValidator } from 'wmic-pe-capability-gateway-common-ho-react';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';

import * as WMICHOYourHomeUtil from 'wmic-pe-capability-gateway-quoteandbind-ho-react/util/WMICHOYourHomeUtil'
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICHOConstructionPage.metadata.json5';
import messages from './WMICHOConstructionPage.messages';

const DWELLINGS_PATH = 'lobData.homeowners.coverables.dwellings';

function WMICHOConstructionPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;

    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const [isEditingDwelling , setIsEditingDwelling] = useState(false)

    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { initialValidation, onValidate, isComponentValid, registerInitialComponentValidation} = useValidation('WMICHOConstructionPage');
    const { authHeader } = useAuthentication();
    const { setWizardLoading, showError } = useWizardModals();

    useDocumentTitle(translator(messages.constructionTitle), submissionVM);

    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState()
    const [hasDwellings, setHasDwellings] = useState(false);

    useEffect(() => {
        registerInitialComponentValidation(() =>
            presentConstruction(submissionVM)
                && !WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.HO_CONSTRUCTION)
        );
        setHasDwellings(_.get(submissionVM, `${DWELLINGS_PATH}.children`, []).length > 0);
        _.set(submissionVM, 'isEditingPage.value', false);

        // First render only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.HO_CONSTRUCTION;
    }, [submissionVM.value]);

    const onNext = useCallback(async () => {
        if(!isComponentValid || isEditingDwelling){
            setScrollToError(Date.now())
            setShowErrors(true)
            return false;
        }

        try {
            setWizardLoading(true);

            const newSubmissionVM = viewModelService.clone(submissionVM);

            _.unset(newSubmissionVM.value, 'bindData');

            newSubmissionVM.value = await LoadSaveService.syncInitialHOOfferings(newSubmissionVM.value, authHeader);

            newSubmissionVM.value.flowStepId_WMIC = FlowStepId.HO_CONSTRUCTION;
            submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(
                newSubmissionVM.value,
                authHeader
            );

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.HO_CONSTRUCTION)) {
                WMICVariousUtil.scrollToTop();
                return false;
            }

            return submissionVM;
        } catch (error) {
            WMICErrorHandler.processAsModal(error);
            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [viewModelService, submissionVM, LoadSaveService, authHeader, setWizardLoading, isComponentValid, isEditingDwelling]);

    const saveConstruction = useCallback(async () => {
        _.unset(submissionVM.value, 'bindData');

        try {
            setWizardLoading(true, translator(messages.savingConstruction));

            submissionVM.value = await LoadSaveService.updateDraftSubmission(
                submissionVM.value,
                authHeader
            );

            updateWizardData(submissionVM);
            setShowErrors(false);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.HO_CONSTRUCTION)) {
                WMICVariousUtil.scrollToTop();
            }

            return true;
        } catch (error) {
            WMICLogger.error('Save construction failed', error);
            return false;
        } finally {
            setIsEditingDwelling(false);
            setWizardLoading(false);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setWizardLoading, translator, LoadSaveService, authHeader, submissionVM])

    const kitchenBathValidator = useMemo(() => new WMICKitchenBathValidator(viewModelService.productMetadata.get('pc').types.getTypelist('DWIGKitchenBathFeat_WMIC')), []);

    const isKitchenBathValidationValid = (dwellingVM) => {
        const kitchenBathsValidationErrors = kitchenBathValidator.getValidationMessagesForDwelling(dwellingVM?.value);
        if (Array.isArray(kitchenBathsValidationErrors) && kitchenBathsValidationErrors.length > 0) {
            showError({
                title: messages.validationKitchenError,
                message: kitchenBathsValidationErrors.map((validation) => <p>{translator({id: validation.message}, {min: validation.min, max: validation.max})}</p>)
            });
            return false;
        }
        return true;
    }

    const onSaveDwelling = useCallback((dwellingVM, index) => {
        if (!isKitchenBathValidationValid(dwellingVM)) {
            setShowErrors(true);
            return false;
        }

        _.set(dwellingVM, 'value.isUnderEditing', false);
        _.set(submissionVM, 'isEditingPage.value', false);

        // unset heaters' secondarySurveyDetail if not used, to get rid of validation errors
        dwellingVM.construction.heaters.value.forEach((heater, heaterIndex) => {
            const isPrimaryFuelSourceSecondarySurveyDetailNeeded = _.get(heater, 'primaryFuelSource.fuelSurvey.secondarySurveyDetail.oilTankType', false);
            const isSecondaryFuelSourceSecondarySurveyDetailNeeded = _.get(heater, 'secondaryFuelSource.fuelSurvey.secondarySurveyDetail.oilTankType', false);

            if (!isPrimaryFuelSourceSecondarySurveyDetailNeeded) {
                _.unset(dwellingVM.value, `construction.heaters[${heaterIndex}].primaryFuelSource.fuelSurvey.secondarySurveyDetail`)
            }

            if (!isSecondaryFuelSourceSecondarySurveyDetailNeeded) {
                _.unset(dwellingVM.value, `construction.heaters[${heaterIndex}].secondaryFuelSource.fuelSurvey.secondarySurveyDetail`)
            }
        })

        submissionVM.lobData.homeowners.coverables.dwellings.setElement(index, dwellingVM.value);

        return saveConstruction();
    }, [saveConstruction, submissionVM]);

    const onEditDwelling = () => {
        setIsEditingDwelling(true);
        _.set(submissionVM, 'isEditingPage.value', true);
        setShowErrors(false);
        updateWizardData(submissionVM);
    }

    const onCancelDwelling = () => {
        setIsEditingDwelling(false);
        _.set(submissionVM, 'isEditingPage.value', false);
        updateWizardData(submissionVM);
    }

    const overrideProps = {
        noRisksText: {
            visible: !hasDwellings
        },
        risksDataList: {
            clickable: true,
            startOpen: true,
            readOnly: false,
            editEnabled: true,
            value: _.get(submissionVM, `${DWELLINGS_PATH}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.riskNumber),
                    path: 'yourHome.dwellingNumber_EXT',
                    getData: (item, path) => WMICHOYourHomeUtil.renderDwellingRiskNumberCell(item, path, submissionVM)
                },
                {
                    headerText: translator(messages.riskPrimary),
                    path: 'yourHome.primaryDwelling_EXT',
                    getData: WMICHOYourHomeUtil.renderPrimaryCell
                },
                {
                    headerText: translator(messages.riskDescription),
                    path: 'yourHome.locationAddress.displayName',
                    getData: (item) => WMICHOYourHomeUtil.renderDwellingAddressCell(item, translator)
                },
                {
                    headerText: translator(messages.riskType),
                    path: 'rating.hoPolicyType'
                }
            ],
            detailViewComponent: WMICHOConstructionDetailView,
            detailViewComponentProps: {
                jobVM: submissionVM
            },
            showErrors,
            onValidate,
            onSave: onSaveDwelling,
            toEdit: onEditDwelling,
            onCancel: onCancelDwelling,
            canDelete: () => false
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            skipWhen={initialValidation}
            cancelLabel={translator(commonMessages.saveAndExit)}
            showNext
            showRequired
            flowStepId={FlowStepId.HO_CONSTRUCTION}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICHOConstructionPage.propTypes = wizardProps;
export default WMICHOConstructionPage;
