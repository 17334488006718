import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Currency as CurrencyField } from 'gw-components-platform-react';
import { IntlContext, useTranslator } from '@jutro/locale';
import styles from './WMICPendingPaymentCancellation.module.scss';
import messages from './WMICPendingPaymentCancellation.messages';

const WMICPendingPaymentCancellationEntry = (props) => {
    const translator = useTranslator();
    const intl = useContext(IntlContext);
    const {
        payment
    } = props;

    const paymentDate = intl.formatDate(new Date(payment?.draftDate), { year: 'numeric', month: '2-digit', day: '2-digit' });

    const getPendingPaymentLabel = () => {
        let paymentName;
        
        if (payment.bankAccountTypeName) {
            paymentName = payment.bankAccountTypeName;
        } else if (payment.creditCardTypeName) {
            paymentName = payment.creditCardTypeName;
        } else {
            paymentName = payment.displayName;
        }

        return translator(messages.pendingPaymentNameLastFour, { name: paymentName, lastFour: payment.lastFourDigits });
    };

    return (
        <Fragment>
            <p aria-hidden className={styles['wmic-pending-payment-paragraph']}>
                {translator(messages.AreYouSureYoudLikeToCancelTheFollowingPayment)}
            </p>
            <p className="sr-only">
                {
                    translator(messages.pendingPaymentEntryAria, {
                        eventDate: paymentDate,
                        amount: payment?.transactionAmount?.amount
                    })
                }
            </p>
            <p aria-hidden className={styles['wmic-pending-payment-paragraph']}>
                <strong>
                    {paymentDate}
                </strong>
                <br />
                { getPendingPaymentLabel() }
                <br />
                { translator(messages.Amount) }
                <CurrencyField
                    id={`currencyAmountPendingPayment${payment?.processID}`}
                    value={payment?.transactionAmount}
                    dataType="object"
                    readOnly
                    hideLabel
                    className={styles['wmic-pending-payment-value']}
                />
            </p>
        </Fragment>
    );
};

WMICPendingPaymentCancellationEntry.propTypes = {
    payment: PropTypes.bool.isRequired
};

export default WMICPendingPaymentCancellationEntry;
