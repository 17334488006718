import { defineMessages } from 'react-intl';

export default defineMessages({
    policyNotFoundError: {
        id: "wmic-pe-capability-gateway-common-react.WMICAddWawanesaPolicyComponent.Policy not found error",
        defaultMessage: "Policy not found, please try again."
    },
    invalidPolicyNumberError: {
        id: "wmic-pe-capability-gateway-common-react.WMICAddWawanesaPolicyComponent.Invalid search error",
        defaultMessage: "Enter at least 7 digits"
    },
    none: {
        id: "wmic-pe-capability-gateway-common-react.WMICAddWawanesaPolicyComponent.None",
        defaultMessage: "none"
    },
    policyAlreadyAdded: {
        id: "wmic-pe-capability-gateway-common-react.WMICAddWawanesaPolicyComponent.Policy already added message",
        defaultMessage: "Policy {policyNumber} has already been added."
    },
    policyMatchesCurrentPolicy: {
        id: "wmic-pe-capability-gateway-common-react.WMICAddWawanesaPolicyComponent.Policy number cannot match the current policy",
        defaultMessage: "Policy number cannot match the current policy."
    },
    pleaseSelect: {
        id: "wmic-pe-capability-gateway-common-react.WMICAddWawanesaPolicyComponent.Please Select",
        defaultMessage: "Please Select"
    },
    multiVehicleDiscountType: {
        id: "wmic-pe-capability-gateway-common-react.WMICAddWawanesaPolicyComponent.MultiVehicle Discount Coverages",
        defaultMessage: "Multi-Vehicle Discount Coverages"
    },
    multiVehicleDiscountType: {
        id: "quoteandbind.pa.directives.templates.insurance-history-prior-policies.MultiVehicle Discount Coverages",
        defaultMessage: "Multi-Vehicle Discount Coverages"
    }
});
