import { defineMessages } from 'react-intl';

export default defineMessages({
    weAreSorry: {
        id: 'auth.components.TechnicalError.We re Sorry',
        defaultMessage: 'We\'re Sorry'
    },
    technicalDifficulty: {
        id: 'auth.components.TechnicalError.TechnicalDifficulty',
        defaultMessage: 'We are experiencing some technical difficulty with your online account. Please try again later. If you need to report a claim, we are available 24/7. Please call us at &lt;a href="tel:888-929-2252"&gt;888-929-2252&lt;/a&gt; so we can help you right away. '
    },
    tryAgainLater: {
        id: 'auth.components.TechnicalError.Please try again later.',
        defaultMessage: 'Please try again later.'
    },
    backToHome: {
        id: 'auth.components.TechnicalError.Back to home',
        defaultMessage: 'Back to Home'
    },
    cannotAccessDocument: {
        id: 'auth.components.TechnicalError.Your document cannot be accessed at this time.',
        defaultMessage: 'Your document cannot be accessed at this time.'
    },
    tryAgainDocument: {
        id: 'auth.components.TechnicalError.Please try downloading the document again later or call us at',
        defaultMessage: 'Please try again later or call us at &lt;a wmic-track-button-click="Technical Error Page - Open Document Error - Support Phone Number" href="tel:1-888-363-7984"&gt;1-888-363-7984&lt;/a&gt; or email us at &lt;a href="mailto:service.us@wawanesa.com"&gt;service.us@wawanesa.com&lt;/a&gt;.  Our customer service team is available Monday - Friday 7:30 AM - 7:30 PM and Saturday 8:00 AM - 4:30 PM.'
    },
    unavailableDueToMaintenance: {
        id: 'auth.components.TechnicalError.Maintenance',
        defaultMessage: 'Online account access is temporarily unavailable due to maintenance. Please try again later. If you need to report a claim, we are available 24/7. Please call us at &lt;a wmic-track-button-click="Technical Error Page - Maintenance" href="tel:888-929-2252"&gt;888-929-2252&lt;/a&gt; so we can help you right away.'
    },
    issueWithAccountHeader: {
        id: 'auth.components.TechnicalError.Issue With Account Header',
        defaultMessage: 'We\'ve encountered an issue'
    },
    issueWithAccount: {
        id: 'auth.components.TechnicalError.Issue With Account',
        defaultMessage: 'We\'re sorry, we seem to have encountered an issue with your online account.  You\'ll need to speak with an agent to verify your information.'
    },
    issueWithAccountContactUs: {
        id: 'auth.components.TechnicalError.Issue With Account Contact Us',
        defaultMessage: 'Please call us at &lt;a href="tel:1-800-640-2920"&gt;800-640-2920&lt;/a&gt;, Monday to Friday 7:30 am - 7:30 pm PT and Saturday 8:00 am - 4:30pm PT and a helpful agent will gladly assist you.'
    },
    reportAClaim: {
        id: 'auth.components.TechnicalError.Report a claim',
        defaultMessage: 'if you need to report a claim, we are available 24/7. Please call us at &lt;a wmic-track-button-click="Technical Error Page - Open Document Error - Support Phone Number" href="tel:1-877-929-2637"&gt;1-877-929-2637&lt;/a&gt; so we can help you right away.'
    },
    thankYou: {
        id: 'auth.components.TechnicalError.Thank you for your patience',
        defaultMessage: 'Thank you for your patience.'
    }
});