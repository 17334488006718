import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteBlockedUwIssuesHeading: {
        id: 'wmic-pe-capability-gateway-common-react.QuoteAlertMessage.Draft: Underwriting Issues Have Been Raised',
        defaultMessage: 'Draft: Underwriting Issues Have Been Raised That Do Not Allow This Submission to be Quoted'
    },
    uwIssuesHeading: {
        id: 'wmic-pe-capability-gateway-common-react.QuoteAlertMessage.Quoted: Underwriting Issues Have Been Raised',
        defaultMessage: 'Quoted: Underwriting Issues Have Been Raised'
    },
    uwOrValidationIssuesBody: {
        id: 'wmic-pe-capability-gateway-common-react.QuoteAlertMessage.You cannot complete this Submission until these issues have been resolved.',
        defaultMessage: 'You cannot complete this {jobType} until these issues have been resolved.'
    },
    uwAndValidationIssuesHeading: {
        id: 'wmic-pe-capability-gateway-common-react.QuoteAlertMessage.Quoted: Validation and Underwriting Issues Have Been Raised',
        defaultMessage: 'Quoted: Validation and Underwriting Issues Have Been Raised'
    },
    validationIssuesHeading: {
        id: 'wmic-pe-capability-gateway-common-react.QuoteAlertMessage.Quoted: Validation Issues Have Been Raised',
        defaultMessage: 'Quoted: Validation Issues Have Been Raised'
    },
    noUwIssuesHeading: {
        id: 'wmic-pe-capability-gateway-common-react.QuoteAlertMessage.Approved: Payment Pending',
        defaultMessage: 'Approved: Payment Pending'
    },
    noUwIssuesBody: {
        id: 'wmic-pe-capability-gateway-common-react.QuoteAlertMessage.There are no outstanding underwriting issues.',
        defaultMessage: 'There are no outstanding issues.'
    },
    underwriterApprovalPending: {
        id: 'wmic-pe-capability-gateway-common-react.QuoteAlertMessage.Quoted Underwriter Approval Pending',
        defaultMessage: 'Quoted: Underwriter Approval Pending'
    },
    jobTypeSubmission: {
        id: 'wmic-pe-capability-gateway-common-react.QuoteAlertMessage.jobType.Submission',
        defaultMessage: 'Submission'
    },
    jobTypePolicyChange: {
        id: 'wmic-pe-capability-gateway-common-react.QuoteAlertMessage.jobType.PolicyChange',
        defaultMessage: 'Policy Change'
    },
    jobTypeRenewal: {
        id: 'wmic-pe-capability-gateway-common-react.QuoteAlertMessage.jobType.Renewal',
        defaultMessage: 'Renewal'
    },
    uwiBrokerNotifierText: {
        id: "wmic-pe-capability-gateway-common-react.QuoteAlertMessage.Notify Broker about UWI approval via email text",
        defaultMessage: 'An email notification will be sent to &lt;span class="wmicUWIBrokerNotifierEmail"&gt;{broker_email}&lt;/span&gt; when the transaction is approved. If the transaction is not approved, an underwriter will reach out to you.'
    }
});
