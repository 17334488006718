import { getProxiedServiceUrl } from '@xengage/gw-portals-url-js';
import { WMICJsonRPCService } from 'wmic-pe-portals-transport-js';

function processRequest(method, data, additionalHeaders) {
    return WMICJsonRPCService.send(getProxiedServiceUrl('address'), method, data, additionalHeaders);
}

export default class AddressService_Ext {
    static validateAddress(addressVM, additionalHeaders = {}) {
        return processRequest('validateAddress', [addressVM], additionalHeaders);
    }

    static validatePolicyLocation(addressVM, jobNumber, additionalHeaders = {}) {
        return processRequest('validatePolicyLocation', [addressVM, jobNumber], additionalHeaders);
    }
}
