/* eslint-disable no-secrets/no-secrets */
import React, {
    useCallback,
    useState,
    useEffect,
    useContext,
    useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNextContext } from '@jutro/components';
import {
    CONSTANTS,
    WMICLogger,
    LINE_OF_BUSINESS,
    WMICUserAccessUtil,
    WMICVariousUtil,
    JobType
} from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import { WMICWizardChangeOrRenewalPage, useDocumentTitle, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { withViewModelService, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { DocumentService } from 'wmic-pe-capability-gateway-policycommon';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { WMICUnderwritingIssues } from 'wmic-pe-components-platform-react';
import { WMICHOPrintableAgreement } from 'wmic-pe-capability-gateway-common-ho-react';
import { WMICDocuments, WMICQuoteSummary } from 'wmic-pe-capability-gateway-common-react';
import { ERROR_CODE } from 'wmic-pe-portals-utils-js';
import WMICQuoteUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICQuoteUtil';
import WMICUIIssuesModal from 'wmic-pe-capability-quoteandbind-common-react/pages/WMICPaymentPage/WMICUIIssuesModal/WMICUIIssuesModal';

import { messages as commonMessages, WMICFutureRenewalModal } from 'wmic-pe-capability-gateway-policychange-common-react';
import metadata from './WMICPCQuotePage.metadata.json5';
import messages from './WMICPCQuotePage.messages';

function WMICPCQuotePage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        markStepSubmitted,
        stopErrorNavigation
    } = props;
    const history = useHistory();
    const { showConfirm, showInfo, showCustom, showError } = useWizardModals();
    const { showModal } = useContext(ModalNextContext);
    const { authHeader, authUserData: currentUser } = useAuthentication();
    const { EndorsementService } = useDependencies('EndorsementService');
    const { PolicyChangeService } = useDependencies('PolicyChangeService');
    const {
        isComponentValid,
        onValidate,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('WMICPCQuotePage');
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const { isSkipping } = useContext(WizardContext);
    const [userDocuments, setUserDocuments] = useState([]);
    const { setWizardLoading } = useWizardModals();
    const [underwritingIssues, setUnderwritingIssues] = useState();
    const [basedOnPolicy, setBasedOnPolicy] = useState({});
    const [policyChangeSummaryVM, setPolicyChangeSummaryVM] = useState({});
    const jobID = _.get(policyChangeVM.value, 'jobID');

    const hasBlockingValidationIssues = _.get(policyChangeVM, 'errorsAndWarnings.validationIssues.issues.value', []).length > 0;

    const hasBlockingIssues = WMICQuoteUtil.isBlockingUWIssuePresent(policyChangeVM) || hasBlockingValidationIssues;

    const canEditPolicyChange = useMemo(() => WMICUserAccessUtil.canEditPolicyChange(currentUser.permission_Ext), [currentUser.permission_Ext]);
    const canBindPolicyChange = useMemo(() => WMICUserAccessUtil.canBindPolicyChange(currentUser.permission_Ext), [currentUser.permission_Ext]);

    useDocumentTitle(translator(messages.quotePageTitle), policyChangeVM);

    const validateSubmission = useCallback(async () => {
        setWizardLoading(!isSkipping);
        const result = await EndorsementService.validatePolicyChangeForIssue(policyChangeVM.value, authHeader);
        _.extend(policyChangeVM.value, result);
        updateWizardData(policyChangeVM);
        markStepSubmitted(); // the step needs to be marked as submitted for the errors associated with this step to show up
        setWizardLoading(false);
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [EndorsementService, authHeader, policyChangeVM, setWizardLoading, markStepSubmitted]);

    useEffect(() => {
        if (canEditPolicyChange) {
            validateSubmission();
        }
    }, [canEditPolicyChange, validateSubmission]);

    useEffect(() => {
        registerInitialComponentValidation(() => !WMICQuoteUtil.isBlockingUWIssuePresent(policyChangeVM));
        stopErrorNavigation();
    }, [registerInitialComponentValidation, policyChangeVM, stopErrorNavigation]);

    const fetchUWIssuesAndDocuments = useCallback(async () => {
        const allPromises = Promise.all([
            PolicyChangeService.findJobByJobNumber(jobID, authHeader),
            DocumentService.getDocumentsForJobNumber(jobID, authHeader)
        ]);

        const results = await allPromises;
        setBasedOnPolicy(results[0]);
        const uwIssues = results[0].underwritingIssues;
        const documents = results[1];
        setUnderwritingIssues(uwIssues);

        if (userDocuments.length !== documents.length){
            setUserDocuments(documents);
        }
    }, [PolicyChangeService, authHeader, jobID, userDocuments.length]);

    const fetchPolicyChangeSummary = useCallback(async() => {
        const LOB = _.get(policyChangeVM,'value.lob');
        let vmType = '';
        let policyChangeResult = {};
        if(LOB === LINE_OF_BUSINESS.PERSONAL_AUTO) {
            vmType = 'wmic.edge.ca.capabilities.gateway.job.policychange.dto.PAPolicyChangeReviewDTO_WMIC';
            policyChangeResult = await PolicyChangeService.getPAPolicyChangeSummary_WMIC(jobID, authHeader);
        } else if (LOB === LINE_OF_BUSINESS.HOME_OWNER) {
            vmType = 'wmic.edge.ca.capabilities.gateway.job.policychange.dto.HOPolicyChangeReviewDTO_WMIC';
            policyChangeResult = await PolicyChangeService.getHOPolicyChangeSummary_WMIC(jobID, authHeader);
        } else if (LOB === LINE_OF_BUSINESS.PERSONAL_UMBRELLA) {
            vmType = 'wmic.edge.ca.capabilities.gateway.job.policychange.dto.PUPPolicyChangeReviewDTO_WMIC';
            policyChangeResult = await PolicyChangeService.getPUPPolicyChangeSummary_WMIC(jobID, authHeader);
        }

        const policyChangeSummary = viewModelService.create(policyChangeResult, 'pc', vmType, policyChangeVM.aspects.context());
        setPolicyChangeSummaryVM(policyChangeSummary);
    }, [policyChangeVM, viewModelService, PolicyChangeService, jobID, authHeader]);

    const showUWIssueModal = (uwIssues, jobId) => {
        showCustom(
            <WMICUIIssuesModal
                uwIssues={uwIssues}
            />
        ).then(() => {
            history.push(`/change/${jobId}/summary`)
        }).catch(() => {
            _.noop();
        });
    }

    const bindFuture = useCallback(async () => {
        setWizardLoading(true, translator(messages.applyingPolicyChange));
        const bindResponse = await EndorsementService.bindAndApplyChangesToFutureTerm(
            jobID,
            authHeader
        );
        setWizardLoading(false);

        return bindResponse;
    }, [EndorsementService, authHeader, jobID, setWizardLoading, translator]);

    const bind = useCallback(async () => {
        setWizardLoading(true, translator(messages.binding));
        const bindResponse = await EndorsementService.bind_WMIC(
            jobID,
            authHeader
        ).then((response) => {
            _.extend(policyChangeVM.value, response);
            updateWizardData(policyChangeVM);
            history.push(
                `/policies/${policyChangeVM.value.policyNumber}/${policyChangeVM.value.termNumber}/summary`
            );
            return policyChangeVM;
        }).catch((error) => {
            if (error.errorCode === ERROR_CODE.GW_UNDERWRITING_ERROR) {
                const uwIssues = WMICErrorHandler.extractUWIssuesFromErrorMessage(error);
                showUWIssueModal(uwIssues, jobID);
            } else {
                showError({
                    title: translator(messages.error),
                    message: translator(messages.bindingFailure),
                })
            }
            return false;
        }).finally(() => {
            setWizardLoading(false);
        });

        return bindResponse;
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [EndorsementService, authHeader, history, jobID, policyChangeVM, setWizardLoading, translator]);

    const onNext = useCallback(() => {
        if (!isComponentValid || hasBlockingIssues) {
            WMICVariousUtil.scrollToTop();
            return false;
        }

        if (_.get(policyChangeVM, 'boundRenewalExists.value')) {
            return showModal(<WMICFutureRenewalModal
                isOpen
                onResolve={_.noop}
                policyChangeVM={policyChangeVM}
            />).then(async (result) => {
                if (result === CONSTANTS.FUTURE_RENEWAL_MODAL_RESULT.CURRENT_AND_RENEWING) {
                    const bindResponse = await bindFuture();
                    return showInfo({
                        title: messages.proceedWithRenewalChanges,
                        message: messages.changesCopiedToRenewal,
                        confirmButtonText: messages.proceed,
                        icon: 'gw-info-outline'
                    }).then(async () => {
                        history.push(
                            `/change/${bindResponse}/summary`
                        );
                        return policyChangeVM;
                    }, _.noop);
                }

                if (result === CONSTANTS.FUTURE_RENEWAL_MODAL_RESULT.CURRENT_ONLY) {
                    return bind();
                }

                updateWizardData(policyChangeVM);
                return false;
            }, _.noop);
        }

        return showConfirm({
            title: messages.bindpolicytitle,
            message: messages.bindmessage,
            confirmButtonText: messages.bindpolicy,
            cancelButtonText: messages.cancelbutton,
        }).then(async (results) => {
            if (
                results === CONSTANTS.MODAL_RESULT.CANCEL ||
                results === CONSTANTS.MODAL_RESULT.CLOSE
            ) {
                updateWizardData(policyChangeVM);
                return false;
            }
            return bind();
        }, _.noop);
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentValid, hasBlockingIssues, policyChangeVM, showConfirm, showModal, bindFuture, showInfo, history, bind]);

    const getCurrentPremiumData = () =>
        ({
            totalBeforeTaxes: _.get(basedOnPolicy, 'policy.latestPeriod.totalPremium'),
            taxes: _.get(basedOnPolicy, 'policy.latestPeriod.taxes'),
            fees: _.get(basedOnPolicy, 'policy.latestPeriod.fees'),
            total: _.get(basedOnPolicy, 'policy.latestPeriod.totalCost')
        });

    useEffect(() => {
        setWizardLoading(true, translator(messages.loading));
        try {
            fetchUWIssuesAndDocuments();
            fetchPolicyChangeSummary();
        } catch (error) {
            WMICLogger.error(
                'Failed either request=findJobByJobNumber or request=getDocumentsForJobNumber or both',
                error
            );
        } finally {
            setWizardLoading(false);
        }
    }, [fetchUWIssuesAndDocuments, fetchPolicyChangeSummary, translator, setWizardLoading, userDocuments]);

    const overrideProps = {
        quoteAlertMessage: {
            jobVM: policyChangeVM,
            underwritingIssues,
            // Hide alert message until underwriting issues are fetched
            visible: !!underwritingIssues,
        },
        quotePagePremiumCosts: {
            premium: getCurrentPremiumData(),
            jobVM: basedOnPolicy,
            policyChange: policyChangeSummaryVM,
            enableFlex: true,
            enableBrokerFees: true,
            updateJobVM: updateWizardData
        },
        quotePageUWIssues: {
            visible: !!underwritingIssues,
            underwritingIssues,
            quoteID: jobID,
            authHeader,
            jobVM: policyChangeVM.baseData,
            jobType: JobType.POLICY_CHANGE
        },
        quotePageSummary: {
            jobVM: policyChangeSummaryVM
        },
        quotePageUploadDocuments: {
            documents: userDocuments,
            updateDocuments: setUserDocuments,
            value: {
                documentDTOs: userDocuments,
                canUploadDocument: true,
                jobNumber: jobID
            }
        },
        quotePageHOPrintableAgreement: {
            visible: policyChangeVM?.value?.lob === LINE_OF_BUSINESS.HOME_OWNER
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICQuoteSummary,
            WMICUnderwritingIssues,
            WMICDocuments,
            WMICHOPrintableAgreement
        }
    }

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            nextLabel={translator(messages.bindpolicychangebutton)}
            showNext={canBindPolicyChange}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPCQuotePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default withViewModelService(WMICPCQuotePage);
