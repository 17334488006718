/* eslint-disable no-secrets/no-secrets */
import React, { useCallback, useMemo, useContext, useState } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import { WMICWizardChangeOrRenewalPage, useDocumentTitle, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import _ from 'lodash';

import { WMICScheduledItemComponent, checkIfScheduledItemsHasNoDuplicate } from 'wmic-pe-capability-gateway-common-ho-react';
import { WMICUserAccessUtil, CONSTANTS } from 'wmic-pe-portals-utils-js';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import metadata from './WMICHOPolicyChangeScheduledItemsPage.metadata.json5';
import messages from './WMICHOPolicyChangeScheduledItemsPage.messages';

const SCHEDULED_ITEMS_PATH = 'lobData.homeowners.coverables.scheduledItems';
const MAX_NUMBER_DESCRIPTION_CHARS = 100;

function WMICHOPolicyChangeScheduledItemsPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;

    const translator = useContext(TranslatorContext);
    const { showConfirm } = useWizardModals();
    const { authUserData, authHeader } = useAuthentication();
    const { setWizardLoading, showError } = useWizardModals();
    const { EndorsementService } = useDependencies('EndorsementService');
    const [scrollToError, setScrollToError] = useState(); // we toggle this when we want to scroll to the first error on the page
    const viewModelService = useContext(ViewModelServiceContext);

    const { onValidate, initialValidation, isComponentValid } = useValidation('WMICHOPolicyChangeScheduledItemsPage');

    const [showErrors, setShowErrors] = useState(false);

    const canEditPolicyChange = useMemo(() => WMICUserAccessUtil.canEditPolicyChange(authUserData.permission_Ext), [authUserData.permission_Ext]);

    useDocumentTitle(translator(messages.scheduledItemsTitle), policyChangeVM);

    const onEditScheduledItem = useCallback(() => {
        _.set(policyChangeVM, 'isEditingPage.value', true);
    }, [policyChangeVM]);

    const onAddNewScheduledItem = () => {
        _.set(policyChangeVM, 'isEditingPage.value', true);
        // To invalidate Quote step when adding a new scheduled item
        updateWizardData(policyChangeVM);

        const newScheduledItemVM = viewModelService.create(
            { additionalInterests: [] },
            'pc', 'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.scheduleditems.HoScheduledItemsDTO',
            policyChangeVM.aspects.context()
        );

        setShowErrors(false);
        _.set(policyChangeVM, "isEditingSI.value", true)
        policyChangeVM.lobData.homeowners.coverables.scheduledItems.value.push(newScheduledItemVM.value);
        updateWizardData(policyChangeVM)
        return newScheduledItemVM;
    };

    const isScheduledItemsValid = () => {
        const allScheduledItems = _.get(policyChangeVM, `${SCHEDULED_ITEMS_PATH}.value`, []);
        return checkIfScheduledItemsHasNoDuplicate(allScheduledItems);
    };

    const showScheduledItemsDuplicateError = () => 
        showError({
            title: translator(messages.duplicateScheduledItemsDialogTitle),
            message: translator(messages.duplicateScheduledItemsDialogMessage)
        });

    const onSaveScheduledItem = useCallback(() => {
        if(!isScheduledItemsValid()) {
            showScheduledItemsDuplicateError();
            setShowErrors(true);
            return false;
        }
        
        _.set(policyChangeVM, "isEditingSI.value", false);
        _.set(policyChangeVM, 'isEditingPage.value', false);
        updateWizardData(policyChangeVM);
        setShowErrors(false);

        return true;
    }, [updateWizardData, policyChangeVM]);

    const onRemoveScheduledItem = async (si, index) => {
        const response = await showConfirm({
            title: messages.removeScheduledItemShort,
            message: messages.removeScheduledItemLong,
        });

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const allScheduledItems = _.get(policyChangeVM, `${SCHEDULED_ITEMS_PATH}.value`, []);

            allScheduledItems.splice(index, 1);
            _.set(policyChangeVM, `${SCHEDULED_ITEMS_PATH}.value`, allScheduledItems);
            updateWizardData(policyChangeVM);
        }

        return _.noop();
    };

    const getScheduledItemDescription = (item) => {
        const description = _.get(item, 'description.value', '');

        return description.length > MAX_NUMBER_DESCRIPTION_CHARS ? description.substring(0, MAX_NUMBER_DESCRIPTION_CHARS) : description;
    }

    const onCancelScheduledItem = () => {
        _.set(policyChangeVM, "isEditingSI.value", false);
        _.set(policyChangeVM, 'isEditingPage.value', false);
        updateWizardData(policyChangeVM);
    }

    const onNext = useCallback(async () => {

        if(!isComponentValid)  {
            setShowErrors(true);
            return false;
        }

        try {
            setWizardLoading(true);

            const newPolicyChangeVM = _.cloneDeep(policyChangeVM.value);

            policyChangeVM.value = await EndorsementService.saveEndorsement(
                [newPolicyChangeVM],
                authHeader
            );

            return policyChangeVM;
        } finally {
            setWizardLoading(false);
        }
    }, [EndorsementService, authHeader, isComponentValid, policyChangeVM, setWizardLoading]);

    const overrideProps = {
        scheduledItemsDataList: {
            startOpen: false,
            clickable: true,
            readOnly: !canEditPolicyChange,
            showErrors,
            VMData: [
                {
                    headerText: translator(messages.scheduledItemType),
                    path: 'scheduledItemTypeCode'
                },
                {
                    headerText: translator(messages.scheduledItemDescription),
                    path: 'description',
                    getData: (item) => getScheduledItemDescription(item)
                },
                {
                    headerText: translator(messages.scheduledItemLimit),
                    path: 'limit'
                }
            ],
            detailViewComponent: WMICScheduledItemComponent,
            detailViewComponentProps: {
                jobVM: policyChangeVM,
            },
            onValidate,
            toCreate: onAddNewScheduledItem,
            toEdit: onEditScheduledItem,
            toUndoCreate: () => {
                const scheduledItems = _.get(policyChangeVM.value, SCHEDULED_ITEMS_PATH);

                scheduledItems.splice(scheduledItems.length-1, 1);
                _.set(policyChangeVM.value, SCHEDULED_ITEMS_PATH, scheduledItems);
                _.set(policyChangeVM, "isEditingSI.value", false);
                _.set(policyChangeVM, 'isEditingPage.value', false);
                updateWizardData(policyChangeVM);
            },
            onSave: onSaveScheduledItem,
            onDelete: onRemoveScheduledItem,
            onCancel: onCancelScheduledItem,
            showCancelModal: true,
            canDelete: (index, value) => value?.scheduledItemTypeCode?.value?.code !== CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.MISCELLANEOUS,
            canEdit: (index, value) => value?.scheduledItemTypeCode?.value?.code !== CONSTANTS.HO_SCHEDULED_ITEM_COV_TYPES.MISCELLANEOUS
        },
        scheduledItemsOptionalContainer: {
            visible: _.get(policyChangeVM, `${SCHEDULED_ITEMS_PATH}.children`, []).length === 0
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICScheduledItemComponent
        }
    };

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICHOPolicyChangeScheduledItemsPage.propTypes = wizardProps;

export default withViewModelService(WMICHOPolicyChangeScheduledItemsPage);
