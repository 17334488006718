import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import {  WMICDateTimeService } from 'wmic-pe-portals-utils-js';
import WMICPolicyMVRDetailView from './WMICPolicyMVRDetailView/WMICPolicyMVRDetailView';

import metadata from './WMICPolicyPaMVRComponent.metadata.json5';
import messages from './WMICPolicyPaMVRComponent.messages.js';

function WMICPolicyPaMVRComponent(props) {
    const {
        driverVM
    } = props

    const translator = useContext(TranslatorContext);

    const displayMVRDate = (data) => {
        return data?.value?.mvrDate_WMIC ? <span>{WMICDateTimeService.toMidnightDate(data.value.mvrDate_WMIC)}</span> : ''
    };

    const displayViolationsSuspensions = (data) => {
        const incidents = data.value.mvrIncidents_WMIC;
        const hasIncidents = incidents && incidents.length > 0;
        return hasIncidents ? translator(messages.yesIncidentLength, {incidents:incidents.length}) : translator(messages.No);
    };

    const overrideProps = {
        '@field': {
            parentNode: driverVM,
            readOnly: true
        },
        MVRListView: {
            VMData: [
                {
                    headerText: translator(messages.MVRDate),
                    getData: displayMVRDate
                },
                {
                    headerText: translator(messages.MVRType),
                    path: 'mvrType_WMIC'
                },
                {
                    headerText: translator(messages.MVRViolationsSuspensions),
                    getData: displayViolationsSuspensions
                },
                {
                    headerText: translator(messages.MVRReportSource),
                    path: 'reportSource'
                }
            ],
            clickable: true,
            readOnly: true,
            detailViewComponent: WMICPolicyMVRDetailView,
            startOpen: false
        },
        MVRandViolationsSuspensions: {
            visible: _.get(driverVM, 'orderMVR_WMIC.value') !== false
        }
    }

    const resolvers = {
        resolveComponentMap: {
            WMICPolicyMVRDetailView
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    )
}

WMICPolicyPaMVRComponent.propTypes = {
    driverVM: PropTypes.shape({
        person: PropTypes.shape({
            publicID: PropTypes.shape({})
        })
    }).isRequired
};

export default WMICPolicyPaMVRComponent
