import moment from 'moment';
import _ from 'lodash';
import { DateUtil } from '@xengage/gw-portals-util-js'
import { WMICLogger } from 'wmic-pe-portals-utils-js';

/**
 * Module that returns the current date. Can be replaced with
 * another implementation, for example if you needed to time travel and return
 * a different current date
 */

function getDateFormat(aDate, dateFormat) {
    if (aDate === null || aDate === undefined) {
        WMICLogger.warn('Unable to format empty date');
        return;
    }
    if(localStorage.getItem('selectedLocale') === "fr-CA"){
        require('moment/locale/fr');
    }  
    return moment(aDate).format(dateFormat);
}

/**
 * @param {Date} aDate to format
 * @returns {String} formmated date to "MMMM DD YYYY" ie. "May 5 2021"
 */
function getDateFormatMMMMDDYYYY(aDate) {
    const dateFormat = 'MMMM DD YYYY';
    return getDateFormat(aDate, dateFormat);
}

/**
 * @param {Date} aDate to format
 * @returns {String} formmated date to "DD MMMM YYYY" ie. "30 May 2021"
 */
function getDateFormatDDMMMMYYYY(aDate) {
    const dateFormat = 'DD MMMM YYYY';
    return getDateFormat(aDate, dateFormat);
}

/**
 * @param {Date} aDate to format
 * @returns {String} formmated date to "MM/DD/YYYY" ie. "5/5/2021"
 */
function getDateFormatMMDDYYYY(aDate) {
    const dateFormat = 'MM/DD/YYYY';
    return getDateFormat(aDate, dateFormat);
}


/**
 * @param {Date} aDate to format
 * @returns {String} formmated date to "MM/DD/YYYY" string ie. "05/13/2021"
 */
function formatDateWMIC(aDate) {
    const date = new Date(aDate);
    const year = DateUtil.getYear(date);
    const month = DateUtil.getMonth(date);
    const day = DateUtil.getDate(date);
    return `${month}/${day}/${year}`;
}

/**
 * @param {Date} aDate that need to add days to it
 * @param {Number} daysToAdd is how many days to add to the target date
 * @returns {Date} a new date that has added days
 */
function addDays(aDate, daysToAdd) {
    aDate.setDate(aDate.getDate() + daysToAdd);
    return aDate;
}

/**
 * @param {localDateDTO} aLocalDate to convert to Date
 * @returns {Date} a date object that be converted from LocalDateDTO
 */
function localDateDTOToDate(aLocalDate) {
    return new Date(aLocalDate.year, aLocalDate.month, aLocalDate.day);
}

function addYears(date, yearsToAdd) {
    date.setFullYear(date.getFullYear() + yearsToAdd);
    return date;
}

function getDiffBetweenDatesInDays(date1, date2) {
    return Math.ceil((date1 - date2) / (1000 * 60 * 60 * 24));
};

export default {
    // Platform DateUtil functions
    currentDate: DateUtil.currentDate,
    currentDateAtMidnight: DateUtil.currentDateAtMidnight,
    getYear: DateUtil.getYear,
    getMonth: DateUtil.getMonth,
    getDate: DateUtil.getDate,
    getHour: DateUtil.getHour,
    trimToMidnight: DateUtil.trimToMidnight,
    addHours: DateUtil.addHours,
    getCurrentTimeTrimmedToHour: DateUtil.getCurrentTimeTrimmedToHour,
    compareIgnoreTime: DateUtil.compareIgnoreTime,
    dateYearsAgo: DateUtil.dateYearsAgo,
    // Wawanesa DateUtil functions
    getDateFormatMMMMDDYYYY,
    getDateFormatDDMMMMYYYY,
    getDateFormatMMDDYYYY,
    formatDateWMIC,
    localDateDTOToDate,
    addDays,
    addYears,
    getDiffBetweenDatesInDays
};
