import React, {
    useState, useEffect, useContext, useCallback, useMemo
} from 'react';
import { Icon } from '@jutro/components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { WMICHeading, WMICIcon, WMICButton } from 'wmic-pe-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { ICON_NAMES, WMICVariousUtil, JobType, DateUtil, CONSTANTS, PRODUCT, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import WMICDocumentUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICDocumentUtil';
import styles from './WMICWizardPageHeader.module.scss';
import messages from './WMICWizardPageHeader.messages';
import { JobService } from 'gw-capability-gateway';

const QUOTE_TRANSACTION = [
    'CommercialProperty',
    'WC7WorkersComp',
    'Homeowners',
    'BusinessOwners',
    'PersonalAuto',
    'HOPHomeowners',
    'PersonalUmbrella',
    'CommercialPackage'
];

const PRINTABLE_PAGES = ["/quote"];
const QUOTE_PAGES = ["/quote", "/quote-summary"];
const DOWNLOAD_QUOTE_PAGES = ["/quote-summary"];

const WMICWizardPageHeader = (props) => {
    const translator = useContext(TranslatorContext);
    const { currentStep } = useContext(WizardContext);
    const [wizardHeaderTemplate, setWizardHeaderTemplate] = useState('transaction');
    const { authHeader, authUserData } = useAuthentication();
    const {
        wizardSubmission, isSideHeader, displayWizardHeader
    } = props;
    const { showConfirm, setWizardLoading } = useWizardModals();
    const history = useHistory();
    const isLobPA = _.get(wizardSubmission.value, "baseData.productCode") === PRODUCT.PERSONAL_AUTO;
    const isCommercialPackage = _.get(wizardSubmission.value, "baseData.productCode") === PRODUCT.COMMERCIAL_PACKAGE;
    const isQuoted = _.get(wizardSubmission.value, 'baseData.periodStatus') === CONSTANTS.QUOTED;
    const canEditAccount = useMemo(() => WMICUserAccessUtil.canEditAccount(authUserData.permission_Ext), [authUserData.permission_Ext]);
    const canWithdrawTransaction = useMemo(() => WMICUserAccessUtil.canWithdrawTransaction(authUserData.permission_Ext), [authUserData.permission_Ext]);

    useEffect(() => {
        const productCode = _.get(wizardSubmission.value, 'baseData.productCode');
        const jobType = _.get(wizardSubmission.value, 'baseData.jobType');
        const productAndJobType = (jobType === JobType.RENEWAL && `${productCode}${jobType}`)
            || (jobType === JobType.POLICY_CHANGE && `${productCode}${jobType}`)
            || productCode;


        if (productAndJobType && QUOTE_TRANSACTION.includes(productCode)) {
            setWizardHeaderTemplate('transaction');
        }
        /* eslint no-underscore-dangle: 0 */
        if (wizardSubmission._dtoName.indexOf('fnol') !== -1) {
            setWizardHeaderTemplate('claim');
        }
    }, [translator, wizardSubmission]);

    const accountNumber = _.get(wizardSubmission.value, 'baseData.accountNumber');
    const routePath = `/accounts/${accountNumber}/summary`;

    const accountAddress = useMemo(() => {
        const address = _.get(wizardSubmission.value, 'baseData.jobType') === JobType.POLICY_CHANGE
            ? _.get(wizardSubmission.value, 'baseData.primaryNamedInsured_WMIC.primaryAddress.displayName')
            : _.get(wizardSubmission.value, 'baseData.accountHolder.primaryAddress.displayName');

        return address || '';
    }, [wizardSubmission.value]);

    const accountName = useMemo(() => {
        const firstName = _.get(wizardSubmission.value, 'baseData.accountHolder.firstName') || '';
        const lastName = _.get(wizardSubmission.value, 'baseData.accountHolder.lastName') || '';
        const displayName = _.get(wizardSubmission.value, 'baseData.accountHolder.displayName');
        const accountDisplayName = !displayName ? `${firstName} ${lastName}` : displayName;

        return _.trim(accountDisplayName)
            || _.get(wizardSubmission.value, 'baseData.accountHolder.contactName')
            || _.get(wizardSubmission.value, 'baseData.accountName');
    }, [wizardSubmission]);

    const lobWithQuoteNumber = useMemo(() => {
        const productCode = _.get(wizardSubmission.value, 'baseData.productCode');
        const jobType = _.get(wizardSubmission.value, 'baseData.jobType');
        const productAndJobType = (jobType === JobType.RENEWAL && `${productCode}${jobType}`)
            || (jobType === JobType.POLICY_CHANGE && `${productCode}${jobType}`)
            || productCode;

        return translator(messages[_.camelCase(productAndJobType)], {
                quoteNumber:
                    _.get(wizardSubmission.value, 'quoteID')
                    || _.get(wizardSubmission.value, 'policyNumber'),
                jobId: _.get(wizardSubmission.value, 'jobID') || ''
            });
    }, [wizardSubmission, translator]);

    const getWizardTitleIcon = () => {
        const productCode = _.get(wizardSubmission.value, 'baseData.productCode');
        const icon = WMICVariousUtil.getLobIcon(productCode)
        return <WMICIcon
            icon={icon}
            id="lobIcon"
            size="large"
            className={cx("lobIcon", "gw-mr-2", "gw-mt-1")}
        />
    }

    const storeJobDataToSessionStorage = () => {
        const redirectURL = WMICVariousUtil.getRedirectURLForJob(wizardSubmission);
        sessionStorage.setItem('backButtonRedirectUrl', redirectURL )
    }

    const showLeavePageConfirmModal = (event) => {
        event.preventDefault();
        showConfirm({
            title: translator(messages.editAccountModalTitle),
            message: translator(messages.editAccountModalMessage),
            confirmButtonText: translator(commonMessages.yes),
            cancelButtonText: translator(commonMessages.cancelModel),
        }).then(async (result) => {
            if (result === CONSTANTS.MODAL_RESULT.CANCEL || result === CONSTANTS.MODAL_RESULT.CLOSE) {
                return _.noop();
            }

            storeJobDataToSessionStorage();
            history.push(routePath);

        }, _.noop);
    }

    const isPrintablePage = useCallback(() => currentStep
        ? PRINTABLE_PAGES.includes(currentStep.path) && !isLobPA
        : false, [currentStep, isLobPA]);

    const isQuoteDownloadable = useCallback(() => currentStep
        ? isCommercialPackage && isQuoted && DOWNLOAD_QUOTE_PAGES.includes(currentStep.path)
        : false , [currentStep, isCommercialPackage, isQuoted]);

    const downloadQuoteDocument = () => {
        if (isQuoted) {
            return WMICDocumentUtil.downloadQuoteDocument(wizardSubmission.quoteID.value, authHeader);
        }
        return Promise.resolve(undefined);
    }

    const titleContainerClass = cx({
        [styles.gwWizardPageTitle]: !isSideHeader,
        [styles.gwWizardSidePageTitle]: isSideHeader
    });

    const categoryContainerClass = cx({
        [styles.gwWizardPageCategory]: !isSideHeader,
        [styles.gwWizardSidePageCategory]: isSideHeader
    });

    const associatedPageClass = cx({
        [styles.gwWizardPageAssociated]: !isSideHeader,
        [styles.gwWizardSidePageAssociated]: isSideHeader
    });

    const titleSeparator = isSideHeader && (
        <div className={styles.gwWizardSidePageTitleSeparator} />
    );

    const handleWithdrawQuote = () => {
        const isPolicyChange = wizardSubmission?.value?.baseData?.jobType === JobType.POLICY_CHANGE;
        showConfirm({
            title: isPolicyChange
                ? translator(messages.withdrawPolicyChangeHeading)
                : translator(messages.withdrawQuoteHeading),
            message: isPolicyChange
                ? translator(messages.withdrawPolicyChangeBody)
                : translator(messages.withdrawQuoteBody),
            confirmButtonText: translator(messages.withdrawQuoteButton),
            cancelButtonText: commonMessages.no
        }).then(result => {
            if (
                result === CONSTANTS.MODAL_RESULT.CANCEL ||
                result === CONSTANTS.MODAL_RESULT.CLOSE
            ) {
                return;
            }

            let location;
            let jobNumber;

            setWizardLoading(
                true,
                translator(messages.withdrawingLoadingBoxText)
            );
            if (isPolicyChange) {
                jobNumber = _.get(wizardSubmission.value, 'jobID');
                location = `/change/${jobNumber}/summary`;
            } else {
                jobNumber = _.get(wizardSubmission.value, 'quoteID');
                location = `/quotes/${jobNumber}/summary`;
            }

            JobService.withdrawJobByJobNumber(jobNumber, authHeader)
                .then(() => history.push(location))
                .finally(() => setWizardLoading(false));
        });
    };

    const createWithdrawButton = useCallback(() => {
        if (
            QUOTE_PAGES.includes(currentStep.path) &&
            (_.get(wizardSubmission.value, 'baseData.jobType') === JobType.POLICY_CHANGE ||
                _.get(wizardSubmission.value, 'baseData.jobType') === JobType.SUBMISSION)
        ) {
            return (
                <WMICButton
                    className="noPrint"
                    type="primary-outlined"
                    onClick={handleWithdrawQuote}
                >
                    {translator(messages.withdrawQuoteButton)}
                </WMICButton>
            );
        }
    });

    const submissionQuote = wizardHeaderTemplate === 'transaction' && (
        <div className={titleContainerClass}>
            {titleSeparator}
            {displayWizardHeader ? (
                <div>
                    <div className={cx("wizardHeaderJobDetails", { [styles.gwWizardSidepageTitle]: isSideHeader }, 'gw-mb-5')}>
                        {getWizardTitleIcon()}
                        <div className={styles.jobDetailsTitle}>
                            <div>{lobWithQuoteNumber}</div>
                            <span className={styles.termDetails}>{translator(messages.termWithDates, {
                                periodStart: localStorage.getItem('selectedLocale') === "fr-CA" ? DateUtil.getDateFormatDDMMMMYYYY(_.get(wizardSubmission, 'baseData.periodStartDate.value')) : DateUtil.getDateFormatMMMMDDYYYY(_.get(wizardSubmission, 'baseData.periodStartDate.value')),
                                periodEnd: localStorage.getItem('selectedLocale') === "fr-CA" ? DateUtil.getDateFormatDDMMMMYYYY(_.get(wizardSubmission, 'baseData.periodEndDate.value')) : DateUtil.getDateFormatMMMMDDYYYY(_.get(wizardSubmission, 'baseData.periodEndDate.value'))
                            })}</span>
                        </div>
                    </div>
                    <div className={categoryContainerClass}>
                        <div className={cx(styles.accountHolderName)}>{accountName}</div>
                        <p className={styles.accountAddress}>
                            {accountAddress}
                            {   // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                canEditAccount && <a href="#" className={associatedPageClass} onClick={showLeavePageConfirmModal}>
                                    <Icon
                                        icon={ICON_NAMES.ICON_EDIT}
                                        id="iconEdit"
                                        size="small"
                                        className="gw-ml-1"
                                    />
                                    <span className={styles.editAccount}>{translator(messages.editAccount)}</span>
                                </a>
                            }
                        </p>
                        <WMICHeading
                            title={translator(currentStep.title)}
                            variant='h1'
                            isPrintablePage={isPrintablePage()}
                            isQuoteDownloadable={isQuoteDownloadable()}
                            downloadQuoteDocument={downloadQuoteDocument}
                            underlined
                            withdrawButton = {canWithdrawTransaction && createWithdrawButton()}
                        />
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );

    const claimHeader = useCallback(() => {
        const claimNumber = _.get(wizardSubmission.value, 'claimNumber', '');
        return wizardHeaderTemplate === 'claim' ? (
            <h1 className={styles.gwPageTitle}>
                {translator(messages.fnolNewClaim)}
                <span className={styles.gwClaimsWizardSpanNumber}>{claimNumber}</span>
            </h1>
        ) : null;
    }, [wizardHeaderTemplate, translator, wizardSubmission.value]);

    return (
        <>
            {submissionQuote}
            {claimHeader()}
        </>
    );
};

WMICWizardPageHeader.propTypes = {
    wizardSubmission: PropTypes.shape({
        value: PropTypes.shape({}),
        _dtoName: PropTypes.string
    }).isRequired,
    isSideHeader: PropTypes.bool,
    lobFlow: PropTypes.string,
    displayWizardHeader: PropTypes.bool
};

WMICWizardPageHeader.defaultProps = {
    isSideHeader: false,
    lobFlow: '',
    displayWizardHeader: true
};

export default WMICWizardPageHeader;
