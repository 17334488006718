import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICCreditConsentUtil } from 'wmic-pe-portals-utils-js';
import { WMICCreditConsentPNIComponent, WMICCreditConsentANIComponent } from 'wmic-pe-capability-gateway-common-react';

import _ from 'lodash';
import metadata from './WMICPACreditConsentComponent.metadata.json5';

function WMICPACreditConsentComponent(props) {
    const {
        id,
        submissionVM,
        updateWizardData,
        showErrors,
        onValidate,
        isReadOnlyUser,
    } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const { onValidate: setComponentValidation, registerComponentValidation, isComponentValid } = useValidation(id);

    const [isPrimaryNamedInsuredConsentable, setPrimaryNamedInsuredConsentable] = useState(true);
    const [additionalNamedInsureds, setAdditionalNamedInsureds] = useState([]);
    const [baseData, setBaseData] = useState();

    const consentValidMap = useMemo(() => ({}), []);

    const onValidateConsentMap = useCallback((isValid, componentId) => {
        consentValidMap[componentId] = isValid;
    }, [consentValidMap]);

    const checkComponentValidation = useCallback(() => (
        WMICCreditConsentUtil.isConsentValid(consentValidMap)
    ), [consentValidMap]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
        return () => onValidate(true, id);
    }, [isComponentValid, onValidate, id]);

    useEffect(() => {
        registerComponentValidation(checkComponentValidation);
    }, [checkComponentValidation, registerComponentValidation]);

    useEffect(() => {
        const updatedBaseData = WMICCreditConsentUtil.populateContextIfEmpty(
            _.get(submissionVM, 'baseData', {}), submissionVM, viewModelService
        )
        setBaseData(updatedBaseData);
        setPrimaryNamedInsuredConsentable(
            WMICCreditConsentUtil.isPniCreditConsentable(updatedBaseData.primaryNamedInsured_WMIC)
        );
        setAdditionalNamedInsureds(
            WMICCreditConsentUtil.getCreditConsentableAnis(submissionVM, viewModelService)
        );
    }, [submissionVM, viewModelService]);

    const updateBaseData = (updatedBaseData) => {
        WMICCreditConsentUtil.updateBaseData(submissionVM, updatedBaseData);
        setBaseData(updatedBaseData);
        updateWizardData(submissionVM);
    }

    const setAdditionalNamedInsured = (ani, index) => {
        const allConsentableAnis = WMICCreditConsentUtil.updateAdditionalNamedInsured(submissionVM, additionalNamedInsureds, ani, index);
        setAdditionalNamedInsureds(allConsentableAnis);
        updateWizardData(submissionVM);
    };

    const overrideProps = {
        creditConsentPNIComponent: {
            baseData: baseData,
            setBaseData: updateBaseData,
            onValidateConsentMap,
            showErrors,
            visible: isPrimaryNamedInsuredConsentable,
            isReadOnlyUser,
        },
        creditConsentANIIterable: {
            data: additionalNamedInsureds,
            submissionVM
        },
        ...WMICCreditConsentUtil.updateAniProps(additionalNamedInsureds, setAdditionalNamedInsured, onValidateConsentMap, showErrors, isReadOnlyUser)
    };

    const resolvers = {
        resolveComponentMap: {
            WMICCreditConsentPNIComponent,
            WMICCreditConsentANIComponent
        }
    };


    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={submissionVM}
            overrideProps={overrideProps}
            onModelChange={updateWizardData}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
};

WMICPACreditConsentComponent.propTypes = {
    id: PropTypes.string.isRequired,
    submissionVM: PropTypes.shape({}).isRequired,
    updateWizardData: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    showErrors: PropTypes.bool.isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
};

export default WMICPACreditConsentComponent;
