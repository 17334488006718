import { useEffect, useContext } from 'react'
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import { JobType, LINE_OF_BUSINESS } from 'wmic-pe-portals-utils-js';
import messages from './useDocumentTitle.messages';

function useDocumentTitle(title, jobVM = undefined) {
    const translator = useContext(TranslatorContext);

    const getLob = jobViewModel => {
        const lob = _.get(jobViewModel, 'lob.value.code', '');
        switch (lob) {
            case LINE_OF_BUSINESS.PERSONAL_AUTO:
                return translator(messages.personalAuto);
            case LINE_OF_BUSINESS.HOME_OWNER:
                return translator(messages.personalProperty);
            case LINE_OF_BUSINESS.PERSONAL_UMBRELLA:
                return translator(messages.personalUmbrella);
            case LINE_OF_BUSINESS.GENERAL_LIABILITY:
                return translator(messages.commercialLinesSmallBusiness);
            default:
                return lob;
        }
    };

    useEffect(() => {
        if (_.isUndefined(jobVM)) {
            const displayTitle = `${title} - ${translator(messages.gpaTitle)}`;
            document.title = displayTitle;
            document.getElementById('bluepass-app-title').innerHTML = displayTitle;
        } else {
            let displayTitle = "";
            const lob = getLob(jobVM);
            if (_.get(jobVM, 'baseData.jobType.value.code', '') === JobType.SUBMISSION) {
                const id = _.get(jobVM, 'quoteID.value', 0);
                displayTitle = `${translator(messages.submissionNumber)} #${id} - ${title} - ${lob} - ${translator(messages.gpaTitle)}`;
            }
            else if (_.get(jobVM, 'baseData.jobType.value.code', '') === JobType.POLICY_CHANGE) {
                const id = _.get(jobVM, 'jobID.value', 0);
                displayTitle = `${translator(messages.policyChange)} #${id} - ${title} - ${lob} - ${translator(messages.gpaTitle)}`;
            }
            else if (_.get(jobVM, 'baseData.jobType.value.code', '') === JobType.RENEWAL) {
                const id = _.get(jobVM, 'jobID.value', 0);
                displayTitle = `${translator(messages.renewal)} #${id} - ${title} - ${lob} - ${translator(messages.gpaTitle)}`;
            }
            else {
                const id = _.get(jobVM, 'jobID.value', 0);
                displayTitle = `${translator(messages.policyNumber)} #${id} - ${title} - ${lob} - ${translator(messages.gpaTitle)}`;
            }
            document.title = displayTitle;
            document.getElementById('bluepass-app-title').innerHTML = displayTitle;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title]);

}

export default useDocumentTitle