import React, { useEffect, useContext, useState, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import { TranslatorContext } from '@jutro/locale';

import { useDocumentTitle, useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICUserAccessUtil, ACTION_TYPES, CONSTANTS, WMICDateTimeService, WMICVariousUtil, WMICLogger, FlowStepId, WMICValidationUtil } from 'wmic-pe-portals-utils-js';
import {
    WMICHouseholdMemberDetailsComponent,
    WMICHouseholdMemberUtil,
    WMICPUPAddHouseholdMemberDropdownMenu
} from 'wmic-pe-capability-gateway-common-pup-react';
import { WMICListView } from 'wmic-pe-components-platform-react';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICPUPHouseholdMembersPage.metadata.json5';
import messages from './WMICPUPHouseholdMembersPage.messages.js';

function WMICPUPHouseholdMembersPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const { isComponentValid, onValidate, initialValidation } = useValidation('wmicPUPHouseholdMembers');
    const { showInfo, showConfirm, setWizardLoading } = useWizardModals();
    const { authHeader, authUserData } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { refreshData } = useDataRefresh();

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);

    const [householdMemberVM, setHouseholdMemberVM] = useState(_.get(submissionVM, `${CONSTANTS.HOUSEHOLD_MEMBERS_PATH}.children[0]`, undefined));
    const [householdMemberIndex, setHouseholdMemberIndex] = useState(0);
    const [backupHouseholdMember, setBackupHouseholdMember] = useState();
    const [householdMemberAction, setHouseholdMemberAction] = useState(ACTION_TYPES.NONE);
    const [showErrors, setShowErrors] = useState(false);

    const canEditSubmission = useMemo(() => WMICUserAccessUtil.canEditSubmission(authUserData.permission_Ext), [authUserData.permission_Ext]);

    useDocumentTitle(translator(messages.householdMemberTitle), submissionVM);
    
    useEffect(() => {
        _.set(submissionVM, 'isEditingPage.value', false);
    }, [submissionVM]);


    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.PUP_HOUSEHOLDMEMBERS
    }, [submissionVM.value]);

    const addHouseHoldMember = (index, namedInsured) => {
        const householdMember = WMICHouseholdMemberUtil.createHouseholdMember(submissionVM, namedInsured);
        const newHouseholdMember = viewModelService.create(householdMember,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalumbrella.coverables.dto.householdmember.HouseholdMemberDTO_WMIC',
            submissionVM.aspects.context()
        );

        setHouseholdMemberVM(newHouseholdMember);
        setHouseholdMemberAction(ACTION_TYPES.ADD);
        _.set(submissionVM, 'isEditingPage.value', true);

        submissionVM.lobData.personalUmbrella.coverables.householdMembers.value.push(newHouseholdMember.value);
        updateWizardData(submissionVM);

        return newHouseholdMember
    }

    const onNext = async () => {
        if (canEditSubmission) {
            if (!isComponentValid) {
                return false;
            }
        } else {
            return false;
        }

        try {
            setWizardLoading(true);

            const newSubmissionVM = viewModelService.clone(submissionVM);

            _.unset(newSubmissionVM.value, 'bindData');

            const result = await LoadSaveService.updateDraftSubmission(
                newSubmissionVM.value,
                authHeader
            );

            submissionVM.value = result;
            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.PUP_HOUSEHOLDMEMBERS)) {
                WMICVariousUtil.scrollToTop();
                return false;
            }

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    };

    const onSave = (houseHoldMember) => {
        if (!houseHoldMember) {
            return false
        }

        setWizardLoading(true);
        
        if (WMICHouseholdMemberUtil.isHouseholdMemberPromotedToPNI(houseHoldMember, submissionVM)) {
            WMICHouseholdMemberUtil.clearOldPNI(submissionVM, viewModelService);
        }

        return LoadSaveService.saveWithNoValidationRuleCheck(
            submissionVM.value,
            authHeader
        ).then((result) => {
            submissionVM.value = result;
            updateWizardData(submissionVM);

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.PUP_HOUSEHOLDMEMBERS)) {
                WMICVariousUtil.scrollToTop();
            }

            setHouseholdMemberAction(ACTION_TYPES.NONE);
            setShowErrors(false);

            return true;
        }).catch((error) => {
            WMICLogger.error('Save household member failed', error);
            return false
        }).finally(() => {
            setWizardLoading(false);
        });
    };

    const updateSelectedHouseholdMember = (memberVM) => {
        setHouseholdMemberVM(memberVM);
        refreshData();
    };

    const viewHouseholdMember = (index, edit) => {
        const memberVM = _.get(submissionVM, `${CONSTANTS.HOUSEHOLD_MEMBERS_PATH}.children[${index}]`);

        if(edit) {
            updateWizardData(submissionVM);
            setHouseholdMemberAction(ACTION_TYPES.EDIT);
            setBackupHouseholdMember(_.cloneDeep(memberVM.value));
            _.set(submissionVM, 'isEditingPage.value', true);
        }

        updateSelectedHouseholdMember(memberVM);
        setHouseholdMemberIndex(index);
    };

    const onRemoveHouseholdMember = (householdMember, index) => {
        if (householdMember?.isPNI?.value) {
            showInfo({
                title: messages.householdMemberTitle,
                message: messages.wmicPUPHouseholdMembersPrimaryNamedInsuredCannotBeRemoved
            });
            return _.noop();
        }

        showConfirm({
            title: messages.householdMemberTitle,
            message: messages.wmicPUPHouseholdMembersRemovingHouseholds
        }).then((response) => {
            if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                const allHouseholdMembers = _.get(submissionVM, `${CONSTANTS.HOUSEHOLD_MEMBERS_PATH}.value`, []);

                allHouseholdMembers.splice(index, 1);
                _.set(submissionVM, `${CONSTANTS.HOUSEHOLD_MEMBERS_PATH}.value`, allHouseholdMembers);
                updateWizardData(submissionVM);
            }
        });

        return _.noop();
    }

    const onCancel = () => {
        if(householdMemberAction === ACTION_TYPES.ADD) {
            setHouseholdMemberVM(undefined);
            setHouseholdMemberIndex(-1);
        } else if (householdMemberAction === ACTION_TYPES.EDIT) {
            _.get(submissionVM, CONSTANTS.HOUSEHOLD_MEMBERS_PATH).setElement(householdMemberIndex, backupHouseholdMember);
            viewHouseholdMember(householdMemberIndex);
        }

        setHouseholdMemberAction(ACTION_TYPES.NONE);
        setShowErrors(false);
        _.set(submissionVM, 'isEditingPage.value', false);
    };

    const displayRelationship = (member) => {
        const relationshipTypeList = viewModelService.productMetadata.get('pc').types.getTypelist('ApplicantRelation_wmic');

        if (member?.isPNI) {
            return translator({id: relationshipTypeList.getCode('insured').name});
        }

        if (member?.relationshipToPrimaryInsured_WMIC) {
            return translator({id: relationshipTypeList.getCode(member.relationshipToPrimaryInsured_WMIC).name});
        }

        return '';
    };

    const displayDate = (data, path) => {
        const dateValue = _.get(data.value, path);

        return dateValue ? <span>{WMICDateTimeService.toMidnightDate(dateValue)}</span> : '';
    };

    const getAddHouseholdMemberButton = ({ isEditing, onClick }) => {
        const additionalNamedInsureds = _.get(submissionVM, `${CONSTANTS.ANIS_PATH}.value`);
        const householdMembers = _.get(submissionVM, `${CONSTANTS.HOUSEHOLD_MEMBERS_PATH}.value`, []);

        return <WMICPUPAddHouseholdMemberDropdownMenu
            additionalNamedInsureds={additionalNamedInsureds}
            isEditing={isEditing}
            onClick={onClick}
            householdMembers={householdMembers}>
        </WMICPUPAddHouseholdMemberDropdownMenu>
    };

    const overrideProps = {
        householdMembersListView: {
            value: _.get(submissionVM, `${CONSTANTS.HOUSEHOLD_MEMBERS_PATH}.children`, []),
            clickable: true,
            onSave,
            onDelete: onRemoveHouseholdMember,
            readOnly: false,
            toCreate: addHouseHoldMember,
            addButtonLabel: translator(messages.wmicPUPHouseholdMembersTitle),
            toUndoCreate: () => {
                // to undo new houseHoldMember creation, simply remove the last houseHoldMember in the array (it's always the one being "added")
                const houseHoldMembers = _.get(submissionVM.value, CONSTANTS.HOUSEHOLD_MEMBERS_PATH);

                houseHoldMembers.splice(houseHoldMembers.length-1, 1);
                _.set(submissionVM.value, CONSTANTS.HOUSEHOLD_MEMBERS_PATH, houseHoldMembers);
                updateWizardData(submissionVM);
            },
            toEdit: updateSelectedHouseholdMember,
            detailViewComponent: WMICHouseholdMemberDetailsComponent,
            onValidate,
            startOpen: true,
            detailViewComponentProps: {
                value: householdMemberVM,
                saveHouseHoldMember: onSave,
                cancelHouseHoldMember: onCancel,
                updateModel: updateSelectedHouseholdMember,
                isEditing: [ACTION_TYPES.ADD, ACTION_TYPES.EDIT].includes(householdMemberAction),
                onValidate,
                showErrors,
                setShowErrors,
                jobVM: submissionVM,
                showButtons: false,
            },
            VMData: [
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersName),
                    path: 'person.displayName'
                },
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersRelationship),
                    getData: (member) => displayRelationship(member.value)
                },
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersDateOfBirth),
                    getData: displayDate,
                    path: 'person.dateOfBirth'
                },
                {
                    headerText: translator(messages.wmicPUPHouseholdMembersPrimaryNamedInsured),
                    path: 'isPNI'
                }
            ],
            renderAddButton: getAddHouseholdMemberButton
        }
    };

    const resolvers = {
        resolveCallbackMap:{
            addHouseHoldMember
        },
        resolveComponentMap: {
            WMICHouseholdMemberDetailsComponent,
            WMICListView
        }
    };

    return (
        <WMICWizardSubmissionPage
            disableNext={!isComponentValid || [ACTION_TYPES.ADD, ACTION_TYPES.EDIT].includes(householdMemberAction)}
            onNext={onNext}
            skipWhen={initialValidation}
            cancelLabel={translator(commonMessages.saveAndExit)}
            flowStepId={FlowStepId.PUP_HOUSEHOLDMEMBERS}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICPUPHouseholdMembersPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default WMICPUPHouseholdMembersPage;
