import { defineMessages } from 'react-intl';

export default defineMessages({
    vehiclesTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.pages.Vehicles.WMICPRPAVehiclesPage.Title',
        defaultMessage: 'Vehicles'
    },
    savingVehicle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.savingVehicle',
        defaultMessage: 'Saving Vehicle'
    },
    vinIsNotUnique: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.vinIsNotUnique',
        defaultMessage: 'VIN is not unique'
    },
    vinMatchMsg: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.vinMatchMsg',
        defaultMessage: 'Sorry, but this vehicle\'s VIN matches that of {vehicle}. Please ensure that all VINs are unique.'
    },
    actionRequired: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.actionRequired',
        defaultMessage: 'Action required'
    },
    vinSearchRequired: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.vinSearchRequired',
        defaultMessage: 'A vehicle search is required to continue.'
    },
    close: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.close',
        defaultMessage: 'Close'
    },
    snowVehicleCombinationError: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.Snow Vehicle With PPV',
        defaultMessage: 'Vehicle Type: Snow Vehicle cannot be combined with any other vehicle type.'
    },
    snowMotorcycleVehicleCombinationError: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.Snow And Motorcycle With PPV',
        defaultMessage: 'Vehicle Type: Snow Vehicle cannot be combined with any other vehicle type, Motorcycle cannot be combined with any other vehicle type.'
    },
    motorcycleVehicleCombinationError: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.Motorcycle With PPV',
        defaultMessage: 'Vehicle Type: Motorcycle cannot be combined with any other vehicle type.'
    },
    snowMotorcycleVehicleCombination: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.Snow Vehicle and Motorcycle Together',
        defaultMessage: 'Vehicle Type: Motorcycle cannot be combined with Snow Vehicle.'
    },
    errorVehicleCombinationError: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.Error Description',
        defaultMessage: 'Error'
    },
    removeVehicleTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.removeVehicleTitle',
        defaultMessage: 'Remove Vehicle?'
    },
    removeVehicle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.removeVehicle',
        defaultMessage: 'Are you sure you want to remove {vehicle} from the list of vehicles?'
    },
    vehicleNumber: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.vehicleNumber',
        defaultMessage: '#'
    },
    theftIdentifier: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.theftIdentifier',
        defaultMessage: 'THEFT IDENTIFIER'
    },
    vehicleType: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.vehicleType',
        defaultMessage: 'VEHICLE TYPE'
    },
    vehicleDescription: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.vehicleDescription',
        defaultMessage: 'VEHICLE DESCRIPTION'
    },
    vehicleBusinessSegment: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.vehicleBusinessSegment',
        defaultMessage: 'BUSINESS SEGMENT'
    },
    saveVehicleFailed: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.saveVehicleFailed',
        defaultMessage: 'Save vehicle failed'
    },
    newVehicle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPRPAVehiclesPage.newVehicle',
        defaultMessage: 'New Vehicle'
    },
});
