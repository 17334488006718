/* eslint-disable indent */
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useCallback, useEffect, useState } from 'react';
import { TranslatorContext, IntlContext } from '@jutro/locale'; 
import { WMICRichTextUtil } from 'wmic-portals-utils-js';
import { ToggleField } from '@jutro/components';
import styles from '../WMICRemoveVehicleWizard.module.scss';
import messages from '../WMICRemoveVehicleWizard.messages';
import { getVehicleName, getCurrentVehicleIndexByNumber, getElementByXpath } from '../WMICRemoveVehicleWizardUtils';
import { getMessageContent } from './index';
import { PRIMARY_USE, STATE_KEYS, VEHICLE_KEYS, ANNUAL_MILEAGE_UNDER12000_TOGGLE, MESSAGE_TYPES, VIEW_KEYS } from '../WMICRemoveVehicleWizardConsts';
import WMICRemoveVehicleMessage from '../../WMICRemoveVehicleMessage/WMICRemoveVehicleMessage';
import { InputField, DropdownSelectField } from '@jutro/legacy/components';

// check vehicle mileage
export const checkVehicleMileage = (modelState, currentVehicleNumber) => {
    const originalVehicle = modelState[STATE_KEYS.ORIGINAL_VEHICLES].find(
        (v) => v.vehicleNumber === currentVehicleNumber);
    const remainingVehicle = modelState[STATE_KEYS.REMAINING_VEHICLES].find(
        (v) => v.vehicleNumber === currentVehicleNumber);

    return ({
        isMileageReduced: originalVehicle.annualMileage > parseInt(remainingVehicle.annualMileage)
    });
};

export const adjustVehicleUsing = (modelState, modelStateDispatch) => {
    // if delete vehicle is business and one vehicle remaining
    if (modelState[STATE_KEYS.VEHICLE_TO_REMOVE]
        && modelState[STATE_KEYS.VEHICLE_TO_REMOVE][VEHICLE_KEYS.PRIMARY_USE] === 'business'
        && modelState[STATE_KEYS.REMAINING_VEHICLES].length === 1) {

        modelStateDispatch({ type: `${STATE_KEYS.REMAINING_VEHICLES}.0.${VEHICLE_KEYS.PRIMARY_USE}`, value: 'business' });
        if (parseInt(modelState[STATE_KEYS.VEHICLE_TO_REMOVE][VEHICLE_KEYS.ANNUAL_MILEAGE]) > parseInt(modelState[STATE_KEYS.REMAINING_VEHICLES][0][VEHICLE_KEYS.ANNUAL_MILEAGE])) {
            modelStateDispatch({
                type: `${STATE_KEYS.REMAINING_VEHICLES}.0.${VEHICLE_KEYS.ANNUAL_MILEAGE}`,
                value: modelState[STATE_KEYS.VEHICLE_TO_REMOVE][VEHICLE_KEYS.ANNUAL_MILEAGE]
            });
        }
    }

    // if only one driver
    if (modelState[STATE_KEYS.DRIVERS_TO_ASSIGN].length === 1) {
        const driverId = modelState[STATE_KEYS.DRIVERS_TO_ASSIGN][0].code;
        modelState[STATE_KEYS.REMAINING_VEHICLES].forEach((v, idx) => {
            modelStateDispatch({ type: `${STATE_KEYS.REMAINING_VEHICLES}.${idx}.${VEHICLE_KEYS.PRINCIPAL_DRIVER}`, value: driverId })
        });
    }
};

// check vehicles usage
export const checkVehiclesUsages = (modelState) => {
   const checkResult = ({
       isChangeToBusiness: true,
       isChangeUnCorrectPrimaryUseList: [],
       summary: false
   });
   
   if (modelState[STATE_KEYS.VEHICLE_TO_REMOVE]) {
       checkResult.isChangeToBusiness = modelState[STATE_KEYS.VEHICLE_TO_REMOVE]?.primaryUse === 'business'
           ? !_.some(modelState[STATE_KEYS.REMAINING_VEHICLES], (v) => v.primaryUse === 'business')
           : false;

        if (!checkResult.isChangeToBusiness) {
           _.forEach(modelState[STATE_KEYS.REMAINING_VEHICLES], (v) => {
               const originalVehicle = _.find(modelState[STATE_KEYS.ORIGINAL_VEHICLES], (_v) => _v.vehicleNumber === v.vehicleNumber);
               if (originalVehicle) {
                   if (originalVehicle.primaryUse !== v.primaryUse &&
                       (!(modelState[STATE_KEYS.VEHICLE_TO_REMOVE]?.primaryUse === 'business' && v.primaryUse === 'business') &&
                       !(originalVehicle.primaryUse === 'pleasure' && v.primaryUse === 'commuting')) ||
                        (v.vehicleType === 'motorhome' && v.primaryUse !== 'pleasure') ) //new condition that motorhome stay usage as "Pleasure".
                        {
                           checkResult.isChangeUnCorrectPrimaryUseList.push({
                               displayName: getVehicleName(v),
                               originalPrimaryUse: originalVehicle.primaryUse,
                               currentPrimaryUse: v.primaryUse,
                               fixedId: v.fixedId
                           });
                   }
               }
           });
       }

       checkResult.summary = !checkResult.isChangeToBusiness && checkResult.isChangeUnCorrectPrimaryUseList.length === 0;
   }

   return checkResult;
};

const WMICRemoveVehicleUsingStep = (props) => {
    const { modelState, modelStateDispatch, currentVehicleNumber, viewState, viewStateDispatch, ...otherProps } = props;
    const vehicle = modelState[STATE_KEYS.REMAINING_VEHICLES].find((v) => v.vehicleNumber === currentVehicleNumber);
    const translator = useContext(TranslatorContext);
    const vehicleIdx = getCurrentVehicleIndexByNumber(currentVehicleNumber, modelState[STATE_KEYS.REMAINING_VEHICLES]);
    const intl = useContext(IntlContext);

    const message = vehicleIdx === 0 ? getMessageContent(MESSAGE_TYPES.VEHICLE_USING, translator, intl, modelState) : null;

    const mileagePattern = /[1-9]\d{0,4}/;
    const validateInput = useCallback((v) => {
        const patMatches = v.match(mileagePattern);
        if (!v || (patMatches && patMatches.length===1 && patMatches[0]===v)) {
            modelStateDispatch({ type: `${STATE_KEYS.REMAINING_VEHICLES}.${vehicleIdx}.${VEHICLE_KEYS.ANNUAL_MILEAGE}`, value: v });
        }
    }, [mileagePattern]);
    
    const [exVehicleIdx, setExVehicleIdx] = useState(-1);  //used so react redraw due to value change would not trigger reset of focus

    const getAnnualMileageToggleValue = () => {
        switch (modelState[STATE_KEYS.REMAINING_VEHICLES][vehicleIdx]?.annualMileageUnder12000Toggle) {
            case ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_YES:
                return true;
            case ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_NO:
                return false;
            default:
                return;
        }
    };

    const [toggleAnnualMileage, setToggleAnnualMileage] = useState(getAnnualMileageToggleValue);
    const [viewVisibility, setViewVisibility] = useState(vehicle.ANNUAL_MILEAGE_UNDER12000_TOGGLE);

    // eslint-disable-next-line arrow-body-style
    const shouldShowToggleValidationMsg = () => {
        return viewVisibility === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_REMAIN_UNKNOWN;
    };

    const focusOnValidationMsg = () => {   
        if (shouldShowToggleValidationMsg()) {
            const toggleButton = getElementByXpath("//div[contains(@id,'annual_mileage_under_12000_toggle')]//button");
            toggleButton.setAttribute('tabindex', '0');
            toggleButton.focus({preventScroll: false});
        }
    };

    const focusRemoveVehicleUsingStepLabel = () => {
        const ariaLabelToBeRead = document.getElementById('WMICRemoveVehicleUsingStepLabel');
        if (ariaLabelToBeRead) {
            ariaLabelToBeRead.setAttribute('tabindex', '0');
            ariaLabelToBeRead.focus({preventScroll: false});
        }
    };

    useEffect(() => {   
        if (exVehicleIdx !== vehicleIdx) {
            setExVehicleIdx(vehicleIdx);
            if (vehicleIdx !== 0 || !message) {
                focusRemoveVehicleUsingStepLabel();
            }
        }
        setViewVisibility(vehicle.annualMileageUnder12000Toggle);
    });

    useEffect(() => {  //reset toggle value and focus after display of the error message when toggle button was not selected.
        setToggleAnnualMileage(getAnnualMileageToggleValue);
        focusOnValidationMsg();
    }, [viewVisibility]);

    const handleToggleAnnualMileage = (value) => {
        setToggleAnnualMileage(value);
        if (value) {
            modelState[STATE_KEYS.REMAINING_VEHICLES][vehicleIdx].annualMileageUnder12000Toggle = ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_YES;
            setViewVisibility(ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_YES);
        } else {
            modelState[STATE_KEYS.REMAINING_VEHICLES][vehicleIdx].annualMileageUnder12000Toggle = ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_NO;
            setViewVisibility(ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_NO);
        }
    };

    const getAnnualMileageLabel = () => {
        if (viewVisibility === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_DISPLAY_NON) {
            return translator(messages.annualMileageLabel, { vehicleName: getVehicleName(vehicle) });
        }
        else if (viewVisibility === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_NO) {
            return translator(messages.annualMileageFromRecordLabel, { vehicleName: getVehicleName(vehicle) });
        }
    }

    const getAnnualMileageAriaLabel = () => {
        if (viewVisibility === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_DISPLAY_NON) {
            return translator(messages.annualMileageOverLimitAria, { vehicleName: getVehicleName(vehicle) });
        }
        else if (viewVisibility === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_NO) {
            return translator(messages.annualMileageFromRecordAriaLabel, { vehicleName: getVehicleName(vehicle) });
        }
    }

    return (
        <div {...otherProps}>
            {vehicleIdx === 0 && <WMICRemoveVehicleMessage {...message} />}
            <div className={styles['wmic-edit-title']}  id="WMICRemoveVehicleUsingStepLabel">
                {WMICRichTextUtil.translateRichText(
                    translator(viewVisibility !== ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_DISPLAY_NON ? messages.usingVehicleUnder12000MilesMessage : messages.usingVehicleOver12000MilesMessage, 
                    { vehicleName: getVehicleName(vehicle) })
                )}
            </div>
            <div className={`ww-edit-field-container ${styles['wmic-field-container-margin-bottom']}`}>
                <DropdownSelectField
                    id="primary_use_dropdown"
                    secondaryLabel={translator(messages.primaryUseVehicleLabel)}
                    className={styles['wmic-field-fit-to-content']}
                    secondaryLabelClassName={styles['wmic-field-label-fit-to-content']}
                    //label is hidden to provide better user experience for accessibility purpose. secondary label is displayed in UI.
                    label={WMICRichTextUtil.translateRichText(translator(messages.primaryUseVehicleLabelAria, { vehicleName: getVehicleName(vehicle) }))}
                    labelClassName={styles['wmicHidden']}
                    availableValues={PRIMARY_USE}
                    value={modelState[STATE_KEYS.REMAINING_VEHICLES][vehicleIdx]?.primaryUse}
                    onValueChange={(v) => modelStateDispatch({ type: `${STATE_KEYS.REMAINING_VEHICLES}.${vehicleIdx}.${VEHICLE_KEYS.PRIMARY_USE}`, value: v })}
                />
            </div>
            <div className={viewVisibility !== ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_DISPLAY_NON ? 'ww-edit-field-container' : styles['wmicHidden']}>
                <div className={styles.wmicAnnualMileageToggleRow}>
                    <ToggleField
                        id="annual_mileage_under_12000_toggle"
                        //label is hidden to provide better user experience for accessibility purpose. secondary label is displayed in UI.
                        secondaryLabel={WMICRichTextUtil.translateRichText(translator(messages.annualMileageUnder12000Toggle, { vehicleName: getVehicleName(vehicle) }))}
                        secondaryLabelClassName={styles['wmic-field-label-fit-to-content']}
                        label={shouldShowToggleValidationMsg() ? 
                            translator(messages.annualMileageUnder12000ToggleWarningAria, { vehicleName: getVehicleName(vehicle) }) : 
                            translator(messages.annualMileageUnder12000ToggleAria, { vehicleName: getVehicleName(vehicle) })}
                        labelClassName={styles['wmicHidden']}
                        availableValues={[
                            {
                                displayName: translator(messages.annualMileageToggleYes),
                                id: 'true'
                            },
                            {
                                displayName: translator(messages.annualMileageToggleNo),
                                id: 'false'
                            }
                        ]}
                        onValueChange={handleToggleAnnualMileage}
                        value={toggleAnnualMileage}
                        buttonAriaLabels={[
                            translator(messages.toggleYesAnnualMileageAbout12000Aria, { vehicleName: getVehicleName(vehicle) }),
                            translator(messages.toggleNoAnnualMileageAbout12000Aria, { vehicleName: getVehicleName(vehicle) })
                        ]}
                    />
                </div>
            </div>
            <div className={shouldShowToggleValidationMsg() ? cx(styles.wmicInputBoxNotes, styles.errorRed) : styles['wmicHidden']} >
                {translator(messages.pleaseSelectAnnualMileageUnder12000MilesToggle)}
            </div>
            <div className={viewVisibility === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_YES ? styles['wmic-field-label-fit-to-content'] : styles['wmicHidden']} >
                {WMICRichTextUtil.translateRichText(translator(messages.annualMileageUpdatedTo12000Miles, { vehicleName: getVehicleName(vehicle) }))}
            </div>
            <div className={(viewVisibility === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_DISPLAY_NON || viewVisibility === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_NO) ? 'ww-edit-field-container' : styles['wmicHidden']}>
                <InputField
                    decimalPlaces={0}
                    id="annual_mileage_input"
                    className={styles['wmic-field-fit-to-content']}
                    //label is hidden to provide better user experience for accessibility purpose. secondary label is displayed in UI.
                    secondaryLabel={WMICRichTextUtil.translateRichText(getAnnualMileageLabel())}
                    secondaryLabelClassName={styles['wmic-field-label-fit-to-content']}
                    label={getAnnualMileageAriaLabel()}
                    labelClassName={styles['wmicHidden']}
                    value={modelState[STATE_KEYS.REMAINING_VEHICLES][vehicleIdx]?.annualMileage}
                    onValueChange={(v) => validateInput(v)}
                />
            </div>
            <div className={(viewVisibility === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_DISPLAY_NON || viewVisibility === ANNUAL_MILEAGE_UNDER12000_TOGGLE.TOGGLE_NO) ? styles.wmicInputBoxNotes : styles['wmicHidden']}>
                {translator(messages.annualMileageOverLimit)}
            </div>
        </div>
    );
}

WMICRemoveVehicleUsingStep.propTypes = {
    modelState: PropTypes.shape({}).isRequired,
    currentVehicleNumber: PropTypes.string,
    modelStateDispatch: PropTypes.func.isRequired
};

export default WMICRemoveVehicleUsingStep;
