import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetailsTitle: {
        id: "wmic-pe-capability-gateway-quoteandbind-pup-react.pages.WMICPUPPolicyDetails.WMICPUPPolicyDetailsPage.Title",
        defaultMessage : "Policy Details"
    },
    defaultAnswers: {
        id: "wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPPolicyDetailsPage.Create Default Answer",
        defaultMessage : "Creating Default Answer"
    }
})
