import { defineMessages } from 'react-intl';

export default defineMessages({
    reductionOfCoverageWithLimit: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.ReductionOfCoverageWithLimit',
        defaultMessage: '28- Reduction of Coverage when Vehicle Operated by Named Drivers - Limit of ${driverLimit}'
    },
    reductionOfCoverage_AB_YT: {
        id: 'wmic-pe-capability-policy-common-react.DriverEndorsementsCoverages.ReductionOfCoverage_AB_YT',
        defaultMessage: '28(C) - Named Person(s) Reduction of Coverage Endorsement - Limit of $200,000'
    },
})
