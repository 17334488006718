/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { TranslatorContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';

import metadata from './WMICBankAccountForm.metadata.json5';
import messages from './WMICBankAccountForm.messages';
import styles from './WMICBankAccountForm.module.scss';

const NEW_PAYMENT_INSTRUMENT_ID = '-1';
const NEW_PERSON_CONTACT_ID = '-1';
const NEW_COMPANY_CONTACT_ID = '-2';

function WMICBankAccountForm(props) {
    const { id, onValidate, eftVM, updateEftVM, viewModelService, authHeader, disableAccountOwnerName } = props;
    const { onValidate: setComponentValidation, isComponentValid } = useValidation(id);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { refreshData } = useDataRefresh()
    const translator = useContext(TranslatorContext);
    const [showErrors, updateShowErrors] = useState(false);
    const [bankAccountVM, updateBankAccountVM] = useState(eftVM);
    const [invalidSearchError, updateInvalidSearchError] = useState(false);
    const [countOfFailedSearch, updateCountOfFailedSearch] = useState(0);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid])

    const getFirstDateOfMonthValues = () => {
        const days = [];
        for (let day = 1; day <= 27; day++) {
            days.push({
                code: day,
                name: day,
            });
        }
        return days;
    };    

    const fetchInstitutionName = () => {
        const transitNumber = _.get(bankAccountVM, 'transitNumber');
        const financialInstitutionNumber = _.get(bankAccountVM, 'institutionNumber');
        const transitNumberValue = transitNumber.value;
        const financialInstitutionNumberValue = financialInstitutionNumber.value;
        if (transitNumber.aspects.valid && financialInstitutionNumber.aspects.valid) {
            if (transitNumberValue.toString().match(/[0-9]/g).length === 5 &&
                financialInstitutionNumberValue.toString().match(/[0-9]/g).length === 3) {
                _.set(bankAccountVM, 'bankName.value', null);
                const fetchInstitutionNamePromise = LoadSaveService.fetchInstitutionName(transitNumberValue, financialInstitutionNumberValue, authHeader);
                fetchInstitutionNamePromise.then((response) => {
                    _.set(bankAccountVM, 'bankName.value', response);
                    updateBankAccountVM(bankAccountVM);
                    updateEftVM(bankAccountVM);
                    refreshData();
                    updateCountOfFailedSearch(0);
                    updateInvalidSearchError(false);
                }).catch(() => {
                    updateCountOfFailedSearch(countOfFailedSearch + 1);
                    updateInvalidSearchError(true);
                });
            }
        }
    };

    const getFinancialInstitutionErrorMessage = () => {
        if (invalidSearchError) {
            if (countOfFailedSearch === 1) {
                return [translator(messages.fiNameNotFoundFirstError)];
            }
            return [translator(messages.fiNameNotFoundSecondError)];
        }
        return [];
    };
    
    const onEftVMChange = (newVM) => {
        _.set(newVM, 'bankAccountNumber.value', newVM?.bankAccountNumber?.value?.trim());
        updateBankAccountVM(newVM);
        updateEftVM(newVM);
        refreshData();
    };

    const overrideProps = {
        '@field': {
            parentNode: bankAccountVM,
        }, 
        firstDateOfMonth: {
            availableValues: getFirstDateOfMonthValues()
        },
        bankName: {
            disabled: true,
            validationMessages: getFinancialInstitutionErrorMessage(),
            showErrors: invalidSearchError
        },
        accountHolder: {
            disabled: disableAccountOwnerName
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            fetchInstitutionName
        },
        resolveClassNameMap: styles,
    };

    return (
        <ViewModelForm
            model={bankAccountVM}
            onModelChange={onEftVMChange}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

export default WMICBankAccountForm;
