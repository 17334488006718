import React, {
    useCallback,
    useContext,
    useState,
    useEffect,
    useMemo,
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale'
import { Accordion } from '@jutro/legacy/components';
import { withViewModelService, ViewModelForm, useDataRefresh } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICInsuranceHistoryPoliciesComponent } from 'wmic-pe-capability-gateway-common-react';
import { WMICCreditConsentUtil, WMICRPCUtil, WMICLobUtil, WMICLogger, WMICUserAccessUtil, JURISDICTIONS } from 'wmic-pe-portals-utils-js';
import { WMICPALossHistoryComponent, WMICPACreditConsentComponent } from 'wmic-pe-capability-gateway-quoteandbind-pa-react';
import { useAccordionValidation, useDocumentTitle, WMICWizardChangeOrRenewalPage, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';
import { WMICPAValidationWarningUtil } from 'wmic-pe-capability-gateway-common-pa-react';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import styles from '../../WMICPEPARenewalWizard.module.scss';
import metadata from './WMICPARenewalInsuranceHistoryPage.metadata.json5';
import messages from './WMICPARenewalInsuranceHistoryPage.messages';

function WMICPARenewalInsuranceHistoryPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData
    } = props;

    const { RenewalService } = useDependencies('RenewalService');

    const translator = useContext(TranslatorContext);
    const { onValidate, initialValidation, isComponentValid } = useValidation(
        'WMICPARenewalInsuranceHistoryPage'
    );
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);
    const { refreshData } = useDataRefresh();
    const { authHeader, authUserData } = useAuthentication();
    const { setWizardLoading, showCustom } = useWizardModals();
    const [carriersList, setCarrierList] = useState();
    const [showErrors, setShowErrors] = useState(false);
    const lobPath = WMICLobUtil.getProductPath(_.get(renewalVM, "lob.value.code"));
    const [insuranceHistoryVM, setInsuranceHistoryVM] = useState(_.get(renewalVM, `lobData.${lobPath}.insuranceHistory_WMIC`));
    const [isCreditConsentVisible, setCreditConsentVisible] = useState(false);
    const [isCreditScorevalidated, setIsCreditScorevalidated] = useState(false);
    const vehicles = _.get(renewalVM, 'lobData.personalAuto.coverables.vehicles.value');
    const anisVM = _.get(renewalVM, 'baseData.additionalNamedInsureds_WMIC');
    const baseStateCode = _.get(renewalVM, 'baseData.baseState.value.code');

    const canEditRenewal = useMemo(() => WMICUserAccessUtil.canEditRenewal(authUserData.permission_Ext), [authUserData.permission_Ext]);

    useDocumentTitle(translator(messages.insuranceHistoryTitle), renewalVM);

    useEffect(() => {
        const baseData = _.get(renewalVM, 'baseData');

        // Keeping this lookup here to reduce network payload size for performance
        UISupportingInfoLookupService.retrieveInsuranceCarriersList([baseData.baseState.value.code, baseData.periodStartDate.value], true, authHeader)
            .then((carriers) => {
                carriers.forEach((item) => {
                    item.name = item.carrier
                    item.code = item.carrier
                })
                setCarrierList(carriers);
            });
        const discountCreditConsentRPCIsEffective = WMICRPCUtil.getIsRPCEffective(
            _.get(baseData, 'baseState.value.code'),
            _.get(baseData, 'rateAsOfDate.value'),
            '1503', '1504', '1576', '1543'
        );
        const hasOnlyIRCAVehicles = WMICCreditConsentUtil.hasOnlyIRCAVehicles(vehicles); 
        const hasPersonalVehicleOrMotorHome = WMICCreditConsentUtil.hasPersonalVehicleOrMotorHome(vehicles);
        const isPniCreditConsentable = WMICCreditConsentUtil.isPniCreditConsentable(_.get(baseData, 'primaryNamedInsured_WMIC'));
        const isAnyAniCreditConsentable = WMICCreditConsentUtil.isAnyAniCreditConsentable(_.get(baseData, 'additionalNamedInsureds_WMIC.value'));

        setCreditConsentVisible(
            (!hasOnlyIRCAVehicles && discountCreditConsentRPCIsEffective && hasPersonalVehicleOrMotorHome && (isPniCreditConsentable || isAnyAniCreditConsentable)) 
            || 
            (isAnyAniCreditConsentable && _.get(baseData, 'baseState.value.code') === JURISDICTIONS.QUEBEC)
        );
    }, [authHeader, renewalVM, vehicles]);

    const updateInsuranceHistoryVM = (historyVM) => {
        refreshData();
        setInsuranceHistoryVM(historyVM);
        _.set(renewalVM, `lobData.${lobPath}.insuranceHistory_WMIC`, historyVM.value);
        updateWizardData(renewalVM);
    }

    const updateDrivers = useCallback((namedInsured) => {
        const allDrivers = _.get(renewalVM, 'lobData.personalAuto.coverables.drivers.value');
        if (WMICCreditConsentUtil.isAniCreditConsentable(namedInsured) && allDrivers && allDrivers.length > 0) {
            let found = false;
            const ani = namedInsured.value;
            for (let driverCounter = 0; !found && driverCounter < allDrivers.length; driverCounter++) {
                const driver = allDrivers[driverCounter];
                if (driver.person.publicID === ani.contactPublicID || driver.person.publicID === ani.publicID) {
                    driver.dateOfBirth = _.get(ani, 'dateOfBirth');
                    driver.creditConsentReceived = _.get(ani, 'creditConsentReceived');
                    driver.creditConsentDate = _.get(ani, 'creditConsentDate');
                    driver.creditInfoWithdrawalConsent = _.get(ani, 'creditInfoWithdrawalConsent');
                    driver.personalInfoConsentForm = _.get(ani, 'personalInfoConsentForm');
                    found = true;
                }
            }
            updateWizardData(renewalVM);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renewalVM]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }
        try {
            setShowErrors(false);
            setWizardLoading(true, translator(commonMessages.savingTransactionDetails));

            if (baseStateCode === JURISDICTIONS.QUEBEC) {
                anisVM.forEach((ani) => updateDrivers(ani));
            }

            const result = await RenewalService.saveRenewal(
                [renewalVM.value],
                authHeader
            );
            _.extend(renewalVM.value, result);

            const isInvalid = !isCreditScorevalidated && WMICPAValidationWarningUtil.checkPACreditScoreValidation(renewalVM, translator, showCustom);
            setIsCreditScorevalidated(true);

            return isInvalid ? false : renewalVM;
        } catch (err) {
            WMICLogger.error('Save Insurance History failed', err);
        } finally {
            setWizardLoading(false);
        }
        return false;
    }, [isComponentValid, setWizardLoading, translator, baseStateCode, RenewalService, renewalVM, authHeader, isCreditScorevalidated, showCustom, anisVM, updateDrivers]);

    const commonAccordionProps = {
        showErrors
    }

    const commonAccordionContentProps = {
        showErrors,
        isReadOnlyUser: !canEditRenewal,
        isEditMode: true,
        onValidate: onValidateAccordion,
        submissionVM: renewalVM,
        updateWizardData,
        authHeader,
        baseData: _.get(renewalVM, 'baseData'),
        updateHistory: updateInsuranceHistoryVM,
        insuranceHistoryVM,
        readOnly: !canEditRenewal,
    };

    const overrideProps = {
        policiesAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('policiesAccordionContent')
        },
        policiesAccordionContent: {
            ...commonAccordionContentProps,
            carriersList,
            jobVM: renewalVM
        },
        lossHistoryAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('lossHistoryAccordionComponent')
        },
        lossHistoryAccordionComponent: {
            ...commonAccordionContentProps
        },
        creditConsentAccordion: {
            ...commonAccordionProps,
            visible: isCreditConsentVisible,
            isValid: isAccordionValid('creditConsentAccordionComponent')
        },
        creditConsentAccordionComponent: {
            ...commonAccordionContentProps,
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap:{
            WMICInsuranceHistoryPoliciesComponent,
            WMICPALossHistoryComponent,
            WMICPACreditConsentComponent,
            Accordion
        },
        resolveClassNameMap: styles
    };

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPARenewalInsuranceHistoryPage.propTypes = wizardProps;

export default withViewModelService(WMICPARenewalInsuranceHistoryPage);
