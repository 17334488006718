/* eslint-disable no-secrets/no-secrets */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import {TranslatorContext} from '@jutro/locale'
import { DocumentService } from 'wmic-pe-capability-gateway-policycommon';

import { useDocumentTitle, useWizardModals, WMICWizardSubmissionPage  } from 'wmic-pe-portals-wizard-components-ui';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICUnderwritingIssues } from 'wmic-pe-components-platform-react';
import { WMICHOPrintableAgreement } from 'wmic-pe-capability-gateway-common-ho-react';
import { WMICDocuments } from 'wmic-pe-capability-gateway-common-react';
import WMICQuoteUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICQuoteUtil';
import { CONSTANTS, WMICLogger } from 'wmic-pe-portals-utils-js';

import PropTypes from 'prop-types';
import _ from 'lodash';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICHOQuotePage.metadata.json5';
import messages from './WMICHOQuotePage.messages';

function WMICHOQuotePage(props) {
    const { wizardData: submissionVM, updateWizardData, markStepSubmitted, stopErrorNavigation } = props;
    const { authHeader } = useAuthentication();
    const viewModelService = useContext(ViewModelServiceContext);
    const { isSkipping } = useContext(WizardContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { isComponentValid, onValidate, initialValidation, registerInitialComponentValidation } = useValidation('QuotePage');
    const translator = useContext(TranslatorContext);
    const [userDocuments, setUserDocuments] = useState([]);
    const { setWizardLoading } = useWizardModals();
    const [underwritingIssues, updateUnderwritingIssues] = useState();

    const isBMSi = _.get(submissionVM, "isFromBMS_WMIC.value");
    const defaultedPropertiesToReview = _.get(submissionVM, "defaultedProperties_WMIC.value", []).filter((p) => p.propertyDisplayName && p.status !== CONSTANTS.REVIEWED);

    useDocumentTitle(translator(messages.quoteTitle), submissionVM);
    
    const fetchDocuments = useCallback(async () => {
        try {
            const documents = await DocumentService.getDocumentsForJobNumber(submissionVM.quoteID.value, authHeader)

            if (userDocuments.length !== documents.length){
                setUserDocuments(documents);
            }
        } catch(err) {
            WMICLogger.error('Unable to fetch documents', err);
            return []
        }
    }, [authHeader, submissionVM, userDocuments]);

    const validateSubmission = useCallback(async () => {
        setWizardLoading(!isSkipping);
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.unset(newSubmissionVM.value, 'bindData');
        const result = await LoadSaveService.validateSubmissionForIssue(newSubmissionVM.value, authHeader);
        _.extend(submissionVM.value, result);
        updateWizardData(submissionVM);
        updateUnderwritingIssues(_.get(submissionVM, 'errorsAndWarnings.underwritingIssues.value', []));
        markStepSubmitted(); // the step needs to be marked as submitted for the errors associated with this step to show up
        setWizardLoading(false);
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LoadSaveService, authHeader, submissionVM, viewModelService, setWizardLoading, markStepSubmitted]);

    useEffect(() => {
        registerInitialComponentValidation(() => !WMICQuoteUtil.isBlockingUWIssuePresent(submissionVM));
        stopErrorNavigation()
    }, [registerInitialComponentValidation, submissionVM]);

    useEffect(() => {
        fetchDocuments()
    }, [fetchDocuments, userDocuments])

    useEffect(() => {
        // All default values should be reviewed to avoid
        // causing warning messages to disappear unintentionally.
        if (!isBMSi || (isBMSi && defaultedPropertiesToReview.length === 0)) {
            validateSubmission();
        }
    }, [defaultedPropertiesToReview.length, isBMSi, validateSubmission]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const initialValidationQuotePage = async () => {
        setWizardLoading(!isSkipping);
        await validateSubmission();
        const isInvalid = WMICQuoteUtil.isBlockingUWIssuePresent(submissionVM) || WMICQuoteUtil.hasValidationErrors(submissionVM);
        setWizardLoading(false);
        return isInvalid ? false : initialValidation;
    };

    const onNext = useCallback(async () => {
        try {
            setWizardLoading(true);

            if (!isComponentValid) {
                return false;
            }

            if (
                WMICQuoteUtil.isBlockingUWIssuePresent(submissionVM) ||
                WMICQuoteUtil.hasValidationErrors(submissionVM)
            ) {
                scrollToTop();
                return false;
            }

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [setWizardLoading, isComponentValid, submissionVM]);

    const overrideProps = {
        wmicFlexAlertMessage: {
            jobVM: submissionVM,
        },
        wmicQuoteAlertMessage: {
            jobVM: submissionVM
        },
        quotePagePremiumCosts: {
            premium: _.get(submissionVM, 'quoteData.offeredQuotes.value[0].premium', {}),
            jobVM: submissionVM,
            enableFlex: true,
            enableBrokerFees: true,
            updateJobVM: updateWizardData
        },
        quotePageUWIssues: {
            visible: !!underwritingIssues,
            underwritingIssues: underwritingIssues,
            quoteID: _.get(submissionVM, 'quoteID.value'),
            authHeader: authHeader,
            jobVM: submissionVM
        },
        quotePageSummary: {
            jobVM: submissionVM
        },
        quotePageUploadDocuments: {
            documents: userDocuments,
            updateDocuments: setUserDocuments,
            value: {
                documentDTOs: userDocuments,
                canUploadDocument: true,
                jobNumber: submissionVM.quoteID.value
            }
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICUnderwritingIssues,
            WMICDocuments,
            WMICHOPrintableAgreement
        }
    }

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidationQuotePage}
            cancelLabel={translator(commonMessages.saveAndExit)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICHOQuotePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default WMICHOQuotePage;
