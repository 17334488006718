import { defineMessages } from 'react-intl';

export default defineMessages({
    reductionOfCoverageWithLimit: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.reductionOfCoverageWithLimit',
        defaultMessage: '28- Reduction of Coverage when Vehicle Operated by Named Drivers - Limit of ${driverLimit}'
    },
    reductionOfCoverage_AB_YT: {
      id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.reductionOfCoverage_AB_YT',
      defaultMessage: '28(C) - Named Person(s) Reduction of Coverage Endorsement - Limit of $200,000'
    },
    article6: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.Article6',
        defaultMessage: 'Article6 - Excluded Driver'
    },
    excludedDriver: {
        id: 'wmic-pe-capability-gateway-common-pa-react.DriverEndorsementsCoverages.Excluded Driver',
        defaultMessage: 'Excluded Driver'
    }
})