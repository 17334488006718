import React from 'react';
import { render, screen } from '@testing-library/react';
import '@testing-library/jest-dom';
import vmTranslator from '@xengage/gw-platform-translations';
import productMetaData from 'producer-engage/src/generated/metadata/product-metadata.json';
import { SearchResults } from 'gw-capability-gateway-react';
import { createBrowserHistory } from 'history';
import { Router, Route } from 'react-router-dom';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { ViewModelServiceFactory } from '@xengage/gw-portals-viewmodel-js';
import { SearchService as searchServiceMock } from 'gw-capability-gateway';
import userEvent from '@testing-library/user-event';
import testData from './SearchResults.data.json';

let mockPush = jest.fn();
let viewModelService;

const getRenderComponentData = (searchText) => {

    const props = {match: {params: {searchText}}};
	const path = searchText ? '/search/:searchText' : '/search';
    const history = createBrowserHistory({ initialEntries: ['/'] });

    history.push(`/search/${searchText}`);
    history.push = mockPush;

    return {
        props,
        history,
        path
    }
};

const renderComponent = ({ props, history, path }) => {

    render(
        <Router history={history}>
            <Route path={path}>
                <ViewModelServiceContext.Provider value={viewModelService}>
                    <SearchResults {...props} />
                </ViewModelServiceContext.Provider>
            </Route>
        </Router>
    )
};

jest.mock('@xengage/gw-digital-auth-react', () => ({
    ...jest.requireActual('gw-digital-auth-react'),
    useAuthentication: jest.fn(() => ({
        authHeader: {},
        authUserData: { roles: [{ name: 'GPA_Broker_Write' }] }
    })),
    withAuthenticationContext: jest.fn((WrappedComponent) => <WrappedComponent />)
}));

beforeAll(() => {
    viewModelService = ViewModelServiceFactory.getViewModelService(
        productMetaData,
        vmTranslator
    );
});

afterAll(() => {
    jest.unmock('@xengage/gw-digital-auth-react');
    searchServiceMock.search.mockRestore();
    viewModelService = undefined;
    mockPush = undefined;
});

describe('SearchResults', () => {
    describe('DOM Tests', () => {
        describe('Search Results Title', () => {

            beforeEach(() => {
                searchServiceMock.search.mockReset();
            });

            it(`Search Results for "any search criteria" will be visible if search is done for "any search criteria"`, () => {

                searchServiceMock.search.mockResolvedValueOnce(testData.noSearchResults);
                renderComponent(getRenderComponentData("any search criteria"));

                expect(screen.getByRole('heading', { name: 'Search Results for "any search criteria"' })).toBeInTheDocument();
                expect(searchServiceMock.search).toHaveBeenCalledTimes(1);
            });

            it(`Search Results will be visible if search is done for ""`, () => {

                searchServiceMock.search.mockResolvedValueOnce(testData.noSearchResults);
                renderComponent(getRenderComponentData(""));

                expect(screen.getByRole('heading', { name: 'Search Results' })).toBeInTheDocument();
                expect(searchServiceMock.search).not.toHaveBeenCalled();
            });
        });

        describe('View additional addresses', () => {

            beforeEach(() => {
                searchServiceMock.search.mockReset();
            });

            it('View additional addresses is NOT visible when there aren\'t policy locations associated with the submissions and policies', async () => {

                searchServiceMock.search.mockResolvedValueOnce(testData.noPolicyLocationsForSubmissionsAndPolicies);
                await renderComponent(getRenderComponentData("John Le"));

                expect(searchServiceMock.search).toHaveBeenCalledTimes(1);
                expect(screen.queryByText('View additional addresses')).not.toBeInTheDocument();                
            });

            it('View additional addresses is when there are policy locations associated with the submissions and/or policies' +
                'AND a tooltip element is visible when hover over the View additional addresses element', async () => {

                    searchServiceMock.search.mockResolvedValueOnce(testData.policyLocationsForSubmissionsAndPolicies);
                    await act(() => renderComponent(getRenderComponentData("John Le")));


                    const viewAdditionalAddressesElement = await screen.getByText('View additional addresses');

                    expect(viewAdditionalAddressesElement).toBeInTheDocument();
                    await userEvent.hover(viewAdditionalAddressesElement);
                    expect(screen.getByRole('tooltip')).toBeInTheDocument();
                    expect(searchServiceMock.search).toHaveBeenCalledTimes(1);
                });
        });
    });
});