import React, {
    useContext,
    useCallback,
    useEffect,
    useState,
    useMemo
} from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { WMICLink } from 'wmic-pe-components-platform-react';
import { TranslatorContext, LanguageContext } from '@jutro/locale';
import { MetadataContent } from '@jutro/legacy/uiconfig';
import { Avatar } from '@jutro/components';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { BurgerMenu, BurgerMenuItem, BurgerMenuLink } from '@jutro/router';
import { ServiceManager } from '@jutro/legacy/services';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { JURISDICTION_DISPLAYNAMES } from 'wmic-pe-portals-utils-js';
import { WMICUserService } from 'wmic-pe-capability-gateway';
import { WMICGoogleTagManagerUtil } from 'wmic-pe-capability-gateway-common-react';
import appConfig from 'app-config';
import { Header } from '@jutro/legacy/router';
import { HeaderActions, LogoTitle, InputField } from '@jutro/legacy/components';

import WMICTrainingMaterialLink from '../WMICTrainingMaterialLink/WMICTrainingMaterialLink';
import WMICCoBrowseButton from '../WMICCoBrowseButton/WMICCoBrowseButton';
import WMICLanguageSwitcher from '../WMICLanguageSwitcher/WMICLanguageSwitcher';

import metadata from './WMICPageHeaderComponent.metadata.json5';
import constants from '../../constants';
import OpenHelpModalPopup from './openHelpModalPopup/openHelpModalPopup';

import messages from './WMICPageHeaderComponent.messages';
import styles from './WMICPageHeaderComponent.module.scss';

const WMICPageHeaderComponent = ({ navRoutes }) => {
    const { authHeader } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const history = useHistory();
    const localeService = ServiceManager.getService('locale-service');
    const [currentUser, setCurrentUser] = useState({});
    const [languageSwitcherVisible, setLanguageSwitcherVisible] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const {buildNo, isNotProduction} = appConfig
    const { showCustom } = useWizardModals()
    const languageContext = useContext(LanguageContext);

    const subHeaderRoutes = useMemo(
        () => navRoutes.filter(route => route.showOnNavBar),
        [navRoutes]
    );

    const setCurrentUserName = useCallback(() => {
        WMICUserService.getGatewayCurrentUser(authHeader).then(aUser => {
            setCurrentUser(aUser);
            WMICGoogleTagManagerUtil.addUserDataToGTagManager(
                aUser.userName,
	            aUser.firstName,
                aUser.lastName,
                aUser.displayName,
                aUser.organization
            );
            if (
                aUser.userPreferredLanguageCode_WMIC ===
                    localeService.localeToPCNotation(constants.LOCALE_KEY_FRENCH) &&
                aUser.userRegionalFormatCode_WMIC ===
                localeService.localeToPCNotation(constants.LOCALE_KEY_FRENCH)
            ) {
                localeService.saveLanguage(constants.LOCALE_KEY_FRENCH);
                languageContext.languageOnChangeCallback(constants.LOCALE_KEY_FRENCH);
            } else {
                localeService.saveLanguage(constants.LOCALE_KEY_ENGLISH);
                languageContext.languageOnChangeCallback(constants.LOCALE_KEY_ENGLISH);
            }
        });
    }, [
        setCurrentUser,
        authHeader,
        localeService,
    ]);

    const setLanguageSwitcherVisibility = useCallback(() => {
        WMICUserService.getAvailableProducerCodesForCurrentUser(authHeader).then( (producerCodes) => {
            let showSwitcher = false;

            if (producerCodes && producerCodes.length > 0) {
                showSwitcher = producerCodes.some((p) => [JURISDICTION_DISPLAYNAMES.QC_V1, JURISDICTION_DISPLAYNAMES.QC_V2].includes(p.brokerJurisdiction_WMIC));
            }

            setLanguageSwitcherVisible(showSwitcher);
        });
    }, [authHeader, setLanguageSwitcherVisible]);

    useEffect(() => {
        setCurrentUserName();
        setLanguageSwitcherVisibility();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showModal = useCallback(() => {
        const componentProps = {
            actionBtnLabel: translator(messages.agentViewClose),
        };

        return showCustom(
            <OpenHelpModalPopup {...componentProps} />
        );
    }, [translator, showCustom]);

    const search = () => {      
        const searchParam = searchValue && searchValue.trim() ? searchValue.trim() : "";
        const path = searchParam ? `/search/${searchParam}` : '/search';
        setSearchValue(undefined);        
        history.push(path);
    };

    const renderBurgerMenuContent = () => [
        <BurgerMenuItem id="searchMenuItem" key="searchMenuItem" isContainerOnly>
            <InputField
                id="searchField"
                label=""
                placeholder={translator(messages.wmicHeaderSearchPlaceholder)}
                className={styles.searchBar}
                onValueChange={setSearchValue}
                onEnter={search}
                value={searchValue}
            />
        </BurgerMenuItem>,
        subHeaderRoutes.map((subHeader) => (
            <BurgerMenuLink id={`subHeaderMenuItem:${subHeader.title.id}`} key={`subHeaderMenuItem:${subHeader.title.id}`} to={subHeader.path} exact={subHeader.exact}>
                {translator(subHeader.title)}
            </BurgerMenuLink>
        )),
        languageSwitcherVisible ? <WMICLanguageSwitcher id="languageSwitcherMenuItem" key="languageSwitcherMenuItem" inSideNav /> : undefined,
        <WMICTrainingMaterialLink isMobile/>,
        <WMICCoBrowseButton isMobile/>,
        <BurgerMenuItem id="userMenuItem" key="userMenuItem" isContainerOnly>
            <Avatar className="gw-mr-3" username={currentUser.displayName} />
            <span>{currentUser.displayName}</span>
        </BurgerMenuItem>,
    ];

    const overrideProps = {
        wmicHeaderLogo: {
            title: undefined,
            src: constants.LOGO_PATH
        },
        wmicHeaderLogoMobile: {
            title: undefined,
            src: constants.LOGO_WHITE_PATH
        },
        wmicHeaderActionUsername: {
            content: currentUser.displayName
        },
        wmicMobileBurgerMenu: {
            burgerContent: renderBurgerMenuContent()
        },
        wmicHeaderActionContactUs: {
            onClick: showModal
        },
        wmicSolrSearchBar: {
            onValueChange: setSearchValue,
            onEnter: search,
            value: searchValue
        },
        buildNo: {
            visible: isNotProduction,
            content: buildNo
        },
        wmicHeaderActionLanguageWrapper: {
            visible: languageSwitcherVisible
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            Header,
            LogoTitle,
            HeaderActions,
            WMICLink,
            BurgerMenu,
            BurgerMenuItem,
            WMICLanguageSwitcher,
            InputField
        },
    };

    return <MetadataContent uiProps={metadata.componentContent} overrideProps={overrideProps} {...resolvers} />;
};

export default withRouter(WMICPageHeaderComponent);
