import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import _ from 'lodash';
import { IntlContext, TranslatorContext } from '@jutro/locale';
import { PAConstants, WMICRichTextUtil, DateUtil } from 'wmic-pe-portals-utils-js';
import { CheckboxField } from '@jutro/legacy/components';
import messages from './WMICPolicyDriverEndorsementCheckbox.messages';

function WMICDriverEndorsementsCheckbox(props) {
    const {
        id,
        driverEndorsement,
        driver,
        selectedVehicleId,
        baseState,
        rateAsOfDate,
        paLobDataVM
    } = props;
    const translator = useContext(TranslatorContext);
    const intl = useContext(IntlContext);

    const getDriverNameWithAccidentWaiverSuffix = () => {
        const hyphen = " -";
        let purchaseDate;
        let inUse;

        if (driverEndorsement.publicID === PAConstants.accidentWaiverCovPublicId) {
            const accidentWaiverEndorsement = _.find(_.get(paLobDataVM, 'endorsements_Ext.accidentWaiverEndorsements_WMIC.value'), { driverPublicID: driver.publicID });
            purchaseDate = accidentWaiverEndorsement?.purchaseDate;
            inUse = accidentWaiverEndorsement?.inUse;
        }
        else if (driverEndorsement.publicID === PAConstants.accidentWaiverMtcCovPublicId) {
            const accidentWaiverEndorsementMtc = _.find(_.get(paLobDataVM, 'endorsements_Ext.accidentWaiverMtcEndorsements_WMIC.value'), {driverPublicID: driver.publicID});
            purchaseDate = accidentWaiverEndorsementMtc?.purchaseDate;
            inUse = accidentWaiverEndorsementMtc?.inUse;
        }

        let result = '';

        if (inUse === true) {
            result = `${hyphen} ${(translator(messages.inUse))}`;
        }
        else if (inUse === false) {
            result = `${hyphen} ${(translator(messages.notInUse))}`;
        }

        if (purchaseDate) {
            const purchaseDateString = intl.formatDate(DateUtil.localDateDTOToDate(purchaseDate), { year: 'numeric', month: '2-digit', day: '2-digit' });
            result += `${hyphen} ${(translator(messages.accidentWaiverPurchaseDate))} ${purchaseDateString}`;
        }

        return driver.displayName + result;
    };

    const checked = useMemo(() => {
        if (driverEndorsement.publicID === PAConstants.accidentWaiverCovPublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.accidentWaiverEndorsements_WMIC.value'), {driverPublicID: driver.publicID})) {
            return true;
        }

        if (driverEndorsement.publicID === PAConstants.accidentWaiverMtcCovPublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.accidentWaiverMtcEndorsements_WMIC.value'), {driverPublicID: driver.publicID})) {
            return true;
        }

        if (driverEndorsement.publicID === PAConstants.minorConvictionWaiver39bPublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.minorConvictionWaiverEndorsements_WMIC.value'), {driverPublicID: driver.publicID})) {
            return true;
        }

        if (driverEndorsement.publicID === PAConstants.reducAmtForNamedCov_28A_PublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.excludedDriverEndorsements_WMIC.value'), {driverPublicID: driver.publicID, vehiclePublicID: selectedVehicleId})) {
            return true;
        }

        if (driverEndorsement.publicID === PAConstants.reducAmtForNamedCov_28_PublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.reductionDriverEndorsements_WMIC.value'), {driverPublicID: driver.publicID, vehiclePublicID: selectedVehicleId})) {
            return true;
        }

        if (driverEndorsement.publicID === PAConstants.article6ExcludedDriver_WMIC_PublicId &&
            _.find(_.get(paLobDataVM, 'endorsements_Ext.accidentWaiverMtcEndorsements_WMIC.value'), {driverPublicID: driver.publicID, vehiclePublicID: selectedVehicleId})) {
            return true;
        }

        return false;
    }, [driver.publicID, driverEndorsement.publicID, paLobDataVM, selectedVehicleId]);

    const getEndorsementLabel = () => {
        const covId = driverEndorsement.publicID;

        if ([PAConstants.reducAmtForNamedCov_28_PublicId,
            PAConstants.minorConvictionWaiver39bPublicId].includes(covId)) {
            return driver.displayName;
        }

        let label;
        switch (covId) {
            case PAConstants.accidentWaiverCovPublicId:
            case PAConstants.accidentWaiverMtcCovPublicId:
                return getDriverNameWithAccidentWaiverSuffix();
            case PAConstants.reducAmtForNamedCov_28A_PublicId:
                label = messages.excluded;
                break;
            case PAConstants.article6ExcludedDriver_WMIC_PublicId:
                label = messages.article6Excluded;
                break;
            default:
                return null;
        }

        return WMICRichTextUtil.translateRichText(translator(label, {name: driver.displayName}));
    };

    return (
        <div className="gw-pb-4">
            <CheckboxField
                id={`${id}_checkbox`}
                className={cx(
                    'gw-pl-5',
                    'checkBoxReadOnly',
                    'wmicMasterInputCheckbox'
                )}
                labelClassName={cx('wmicMasterInputFieldLabel')}
                label={getEndorsementLabel()}
                showInlineLabel
                readOnly
                value={checked}
                path={`${driverEndorsement.publicID}`}
            />
        </div>
    );
}

WMICDriverEndorsementsCheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    driverEndorsement: PropTypes.shape({}).isRequired,
    driver: PropTypes.shape({}).isRequired,
    selectedVehicleId: PropTypes.string,
    baseState: PropTypes.string.isRequired,
    rateAsOfDate: PropTypes.shape({}).isRequired,
    paLobDataVM: PropTypes.shape({}).isRequired
};

export default WMICDriverEndorsementsCheckbox;
