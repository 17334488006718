import React, { useContext, useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { POLICY_DETAILS,
         WMICRichTextUtil,
         JobType,
         CONSTANTS,
         WMICDateTimeService
} from 'wmic-pe-portals-utils-js';
import { PAConsentMessages } from 'wmic-pe-capability-gateway-quoteandbind-pa-react';
import { useViewModelClone } from 'wmic-pe-components-platform-react';
import metadata from './WMICCreditConsentPNIComponent.metadata.json5';
import '../WMICCreditConsent.messages';

function WMICCreditConsentPNIComponent(props) {
    const { id, baseData, setBaseData, onValidateConsentMap, showErrors, readOnly } = props;
    const translator = useContext(TranslatorContext);
    const cloneViewModel = useViewModelClone();

    const consentDate = _.get(baseData, 'creditConsentDate.value');
    const consentRcvd = _.get(baseData, 'creditConsentReceived.value');
    const isCreditConsentReceived = _.get(consentRcvd, 'code', '') === POLICY_DETAILS.YES;
    const isCreditConsentNotReceived = _.get(consentRcvd, 'code', '') === POLICY_DETAILS.NO;
    const isPolicyChangeOrRenewal =
        _.get(baseData, 'jobType.value.code') === JobType.POLICY_CHANGE ||
        _.get(baseData, 'jobType.value.code') === JobType.RENEWAL;

    const writeValue = (value, path) => {
        if (baseData) {
            const newBaseDataVM = cloneViewModel(baseData);
            _.set(newBaseDataVM, path, value);
            setBaseData(newBaseDataVM);            
        }
    }

    useEffect(() => {
        const currentValidateCreditConsentReceived = _.get(baseData, "validateCreditConsentReceived.value");

        if(currentValidateCreditConsentReceived !== true) {
            writeValue(true, "validateCreditConsentReceived");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onValidate = useCallback((isValid) => {
        onValidateConsentMap(isValid, id);
    }, [id, onValidateConsentMap]);

    useEffect(() => {
        if (consentDate === undefined) {
            const currentDate = new Date();
            const todaysDate = { day: currentDate.getDate(), month: currentDate.getMonth(), year: currentDate.getFullYear() };
            _.set(baseData, 'creditConsentDate.value', todaysDate)
            setBaseData(baseData);
        }
    }, [consentDate, baseData, setBaseData]);

    const setCreditConsentInfoPNI = useCallback((data) => {
        if (!_.isNil(consentRcvd)) {
            if (_.isNil(consentDate)) {
                _.set(baseData, 'creditConsentDate.value', WMICDateTimeService.today());
                setBaseData(baseData);
            }

            return consentRcvd.code === data;
        }

        return false;
    }, [consentDate, consentRcvd, baseData, setBaseData]);

    // eslint-disable-next-line no-secrets/no-secrets
    const isPersonPNI = _.upperFirst(_.get(baseData, 'primaryNamedInsured_WMIC.subtype.value', '')) === CONSTANTS.Person;

    const isConsentReceivedPNI = useMemo(() => isPersonPNI && setCreditConsentInfoPNI(POLICY_DETAILS.YES),
        [isPersonPNI, setCreditConsentInfoPNI]);

    const displayName = useMemo(() => _.get(baseData, 'primaryNamedInsured_WMIC.displayName.value', ''), [baseData]);

    const overrideProps = {
        '@field': {
            parentNode: baseData,
            readOnly,
        },
        creditConsentHeading: {
            title: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.consentFor, {name: displayName}))
        },
        creditConsentReceived: {
            label: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.creditConsentReceivedFrom , {name: displayName})),
        },
        personalInfoConsentForm: {
            visible: isConsentReceivedPNI
        },
        creditConsentDate: {
            visible: isCreditConsentReceived
        },
        creditConsentDOB: {
            label: WMICRichTextUtil.translateRichText(translator(PAConsentMessages.dateOfBirthFor , {name: displayName})),
            visible: isCreditConsentReceived && !isPolicyChangeOrRenewal
        },
        withdrawalCreditConsentDate: {
            visible: isCreditConsentNotReceived
        },
        creditInfoWithdrawalConsent: {
            visible: isCreditConsentNotReceived
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={baseData}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            showErrors={showErrors}
        />
    );

}

export default WMICCreditConsentPNIComponent;
