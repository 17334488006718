import { AddressService_Ext } from "wmic-pe-capability-address";
import { CONSTANTS, WMICLogger } from 'wmic-pe-portals-utils-js';

const updateAddressIfNeeded = (addressVM, clonedAddressVM) => {
    if (!addressVM?.isValidationOverridden_Ext?.value) {
        clonedAddressVM.isValidationOverridden_Ext.value = false;
        clonedAddressVM.byPassAddressOverridde_Ext.value = true;
    }
};

const setAddressValidationFields = (addressVM, addressResponse) => {
    if (addressResponse?.addrValidateDate_Ext) {
        addressVM.addrValidateDate_Ext = addressResponse.addrValidateDate_Ext;
        addressVM.addrValidateStatus_Ext = addressResponse.addrValidateStatus_Ext;
    }
};

const setGeocodeFields = (addressVM, addressResponse) => {
    Object.assign(addressVM.geoCodeInfo_Ext, addressResponse?.geoCodeInfo_Ext);
};

const validateAddress = async (addressVM, clonedAddressVM, addressType, jobNumber, authHeader) => {
    try {
        updateAddressIfNeeded(addressVM, clonedAddressVM);
        if (!clonedAddressVM.isValidationOverridden_Ext.value) {
            let addressResponse;
            if (addressType === CONSTANTS.ADDRESS_TYPE.POLICY_LOCATION) {
                addressResponse = await AddressService_Ext.validatePolicyLocation(clonedAddressVM.value, jobNumber, authHeader);
                setGeocodeFields(addressVM, addressResponse);
            }
            else {
                addressResponse = await AddressService_Ext.validateAddress(clonedAddressVM.value, authHeader);
            }

            setAddressValidationFields(addressVM, addressResponse);
        }
    } catch (error) {
        WMICLogger.error(`Address validation failed: ${JSON.stringify(error)}`);
    }
};

const isAddressApplicableToBeValidated = (addressVM) => {
    return !addressVM?.isValidationOverridden_Ext?.value
        && addressVM?.aspects.subtreeValid
};

export default {
    isAddressApplicableToBeValidated: isAddressApplicableToBeValidated,
    setAddressValidationFields: setAddressValidationFields,
    updateAddressIfNeeded: updateAddressIfNeeded,
    validateAddress: validateAddress
};
