import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicUnderlyingPoliciesTitle: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.pages.WMICPUPUnderlyingPolicies.WMICPUPUnderlyingPoliciesPage.Title',
        defaultMessage: 'Underlying Policies'
    },
    wmicUnderlyingPoliciesAtLeastOneHOPolicy: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.AtLeastOneHOPolicy',
        defaultMessage: '&lt;span&gt;At least &lt;b&gt;one Personal Property Policy&lt;/b&gt; is required&lt;/span&gt;'
    },
    wmicUnderlyingPoliciesPolicy: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.Policy',
        defaultMessage: 'Policy'
    },
    wmicUnderlyingPoliciesInsurer: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.Insurer',
        defaultMessage: 'Insurer'
    },
    wmicUnderlyingPoliciesRisks: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.Risks',
        defaultMessage: 'Risks'
    },
    wmicUnderlyingPoliciesPassengerVehicleRisk: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.PassengerVehicleRisk',
        defaultMessage: '1 Passenger Vehicle'
    },
    wmicUnderlyingPoliciesExcessPolicy: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.ExcessPolicy',
        defaultMessage: 'Excess Policy'
    },
    wmicUnderlyingPoliciesPassengerVehicleRisks: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.PassengerVehicleRisks',
        defaultMessage: '{number} Passenger Vehicles'
    },
    wmicUnderlyingPoliciesPersonalPropertyRisk: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.PersonalPropertyRisk',
        defaultMessage: '1 Personal Property'
    },
    wmicUnderlyingPoliciesPersonalPropertyRisks: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.PersonalPropertyRisks',
        defaultMessage: '{number} Personal Properties'
    },
    wmicUnderlyingPoliciesWatercraftRisk: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.WatercraftRisk',
        defaultMessage: '1 Watercraft'
    },
    wmicUnderlyingPoliciesWatercraftRisks: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.WatercraftRisks',
        defaultMessage: '{number} Watercraft'
    },
    wmicUnderlyingPoliciesAddUnderlyingPolicy: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.AddUnderlyingPolicy',
        defaultMessage: 'Add Underlying Policy'
    },
    wmicUnderlyingPoliciesInternalPolicy: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.internalPolicy',
        defaultMessage: ' {policyNumber} - {carrierName}'
    },
    wmicUnderlyingPoliciesRemoveUnderlyingPolicyConfirmationMsg: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.RemoveUnderlyingPolicyConfirmationMsg',
        defaultMessage: 'Are you sure you want to remove policy {policyNumber} from the list of underlying policies?'
    },
    wmicUnderlyingPoliciesRemoveUnderlyingPolicy: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.RemoveUnderlyingPolicy',
        defaultMessage: 'Remove Underlying Policy?'
    },
    wmicUnderlyingPoliciesRemovingLiabilityCoverage: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.RemovingLiabilityCoverage',
        defaultMessage: 'Removing Underlying Policy'
    },
    wmicUnderlyingPoliciesSavingLiabilityCoverage: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.SavingLiabilityCoverage',
        defaultMessage: 'Saving Underlying Policies'
    },
    wmicUnderlyingPoliciesInternalPolicyName: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.InternalPolicyName',
        defaultMessage: 'Wawanesa Mutual Insurance Company'
    },
    wmicUnderlyingPoliciesPersonalProperty: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.PersonalProperty',
        defaultMessage: 'Personal Property'
    },
    wmicUnderlyingPoliciesAuto: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.Automobile',
        defaultMessage: 'Automobile'
    },
    wmicUnderlyingPoliciesWatercraft: {
        id: 'wmic-pe-capability-gateway-quoteandbind-pup-react.WMICPUPUnderlyingPoliciesPage.Watercraft',
        defaultMessage: 'Watercraft'
    }
});