import { defineMessages } from 'react-intl';

export default defineMessages({
    logoutConfirmationPageTitle: {
        id: 'auth.components.wmic-logout-confirmation-page.title',
        defaultMessage: 'Logout Successful',
    },
    logoutConfirmationPageDescription: {
        id: 'auth.components.wmic-logout-confirmation-page.logoutdescription',
        defaultMessage: 'You have been logged out.'
    },
    logoutConfirmationInactivityDes: {
        id: 'auth.components.wmic-logout-confirmation-page.inactivitydescription',
        defaultMessage: 'Due to inactivity, you have been logged out.'
    },
    logoutConfirmationPageButtonText: {
            id: 'auth.components.wmic-logout-confirmation-page.login-button',
            defaultMessage: 'Go to Login Page',
    },
});