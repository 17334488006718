import React, {
    useEffect,
    useState,
    useContext,
    useCallback,
    useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Loader } from '@jutro/components';
import { Wizard } from 'wmic-pe-portals-custom-wizard-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ErrorBoundary } from '@xengage/gw-portals-error-react';
import { messages as platformMessages } from '@xengage/gw-platform-translations';
import { ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICWizardHandler } from 'wmic-pe-capability-gateway-common-react';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { messages as commonPolicyChangeMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import { CONSTANTS, MODAL_CONSTANTS, WIZARD_STEP_PATHS, WMICValidationUtil, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import wizardConfig from './config/ho-wizard-config.json5';
import wizardStepToFieldMapping from './config/ho-wizard-step-to-field-mapping.json5';
import messages from './WMICPEHOPolicyChangeWizard.messages';

function checkValidity(jobVM, extractVMObject, currentStep) {
    // Change Summary page is read-only step and doesn't need validations
    if (currentStep.path === WIZARD_STEP_PATHS.COMMON.CHANGE_SUMMARY) {
        return true;
    }

    const checkValidityPathsForCurrentStep = _.get(currentStep, 'checkValidityPaths', []);

    // Cloning checkValidityPaths to avoid adding duplicates to array
    const checkValidityPaths = _.clone(checkValidityPathsForCurrentStep);

    // For Risk step, validate only dwelling.yourHome path for each dwelling child
    if (currentStep.path === WIZARD_STEP_PATHS.HO.YOUR_HOME) {
        const dwellings = _.get(jobVM, "lobData.homeowners.coverables.dwellings.children");

        for (let index = 0; index < dwellings.length; index++) {
            const validityPath = `lobData.homeowners.coverables.dwellings.children[${index}].yourHome`;

            checkValidityPaths.push(validityPath);
        }
    }

    // For steps that have Edit/Add buttons, only validate if user has started editing the page
    switch (currentStep.path) {
        case WIZARD_STEP_PATHS.COMMON.POLICY_DETAILS:
        case WIZARD_STEP_PATHS.HO.RISKS:
        case WIZARD_STEP_PATHS.HO.CONSTRUCTION:
        case WIZARD_STEP_PATHS.HO.SCHEDULED_ITEMS:
        case WIZARD_STEP_PATHS.COMMON.INSURANCE_HISTORY:
            if (!_.get(jobVM, 'isEditingPage.value', false)) {
                return true;
            }
            break;
        default:
            break;
    }

    return WMICValidationUtil.checkValidity(jobVM, checkValidityPaths);
}

function getTargetPageIndex(steps) {
    const WMICQuotePageRegex = /(QuotePage)$/;

    for (let stepIndex = 0; stepIndex < steps.length; stepIndex += 1) {
        if (WMICQuotePageRegex.test(steps[stepIndex].id)) {
            return stepIndex;
        }
    }

    return undefined;
}

function WMICPEHOPolicyChangeWizard() {
    const { steps } = wizardConfig;
    const [initialSubmission, setInitialSubmission] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [isRenderWizard, setIsRenderWizard] = useState(true);
    const [isCustomStepsInvalidated, setIsCustomStepsInvalidated] = useState(false);
    const viewModelService = useContext(ViewModelServiceContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const history = useHistory();
    const location = useLocation();
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const { showConfirm, showWarning, setDefaultMessage, showError } = useWizardModals();
    const translator = useContext(TranslatorContext);

    const canEditPolicyChange = useMemo(() => WMICUserAccessUtil.canEditPolicyChange(currentUser.permission_Ext), [currentUser.permission_Ext]);

    useEffect(() => {
        if (!location.state) {
            history.push('/');

            return;
        }

        setDefaultMessage(translator(messages.savingTransactionDetails))

        const { state: { policyNumber, selectedTerm, policyChangeEntry } } = location;

        if (!policyNumber) {
            return;
        }

        EndorsementService.getAvailablePolicy(policyNumber, authHeader)
            .then((response) => {
                EndorsementService.loadEndorsementWithEffectiveDate([response.policyNumber, policyChangeEntry.effectiveDate], authHeader)
                    .then((responseData) => {
                        const policyChange = viewModelService.create(
                            responseData,
                            'pc',
                            'wmic.edge.ca.capabilities.policychange.dto.PolicyChangeDataDTO'
                        );

                        steps.forEach((step) => _.set(step, 'stepToFieldMapping', wizardStepToFieldMapping[step.id]))

                        setInitialSubmission(policyChange);
                        setIsLoading(false);
                    }).catch(() => {
                        showError({
                            title: translator(commonPolicyChangeMessages.weAreUnableToProcess), 
                            message: translator(commonPolicyChangeMessages.pleaseContactUnderwriter),
                            status: MODAL_CONSTANTS.STATUS.WARNING,
                            icon: MODAL_CONSTANTS.ICON.WARNING}).then(() => {
                                history.push(`/contactAgent/${policyNumber}/${selectedTerm}`);
                                setIsLoading(false);
                            });
                    });
            });
    },
    // Disabled so we don't rerun this function on every rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const handleOnCancel = useCallback((cancelParams) => {
        const {
            wizardData,
            wizardSnapshot,
            param
        } = cancelParams;

        const nextPath = param || '';
        const status = _.get(wizardData.value, 'status');
        const jobID = _.get(wizardData.value, 'jobID');

        if ((status === 'Bound' && !_.isNil(jobID)) || (nextPath.startsWith('/change') || nextPath.startsWith('/contactAgent')) || hasErrorOccurred) {
            return true;
        }

        return showConfirm({
            title: commonPolicyChangeMessages.leaveWithoutSaving,
            message: commonPolicyChangeMessages.infoWillBeSavedAsDraft,
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.WARNING,
            cancelButtonText: platformMessages.cancelModel
        }).then((results) => {
            if (results === CONSTANTS.MODAL_RESULT.CANCEL || results === CONSTANTS.MODAL_RESULT.CLOSE) {
                return _.noop();
            }

            setIsLoading(true);

            const isValid = wizardData.aspects.valid && wizardData.aspects.subtreeValid;
            const payload = (isValid) ? _.get(wizardData, 'value') : _.get(wizardSnapshot, 'value');
            const jobID = _.get(wizardData.value, 'jobID');
            const quoteStatus = _.get(wizardSnapshot, 'baseData.periodStatus.value.code')
                || _.get(wizardData.value, 'status');
            const isStatusQuoted = quoteStatus !== CONSTANTS.QUOTED;
            let exitPromise;
            const redirectLobPath = (!_.isEmpty(nextPath) && nextPath) || `/change/${jobID}/summary`;

            if (isStatusQuoted && canEditPolicyChange) {
                exitPromise = EndorsementService.saveWithNoValidationRuleCheck([payload], authHeader);
            } else {
                return history.push(redirectLobPath);
            }

            exitPromise.then(() => {
                history.push(redirectLobPath);
            }).catch((error) => {
                if (typeof error === 'object') {
                    return WMICErrorHandler.processAsModal(error);
                }

                showWarning({
                    title: commonPolicyChangeMessages.unableToDraftPolicy,
                    message: commonPolicyChangeMessages.anErrorOccurred,
                    status: MODAL_CONSTANTS.STATUS.WARNING,
                    icon: MODAL_CONSTANTS.ICON.WARNING
                });
            }).finally(() => {
                setIsLoading(false);
            });

            return true;
        }, _.noop);
    }, [hasErrorOccurred, showConfirm, canEditPolicyChange, EndorsementService, authHeader, history, showWarning]);

    const handleError = useCallback((error) => {
        WMICWizardHandler.handlePolicyChangeErrorRender(error, setIsRenderWizard);
        WMICWizardHandler.handlePolicyChangeError(error, location, history, setHasErrorOccurred);
    }, [history, location]);

    if (isLoading) {
        return (
            <Loader loaded={!isLoading} />
        );
    }

    if (!initialSubmission) {
        return null;
    }

    return (
        <ErrorBoundary onError={handleError}>
            {isRenderWizard &&
                <Wizard
                    initialSteps={steps}
                    wizardTitle={translator(messages.progress)}
                    initialData={initialSubmission}
                    onCancel={handleOnCancel}
                    onPreviousModalProps={{
                        title: commonPolicyChangeMessages.wantToJump,
                        message: commonPolicyChangeMessages.wantToJumpMessage,
                        status: MODAL_CONSTANTS.STATUS.WARNING,
                        icon: MODAL_CONSTANTS.ICON.WARNING,
                        confirmButtonText: platformMessages.yes,
                        cancelButtonText: platformMessages.no
                    }}
                    checkValidity={checkValidity}
                    skipCompletedSteps
                    wizardStepToFieldMapping={wizardStepToFieldMapping}
                    maintainFurtherStepsVisitedSubmitted={{
                        flag: !isCustomStepsInvalidated,
                        targetStep: getTargetPageIndex(steps),
                    }}
                    setIsCustomStepsInvalidated={setIsCustomStepsInvalidated}
                />
            }
        </ErrorBoundary>
    );
}

WMICPEHOPolicyChangeWizard.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            address: PropTypes.shape({}),
            policyNumber: PropTypes.string,
            requestType: PropTypes.string,
            policyDetails: PropTypes.shape({
                effective: PropTypes.instanceOf(Date)
            })
        }),
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string
    }).isRequired,
};

export default withViewModelService(WMICPEHOPolicyChangeWizard);
