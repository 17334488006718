import React, { useCallback, useEffect, useState, useContext, useRef, useMemo } from 'react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardChangeOrRenewalPage, useAccordionValidation, useDocumentTitle, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import { Accordion } from '@jutro/legacy/components';
import { PAConstants, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import {
    WMICDriverEndorsementsComponent,
    WMICPAVehicleCoverages,
    WMICPAPolicyCoverages
} from 'wmic-pe-capability-gateway-common-pa-react';
import WMICPACoverageUtil from "wmic-pe-capability-gateway-common-pa-react/util/WMICPACoverageUtil";

import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import _ from 'lodash';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import messages from './WMICPAPolicyRenewalCoveragesPage.messages';
import metadata from './WMICPAPolicyRenewalCoveragesPage.metadata.json5';

const structureCustomQuote = (renewalVM, affectedQuote, clauses) =>
    // convert OfferingDTO to CustomQuotedDTO structure
     ({
        quote: affectedQuote,
        quoteID: renewalVM.jobID.value,
        sessionUUID: renewalVM.sessionUUID.value,
        periodStart: renewalVM.baseData.periodStartDate.value,
        periodEnd: renewalVM.baseData.periodEndDate.value,
        coverages: clauses.personalAuto
    })
;

const generateColumnData = (renewalVM) => {
    const lobOfferingPath = 'lobData.personalAuto.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(renewalVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(renewalVM, `${quoteOfferingPath}.value`) || [];

    const columnData = lobOfferings
        .map((lobOffering, lobIndex) => {
            const quoteDataIndex = quoteOfferings.findIndex(
                (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
            );
            const quoteData = quoteOfferings[quoteDataIndex];
            return {
                name: lobOffering.branchName,
                code: lobOffering.branchCode,
                quote: {
                    path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                    data: quoteData
                },
                lob: {
                    path: `${lobOfferingPath}.children[${lobIndex}]`,
                    data: lobOffering
                }
            };
        })
        .filter(({ quote }) => !_.isUndefined(quote.data));
    return _.sortBy(columnData, ['code']);
};

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        // eslint-disable-next-line max-len
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

function WMICPAPolicyRenewalCoveragesPage(props) {
    const {
        wizardData: renewalVM,
        updateWizardData,
        underwritingIssues
    } = props;

    const translator = useContext(TranslatorContext);
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const { RenewalService } = useDependencies('RenewalService');
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const { onValidate, disregardFieldValidation, initialValidation, isComponentValid } = useValidation("WMICPAPolicyRenewalCoveragesPage");
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);
    const viewModelService = useContext(ViewModelServiceContext);
    const [initialRenewalVM, setInitialRenewalVM] = useState(undefined);
    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState();
    const { setWizardLoading } = useWizardModals();
    const initialized = useRef(false);

    const canEditPolicyRenewal = useMemo(() => WMICUserAccessUtil.canEditRenewal(currentUser.permission_Ext), [currentUser.permission_Ext]);

    useDocumentTitle(translator(messages.coveragesTitle), renewalVM);

    useEffect(() => {
        setInitialRenewalVM(viewModelService.clone(renewalVM));
        initialized.current = true;
        // Store the initial renewalVM when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClauseChange = useCallback(
        (_basePath, lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(renewalVM, lobPath, quotePath, lobName, true);
            
            return RenewalService.updateCoveragesAndRenewalData(renewalVM.value.sessionUUID, { personalAuto: customQuote.coverages }, renewalVM.value, authHeader)
                .then((response) => {
                    const newRenewalVM = viewModelService.clone(renewalVM);
                    newRenewalVM.value = response;
                    const removedFieldsFromBaseCoverages = ClausesUtil.getRemovedClausesID(
                        renewalVM,
                        newRenewalVM,
                        `${lobPath}.coverages.lineCoverages`
                    );
                    const removedFieldsFromAdditionalCoverages = ClausesUtil.getRemovedClausesID(
                        renewalVM,
                        newRenewalVM,
                        `${lobPath}.coverages.vehicleCoverages`
                    );
                    const removedFieldsFromDriverEndorsementCoverages = ClausesUtil.getRemovedClausesID(
                        renewalVM,
                        newRenewalVM,
                        `${lobPath}.coverages.driverEndorsements_WMIC`
                    );
                    const allRemovedFields = [
                        ...removedFieldsFromBaseCoverages,
                        ...removedFieldsFromAdditionalCoverages,
                        ...removedFieldsFromDriverEndorsementCoverages
                    ];
                    disregardFieldValidation(allRemovedFields);
                    updateWizardData(newRenewalVM);
                }
                );
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [renewalVM, RenewalService, authHeader, viewModelService, disregardFieldValidation]
    );

    const syncCoverages = useCallback(
        (value, changedPath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            return onClauseChange(basePath, PAConstants.PACoveragesPaths.OFFERINGS_PATH, PAConstants.PACoveragesPaths.OFFERINGS_PATH);
        },
        [onClauseChange]
    );

    const updateRenewal = useCallback(
        (value, changedPath) => {
            updateWizardData(ClausesUtil.setClauseValue(renewalVM, value, changedPath));
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [renewalVM]
    );

    const updateRenewalAndSync = useCallback(
        (value, changedPath) => {
            updateRenewal(value, changedPath);
            return syncCoverages(value, changedPath, PAConstants.PACoveragesPaths.OFFERINGS_PATH, PAConstants.PACoveragesPaths.OFFERINGS_PATH);
        },
        [updateRenewal, syncCoverages]
    );

    const columnData = generateColumnData(renewalVM);

    const resetQuote = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(initialRenewalVM, lobPath, quotePath, lobName);

            return RenewalService.updateCoverages(renewalVM.value.jobID, renewalVM.value.sessionUUID, { personalAuto: customQuote.coverages }, authHeader)
                .then((response) => {
                    const updatedClauses = _.get(response, `lobData.${lobName}.offerings[0].coverages`);
                    // Update local offering with new one from xcenter
                    _.set(renewalVM, `${lobPath}.coverages`, updatedClauses);
                    // Update local quote status with new one from xcenter
                    const status = _.get(response, 'quote.status', 'Draft');
                    _.set(renewalVM, `${quotePath}.status`, status);
                    // Update local errorsAndWarnings with new one from xcenter
                    _.set(renewalVM, 'errorsAndWarnings', response.errorsAndWarnings);
                    // Update premium with new one from xcenter
                    _.set(renewalVM, `${quotePath}.premium`, _.get(response, 'quoteData.offeredQuotes[0].premium'));
                    updateWizardData(renewalVM);
                }
                );
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [initialRenewalVM, renewalVM, RenewalService, authHeader]
    );

    const recalculate = useCallback(
        (lobPath, quotePath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(renewalVM, lobPath, quotePath, lobName);

            return RenewalService.updateCoverages(renewalVM.value.jobID, renewalVM.value.sessionUUID, { personalAuto: customQuote.coverages }, authHeader)
                .then((response) => {
                    setStaleQuoteBranchCode(undefined);
                    const updatedClauses = _.get(response, `lobData.${lobName}.offerings[0].coverages`);
                    // Update local offering with new one from xcenter
                    _.set(renewalVM, `${lobPath}.coverages`, updatedClauses);
                    const status = _.get(response, 'quote.status', 'Draft');
                    _.set(renewalVM, `${quotePath}.status`, status);
                    // Update local errorsAndWarnings with new one from xcenter
                    _.set(renewalVM, 'errorsAndWarnings', response.errorsAndWarnings);
                    // Update premium with new one from xcenter
                    _.set(renewalVM, `${quotePath}.premium`, _.get(response, 'quoteData.offeredQuotes[0].premium'));
                    updateWizardData(renewalVM);
                    return response;
                }
                );
        },
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [renewalVM, RenewalService, authHeader]
    );

    // props for the accordions themselves
    const commonAccordionProps = {
    }

    // props for the accordion content components
    const commonAccordionContentProps = {
        baseData: _.get(renewalVM, 'baseData'),
        authHeader,
        renewalVM,
        updateWizardData,
        onClauseChange,
        syncCoverages,
        changeSubmissionAndSync: updateRenewalAndSync,
        onValidate: onValidateAccordion,
        readOnly: !canEditPolicyRenewal,
    };

    const getThirdPartyLiabilityWarning = () => [{
            id: "warningThirdPartyLiabilityContainer",
            type: "element",
            component: "div",
            content: [
                {
                    id: "exclamationcircleIcon",
                    type: "element",
                    component: "Icon",
                    componentProps: {
                        icon: "mi-error",
                        className: "exclamation-icon gw-mr-1 iconAlignment"
                    },
                },
                {
                    id: "warningThirdPartyLiabilityMessage",
                    type: "element",
                    component: "span",
                    content: {
                        id: "wmic-pe-capability-gateway-policyrenewal-pa-react.WMICPAPolicyRenewalCoveragesPage.Third Party Liability Applies to All vehicles",
                        defaultMessage: translator(messages.thirdPartyLiabilityWarning)
                    }
                }
            ]
        }]

    const getCustomTerms = (clause) => {
        if (PAConstants.thirdPartyLiabililityLimitNames.includes(clause.publicID)) {
            return getThirdPartyLiabilityWarning();
        }
        return null;
    }

    const onStaleQuoteBranchCode = useCallback(() => staleQuoteBranchCode, [staleQuoteBranchCode]);

    const commonOverrides = {
        'coverageColumnData': columnData,
        'quoteID': renewalVM.jobID.value,
        'changeSubmissionAndSync': updateRenewalAndSync,
        'changeSubmission': updateRenewal,
        'onClauseChange': onClauseChange,
        'syncCoverages': syncCoverages,
        'recalculate': recalculate,
        'resetQuote': resetQuote,
        'onStaleQuoteBranchCode': onStaleQuoteBranchCode,
        'underwritingIssues': underwritingIssues,
        'onValidate': onValidate,
        'getCustomTerms': getCustomTerms,
        'readOnly': !canEditPolicyRenewal,
    }

    const getOrderedVehiclesList = () => {
        const vehicles = _.get(renewalVM, `${PAConstants.PACoveragesPaths.VEHICLES_PATH}.children`, [])
        return _.sortBy(vehicles, ['vehicleNumber_WMIC.value'])
    }

    const _iterableProps = () => {
        const driverEndorsements = _.get(renewalVM, `${PAConstants.PACoveragesPaths.OFFERINGS_PATH}.coverages.driverEndorsements_WMIC.value`);
        const driverEndorsementsForVehicle = driverEndorsements.filter((endorsement) => WMICPACoverageUtil.getIsVehicleDriverEndorsement(endorsement.publicID));
        const vehicleOverrides = getOrderedVehiclesList().map((vehicle, index) => {
            const vehicleCoverages = WMICPACoverageUtil.getVehicleCoveragesForRisk(_.get(renewalVM, 'lobData.personalAuto'), vehicle.publicID.value);
            const vehicleOverride = {
                [`vehicleCoveragesIterableComponent${index}`]: {
                    'vehicleVM': vehicle,
                    'vehicleIndex': index,
                    'baseData': renewalVM.baseData,
                    vehicleCoverages,
                    driverEndorsementsForVehicle,
                    jobVM: renewalVM,
                    ...commonOverrides
                }
            }

            return Object.assign({}, vehicleOverride);
        })

        return Object.assign({}, ...vehicleOverrides);
    }

    const overrideProps = {
        vehicleCoveragesIterableAccordionContainer: {
            data: getOrderedVehiclesList()
        },
        policyCoveragesContainer: {
            jobVM: renewalVM,
            lineCoverages: _.get(renewalVM, 'lobData.personalAuto.offerings.children[0].coverages.lineCoverages'),
            updateWizardData,
            ...commonOverrides
        },
        driverEndorsementsAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('driverEndorsementsComponent')
        },
        driverEndorsementsComponent: {
            accountHolder: _.get(renewalVM, 'baseData.accountHolder.value'),
            jobVM: renewalVM,
            ...commonAccordionContentProps,
        },
        ..._iterableProps()
    };

    const onNext = useCallback(async () => {
        try {
            if (!isComponentValid) {
                setShowErrors(true);
                setScrollToError(Date.now());
                return false;
            }

            setWizardLoading(true, translator(commonMessages.savingTransactionDetails));
            const newRenewalVM = _.cloneDeep(renewalVM.value);
            renewalVM.value = await RenewalService.saveRenewal(
                [newRenewalVM],
                authHeader
            );

            return renewalVM;
        } finally {
            setWizardLoading(false);
        }
    }, [RenewalService, authHeader, isComponentValid, renewalVM, setWizardLoading, translator]);

    const resolvers = {
        resolveComponentMap: {
            WMICDriverEndorsementsComponent,
            WMICPAVehicleCoverages,
            WMICPAPolicyCoverages,
            Accordion
        }
    };

    return (
        <WMICWizardChangeOrRenewalPage
            skipWhen={initialValidation}
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={renewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                onValidationChange={onValidate}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPAPolicyRenewalCoveragesPage.propTypes = wizardProps;

export default withViewModelService(WMICPAPolicyRenewalCoveragesPage);
