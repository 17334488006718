/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslator } from '@jutro/locale';
import { ToggleField } from '@jutro/components';
import { useMediaQuery } from 'react-responsive';
import messages from './WMICChangeRoadsideAssistanceToggle.messages';
import styles from './WMICChangeRoadsideAssistanceToggle.module.scss';

const WMICChangeRoadsideAssistanceToggle = (props) => {
    const { data, onInputChange } = props;
    const translator = useTranslator();
    const [toggleState, setToggleState] = useState(data.initialRoadside);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const vehicle = `${data.vehicle.year} ${data.vehicle.make} ${data.vehicle.model} (${data.vehicle.vehicleNumber})`;

    const handleToggle = (value) => {
        setToggleState(value);
        data.hasRoadsideAssistance = value;
        onInputChange();
    }

    const desktopVersion = () => {
        return (
            <div className={`${styles.changeRoadsideAssistanceVehicleToggleRow} ${styles.changeRoadsideAssistanceVehicleToggleRowDesktop}`}>
                <fieldset className={styles.changeRoadsideAssistanceFieldsetDesktop}>
                    <div className={styles.changeRoadsideAssistanceLegendDesktop}>
                        <legend>
                            <p>{vehicle}</p>
                            <p>
                                <i aria-hidden>{translator(data.initialRoadside ? messages.vehicleHasThisCoverage : messages.doesNotCurrentlyHaveThisCoverage)}</i>
                                <i aria-hidden="false" className='sr-only'>{translator(data.initialRoadside ? messages.vehicleHasThisCoverageAriaLabel : messages.doesNotCurrentlyHaveThisCoverageAriaLabel)}</i>
                            </p>
                        </legend>
                    </div>
                    <ToggleField 
                        id="changeRoadsideAssistanceVehicleToggle"
                        className={`${styles.changeRoadsideAssistanceToggle}`}
                        availableValues={[
                            {
                                displayName: translator(messages.yes),
                                id: 'true'
                            },
                            {
                                displayName: translator(messages.no),
                                id: 'false'
                            }
                        ]}
                        onValueChange={handleToggle}
                        value={toggleState}
                        buttonAriaLabels={[
                            translator(messages.toggleYesApplyThisCoverageAria, {vehicle}),
                            translator(messages.toggleNoDoNotApplyThisCoverageAria, {vehicle})]}
                    />
                </fieldset>
            </div>
        );
    };

    const mobileVersion = () => {
        return (
            <div className={`${styles.changeRoadsideAssistanceVehicleToggleRow}`}>
                <fieldset className={styles.changeRoadsideAssistanceFieldsetMobile}>               
                    <div className={`${styles.changeRoadsideAssistanceVehicleToggleTextRowMobile}`}>
                        <legend>
                            <p>{vehicle}</p>
                            <p><i aria-hidden>{translator(data.initialRoadside ? messages.vehicleHasThisCoverage : messages.doesNotCurrentlyHaveThisCoverage)}</i>
                            <i aria-hidden="false" className='sr-only'>{translator(data.initialRoadside ? messages.vehicleHasThisCoverageAriaLabel : messages.doesNotCurrentlyHaveThisCoverageAriaLabel)}</i>
                            </p>
                        </legend>
                    </div>
                    <ToggleField 
                        id="changeRoadsideAssistanceVehicleToggle"
                        className={`${styles.changeRoadsideAssistanceToggleMobile}`}
                        availableValues={[
                            {
                                displayName: translator(messages.yes),
                                id: 'true'
                            },
                            {
                                displayName: translator(messages.no),
                                id: 'false'
                            }
                        ]}
                        onValueChange={handleToggle}
                        value={toggleState}
                        buttonAriaLabels={[
                            translator(messages.toggleYesApplyThisCoverageAria, {vehicle}),
                            translator(messages.toggleNoDoNotApplyThisCoverageAria, {vehicle})]}
                    />
                </fieldset>
            </div>
        );
    };

    return isMobile ? mobileVersion() : desktopVersion();
};

WMICChangeRoadsideAssistanceToggle.propTypes = {
    data: PropTypes.shape({
        vehicle: PropTypes.shape({
            make: PropTypes.string,
            model: PropTypes.string,
            year: PropTypes.string,
            vin: PropTypes.string,
            vehicleNumber: PropTypes.string
        }),
        roadAssistanceAvailable: PropTypes.bool,
        hasRoadsideAssistance: PropTypes.bool,
        initialRoadside: PropTypes.bool
    }),
    onInputChange: PropTypes.func.isRequired
};

export default WMICChangeRoadsideAssistanceToggle;
