import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICCreditConsentUtil, JURISDICTIONS } from 'wmic-pe-portals-utils-js';

import metadata from './WMICPolicyPaDriverPersonalInfoComponent.metadata.json5';

function WMICPolicyPaDriverPersonalInfoComponent(props) {
    const { driverVM, baseState, policyVM } = props;
    const vehicles = policyVM?.lobs?.personalAuto?.vehicleDTOs?.value;
    const hasPersonalVehicleOrMotorHome = WMICCreditConsentUtil.hasPersonalVehicleOrMotorHome(vehicles);

    const resolvers = {
        resolveCallbackMap: {
        }
    };

    const overrideProps = {
        '@field': {
            parentNode: driverVM,
            readOnly: true
        },
        creditConsentContainer: {
            visible: baseState === JURISDICTIONS.QUEBEC && hasPersonalVehicleOrMotorHome,
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

WMICPolicyPaDriverPersonalInfoComponent.propTypes = {
    driverVM: PropTypes.shape({
        person: PropTypes.shape({
            publicID: PropTypes.shape({})
        })
    }).isRequired
};


export default WMICPolicyPaDriverPersonalInfoComponent;
