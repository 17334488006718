import { defineMessages } from 'react-intl';

export default defineMessages({
    driversTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-pa-react.pages.WMICPAPolicyRenewalDrivers.WMICPAPolicyRenewalDriversDetailsPage.Title',
        defaultMessage: 'Drivers'
    },
    driverName: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-drivers-details.driverName',
        defaultMessage: 'Name'
    },
    licenseNumber: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-drivers-details.licenseNumber',
        defaultMessage: 'License number'
    },
    licenseProvince: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-drivers-details.licenseProvince',
        defaultMessage: 'License province'
    },
    licenseProvinceOther: {
        id: "wmic-pe-capability-gateway-renewal-pa-react.pa-driver-license.Other",
        defaultMessage : 'Other'
    },
    addNewDriver: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-drivers-details.addNewDriver',
        defaultMessage: 'Add New Driver'
    },
    removeDriverTitle: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-drivers-details.Remove driver?',
        defaultMessage: 'Remove driver?'
    },
    removeDriver: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-drivers-details.removeDriver',
        defaultMessage: 'Are you sure you want to remove {driver} from the list of drivers?'
    },
    savingDriver: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-driver-license.Saving driver...',
        defaultMessage: 'Saving driver...'
    },
    removingDriver: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-driver-license.Removing driver...',
        defaultMessage: 'Removing driver...'
    },
    atLeastOne: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-drivers-details.AtLeastOne',
        defaultMessage: '&lt;span&gt;Add a minimum of &lt;b&gt;one Driver&lt;/b&gt;&lt;/span&gt;',
    },
    licenceMatchMsg: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-drivers-details.LicenceMatchMsg',
        defaultMessage: 'Sorry, but this drivers license number matches that of {driver}. Please ensure that all licence numbers are unique.',
    },
    licenseNotUnique: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-drivers-details.Licence number is not unique',
        defaultMessage: 'Licence number is not unique'
    },
    removingDriverErrorModalTitle: {
        id: 'wmic-pe-capability-gateway-renewal-pa-react.pa-drivers-details.removing-driver.errorModalTitle',
        defaultMessage: 'Error'
    }
});
