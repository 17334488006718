import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteDetailsTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICQuoteDetails.WMICQuoteDetailsPage.Title',
        defaultMessage: 'Continue Submission'
    },
    accountTitle: {
        id: 'wmic.pe-portal.components.continue-submission.submission-header-title.accountTitle',
        defaultMessage: 'Account:'
    },
    personalAuto: {
        id: 'wmic.pe-portal.components.continue-submission.submission-header-title.personalAuto',
        defaultMessage: 'Automobile Submission ({quoteNumber})'
    },
    personalUmbrella: {
        id: 'wmic.pe-portal.components.continue-submission.submission-header-title.personalUmbrella',
        defaultMessage: 'Personal Umbrella Submission ({quoteNumber})'
    },
    homeowners: {
        id: 'wmic.pe-portal.components.continue-submission.submission-header-title.homeowners',
        defaultMessage: 'Personal Property Submission ({quoteNumber})'
    },
    commercialPackage: {
        id: 'wmic.pe-portal.components.continue-submission.submission-header-title.CommercialPackage',
        defaultMessage: 'Commercial Lines Small Business Submission ({quoteNumber})'
    },
    editAccount: {
        id: 'wmic.pe-portal.components.continue-submission.submission-header-title.editAccount',
        defaultMessage: 'Edit account'
    },
    continueSubmissionHeader: {
        id: 'wmic.pe-portal.components.continue-submission.submission-header-title.continueSubmissionHeader',
        defaultMessage: 'Continue Submission'
    },
    declinedSubmission: {
        id: 'wmic.pe-portal.components.continue-submission.submission-header-title.Declined',
        defaultMessage: 'Declined'
    },
    quotedSubmission: {
        id: 'wmic.pe-portal.components.continue-submission.submission-header-title.quotedSubmission',
        defaultMessage: 'Quote'
    },
    declinedSubmissionBody : {
        id: 'wmic.pe-portal.components.continue-submission.submission-body.This submission does not meet underwriting criteria',
        defaultMessage: 'This Submission has been reviewed and does not meet underwriting criteria'
    },
    quotePremiumCostsCardTitle: {
        id: 'wmic.pe-portal.components.continue-submission.premium-costs-section.quotePremiumCostsCardTitle',
        defaultMessage: 'Premium Costs'
    },
    quoteUnderwritingIssuesCardCardTitle: {
        id: 'wmic.pe-portal.components.continue-submission.premium-costs-section.quoteUnderwritingIssuesCardCardTitle',
        defaultMessage: 'Underwriting Issues'
    },
    quoteLockedHeading: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteLockedHeading',
        defaultMessage: 'Locked: Submission modified by underwriter. '
    },
    quoteLockedBody: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteLockedBody',
        defaultMessage: 'Please contact underwriting to discuss any updates.'
    },
    quoteReadOnlyHeading: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteReadOnlyHeading',
        defaultMessage: 'Transaction cannot be edited as your access is currently set to read-only.'
    },
    quoteReadOnlyBody: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteReadOnlyBody',
        defaultMessage: 'Please contact Broker Help or an underwriter for assistance.'
    },
    quoteDraftHeading: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteDraftHeading',
        defaultMessage: 'Draft'
    },
    quoteDraftBody: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteDraftBody',
        defaultMessage: 'The submission was successfully started.'
    },
    quoteDraftBMSIHeading: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteDraftBMSIHeading',
        defaultMessage: 'Draft BMS Submission'
    },
    quoteDraftBMSIBody: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteDraftBMSIBody',
        defaultMessage: 'To create this submission from your BMS, some questions were given default answers. To complete this submission, you must first review and/or correct any defaulted questions.'
    },
    quoteQuotedUWHeading: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteQuotedUWHeading',
        defaultMessage: 'Quoted: Underwriting Issues Have Been Raised'
    },
    quoteQuotedUWFVHeading: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.Quoted: Validation and Underwriting Issues Have Been Raised',
        defaultMessage: 'Quoted: Validation and Underwriting Issues Have Been Raised'
    },
    quoteQuotedFVHeading: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.Quoted: Validation Issues Have Been Raised',
        defaultMessage: 'Quoted: Validation Issues Have Been Raised'
    },
    uwiBrokerNotifierText: {
        id: "wmic.pe-portal.components.continue-submission.quote-status.Notify Broker about UWI approval via email text",
        defaultMessage: 'An email notification will be sent to &lt;span class="wmicUWIBrokerNotifierEmail"&gt;{broker_email}&lt;/span&gt; when the transaction is approved. If the transaction is not approved, an underwriter will reach out to you.'
    },
    quoteQuotedUWBody: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteQuotedUWBody',
        defaultMessage: 'You cannot complete this transaction until these issues have been resolved.'
    },
    quoteWithdrawnHeading: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteWithdrawnHeading',
        defaultMessage: 'This quote has been withdrawn.'
    },
    quoteQuotedHeading: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteApprovedHeading',
        defaultMessage: 'Quoted: Payment Pending'
    },
    quoteQuotedBody: {
        id: 'wmic.pe-portal.components.continue-submission.quote-status.quoteApprovedBody',
        defaultMessage: 'There are no outstanding issues.'
    },
    continueQuoteButton: {
        id: 'wmic.pe-portal.components.continue-submission.button.continueQuoteButton',
        defaultMessage: 'Continue'
    },
    editQuoteButton: {
        id: 'wmic.pe-portal.components.continue-submission.button.editQuoteButton',
        defaultMessage: 'Edit'
    },
    withdrawQuoteButton: {
        id: 'wmic.pe-portal.components.continue-submission.button.withdrawQuoteButton',
        defaultMessage: 'Withdraw'
    },
    withdrawQuoteHeading: {
        id: 'wmic.pe-portal.components.continue-submission.button.withdrawQuoteHeading',
        defaultMessage: 'Withdraw Quote?'
    },
    withdrawQuoteBody: {
        id: 'wmic.pe-portal.components.continue-submission.button.withdrawQuoteBody',
        defaultMessage: 'The submission will be withdrawn. Would you like to continue?'
    },
    uwMustReviewTitle: {
        id: 'wmic.pe-portal.components.continue-submission.uw-issues.uwMustReviewTitle',
        defaultMessage: 'An underwriter must review the following ({count}):'
    },
    noOutstandingUWIssuesTitle: {
        id: 'wmic.pe-portal.components.continue-submission.uw-issues.noOutstandingUWIssuesTitle',
        defaultMessage: 'There are no outstanding underwriting issues'
    },
    autoApprovedUWIssuesTitle: {
        id:'wmic.pe-portal.components.continue-submission.uw-issues.autoApprovedTitle',
        defaultMessage: 'The following will be auto-approved and may be subject to a compliance review'
    },
    underwriterApprovalPendingPL: {
        id: 'wmic.pe-portal.components.continue-submission.uw-issues.Quoted: Underwriter Approval Pending',
        defaultMessage: 'Quoted: Underwriter Approval Pending'
    },
    underwriterApprovalPendingCL: {
        id: 'wmic.pe-portal.components.continue-submission.uw-issues.Locked: Transaction under review by underwriter',
        defaultMessage: 'Locked: Transaction under review by underwriter'
    },
    boundSubmission: {
        id: 'wmic.pe-portal.components.continue-submission.submission-header-title.This quote has been bound',
        defaultMessage: 'This quote has been bound'
    },
    downloadFullQuote: {
        id: 'wmic.pe-portal.components.continue-submission.button.downloadFullQuote',
        defaultMessage: 'Download Full Quote'
    }
});
