import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { TranslatorContext } from '@jutro/locale';
import  { Accordion } from '@jutro/legacy/components';
import { withViewModelService, ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals, useAccordionValidation, useDocumentTitle } from 'wmic-pe-portals-wizard-components-ui';
import { UISupportingInfoLookupService } from 'wmic-pe-capability-supportinginfo';
import { WMICBrokerInfo } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import { WMICInsuranceHistoryPoliciesComponent } from 'wmic-pe-capability-gateway-common-react';
import { WMICHOLossHistory, WMICHOCreditConsent } from 'wmic-pe-capability-gateway-common-ho-react';
import { WMICValidationUtil, WMICCreditConsentUtil, WMICLogger, WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
import { WMICScrollToError } from 'wmic-pe-components-platform-react';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import metadata from './WMICHOPolicyChangeInsuranceHistoryPage.metadata.json5';
import messages from './WMICHOPolicyChangeInsuranceHistoryPage.messages';

const INSURANCE_HISTORY_PATH = 'lobData.homeowners.coverables.insuranceHistory_WMIC';
const BROKER_INFO_PATH = 'lobData.homeowners.coverables.insuranceHistory_WMIC.brokerInfo';

function WMICHOPolicyChangeInsuranceHistoryPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;

    const { EndorsementService } = useDependencies('EndorsementService');
    const translator = useContext(TranslatorContext);
    const { isComponentValid, onValidate, initialValidation } = useValidation(
        'WMICHOPolicyChangeInsuranceHistoryPage'
    );
    const { onValidateAccordion, isAccordionValid } = useAccordionValidation(onValidate);
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader, authUserData } = useAuthentication();
    const { setWizardLoading } = useWizardModals();
    const [showErrors, setShowErrors] = useState(false);
    const [scrollToError, setScrollToError] = useState();
    const [carriersList, setCarrierList] = useState(false);
    const [insuranceHistoryVM, setInsuranceHistoryVM] = useState(_.get(policyChangeVM, "lobData.homeowners.coverables.insuranceHistory_WMIC"));
    const [insuranceHistoryPageDetails, setInsuranceHistoryPageDetails] = useState();
    const [loading, setLoading] = useState(true);
    const baseData = _.get(policyChangeVM, 'baseData');
    const periodStartDate = _.get(baseData, 'periodStartDate.value');
    const lob = _.get(policyChangeVM, 'lob.value.code');

    useDocumentTitle(translator(messages.insuranceHistoryTitle), policyChangeVM);

    const isHOCreditConsentTabVisible = useMemo(() => {
        WMICCreditConsentUtil.isHOCreditConsentTabVisibleOnInsuranceHistory(new Date(baseData.rateAsOfDate.value), _.get(baseData, 'additionalNamedInsureds_WMIC.value'));
    }, [baseData]);

    const canEditPolicyChange = useMemo(() => WMICUserAccessUtil.canEditPolicyChange(authUserData.permission_Ext), [authUserData.permission_Ext]);

    useEffect(() => {
        if (isComponentValid) {
            setShowErrors(false);
        }
    }, [isComponentValid]);

    const createBrokerInfo = useCallback(() => {
        const brokerInfo  = viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.BrokerInfo_WMICDTO'
        );
        return brokerInfo;
    }, [viewModelService]);

    useEffect(() => {
        // Keeping this lookup here to reduce network payload size for performance
        UISupportingInfoLookupService.retrieveInsuranceCarriersList([baseData.baseState.value.code, periodStartDate], true, authHeader)
            .then((carriers) => {
                carriers.forEach((item) => {
                    item.name = item.carrier
                    item.code = item.carrier
                })
                setCarrierList(carriers);
            });

        const insuranceHistoryTmp = _.get(policyChangeVM, INSURANCE_HISTORY_PATH);

        if (!insuranceHistoryTmp.priorPolicyInfo.value) {
            const newPriorPolicyInfo = viewModelService.create(
                {},
                'pc',
                'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PriorPolicyInfo_WMICDTO',
                policyChangeVM.aspects.context()
            );
            _.set(insuranceHistoryTmp, 'priorPolicyInfo', newPriorPolicyInfo.value);
        }

        if (!insuranceHistoryTmp.priorPolicyInfo.firstPolicyDate.value) {
            _.set(insuranceHistoryTmp, 'priorPolicyInfo.firstPolicyDate',  periodStartDate)
        }

        if (!insuranceHistoryTmp.priorLosses.value) {
            _.set(insuranceHistoryTmp, 'priorLosses', []);
        }

        let brokerInfo = _.get(policyChangeVM, BROKER_INFO_PATH);
        if (!brokerInfo.value) {
            brokerInfo = createBrokerInfo();

            _.set(insuranceHistoryTmp, 'brokerInfo', brokerInfo.value)
        }
        _.set(policyChangeVM, INSURANCE_HISTORY_PATH, insuranceHistoryTmp);
        setInsuranceHistoryPageDetails(insuranceHistoryTmp);

        updateWizardData(policyChangeVM);
        setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateInsuranceHistoryVM = useCallback((historyVM) => {
        setInsuranceHistoryVM(historyVM);
        const newPolicyChangeVM = _.clone(policyChangeVM);
        _.set(newPolicyChangeVM, "lobData.homeowners.coverables.insuranceHistory_WMIC.value", historyVM.value);
        updateWizardData(newPolicyChangeVM);
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyChangeVM]);

    const updateBrokerInfo = (brokerInfoVM) => {
        _.set(insuranceHistoryVM, 'brokerInfo.value', brokerInfoVM.value);
        updateInsuranceHistoryVM(insuranceHistoryVM);
    };

    const createPolicyInfoVM = useCallback(() => {
        return viewModelService.create(
            {},
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.common.draft.dto.insurancehistory.PriorPolicyInfo_WMICDTO',
            policyChangeVM.aspects.context()
        );
    }, [policyChangeVM.aspects, viewModelService]);

    const initInsuranceHistory = useCallback(() => {
        const insuranceHistoryTmp = _.get(policyChangeVM, INSURANCE_HISTORY_PATH);
        const priorPolicyInfo = _.get(insuranceHistoryTmp, 'priorPolicyInfo');

        _.set(insuranceHistoryTmp, 'isAddingPriorLoss', false);
        _.set(insuranceHistoryTmp, 'isAddingOtherInsuranceCoPolicyRecord', false);
        _.set(insuranceHistoryTmp, 'isAddingOtherWawanesaPolicyRecord', false);
        _.set(insuranceHistoryTmp, 'isAddingDeclinedPolicyRecord', false);

        if (!priorPolicyInfo?.value) {
            const policyInfoVM = createPolicyInfoVM();
            _.set(insuranceHistoryTmp, 'priorPolicyInfo', policyInfoVM.value);
        }

        if (!insuranceHistoryTmp.priorLosses.value) {
            _.set(insuranceHistoryTmp, 'priorLosses', []);
        }

        let brokerInfo = _.get(policyChangeVM, BROKER_INFO_PATH);
        if (!brokerInfo.value) {
            brokerInfo = createBrokerInfo();

            _.set(insuranceHistoryTmp, 'brokerInfo', brokerInfo.value)
        }
        _.set(policyChangeVM, INSURANCE_HISTORY_PATH, insuranceHistoryTmp);
        setInsuranceHistoryPageDetails(insuranceHistoryTmp);
        setLoading(false);

        updateInsuranceHistoryVM(insuranceHistoryTmp);
    }, [createBrokerInfo, createPolicyInfoVM, policyChangeVM, updateInsuranceHistoryVM]);

    const onSaveInsuranceHistory = useCallback(async () => {
        if (!isComponentValid) {
            setScrollToError(Date.now());
            setShowErrors(true);
            return false;
        }
        try {
            setShowErrors(false);
            setWizardLoading(true, 'Saving')
            const result = await EndorsementService.saveEndorsement(
                [policyChangeVM.value],
                authHeader
            );
            _.extend(policyChangeVM.value, result);
            initInsuranceHistory();
            updateWizardData(policyChangeVM);
        } catch (err) {
            WMICLogger.error('Save Insurance History failed', err);
        } finally {
            setWizardLoading(false);
        }

        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentValid, setWizardLoading, EndorsementService, policyChangeVM, authHeader, initInsuranceHistory]);

    const onNext = useCallback(async () => {

        if (!isComponentValid) {
            setScrollToError(Date.now());
            setShowErrors(true);
            return false;
        }

        try {
            setWizardLoading(true);
            const newPolicyChangeVM = viewModelService.clone(policyChangeVM);
            _.unset(newPolicyChangeVM.value, 'bindData');

            policyChangeVM.value = await EndorsementService.saveEndorsement(
                [newPolicyChangeVM.value],
                authHeader
            );

            return policyChangeVM;
        } catch (err) {
            WMICLogger.error('Save Insurance History failed', err);
        } finally {
            setWizardLoading(false);
        }
    }, [isComponentValid, setWizardLoading, viewModelService, policyChangeVM, EndorsementService, authHeader]);

    const commonAccordionProps = {
        showErrors,
        readOnly: !canEditPolicyChange
    };

    const commonAccordionContentProps = {
        showErrors,
        onValidate: onValidateAccordion,
        jobVM: policyChangeVM,
        updateWizardData,
        authHeader,
        baseData,
        updateHistory: updateInsuranceHistoryVM,
        insuranceHistoryVM,
        readOnly: !canEditPolicyChange
    };

    const overrideProps = {
        insuranceHistoryContainerButtons: {
            onSave: onSaveInsuranceHistory,
            saveLabel: translator(messages.saveInsuranceHistory),
            visible: canEditPolicyChange,
        },
        policiesAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('policiesAccordionContent')
        },
        policiesAccordionContent: {
            ...commonAccordionContentProps,
            carriersList,
            jobVM: policyChangeVM
        },
        lossHistoryAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('lossHistoryAccordionContent')
        },
        lossHistoryAccordionContent: {
            ...commonAccordionContentProps,
            updateHistory: updateInsuranceHistoryVM,
            insuranceHistoryVM,
            carriersList,
            readOnly: true
        },
        creditConsentAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('creditConsentAccordionContent'),
            visible: isHOCreditConsentTabVisible
        },
        creditConsentAccordionContent: {
            ...commonAccordionContentProps
        },
        brokerAccordion: {
            ...commonAccordionProps,
            isValid: isAccordionValid('brokerInfo')
        },
        brokerInfo: {
            ...commonAccordionContentProps,
            updateBrokerInfo,
            brokerInfo: _.get(insuranceHistoryPageDetails, 'brokerInfo'),
            authUserData,
            lob
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap:{
            WMICInsuranceHistoryPoliciesComponent,
            WMICHOLossHistory,
            WMICHOCreditConsent,
            WMICBrokerInfo,
            Accordion
        }
    };

    if (loading) {
        return null;
    }

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <WMICScrollToError counter={scrollToError} />
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICHOPolicyChangeInsuranceHistoryPage.propTypes = wizardProps;

export default withViewModelService(WMICHOPolicyChangeInsuranceHistoryPage);
