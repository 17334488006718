import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import WMICButton from './WMICButton/WMICButton';
import WMICLink from './WMICLink/WMICLink';
import WMICIntlFormattedDateCell from './WMICIntlFormattedDateCell/WMICIntlFormattedDateCell';
import WMICDropdownMenuButton from './WMICDropdownMenuButton/WMICDropdownMenuButton';
import WMICMasterInput from './WMICMasterInput/WMICMasterInput';
import WMICRequiredText from './WMICRequiredText/WMICRequiredText';
import { WMICFilterBar } from './WMICFilterBar/WMICFilterBar';
import { WMICFilterBarContent } from './WMICFilterBar/WMICFilterBarContent';
import WMICIconButton from './WMICIconButton/WMICIconButton';
import WMICEditForm from './WMICEditForm/WMICEditForm';
import WMICCard from './WMICCard/WMICCard';
import WMICFormButtons from './WMICFormButtons/WMICFormButtons';
import WMICBackToTopButton from './WMICBackToTopButton/WMICBackToTopButton';
import WMICQuestionSetComponent from './WMICQuestionSetComponent/WMICQuestionSetComponent';
import WMICDataList from './WMICDataList/WMICDataList';
import WMICStepProgressBar from './WMICStepProgressBar/WMICStepProgressBar';
import WMICModalHeader from './WMICModalHeader/WMICModalHeader';
import WMICAccordionCard from './WMICAccordionCard/WMICAccordionCard';
import WMICHeading from './WMICHeading/WMICHeading';
import WMICFieldAlert from "./WMICFieldAlert/WMICFieldAlert";
import ClauseComponent from './QuoteClause/Clauses/ClauseComponent';
import ClausesComponentVM from './QuoteClause/Clauses/ClausesComponentVM';
import SingleClauseComponentVM from './QuoteClause/Clauses/SingleClauseComponentVM';
import QuoteClauseTable from './QuoteClause/QuoteClauseTable/QuoteClauseTable';
import WMICUnderwritingIssues from './QuoteClause/WMICUnderwritingIssues/WMICUnderwritingIssues';
import WMICIcon from "./WMICIcon/WMICIcon";
import WMICAddressDetails from './WMICAddressDetails/WMICAddressDetails';
import WMICDataTable from './WMICDataTable/WMICDataTable';
import WMICContactDetails from './WMICContactDetails/WMICContactDetails';
import WMICDataView from "./WMICDataView/WMICDataView";
import WMICDataBlocks from './WMICDataBlocks/WMICDataBlocks';
import WMICPhoneDetails from './WMICPhoneDetails/WMICPhoneDetails'
import WMICListView from './WMICListView/WMICListView';
import useViewModelClone from './hooks/useViewModelClone';
import WMICTransactionDetailsHeader from './WMICTransactionDetailsHeader/WMICTransactionDetailsHeader';
import WMICListDownloadButton from './WMICListDownloadButton/WMICListDownloadButton';
import WMICUnderwritingIssuesList from './QuoteClause/WMICUnderwritingIssuesList/WMICUnderwritingIssuesList';

export { default as WMICScrollToError } from './WMICScrollToError/WMICScrollToError';

setComponentMapOverrides(
    {
        WMICButton: { component: 'WMICButton'},
        WMICLink: { component: 'WMICLink'},
        WMICDropdownMenuButton: { component: 'WMICDropdownMenuButton'},
        WMICMasterInput: { component: 'WMICMasterInput'},
        WMICEditForm: { component: 'WMICEditForm'},
        WMICCard: { component: 'WMICCard'},
        WMICFormButtons: { component: 'WMICFormButtons'},
        WMICDataList: { component: 'WMICDataList' },
        WMICAccordionCard: {component: 'WMICAccordionCard'},
        WMICRequiredText: {component: 'WMICRequiredText'},
        WMICHeading: {component: 'WMICHeading'},
        WMICIcon : {component: 'WMICIcon'},
        WMICFieldAlert: {component: 'WMICFieldAlert'},
        WMICDataTable: {component: 'WMICDataTable'},
        WMICDataView: {component: 'WMICDataView'},
        WMICDataBlocks: {component: 'WMICDataBlocks'},
        WMICListView: {component: 'WMICListView'},
        WMICTransactionDetailsHeader: { component: 'WMICTransactionDetailsHeader'},
        WMICUnderwritingIssuesList: { component: 'WMICUnderwritingIssuesList' }
    },
    {
        WMICButton,
        WMICLink,
        WMICDropdownMenuButton,
        WMICMasterInput,
        WMICEditForm,
        WMICCard,
        WMICFormButtons,
        WMICDataList,
        WMICAccordionCard,
        WMICRequiredText,
        WMICHeading,
        WMICIcon,
        WMICFieldAlert,
        WMICDataTable,
        WMICDataView,
        WMICDataBlocks,
        WMICListView,
        WMICTransactionDetailsHeader,
        WMICUnderwritingIssuesList
    }
);

const pePlatformComponentMap = {
    ClauseComponent: { component: 'ClauseComponent' },
    ClausesComponentVM: { component: 'ClausesComponentVM' },
    WMICUnderwritingIssues: { component: 'WMICUnderwritingIssues' },
    QuoteClauseTable: { component: 'QuoteClauseTable' },
    SingleClauseComponentVM: { component: 'SingleClauseComponentVM' },
}

const pePlatformComponents = {
    ClauseComponent: ClauseComponent,
    ClausesComponentVM: ClausesComponentVM,
    WMICUnderwritingIssues,
    QuoteClauseTable,
    SingleClauseComponentVM,
}

export {
    WMICButton,
    WMICLink,
    WMICDropdownMenuButton,
    WMICMasterInput,
    WMICRequiredText,
    WMICFilterBar,
    WMICFilterBarContent,
    WMICIconButton,
    WMICEditForm,
    WMICCard,
    WMICFormButtons,
    WMICBackToTopButton,
    WMICQuestionSetComponent,
    WMICDataList,
    WMICStepProgressBar,
    WMICModalHeader,
    WMICAccordionCard,
    WMICHeading,
    ClauseComponent,
    SingleClauseComponentVM,
    ClausesComponentVM,
    QuoteClauseTable,
    WMICUnderwritingIssues,
    pePlatformComponents,
    pePlatformComponentMap,
    WMICIcon,
    WMICIntlFormattedDateCell,
    WMICFieldAlert,
    WMICDataTable,
    WMICAddressDetails,
    WMICContactDetails,
    WMICDataView,
    WMICDataBlocks,
    WMICPhoneDetails,
    WMICListView,
    WMICListDownloadButton,
    useViewModelClone,
    WMICTransactionDetailsHeader,
    WMICUnderwritingIssuesList
};
