import React, {
    useCallback,
    useContext,
    useState,
    useMemo
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals, useDocumentTitle } from 'wmic-pe-portals-wizard-components-ui';
import { WMICUserAccessUtil, WMICCreditConsentUtil, WMICValidationUtil, WMICJurisdictionUtil, JURISDICTIONS } from 'wmic-pe-portals-utils-js';
import { WMICPolicyDetailsComponent } from 'wmic-pe-capability-gateway-common-react';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';

function WMICPCPAPolicyDetailsPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;

    const { EndorsementService } = useDependencies('EndorsementService');
    const { setWizardLoading } = useWizardModals();
    const { authHeader, authUserData } = useAuthentication();
    const [showErrors, setShowErrors] = useState(false);
    const translator = useContext(TranslatorContext);

    const {
        onValidate: setComponentValidation,
        isComponentValid,
        initialValidation
    } = useValidation(
        'WMICPCPAPolicyDetailsPage'
    );

    useDocumentTitle(translator(commonMessages.policyDetailsTitle), policyChangeVM);

    const canEditPolicyChange = useMemo(() => WMICUserAccessUtil.canEditPolicyChange(authUserData.permission_Ext), [authUserData.permission_Ext]);

    const updateDriversForPNI = useCallback((namedInsured) => {
        const allDrivers = _.get(policyChangeVM, 'draftData.lobs.personalAuto.coverables.drivers.value')
        if (WMICCreditConsentUtil.isPniCreditConsentable(_.get(policyChangeVM, 'baseData.primaryNamedInsured_WMIC'))
            && allDrivers
            && allDrivers.length > 0) {
            let found = false;
            for (let driverCounter = 0; !found && driverCounter < allDrivers.length; driverCounter++) {
                const driver = allDrivers[driverCounter];
                if (driver.person.publicID === namedInsured.contactPublicID || driver.person.publicID === namedInsured.publicID) {
                    driver.creditConsentReceived = _.get(policyChangeVM, 'baseData.creditConsentReceived.value.code');
                    driver.creditConsentDate = _.get(policyChangeVM, 'baseData.creditConsentDate.value');
                    driver.creditInfoWithdrawalConsent = _.get(policyChangeVM, 'baseData.creditInfoWithdrawalConsent.value');
                    driver.personalInfoConsentForm = _.get(policyChangeVM, 'baseData.personalInfoConsentForm.value');
                    found = true;
                }
            }
            updateWizardData(policyChangeVM)
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [policyChangeVM]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }
        try {
            setWizardLoading(true);

            if (WMICJurisdictionUtil.isBaseState(_.get(policyChangeVM, 'baseData'), JURISDICTIONS.QUEBEC)) {
                updateDriversForPNI(_.get(policyChangeVM, 'draftData.primaryNamedInsured_WMIC.value'));
            }

            const newPolicyChangeVM = _.cloneDeep(policyChangeVM.value);

            policyChangeVM.value = await EndorsementService.saveEndorsement(
                [newPolicyChangeVM],
                authHeader
            );
            updateWizardData(policyChangeVM);

            return policyChangeVM;
        } finally {
            setWizardLoading(false);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentValid, setWizardLoading, policyChangeVM, EndorsementService, authHeader, updateDriversForPNI]);

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
            showRequired
        >
            <WMICPolicyDetailsComponent
                jobVM={policyChangeVM}
                updateWizardData={updateWizardData}
                onValidate={setComponentValidation}
                isReadOnly={!canEditPolicyChange}
                authHeader={authHeader}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPCPAPolicyDetailsPage.propTypes = wizardProps;

export default withViewModelService(WMICPCPAPolicyDetailsPage);
