import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import _ from 'lodash';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICCreditConsentUtil } from 'wmic-pe-portals-utils-js';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WMICCreditConsentPNIComponent, WMICCreditConsentANIComponent } from 'wmic-pe-capability-gateway-common-react';

import metadata from './WMICHOCreditConsent.metadata.json5';

function WMICHOCreditConsent(props) {
    const { id, jobVM, updateWizardData, showErrors, onValidate, readOnly } = props;
    const { onValidate: setComponentValidation, registerComponentValidation, isComponentValid } = useValidation('WMICHOCreditConsent');
    const viewModelService = useContext(ViewModelServiceContext);

    const [isPrimaryNamedInsuredConsentable, setPrimaryNamedInsuredConsentable] = useState(true);
    const [additionalNamedInsureds, setAdditionalNamedInsureds] = useState([]);
    const [baseData, setBaseData] = useState();

    const consentValidMap = useMemo(() => ({}), []);

    const onValidateConsentMap = useCallback((isValid, componentId) => {
        consentValidMap[componentId] = isValid;
    }, [consentValidMap]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [id, onValidate, isComponentValid]);

    const checkComponentValidation = useCallback(() => (
        WMICCreditConsentUtil.isConsentValid(consentValidMap)
    ), [consentValidMap]);

    useEffect(() => {
        registerComponentValidation(checkComponentValidation);
    }, [checkComponentValidation, registerComponentValidation]);

    useEffect(() => {
        const updatedBaseData = WMICCreditConsentUtil.populateContextIfEmpty(
            _.get(jobVM, 'baseData', {}), jobVM, viewModelService
        )
        setBaseData(updatedBaseData);
        setPrimaryNamedInsuredConsentable(
            WMICCreditConsentUtil.isPniCreditConsentable(updatedBaseData.primaryNamedInsured_WMIC)
        );
        setAdditionalNamedInsureds(
            WMICCreditConsentUtil.getCreditConsentableAnis(jobVM, viewModelService)
        );
    }, [jobVM, viewModelService]);

    const updateBaseData = (updatedBaseData) => {
        WMICCreditConsentUtil.updateBaseData(jobVM, updatedBaseData);
        setBaseData(updatedBaseData);
        updateWizardData(jobVM);
    }

    const setAdditionalNamedInsured = (ani, index) => {
        const allConsentableAnis = WMICCreditConsentUtil.updateAdditionalNamedInsured(jobVM, additionalNamedInsureds, ani, index);
        setAdditionalNamedInsureds(allConsentableAnis);
        updateWizardData(jobVM);
    }

    const overrideProps = {
        creditConsentPNIComponent: {
            baseData: baseData,
            setBaseData: updateBaseData,
            onValidateConsentMap,
            showErrors,
            visible: isPrimaryNamedInsuredConsentable,
            readOnly,
        },
        creditConsentANIIterable: {
            data: additionalNamedInsureds,
            jobVM
        },
        ...WMICCreditConsentUtil.updateAniProps(additionalNamedInsureds, setAdditionalNamedInsured, onValidateConsentMap, showErrors, readOnly)
    };

    const resolvers = {
        resolveComponentMap: {
            WMICCreditConsentPNIComponent,
            WMICCreditConsentANIComponent
        }
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={jobVM}
            overrideProps={overrideProps}
            onValidationChange={setComponentValidation}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default WMICHOCreditConsent;
