import { defineMessages } from 'react-intl';

export default defineMessages({
    insuranceHistoryTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.pages.InsuranceHistory.WMICHOPolicyChangeInsuranceHistoryPage.Title',
        defaultMessage: 'Insurance History'
    },
    policies: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICHOPolicyChangeInsuranceHistory.Policies',
        defaultMessage: 'Policies'
    },
    lossHistoryTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICHOPolicyChangeInsuranceHistory.Loss History',
        defaultMessage: 'Loss History'
    },
    creditConsent: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICHOPolicyChangeInsuranceHistory.Credit Consent',
        defaultMessage: 'Credit Consent'
    },
    brokerInfoTitle: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICHOPolicyChangeInsuranceHistory.Broker',
        defaultMessage: 'Broker'
    },
    saveInsuranceHistory: {
        id: 'wmic-pe-capability-gateway-policychange-ho-react.WMICHOPolicyChangeInsuranceHistory.saveInsuranceHistory',
        defaultMessage: 'Save Insurance History'
    },
});
