import { defineMessages } from 'react-intl';

export default defineMessages({
    renewalQuoteTitle: {
        id: 'wmic-pe-capability-gateway-policyrenewal-common-react.pages.Quote.WMICPolicyRenewalQuote.Title',
        defaultMessage: 'Renewal Quote'
    },
    renew: {
        id: 'wmic-pe-capability-gateway-renewal-common-react.QuotePage.Renew',
        defaultMessage: 'Renew'
    },
    binding: {
        id: 'wmic-pe-capability-gateway-renewal-common-react.QuotePage.Binding renewal...',
        defaultMessage: 'Binding renewal...'
    },
    loading: {
        id: 'wmic-pe-capability-gateway-renewal-common-react.QuotePage.Loading quote...',
        defaultMessage: 'Loading quote...'
    },
    bindpolicy: {
        id: 'wmic-pe-capability-gateway-renewal-common-react.QuotePage.Bindrenewal',
        defaultMessage: 'Bind Renewal'
    },
    bindmessage: {
        id: 'wmic-pe-capability-gateway-renewal-common-react.QuotePage.renewalBound',
        defaultMessage: 'The Renewal will be bound. Would you like to continue?'
    },
    bindpolicytitle: {
        id: 'wmic-pe-capability-gateway-renewal-common-react.QuotePage.BindrenewalTitle',
        defaultMessage: 'Bind Renewal'
    },
    cancelbutton: {
        id: 'wmic-pe-capability-gateway-renewal-common-react.QuotePage.cancel',
        defaultMessage: 'Cancel'
    },
    error: {
        id: 'wmic-pe-capability-gateway-renewal-common-react.QuotePage.Error',
        defaultMessage: 'Error'
    },
    bindingFailure: {
        id: 'wmic-pe-capability-gateway-renewal-common-react.QuotePage.Binding Failure',
        defaultMessage: 'Cannot bind the policy.'
    },
});
