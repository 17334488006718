/* eslint-disable no-secrets/no-secrets */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import { useDocumentTitle, useWizardModals, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import {
    WMICUserAccessUtil,
    FlowStepId,
    WMICCreditConsentUtil,
    WMICJurisdictionUtil,
    JURISDICTIONS,
    CONSTANTS,
    WMICLogger,
    WMICVariousUtil,
    WMICValidationUtil
} from 'wmic-pe-portals-utils-js';
import { LoadSaveService } from 'wmic-pe-capability-gateway-quoteandbind';
import { WMICPolicyDetailsComponent } from 'wmic-pe-capability-gateway-common-react';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import messages from './WMICPolicyDetailsPage.messages'

function WMICPolicyDetailsPage(props) {
    const { isComponentValid, onValidate: setComponentValidation, initialValidation, registerInitialComponentValidation } = useValidation('paPolicyDetailsPage');
    const { authHeader, authUserData } = useAuthentication();
    const { wizardData: submissionVM, updateWizardData } = props;
    const { setWizardLoading } = useWizardModals();
    const [showErrors, setShowErrors] = useState(false);
    const translator = useContext(TranslatorContext);
    const isBMSi = _.get(submissionVM, "isFromBMS_WMIC.value");
    const status = _.get(submissionVM, "quoteData.value.offeredQuotes[0].status", '');
    const isQuoted = status.toLowerCase() === CONSTANTS.QUOTED.toLowerCase();

    useEffect(() => {
        if (isComponentValid) {
            setShowErrors(false);
        }
    }, [isComponentValid]);

    useDocumentTitle(translator(messages.policyDetailsTitle), submissionVM);

    const validateInitialTableForm = useCallback(() => {
        const answers = _.get(submissionVM, 'lobData.personalAuto.preQualQuestionSets.children[0].value.answers');
        // If the qualification questions were not answered, start wizard from page 1 (Policy Details page)
        // However, in the case where BMSi has been created and the submission is in the Quoted status,
        // if it is user's first visit or if they revisit without making any changes,
        // the initial validation is bypassed to allow freely jump between steps.
        return isBMSi && isQuoted
            ? true
            : Object.values(answers).find(value => !_.isNil(value))
                && !WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.POLICY_DETAILS);
    }, [submissionVM, isBMSi, isQuoted]);

    useEffect(() => {
        registerInitialComponentValidation(validateInitialTableForm);
    }, [registerInitialComponentValidation, validateInitialTableForm]);

    const canEditSubmission = useMemo(() => WMICUserAccessUtil.canEditSubmission(authUserData.permission_Ext), [authUserData.permission_Ext]);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.POLICY_DETAILS;
    }, [submissionVM.value]);

    const launchDefaultAnswers = useCallback(async () => {
        try {
            setWizardLoading(true, translator(messages.defaultAnswers))
            const newVM = _.clone(submissionVM);
            newVM.value = await LoadSaveService
                .createDefaultAnswers_WMIC(submissionVM.value, authHeader);
            updateWizardData(newVM);
        } catch (e) {
            WMICLogger.error('Error launching default answers', e)
        } finally {
            setWizardLoading(false)
        }
    }, [submissionVM, authHeader]);

    const updateDriversForPNI = (namedInsured) => {
        const allDrivers = _.get(submissionVM, 'lobData.personalAuto.coverables.drivers.value')
        if (WMICCreditConsentUtil.isPniCreditConsentable(_.get(submissionVM, 'baseData.primaryNamedInsured_WMIC'))
           && allDrivers
           && allDrivers.length > 0) {
            let found = false;
            for(let driverCounter = 0; !found && driverCounter < allDrivers.length; driverCounter++) {
                const driver = allDrivers[driverCounter];
                if(driver.person.publicID === namedInsured.contactPublicID || driver.person.publicID === namedInsured.publicID) {
                    driver.creditConsentReceived =  _.get(submissionVM, 'baseData.creditConsentReceived.value.code');
                    driver.creditConsentDate = _.get(submissionVM, 'baseData.creditConsentDate.value');
                    driver.creditInfoWithdrawalConsent = _.get(submissionVM, 'baseData.creditInfoWithdrawalConsent.value');
                    driver.personalInfoConsentForm = _.get(submissionVM, 'baseData.personalInfoConsentForm.value');
                    found = true;
                }
            }
            updateWizardData(submissionVM);
        }
    };

    const onNext = useCallback(async () => {
        if (isComponentValid) {
            try {
                setWizardLoading(true);
                if (WMICJurisdictionUtil.isBaseState(_.get(submissionVM, 'baseData'), JURISDICTIONS.QUEBEC)) {
                    updateDriversForPNI(_.get(submissionVM, 'baseData.primaryNamedInsured_WMIC.value'));
                }

                _.unset(submissionVM.value, 'bindData');

                // TODO - BE does not save all of the values correctly. E.g. date of birth
                submissionVM.value = await LoadSaveService.updateDraftSubmission(
                    submissionVM.value,
                    authHeader
                );

                updateWizardData(submissionVM);

                if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.POLICY_DETAILS)) {
                    WMICVariousUtil.scrollToTop();
                    return false
                }

                return submissionVM;
            } finally {
                setWizardLoading(false);
            }
        } else {
            setShowErrors(true)
            return false;
        }
    }, [submissionVM, authHeader, isComponentValid]);


    return (
        <WMICWizardSubmissionPage
            showPrevious={false}
            skipWhen={initialValidation}
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            showRequired
        >
            <WMICPolicyDetailsComponent
                jobVM={submissionVM}
                updateWizardData={updateWizardData}
                onValidate={setComponentValidation}
                isReadOnly={!canEditSubmission}
                authHeader={authHeader}
                launchDefaultAnswers={launchDefaultAnswers}
                showErrors={showErrors}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICPolicyDetailsPage.propTypes = wizardProps;
export default WMICPolicyDetailsPage;
