import React, {useCallback, useContext} from 'react';
import _ from 'lodash';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import {JURISDICTIONS, PAConstants, WMICRichTextUtil} from 'wmic-pe-portals-utils-js';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { WMICHeading } from 'wmic-pe-components-platform-react';
import WMICPACoverageUtil from "wmic-pe-capability-gateway-common-pa-react/util/WMICPACoverageUtil";
import WMICPolicyDriverEndorsementComponent from './WMICPolicyDriverEndorsementComponent/WMICPolicyDriverEndorsementComponent';
import metadata from './WMICPAPolicyDriverEndorsements.metadata.json5';
import messages from './WMICPAPolicyDriverEndorsements.messages';

function WMICPAPolicyDriverEndorsements(props) {
    const { isVehicle, id, selectedVehicleId } = props;
    const { wizardData: { policyVM, selectedPolicyTermNumber } } = useContext(WizardContext);
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);

    const selectedTerm = _.find(_.get(policyVM, "periods.value"), (period) => period.termNumber_WMIC === selectedPolicyTermNumber)
    const coveragesVM = _.get(policyVM, `lobs.personalAuto.coverageDTOs.driverEndorsements`)
    const baseState = _.get(policyVM, 'lobs.personalAuto.baseState.value')
    const rateAsOfDate = _.get(selectedTerm, "rateAsOfDate")

    const getCov28Heading = useCallback(() => {

        if ([JURISDICTIONS.ALBERTA, JURISDICTIONS.YUKON].includes(baseState.code)) {
            return messages.reductionOfCoverage_AB_YT;
        }

        const coverage = _.find(coveragesVM.value, {publicID: PAConstants.reducAmtForNamedCov_28_PublicId});
        const limit = _.find(coverage?.terms, {publicID: PAConstants.reducAmtForNamedCov_28_LimitPatternCode})?.chosenTermValue;

        return limit ? WMICRichTextUtil.translateRichText(translator(messages.reductionOfCoverageWithLimit, {driverLimit: limit})) : undefined;
    }, [baseState, translator, coveragesVM]);

    const getDriverEndorsementHeading = (covPublicId) => {

        switch (covPublicId) {
            case PAConstants.accidentWaiverCovPublicId:
            case PAConstants.accidentWaiverMtcCovPublicId:
            case PAConstants.minorConvictionWaiver39bPublicId:
                return _.find(coveragesVM.value, {publicID: covPublicId})?.name;
            case PAConstants.reducAmtForNamedCov_28_PublicId:
                return getCov28Heading();
            default:
                return '';
        }
    }

    const isAccidentWaiverEndorsementVisible = () => {
        return baseState !== JURISDICTIONS.QUEBEC &&
        coveragesVM.value.find((coverage) => coverage.publicID === PAConstants.accidentWaiverCovPublicId) === undefined;
    };

    const getDriverEndorsements = () => {
        const endorsements = viewModelService.clone(coveragesVM);

        if (isAccidentWaiverEndorsementVisible()) {
            endorsements.value.push({
                publicID: PAConstants.accidentWaiverCovPublicId,
            });
        }

        return endorsements.value;
    };

    const renderDriverEndorsements = () => {
        const endorsements = getDriverEndorsements();

        return endorsements.filter((endorsement) => {
            return !WMICPACoverageUtil.getIsVehicleDriverEndorsement(endorsement.publicID);
        }).map((endorsement) => {
            return (
                <>
                    <WMICHeading
                        id={`${id}_heading`}
                        underlined
                        title={getDriverEndorsementHeading(endorsement.publicID)}
                        className="gw-mb-3"
                    />
                    <WMICPolicyDriverEndorsementComponent
                        id={endorsement.coverageUniqueID}
                        driverEndorsement={endorsement}
                        offeredCoverages={coveragesVM}
                        policyVM={policyVM}
                        baseState={baseState}
                        rateAsOfDate={rateAsOfDate}
                    />
                </>
            )
        })
    }

    const renderDriverEndorsementsForVehicle = () =>
        coveragesVM.filter(endorsement =>
            WMICPACoverageUtil.getIsVehicleDriverEndorsement(endorsement.publicID.value)
        ).map(endorsement =>
            <>
                <WMICHeading
                    id={`${id}_heading`}
                    underlined
                    title={getDriverEndorsementHeading(endorsement.publicID.value)}
                    className="gw-mb-3"
                />
                <WMICPolicyDriverEndorsementComponent
                    id={endorsement.coverageUniqueID.value}
                    driverEndorsement={endorsement.value}
                    offeredCoverages={coveragesVM}
                    policyVM={policyVM}
                    baseState={baseState}
                    selectedVehicleId={selectedVehicleId}
                />
            </>
        )

    const overrideProps = {
        '@field': {
            readOnly: true,
        },
        driverEndorsementsCoveragesContainer: {
            content: isVehicle ? renderDriverEndorsementsForVehicle() : renderDriverEndorsements()
        },
    };

    const resolvers = {
        resolveComponentMap:{
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

export default WMICPAPolicyDriverEndorsements;
