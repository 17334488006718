import { defineMessages } from 'react-intl';

export default defineMessages({
    issueDeclined: {
        id: "wmic.component.WMICUnderwritingIssuesList.issueDeclined",
        defaultMessage: "Declined"
    },
    issueApproved: {
        id: "wmic.component.WMICUnderwritingIssuesList.issueApproved",
        defaultMessage: "Approved"
    }
});
