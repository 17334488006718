/* eslint-disable unicorn/filename-case */
import React, { useCallback, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
    ModalNext,
    ModalBody,
    ModalFooter
} from '@jutro/components';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from 'wmic-digital-auth-react';
import { AccountBillingDetailsService } from 'gw-capability-billing';
import { WMICButton } from 'wmic-components-platform-react';
import messages from './WMICPendingPaymentCancellation.messages';
import WMICPendingPaymentCancellationEntry from './WMICPendingPaymentCancellationEntry';
import WMICPendingPaymentCancellationProgress from './WMICPendingPaymentCancellationProgress';
import WMICPendingPaymentCancellationSuccess from './WMICPendingPaymentCancellationSuccess';
import WMICPendingPaymentCancellationError from './WMICPendingPaymentCancellationError';

const STEPS = {
    ENTRY: 1,
    PROGRESS: 2,
    SUCCESS: 3,
    ERROR: 4
};

const WMICPendingPaymentCancellation = (props) => {
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const {
        isOpen,
        onReject,
        payment,
        callBack
    } = props;

    const [step, updateStep] = useState(STEPS.ENTRY);
    const focusRef = useRef(null);

    const setFocusDialog = useCallback(() => {
        setTimeout(() => {
            focusRef?.current?.focus()
        }, 0);
    }, []);

    const updateStepAndFocus = useCallback((nextStep) => {
        updateStep(nextStep);
        setFocusDialog();
    }, [setFocusDialog]);

    const stepMapping = useMemo(() => [
        {
            step: STEPS.ENTRY,
            contextDescription: translator(messages.paymentEntryDescriptionAria)
        },
        {
            step: STEPS.PROGRESS,
            contextDescription: translator(messages.paymentProgressDescriptionAria)
        },
        {
            step: STEPS.SUCCESS,
            contextDescription: translator(messages.paymentSuccessDescriptionAria)
        },
        {
            step: STEPS.ERROR,
            contextDescription: translator(messages.paymentErrorDescriptionAria)
        },
    ], [translator]);

    const getStepContextDescription = useMemo((_step) => _.find(stepMapping, (currentMapping) => currentMapping.step === _step)?.contextDescription, [stepMapping]);

    const modalHeader = useMemo(() => {
        switch (step) {
            case STEPS.ENTRY:
                return (
                    <h1 className='wmic-modal-header-title'>
                        {translator(messages.ConfirmCancellation)}
                        <span className="sr-only">{getStepContextDescription}</span>
                    </h1>
                )
            case STEPS.PROGRESS:
                return [];
            case STEPS.SUCCESS:
                return (
                    <h1 className='wmic-modal-header-title'>
                        {translator(messages.Success)}
                        <span className="sr-only">{getStepContextDescription}</span>
                    </h1>
                )
            case STEPS.ERROR:
                return (
                    <h1 className='wmic-modal-header-title'>
                        {translator(messages.WeAreSorry)}
                        <span className="sr-only">{getStepContextDescription}</span>
                    </h1>
                )
            default:
                return [];
        }
    }, [getStepContextDescription, step, translator]);

    const modalBody = useMemo(() => {
        switch (step) {
            case STEPS.ENTRY:
                return <WMICPendingPaymentCancellationEntry payment={payment} />;
            case STEPS.PROGRESS:
                return <WMICPendingPaymentCancellationProgress />;
            case STEPS.SUCCESS:
                return <WMICPendingPaymentCancellationSuccess payment={payment} />;
            case STEPS.ERROR:
                return <WMICPendingPaymentCancellationError payment={payment} />;
            default:
                return '';
        }
    }, [payment, step]);

    const cancelPendingPayment = useCallback((p) => {
        updateStepAndFocus(STEPS.PROGRESS);

        const processID = p?.scheduledPaymentReference_Ext;

        AccountBillingDetailsService.cancelPendingPaymentRequest_WMIC(processID, authHeader).then((result) => {
            if (result) {
                updateStepAndFocus(STEPS.SUCCESS);
            } else {
                updateStepAndFocus(STEPS.ERROR);
            }
        }).catch(() => {
            updateStepAndFocus(STEPS.ERROR);
        });
    }, [authHeader, updateStepAndFocus]);

    const modalFooter = useMemo(() => {
        switch (step) {
            case STEPS.ENTRY:
                return (
                    <ModalFooter>
                        <WMICButton
                            type="outlined"
                            onClick={() => { onReject(); }}
                        >
                            {translator(messages.NoKeepPayment)}
                        </WMICButton>
                        <WMICButton
                            type="primary"
                            onClick={() => { cancelPendingPayment(payment); }}
                        >
                            {translator(messages.YesCancelPayment)}
                        </WMICButton>
                        
                    </ModalFooter>
                );
            case STEPS.PROGRESS:
                return [];
            case STEPS.SUCCESS:
                return (
                    <ModalFooter>
                        <WMICButton
                            type="primary"
                            onClick={() => { onReject(); callBack(); }}
                        >
                            {translator(messages.Close)}
                        </WMICButton>
                    </ModalFooter>
                );
            case STEPS.ERROR:
                return (
                    <ModalFooter>
                        <WMICButton
                            type="primary"
                            onClick={() => { onReject(); }}
                        >
                            {translator(messages.Close)}
                        </WMICButton>
                    </ModalFooter>
                );
            default:
                return '';
        }
    }, [callBack, cancelPendingPayment, onReject, payment, step, translator]);

    const handleClose = () => {
        onReject();
    };

    return (
        <ModalNext 
            isOpen={isOpen}             
            shouldCloseOnEsc
            onRequestClose={handleClose}>
            <div ref={focusRef} tabIndex={1} aria-live="assertive">{ modalHeader }</div>
            <ModalBody>
                <div aria-live="assertive">{ modalBody }</div>
            </ModalBody>
            { modalFooter }
        </ModalNext>
    );
};

WMICPendingPaymentCancellation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onReject: PropTypes.func.isRequired,
    payment: PropTypes.func.isRequired,
    callBack: PropTypes.func.isRequired,
};

export default WMICPendingPaymentCancellation;
