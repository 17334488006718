import React, { useContext, useCallback, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { TextAreaField } from '@jutro/legacy/components';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { UW_BLOCKING_POINT, JobType } from 'wmic-pe-portals-utils-js';
import { SubmissionService } from 'gw-capability-gateway';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';
import { WMICUnderwritingIssuesList } from 'wmic-pe-components-platform-react';
import WMICQuoteUtil from 'wmic-pe-capability-quoteandbind-common-react/util/WMICQuoteUtil';
import metadata from './WMICUnderwritingIssues.metadata.json5';
import styles from './WMICUnderwritingIssues.module.scss';
import messages from './WMICUnderwritingIssues.messages';

const issueApproved = (issue) => issue.botStatus === "ORANGE"

const issueAutoApproved = (issue) => issue.botStatus === "YELLOW"

const displayIssueDescription = (item) => {
    let issueDescription = item.longDescription;
    const index = item.longDescription?.indexOf(". Underwriter review required");
    if (index > -1) {
        issueDescription = issueDescription.substr(0,index+1);
    }
    return issueDescription;
};

const DisplayUnderwritingIssues = (props) => {
    const {
        underwritingIssues,
        history,
        quoteID,
        authHeader,
        jobVM,
        jobType
    } = props;
    const [formData, updateFormData] = useState({});
    const currentUWnotes = useRef();
    const translator = useContext(TranslatorContext);
    const { quoteUnderWritingService } = useDependencies('quoteUnderWritingService');
    const { PolicyChangeService } = useDependencies('PolicyChangeService');
    const { RenewalService } = useDependencies('RenewalService');
    const { setWizardLoading } = useWizardModals();

    const initUWIssuesData = useCallback((UWIssues) => {
        const nonSortedUWIssues = _.forEach(UWIssues, (issue) => {
            issue.longDescription = displayIssueDescription(issue);
        });
        const approvedIssues = _.orderBy(nonSortedUWIssues.filter((issue) => issueApproved(issue)), ['hasApprovalOrRejection']);
        const autoApprovedIssues = nonSortedUWIssues.filter((issue) => issueAutoApproved(issue));
        const unapprovedIssues = _.orderBy(nonSortedUWIssues.filter((issue) => WMICQuoteUtil.isIssueNotApproved(issue)), ['hasApprovalOrRejection', 'publicID']);
        updateFormData({approvedIssues, autoApprovedIssues, unapprovedIssues, notes: currentUWnotes.current})
    }, []);

    useEffect(() => {
        initUWIssuesData(underwritingIssues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [underwritingIssues]);

    useEffect(()=> {
        currentUWnotes.current = "";
    }, []);

    const writeValue = useCallback(
        (value, path) => {
            const nextFormData = _.cloneDeep(formData);
            _.set(nextFormData, path, value);
            updateFormData(nextFormData);

            if (path === "notes") {
                currentUWnotes.current = value;
            }
        },
        [formData]
    );

    const referToUnderwriter = useCallback(() => {
        setWizardLoading(true);
        const { notes } = formData;
        let jobService = RenewalService;
        let redirectSubPath = "renewal";

        if (jobType === JobType.SUBMISSION) {
            jobService = SubmissionService
            redirectSubPath = "quotes"
        } else if (jobType === JobType.POLICY_CHANGE) {
            jobService = PolicyChangeService
            redirectSubPath = "change"
        }

        jobService.referToUnderwriter(quoteID, notes, authHeader).then(() => {
            history.push(`/${redirectSubPath}/${quoteID}/summary`);
        }).catch((error) => {
            WMICErrorHandler.processAsModal(error);
        }).finally(() => setWizardLoading(false));
    }, [authHeader, quoteID, history, formData, quoteUnderWritingService]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            referToUnderwriter
        },
        resolveComponentMap: {
            TextAreaField,
            WMICUnderwritingIssuesList
        }
    };

    const overrideProps = {
        autoApprovedUWIssuesItems: {
            underwritingIssues: formData?.autoApprovedIssues
        },
        gwAlertHeading: {
            title: translator(messages.uwIssues)
        },
        quotePageAutoApprovedUWIssuesContainer: {
            visible: !_.isEmpty(_.get(formData, 'autoApprovedIssues', []))
        },
        uwIssuesRaisedIcon: {
            icon: !_.isEmpty(_.get(formData, 'unapprovedIssues', [])) ? "mi-warning" : "mi-check",
            className: !_.isEmpty(_.get(formData, 'unapprovedIssues', [])) ? "gwWarningIcon" : "gwSuccess"
        },
        orangeHeaderIcon: {
            icon: _.isEmpty(_.get(formData, 'unapprovedIssues', [])) ? "mi-check" : "mi-warning"
        },
        quotePageUWIssuesSubTitle: {
            content: _.isEmpty(_.get(formData, 'unapprovedIssues', [])) ?
                translator(messages.noOutstandingIssues) :
                translator(messages.quotePageUWIssuesSubTitle),
            className: _.isEmpty(_.get(formData, 'unapprovedIssues', [])) ? 'uWrequired' : 'uWrequired uwApproved'
        },
        quotePagePendingUWIssues:{
            underwritingIssues: formData?.approvedIssues
        },
        UWFormInput: {
            placeholder: translator(messages.placeholder)
        },
        referToUWForm: {
            visible: jobVM?.canBeReferredForUWReview_Ext?.value
        },
        uwiBrokerNotifierText: {
            visible: WMICQuoteUtil.isUWIBrokerNotifierMsgVisible(jobVM, true) && !_.isEmpty(_.get(formData, 'unapprovedIssues', [])),
            content: translator(messages.uwiBrokerNotifierText)
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={formData}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
};

DisplayUnderwritingIssues.propTypes = {
    underwritingIssues: PropTypes.arrayOf({}),
    blockQuote: PropTypes.bool,
    quoteID: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    filterUWIssuesInCustomOffering: PropTypes.bool
};
DisplayUnderwritingIssues.defaultProps = {
    underwritingIssues: [],
    blockQuote: false,
    filterUWIssuesInCustomOffering: true,
    jobType: JobType.SUBMISSION
};

const WMICUnderwritingIssues = (props) => {
    return <DisplayUnderwritingIssues {...props} />;
};

WMICUnderwritingIssues.propTypes = {
    underwritingIssues: PropTypes.arrayOf({}).isRequired,
    quoteID: PropTypes.string.isRequired,
    filterUWIssuesInCustomOffering: PropTypes.bool
};

WMICUnderwritingIssues.defaultProps = {
    filterUWIssuesInCustomOffering: true
};

export default withRouter(WMICUnderwritingIssues);
