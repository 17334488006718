import React from 'react';
import {useTranslator} from '@jutro/locale';
import {WMICIcon} from 'wmic-pe-components-platform-react';
import cx from 'classnames';
import {STATUS_CONTAINER_ICONS, UW_BLOCKING_POINT} from 'wmic-pe-portals-utils-js/StringConstants';
import styles from './WMICUnderwritingIssuesList.module.scss';
import messages from './WMICUnderwritingIssuesList.messages';

const WMICUnderwritingIssuesList = ({underwritingIssues}) => {
    const translator = useTranslator();
    const issueDeclinedMessage = translator(messages.issueDeclined);
    const issueApprovedMessage = translator(messages.issueApproved);

    const renderUWIssueItem = (issue) => {
        const isDeclined = issue.hasApprovalOrRejection && issue.approvalBlockingPoint === UW_BLOCKING_POINT.REJECTED;
        const isApprovedOrRejectedUWI = issue.hasApprovalOrRejection && issue.currentBlockingPoint !== UW_BLOCKING_POINT.BLOCK_ISSUANCE;
        const statusMessage = isDeclined ? issueDeclinedMessage : issueApprovedMessage;
        const statusIcon = isDeclined ? STATUS_CONTAINER_ICONS.CLOSE : STATUS_CONTAINER_ICONS.CHECK;
        const statusElementClassName = cx(styles.uwBadge, isDeclined ? styles.uwDeclined : styles.uwApproved);

        return (
            <li key={issue.publicID}>
                {isApprovedOrRejectedUWI &&
                    <div className={statusElementClassName}>
                        <WMICIcon icon={statusIcon}
                                  className={styles.uwApprovedItemsIcon}/>
                        <span>{statusMessage}</span>
                    </div>
                }
                <span>{issue.longDescription}</span>
            </li>
        );
    }

    return (
        <ul>
            {underwritingIssues?.map(renderUWIssueItem)}
        </ul>
    );

}

export default WMICUnderwritingIssuesList;
