import React, {useCallback, useContext, useMemo} from 'react';
import _ from 'lodash';
import { QuoteClauseTable, WMICCard, WMICAccordionCard, WMICHeading } from 'wmic-pe-components-platform-react';
import { WMICDriverEndorsementsComponent, WMICPAPFRComponent } from 'wmic-pe-capability-gateway-common-pa-react';
import { TranslatorContext } from '@jutro/locale';
import { Accordion } from '@jutro/legacy/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Position, PAConstants, WMICUserAccessUtil } from "wmic-pe-portals-utils-js";
import WMICPACoverageUtil from "wmic-pe-capability-gateway-common-pa-react/util/WMICPACoverageUtil";
import messages from './WMICPAVehicleCoverages.messages';
import metadata from './WMICPAVehicleCoverages.metadata.json5';

function WMICPAVehicleCoverages(props) {
    const {
        vehicleIndex,
        vehicleVM,
        vehicleCoverages,
        driverEndorsementsForVehicle,
        coverageColumnData,
        changeSubmissionAndSync,
        changeSubmission,
        onClauseChange,
        syncCoverages,
        recalculate,
        resetQuote,
        onStaleQuoteBranchCode,
        underwritingIssues,
        quoteID,
        jobVM,
        onValidate,
        showErrors
    } = props;

    const translator = useContext(TranslatorContext);
    const { authUserData: currentUser } = useAuthentication();

    const canEditJob = useMemo(
        () => WMICUserAccessUtil.canEditJob(currentUser.permission_Ext, jobVM?.baseData?.jobType?.value?.code),
        [currentUser.permission_Ext, jobVM?.baseData?.jobType?.value?.code]
    );

    const formatVehicleHeader = () => translator(messages.vehicleHeader,
            {
                vehicleNumber: vehicleVM.vehicleNumber_WMIC.value,
                year: vehicleVM.year.value,
                make: vehicleVM.make.value,
                model: vehicleVM.model.value,
                vin: vehicleVM.vin.value
            });

    function generateClauseData() {
        return coverageColumnData.map(({ lob, code }) => ({
                code,
                path: `${lob.path}.coverages.vehicleCoverages.children[${vehicleIndex}].coverages`,
                clauses: _.get(vehicleCoverages, 'coverages.value')
            }));
    }

    const getCustomTerms = useCallback((clause, path) => {
        if (['PA_CivilLiabilityCov_WMIC', 'PA_BIPDPackageCov'].includes(clause.publicID) && clause.selected === true) {
            return [{
                component: 'div',
                componentProps: {
                    className: 'exclamation-icon'
                },
                content: [
                    {
                        'id': 'tplIcon',
                        'component': 'icon',
                        'type': 'element',
                        'componentProps': {
                            'className': 'gw-mb-4 gw-mr-1',
                            'icon': 'mi-info',
                            'size': 'small'
                        }
                    },
                    {
                        'id': 'tplMsg',
                        'component': 'span',
                        'type': 'element',
                        'content': {
                            'id': 'wmic-pe-capability-gateway-common-pa-react.PAVehicleCoverages.TPL',
                            'defaultMessage': 'meta.Third Party Liability limits must be...'
                        }
                    }]
            }]
        }

        return null;
    }, [])

    const getVehicleCoverageSections = () => {
        // only show these categories
        const categoryMap = ['liabilityProtection', 'underageMaleDriver', 'underageGenderXOrFemaleDriver', 'physicalDamage', 'additionalEndorsements'];

        const categorizedCoverages = WMICPACoverageUtil.categorizeVehicleCoverages(vehicleCoverages.value, translator);
        const coverageSections = categoryMap.map((categoryKey) => {
            const vehicleCoverage = _.get(categorizedCoverages, categoryKey);
            const coverageTableData = [{
                data: vehicleCoverage.coverages,
                tableContent: generateClauseData()
            }]

            return (
                <React.Fragment>
                    {vehicleCoverage.coverages.length > 0
                        && <WMICHeading
                            variant="h2"
                            underlined
                            bold
                            size="h2"
                            title={vehicleCoverage.categoryName} />}
                    <QuoteClauseTable
                        columnData={coverageColumnData}
                        tableData={coverageTableData}
                        quoteID={quoteID}
                        readOnly={!canEditJob}
                        onChangeSubmissionAndSync={changeSubmissionAndSync}
                        onChangeSubmission={changeSubmission}
                        onSyncCoverages={syncCoverages}
                        onRecalculate={recalculate}
                        onResetQuote={resetQuote}
                        onStaleQuoteBranchCode={onStaleQuoteBranchCode}
                        underwritingIssues={underwritingIssues}
                        filterUWIssuesInCustomOffering={false}
                        getCustomTerms={getCustomTerms}
                        showTooltip={false}
                        onValidate={onValidate}
                        showErrors={showErrors}
                    />
                    {(categoryKey === PAConstants.physicalDamageCoverages && WMICPACoverageUtil.isProofOfFinancialResponsibilityVisible(jobVM)) &&
                        <WMICPAPFRComponent
                            VM={jobVM}
                            path="lobData.personalAuto.pfrexists_WMIC"
                            readOnly={!canEditJob}
                        />
                    }
                </React.Fragment>
            )
        });

        return coverageSections;
    }

    const overrideProps = {
        '@field': {
            labelPosition: Position.LEFT,
            parentNode: vehicleVM
        },
        vehicleCoveragesAccordions: {
            content: getVehicleCoverageSections()
        },
        vehicleDriverEndorsements: {
            jobVM,
            onClauseChange,
            selectedVehicleId: _.get(vehicleVM, 'publicID.value'),
            readyOnly: !canEditJob
        },
        driverEndorsementsContainer: {
            visible: driverEndorsementsForVehicle.length > 0
        }
    }

    const resolvers = {
        resolveComponentMap: {
            WMICDriverEndorsementsComponent,
            Accordion
        }
    }

    return (
        <WMICCard className="gw-mb-4">
            <WMICAccordionCard
                id={`vehicleCoveragesAccordionCard${vehicleIndex}`}
                title={formatVehicleHeader()}
                chevron
                chevronAlignment='left'
                headerVariant='h2'
                headerSize='h2'
                className='gw-mb-0'
                cardBodyClassName='gw-mx-2'
            >
                <ViewModelForm
                    model={vehicleVM}
                    uiProps={metadata.componentContent}
                    overrideProps={overrideProps}
                    componentMap={resolvers.resolveComponentMap}
                />
            </WMICAccordionCard>
        </WMICCard>
    );
}

export default WMICPAVehicleCoverages;
