import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICListDownloadButton } from 'wmic-pe-components-platform-react';
import { JobType, MVR_INCIDENT_CODE, MVR_ACTIONS, MVR_CONSTANTS, WMICJurisdictionUtil, WMICDateTimeService, JURISDICTIONS, WMICRichTextUtil, CONSTANTS, MODAL_CONSTANTS, DateUtil } from 'wmic-pe-portals-utils-js';

import WMICAddMVRComponent from './WMICAddMVRComponent/WMICAddMVRComponent'
import metadata from './WMICMVRComponent.metadata.json5';
import messages from './WMICMVRComponent.messages.js';
import styles from './WMICMVRComponent.module.scss';

const today = new Date();
const latestToday = new Date();
latestToday.setHours(23, 59, 59, 999);

const minMvrDate = new Date();
const maxMvrDate = latestToday;
const minDate = {
    year: today.getFullYear() - 150,
    month: today.getMonth(),
    day: today.getDate()
}

function WMICMVRComponent(props) {
    const {
        id,
        jobVM,
        driverVM,
        updateDriver,
        onValidate,
        baseData,
        isEditMode,
        authHeader,
        showErrors,
        isReadOnlyUser,
    } = props

    const ViewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const { showConfirm } = useWizardModals()
    const {onValidate: setComponentValidation, isComponentValid, registerComponentValidation} = useValidation(id);

    const [MVRAction, updateMVRAction] = useState(MVR_ACTIONS.NONE);
    const [selectedMVR, updateSelectedMVR] = useState();
    const [toggle, forceRender] = useState(false);
    const [isLoaded, updateisLoaded] = useState(false)
    const [editingIndex, updateEditingIndex] = useState(-1);
    const [duplicateMessage, updateDuplicateMessage] = useState('')

    const canOrderMVR = _.get(jobVM.value, 'lobData.personalAuto.coverables.canOrderMVR_Ext', false);

    const isMVRErrorStatus = CONSTANTS.MVR_ORDER_STATUSES.ERROR.includes(_.get(driverVM, 'orderedMVRStatus_Ext.value.code', null)) 
        || CONSTANTS.MVR_ORDER_STATUSES.CANCELLED.includes(_.get(driverVM, 'orderedMVRStatus_Ext.value.code', null));
    const isMVROrderedStatus = CONSTANTS.MVR_ORDER_STATUSES.ORDERED.includes(_.get(driverVM, 'orderedMVRStatus_Ext.value.code', null));
    const isMVRReceivedStatus = CONSTANTS.MVR_ORDER_STATUSES.RECEIVED.includes(_.get(driverVM, 'orderedMVRStatus_Ext.value.code', null));
    const isMVROrderedForSelectedDriver = () => _.get(driverVM, 'selectedForMVROrder_Ext.value', false);

    const isBaseStateQC = useMemo(() => (
        WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC)
    ), [baseData]);

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid, id);
        }
    }, [isComponentValid, onValidate, id]);

    const minimumMVRValidation = () => {
        if(driverVM.orderMVR_WMIC.value && _.get(driverVM, 'mvrContainer.aspects.ocular')) {            
            return _.get(driverVM, 'mvrReports_WMIC.value', []).length > 0 || isMVROrderedForSelectedDriver() || !_.isNull(_.get(driverVM, 'orderedMVRStatus_Ext.value.code', null))
        }
        return true;
    };

    const hasActiveMVRRecord = useMemo(() => {
        const reports = (driverVM?.mvrReports_WMIC?.children || [])
        .filter(r => r.value.reportSource === MVR_CONSTANTS.MVR_SOURCE.CGI && !isMVRErrorStatus)
        .map(r => (
            r?.mvrDate_WMIC ? DateUtil.getDiffBetweenDatesInDays(
                today,
                new Date(r?.mvrDate_WMIC?.value?.year, r?.mvrDate_WMIC?.value?.month, r?.mvrDate_WMIC?.value?.day)
            ) : 0
        ));

        return reports.some(r => r < MVR_CONSTANTS.MAX_MVR_RETENTION_DAYS);
    }, [driverVM?.mvrReports_WMIC?.children, isMVRErrorStatus]);

    useEffect(() => {
        registerComponentValidation(minimumMVRValidation);
        if(isBaseStateQC) {
            _.set(driverVM, 'orderMVR_WMIC.value', false);
        }
        if(_.get(driverVM, 'mvrReports_WMIC.value', []).length) {
            _.set(driverVM, 'mvrReports_WMIC.value', _.get(driverVM, 'mvrReports_WMIC.value').sort((a, b) => a.mvrDate_WMIC > b.mvrDate_WMIC ? 1 : -1));
        }

        updateDriver(driverVM);
        updateisLoaded(true);

    }, []);

    const handleSelectMVR = (data) => {
        if (![MVR_ACTIONS.EDIT, MVR_ACTIONS.ADD].includes(MVRAction)) {
            const selectedMVRCopy = ViewModelService.create(
                data?.value ? _.cloneDeep(data.value) : { isReadonly: false },
                'pc',
                'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.MVRReportDTO_WMIC',
                { OrderMVR: _.get(driverVM, 'orderMVR_WMIC.value')}
            );

            updateSelectedMVR(selectedMVRCopy);

            if(!isBaseStateQC){
                updateMVRAction(MVR_ACTIONS.VIEW);
            }
            forceRender(!toggle);
        }
    };

    useEffect(() => {
        if(isBaseStateQC) {
            if (_.get(driverVM, 'mvrReports_WMIC.value', undefined) && driverVM.mvrReports_WMIC.value.length > 0) {
                const singleMvrReportForQC = driverVM.mvrReports_WMIC.children[0];
                handleSelectMVR(singleMvrReportForQC);
            } else {
                handleSelectMVR({});
            }
        }
    }, [driverVM, isBaseStateQC]);

    const calcDate = (mvrReport) => {
        const minDateAux = WMICDateTimeService.localDate2Date(baseData.writtenDate_WMIC.value);
        minDateAux.setDate(minDateAux.getDate() - MVR_CONSTANTS.DAYS_BACK_MVR_DATE);


        minMvrDate.setFullYear(
            (_.get(mvrReport,' isReadOnly', undefined) && mvrReport.isReadOnly.value === true)
                ? today.getFullYear() - 150
                : minDateAux.getFullYear(),
            minDateAux.getMonth(),
            minDateAux.getDate()
        );

        maxMvrDate.setFullYear(today.getFullYear(), today.getMonth(), today.getDate())
    };

    const addMVR = () => {
        updateMVRAction(MVR_ACTIONS.ADD);
        const newMVR = ViewModelService.create(
            { isReadonly: false },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.MVRReportDTO_WMIC',
            { OrderMVR: _.get(driverVM, 'orderMVR_WMIC.value')}
        );
        _.set(newMVR, 'mvrIncidents_WMIC', []);
        _.set(driverVM, 'isAddingMVR.value', true);
        updateSelectedMVR(newMVR);
        updateDriver(driverVM);
        calcDate(newMVR);
    };

    const editMVR = (data, index) => {
        if (![MVR_ACTIONS.EDIT, MVR_ACTIONS.ADD].includes(MVRAction)) {
            updateEditingIndex(index);

            const selectedMVRCopy = ViewModelService.create(
                _.cloneDeep(data.value),
                'pc',
                'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.MVRReportDTO_WMIC',
                { OrderMVR: _.get(driverVM, 'orderMVR_WMIC.value')}
            );

            updateSelectedMVR(selectedMVRCopy);

            if(!isBaseStateQC){
                updateMVRAction(MVR_ACTIONS.EDIT);
            }

            calcDate(selectedMVR);
            _.set(driverVM, 'isAddingMVR.value', true);
            updateDriver(driverVM);
        };
    };

    const orderMVRHandler = useCallback(() => {
        _.set(driverVM, 'selectedForMVROrder_Ext.value', true)
        updateDriver(driverVM);
    }, [driverVM, updateDriver]);

    const isManualMVR = useCallback((index, data) => data.value.reportSource === MVR_CONSTANTS.MVR_SOURCE.MANUAL, []);
    const isCGIMVR = useCallback((index, data) => data.value.reportSource === MVR_CONSTANTS.MVR_SOURCE.CGI, []);

    const isMVRValid = (mvr) => {
        const convictions = new Set();
        let tracker = convictions.size;
        let duplicateMVRmessage = '';

        return !(mvr.mvrIncidents_WMIC.value).some((incident)=> {

            if (incident.incidentType === MVR_INCIDENT_CODE.CONVICTION) {
                convictions.add(`${incident.convictionDescription} ${WMICDateTimeService.toMidnightDate(incident.convictionDate).toString()} ${incident.violationDate ? WMICDateTimeService.toMidnightDate(incident.violationDate).toString() : ''}`);
                if (tracker === convictions.size) {
                    duplicateMVRmessage = translator(messages.DuplicateMessage, {
                        desc: incident.convictionDescription,
                        date: WMICDateTimeService.toMidnightDate(incident.convictionDate).toString()
                    });
                    updateDuplicateMessage(duplicateMVRmessage)
                }
                tracker++;
            }

            return duplicateMVRmessage.length > 0;
        });
    };

    const saveMVR = (data) => {
        if(isMVRValid(data)){
            updateDuplicateMessage('');
            switch(MVRAction) {
                case MVR_ACTIONS.ADD:
                    const mvrReport = data.value;
                    mvrReport.reportSource = MVR_CONSTANTS.MVR_SOURCE.MANUAL;

                    if (!driverVM.mvrReports_WMIC.value) {
                        _.set(driverVM, 'mvrReports_WMIC.value', []);
                    }

                    _.get(driverVM, 'mvrReports_WMIC.value').push(mvrReport);
                    _.set(driverVM, 'isAddingMVR.value', false);
                    updateDriver(driverVM);
                    break;
                case MVR_ACTIONS.EDIT:
                    _.get(driverVM,'mvrReports_WMIC.value').splice(editingIndex, 1, selectedMVR.value);
                    _.set(driverVM, 'isAddingMVR.value', false);
                    updateDriver(driverVM);
                    break;
                default:
                    break;
            }
            updateMVRAction(MVR_ACTIONS.NONE);
        }
    };

    const removeMVR = (item, index) => {
        showConfirm({
            title: messages.RemovingMVR,
            message: translator(messages.RemovingMVRConfirm),
            status: MODAL_CONSTANTS.STATUS.WARNING,
            icon: MODAL_CONSTANTS.ICON.WARNING,
            confirmButtonText: messages.Yes,
            cancelButtonText: messages.No
        }).then((result)=> {
            if(result === CONSTANTS.MODAL_RESULT.CONFIRM) {

                const mvrReports = _.get(driverVM, 'mvrReports_WMIC.value', []);
                mvrReports.splice(index, 1);
                _.set(driverVM, 'mvrReports_WMIC.value', mvrReports);

                _.set(driverVM, 'isAddingMVR.value', false);

                updateDriver(driverVM);
                updateMVRAction(MVR_ACTIONS.NONE);
            }
        });
    };

    const cancelMVR = () => {
        updateMVRAction(MVR_ACTIONS.NONE);
        _.set(driverVM, 'isAddingMVR.value', false);
        updateDriver(driverVM);
    };

    const disableMVRActions = () => {
        return [MVR_ACTIONS.ADD, MVR_ACTIONS.EDIT].includes(MVRAction);
    };

    const orderMVRChanged = (value, path) => {
        if (isLoaded) {
            const orderMVRValue = value;
            if (!_.isBoolean(orderMVRValue)) {
                _.set(driverVM, path, value);
                if (disableMVRActions()) {
                    updateMVRAction(MVR_ACTIONS.NONE);
                }
            } else if (orderMVRValue) {
                _.set(driverVM, path, value);
                _.set(driverVM, 'noMVRReason_WMIC.value',undefined);
                _.set(driverVM, 'doNotOrderMVRDetails_WMIC.value', undefined);
            } else if (_.get(driverVM, 'mvrReports_WMIC.value')
                && _.get(driverVM,'mvrReports_WMIC.value').length > 0) {
                showConfirm({
                    title: messages.ChangeMVROrderNo,
                    message: messages.ChangeMVROrderNoConfirmationMsg,
                    status: MODAL_CONSTANTS.STATUS.WARNING,
                    confirmButtonText: messages.yes,
                    cancelButtonText: messages.no
                }).then((result)=> {
                    if(result === CONSTANTS.MODAL_RESULT.CONFIRM) {
                        _.set(driverVM, path, value);
                        _.set(driverVM, 'mvrReports_WMIC.value', _.filter(_.get(driverVM, 'mvrReports_WMIC.value'), (item) => item.isReadOnly === true));
                        _.set(driverVM, 'selectedForMVROrder_Ext.value', false);
                        updateMVRAction(MVR_ACTIONS.NONE);
                        forceRender(!toggle);
                    }
                });
            } else {
                _.set(driverVM, path, value);
                _.set(driverVM, 'selectedForMVROrder_Ext.value', false);
            }
        }
        _.set(driverVM, 'selectedByClick', false);
        updateDriver(driverVM)
    };

    const noMVRReasonChanged = (value, path) => {
        _.set(driverVM, path, value);
        if (isLoaded && _.get(driverVM, 'noMVRReason_WMIC.value') !== 'Other') {
            _.set(driverVM, 'doNotOrderMVRDetails_WMIC.value', undefined);
        }
        updateDriver(driverVM);
    };

    const noMVRReasonOtherDescriptionChanged = (value, path) => {
        _.set(driverVM, path, value);
        updateDriver(driverVM)
    };

    const displayMVRDate = (data) => {
        return data?.value?.mvrDate_WMIC ? <span>{WMICDateTimeService.toMidnightDate(data.value.mvrDate_WMIC)}</span> : ''
    };

    const displayViolationsSuspensions = (data) => {
        const incidents = data.value.mvrIncidents_WMIC;
        const hasIncidents = incidents && incidents.length > 0;
        return hasIncidents ? translator(messages.yesIncidentLength, {incidents:incidents.length}) : translator(messages.No);
    };

    const presentMVRList = ()=> {
        return ((_.get(driverVM, 'orderMVR_WMIC.value',false) && _.get(driverVM, 'orderMVR_WMIC.value') === true)
            || (_.get(driverVM, 'mvrReports_WMIC.value',false) && _.get(driverVM, 'mvrReports_WMIC.value').length > 0));
    };

    const showMinimumMVRMessage = () => {
        return !!_.get(driverVM, 'orderMVR_WMIC.value') && !_.get(driverVM, 'mvrReports_WMIC.value.length', 0);
    };

    const shouldActionMVRButtonBeActive = useCallback(() => (
        _.get(driverVM, 'orderMVR_WMIC.value', false) && (isEditMode === undefined || isEditMode)
    ), [driverVM, isEditMode]);

    const isLicenseProvinceValid = CONSTANTS.MVR_LICENSE_PROVINCES.includes(_.get(driverVM, 'licenseState.value.code'))
    const isDriverAssigned = driverVM.assignment_WMIC?.value?.code === CONSTANTS.DRIVER_TYPES.ASSIGNED
    const canDriverOrderMVR = (() => {
        let canOrder = canOrderMVR && isDriverAssigned && isLicenseProvinceValid
        const isPolicyChange = jobVM.baseData.jobType.value.code === JobType.POLICY_CHANGE
        if (canOrder && isPolicyChange) {
          const isNewDriver = !driverVM.publicID
          const wasAssignedInPreviousPeriod = driverVM.assignmentInPreviousPolicyPeriod_WMIC?.value?.code === CONSTANTS.DRIVER_TYPES.ASSIGNED
          canOrder &&= isNewDriver || !wasAssignedInPreviousPeriod
        }
        return canOrder
    })()

    const shouldShowOrderMVR = canDriverOrderMVR && shouldActionMVRButtonBeActive();

    const overrideProps = {
        '@field': {
            parentNode: driverVM,
            readOnly: !isEditMode || _.get(driverVM, 'isAddingMVR.value') || isReadOnlyUser,
        },
        mvrForm: {
            onValidate: setComponentValidation,
            visible: MVRAction !== MVR_ACTIONS.NONE || isBaseStateQC,
            selectedMVR,
            addMVR,
            saveMVR,
            cancelMVR,
            driverVM,
            baseData,
            updateSelectedMVR,
            MVRAction,
            updateDriver,
            minMvrDate,
            maxMvrDate,
            minDate,
            authHeader,
            calcDate,
            isEditMode,
            duplicateMessage
        },
        MVRList: {
            visible: presentMVRList()
        },
        mvrAddButton: {
            disabled: disableMVRActions() || isReadOnlyUser || hasActiveMVRRecord,
            visible: shouldActionMVRButtonBeActive()
        },
        mvrOrderButton: {
            disabled: !isMVRErrorStatus && (isMVROrderedForSelectedDriver() || hasActiveMVRRecord || isMVROrderedStatus),
            visible: shouldShowOrderMVR
        },
        MVROrderedMessage: {
            content: WMICRichTextUtil.translateRichText(translator(messages.MVROrderedMessage)),
            visible: isMVROrderedForSelectedDriver() && shouldShowOrderMVR
        },
        MVRWaitingMessage: {
            content: WMICRichTextUtil.translateRichText(translator(messages.MVRWaitingMessage)),
            visible: isMVROrderedStatus && shouldShowOrderMVR
        },
        MVRErrorMessage: {
            content: WMICRichTextUtil.translateRichText(translator(messages.MVRErrorMessage)),
            visible: isMVRErrorStatus && shouldShowOrderMVR
        },
        MVRNotAvailableMessage: {
            content: WMICRichTextUtil.translateRichText(translator(messages.MVRNotAvailableMessage)),
            visible: isMVRReceivedStatus && hasActiveMVRRecord && shouldShowOrderMVR
        },
        MVRMinimumMessage: {
            content: shouldShowOrderMVR ? WMICRichTextUtil.translateRichText(translator(messages.MVRManualAndCGIMinimumMessage)) : WMICRichTextUtil.translateRichText(translator(messages.MVRMinimumMessage)),
            visible: showMinimumMVRMessage()
        },

        MVRDataList: {
            VMList: _.get(driverVM, 'mvrReports_WMIC.children', []),
            VMData: [
                {
                    headerText: translator(messages.MVRDate),
                    getData: displayMVRDate
                },
                {
                    headerText: translator(messages.MVRType),
                    path: 'mvrType_WMIC'
                },
                {
                    headerText: translator(messages.MVRViolationsSuspensions),
                    getData: displayViolationsSuspensions
                },
                {
                    headerText: translator(messages.MVRReportSource),
                    path: 'reportSource'
                }
            ],
            onEditAction: editMVR,
            customElement:  WMICListDownloadButton,
            canShowCustomElement: isCGIMVR,
            onRemoveAction: removeMVR,
            flatCards: true,
            clickable: true,
            selectedCardIndex: editingIndex,
            updateSelectedCardIndex: updateEditingIndex,
            isEditing: disableMVRActions() || !isEditMode,
            onClickAction: handleSelectMVR,
            readOnly: !isEditMode,
            canDelete: isManualMVR,
            canEdit: isManualMVR
        },
        MVRNotRequiredMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.MVRNotRequired)),
        },
        MVRNotRequiredMsgContainer: {
            visible: !_.get(driverVM, 'mvrContainer.aspects.ocular')
        },
        MVRContainer: {
            visible:  _.get(driverVM, 'mvrContainer.aspects.ocular')
        },
        MvrIncidentContainer: {
            visible: _.get(driverVM, 'mvrIncidentContainer.aspects.ocular')
        },
        MVRandViolationsSuspensions: {
            visible: _.get(driverVM, 'orderMVR_WMIC.value', false)
        },
        moreAccurateQuoteMessageContainer: {
            visible: _.isNull(_.get(driverVM, 'orderMVR_WMIC.value', null))
        }
    }

    const resolvers = {
        resolveCallbackMap: {
            orderMVRChanged,
            noMVRReasonChanged,
            noMVRReasonOtherDescriptionChanged,
            addMVR,
            orderMVRHandler
        },
        resolveComponentMap: {
            WMICAddMVRComponent
        },
        resolveClassNameMap: styles
    }
    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            onModelChange={updateDriver}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    )
}

WMICMVRComponent.propTypes = {
    id: PropTypes.string.isRequired,
    driverVM: PropTypes.shape({
        person: PropTypes.shape({
            publicID: PropTypes.shape({})
        })
    }).isRequired,
    updateDriver: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    baseData: PropTypes.shape({}).isRequired,
    isEditMode: PropTypes.bool.isRequired,
    authHeader: PropTypes.shape({
        Authorization: PropTypes.string.isRequired
    }).isRequired,
    isReadOnlyUser: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
};

export default WMICMVRComponent
