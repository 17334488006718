import { defineMessages } from 'react-intl';

export default defineMessages({
    noPriorLossesMsg: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.noPriorLossesMsg',
        defaultMessage: 'No losses have been added'
    },
    addLossButton: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.addLossButton',
        defaultMessage: 'Add Loss'
    },
    driverName: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.driverName',
        defaultMessage: 'Driver'
    },
    policyNumber: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.policyNumber',
        defaultMessage: 'Policy #'
    },
    claimNumber: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.claimNumber',
        defaultMessage: 'Claim Number'
    },
    atFault: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.atFault',
        defaultMessage: 'At Fault'
    },
    lossDate: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.lossDate',
        defaultMessage: 'Date of Loss'
    },
    removeLossHistoryTitle: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.removeLossHistoryTitle',
        defaultMessage: 'Are you sure you want to remove this prior loss?'
    },
    removeLossHistoryMsg: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.removeLossHistoryMsg',
        defaultMessage: 'Remove prior loss?'
    },
    no: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.no',
        defaultMessage: 'No'
    },
    yes: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.yes',
        defaultMessage: 'Yes'
    },
    pending: {
        id:  'quoteandbind.pa.views.pa-insurance-history-details.history-loss.pending',
        defaultMessage: 'Pending'
    },
    reportedDriver: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.reportedDriver',
        defaultMessage: 'Reported Driver'
    },
    FCSALossHistoryQC: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSALossHistoryQC',
        defaultMessage: 'FCSA: Loss History For Quebec Drivers'
    },
    FCSAFailure: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSAFailure',
        defaultMessage: 'FCSA Loss History is required for all Quebec drivers to bind.'
    },
    FCSAFailurePolicyChanged: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSAFailurePolicyChanged',
        defaultMessage: 'FCSA Loss History is required for all Quebec drivers to quote. Please use the FCSA Loss History button below.'
    },
    FCSAPartialFailure: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSAPartialFailure',
        defaultMessage: 'FCSA Loss History is required for all Quebec drivers to bind.Please try again.'
    },
    FCSAFullFailure: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSAFullFailureHeading',
        defaultMessage: '&lt;span&gt;&lt;b&gt;An error occurred, please try again.&lt;/b&gt; FCSA Loss History is required for all Quebec drivers to quote. Please try again.&lt;/span&gt;'
    },
    FCSASuccess: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSASuccess',
        defaultMessage: 'Success'
    },
    FCSARequired: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSARequired',
        defaultMessage: 'Required'
    },
    FCSANotRequested: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSANotRequested',
        defaultMessage: 'Not Requested'
    },
    FCSAWait: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSAWait',
        defaultMessage: 'Wait'
    },
    FCSAError: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSAError',
        defaultMessage: 'Error'
    },
    FCSADriverNameError: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSADriverNameError',
        defaultMessage: '{driverName} on {date} - An error occurred, please try again.'
    },
    FCSADriverNameSuccess: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSADriverNameSuccess',
        defaultMessage: '{driverName} on {date}'
    },
    FCSADriverNameReportError: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.FCSADriverNameReportError',
        defaultMessage: '{driverName} must have an FSCA report pulled in order to quote'
    },
    selectedDriversForFCSA: {
        id: 'quoteandbind.pa.views.pa-drivers-details.selectedDriversForFCSA',
        defaultMessage: 'FCSA Loss History'
    },
    getDriversButton: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.getDriversButton',
        defaultMessage: 'FCSA Loss History'
    },
    fcsaConfirmationMsg: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.fcsaConfirmationMsg',
        defaultMessage: 'FCSA Loss History for {driverName} already exists. Would you like to request it again?'
    },
    fcsaConfirmationTitle: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.fcsaConfirmationTitle',
        defaultMessage: 'FCSA Already Retrieved'
    },
    vehicleOther: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.vehicleOther',
        defaultMessage: 'Other'
    },
    vehicleChargeable: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.Chargeable',
        defaultMessage: 'Chargeable?'
    },
    vehicleUseForRating: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.UseForRating',
        defaultMessage: 'Use for rating?'
    },
    vehicleRatingOverrideApplied: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.RatingOverrideApplied',
        defaultMessage: 'Override Applied?'
    },
    vehicleRatingReason: {
        id: 'quoteandbind.pa.views.pa-insurance-history-details.history-loss.RatingReason',
        defaultMessage: 'Reason'
    }
});