import React, {useContext, useCallback, useState } from 'react';
import _ from 'lodash';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { TranslatorContext } from '@jutro/locale';
import { CONSTANTS, WMICRichTextUtil, JobType } from 'wmic-pe-portals-utils-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICListView, WMICAddressDetails } from 'wmic-pe-components-platform-react';

import WMICBuildingsDetailView from '../WMICBuildingsDetailView/WMICBuildingsDetailView';
import metadata from './WMICLocationDetailView.metadata.json5';
import messages from './WMICLocationDetailView.messages';

const LOCATION_PATH = 'lobData.commercialProperty.coverables.locations';

function WMICLocationDetailView(props) {
    const translator = useContext(TranslatorContext);
    const viewModelService = useContext(ViewModelServiceContext);
    const [showErrorsBldg, setShowErrorsBldg] = useState(false);

    const { authHeader } = useAuthentication();
    const { setWizardLoading, showConfirm } = useWizardModals();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const { value: locationVM, isEditing, updateModel, showErrors, jobVM, updateWizardData, showErrorsLocation, isBoundPolicy } = props;

    const { onValidate } = useValidation('WMICLocationDetailView');

    const formatBuildingNumberLabel = useCallback((building) => {
        return `${translator(messages.buildingNumber, { displayName: building.buildingNumber.value })}`
    }, [translator]);

    const saveSubmission = useCallback(async (newSubmission, onError = _.noop) => {
        setWizardLoading(true);
        return LoadSaveService.updateDraftSubmission(
            newSubmission,
            authHeader
        ).then((result) => {
            _.extend(newSubmission, result);
            jobVM.value = newSubmission;
            updateWizardData(jobVM);
            return true;
        }).finally(() => {
            setWizardLoading(false);
        });
    }, [LoadSaveService, authHeader, jobVM, setWizardLoading, updateWizardData]);

    const onSaveClicked = useCallback(async (buildingVM, index) => {
        if (buildingVM.aspects.valid && buildingVM.aspects.subtreeValid) {
            const currentLocations = _.get(jobVM, `${LOCATION_PATH}`)
            const locationIndex = currentLocations.value.findIndex(loc => loc.publicID == locationVM.publicID.value)

            if (buildingVM.value.isPrimary) {
                const buildings = currentLocations.children[locationIndex].buildings.children;
                for (let buildingIndex = 0; buildingIndex < buildings.length; buildingIndex++) {
                    if (index !== buildingIndex) {
                        buildings[buildingIndex].isPrimary = false;
                    }
                }
            }

            return saveSubmission(jobVM.value).then((result) => {
                _.set(locationVM, `buildings.value`, _.get(jobVM, `${LOCATION_PATH}.value[${locationIndex}].buildings`));
                return result
            })
        } else {
            setShowErrorsBldg(true)
        }
    }, [jobVM, locationVM, saveSubmission]);

    const toCreateClicked = useCallback(() => {
        const newVM = viewModelService.create(
            {
                'welding': CONSTANTS.CP_BUILDING_NO,
                'woodWorking': CONSTANTS.CP_BUILDING_NO,
                'sprayPainting': CONSTANTS.CP_BUILDING_NO,
                'fuelStorage': CONSTANTS.CP_BUILDING_NO,
                'alarm': CONSTANTS.CP_BUILDING_NO_ALARM,
                'monitoring': CONSTANTS.CP_BUILDING_NO_MONITORING,
                'additionalInterests': []
            },
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.commercialproperty.coverables.dto.BuildingDTO',
            jobVM.aspects.context()
        );

        if (_.get(locationVM, 'publicID.value') != null) {
            const currentLocations = _.get(jobVM.value, `${LOCATION_PATH}`);
            const locationIndex = currentLocations.findIndex(location => location.publicID == locationVM.publicID.value);
            jobVM.lobData.commercialProperty.coverables.locations.value[locationIndex].buildings.push(newVM.value);
            _.set(locationVM, `buildings.value`, _.get(jobVM, `${LOCATION_PATH}.value[${locationIndex}].buildings`));
        } else {
            locationVM.buildings.value.push(newVM.value)
        }
        setShowErrorsBldg(false)
        return newVM;
    }, [jobVM, locationVM, viewModelService]);

    const onDeleteBuilding = useCallback(async (buildingVM) => {
        const response = await showConfirm({
            title: translator(messages.removeBuildingShort),
            message: translator(messages.removeBuildingLong, { displayName: buildingVM.buildingNumber.value })
        })
        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const newSubmission = _.cloneDeep(jobVM.value);
            const currentLocations = _.get(newSubmission, `${LOCATION_PATH}`)
            const locations = currentLocations.find(loc => loc.publicID == buildingVM.locationId.value)
            const locationIndex = currentLocations.findIndex(loc => loc.publicID == buildingVM.locationId.value)
            const buildingIndex = locations.buildings.findIndex(loc => loc.publicID == buildingVM.publicID.value)
            const buildings = locations.buildings
            buildings.splice(buildingIndex, 1)
            _.set(newSubmission, `${LOCATION_PATH}[${locationIndex}].buildings`, buildings);
            _.set(locationVM, 'buildings.value', buildings)
            return saveSubmission(newSubmission)
        }
    }, [jobVM.value, locationVM, saveSubmission, showConfirm, translator]);

    const overrideProps = {
        '@field': {
            parentNode: locationVM,
            readOnly: !isEditing,
            showErrors: showErrorsLocation
        },
        buildingContainer: {
            onValidate,
            showErrors: showErrors,
        },
        buildingListView: {
            clickable: true,
            startOpen: false,
            readOnly: !isEditing,
            editEnabled: true,
            value: _.get(locationVM, `buildings.children`, []),
            VMData: [
                {
                    headerText: translator(messages.number),
                    path: 'buildingNumber'
                },
                {
                    headerText: translator(messages.name),
                    getData: formatBuildingNumberLabel
                },
                {
                    headerText: translator(messages.primaryBuilding),
                    path: 'isPrimary'
                },
            ],
            detailViewComponent: WMICBuildingsDetailView,
            detailViewComponentProps: {
                jobVM: jobVM,
                updateWizardData,
                showErrorsBldg,
                isBoundPolicy
            },
            onValidate,
            toCreate: toCreateClicked,
            toUndoCreate: () => {
                const currentLocations = _.get(jobVM.value, `${LOCATION_PATH}`)
                const locationIndex = currentLocations.findIndex(location => location.publicID == locationVM.publicID.value)
                const buildings = _.get(jobVM.value, `${LOCATION_PATH}[${locationIndex}].buildings`);
                buildings.splice(buildings.length-1, 1);
                _.set(jobVM.value, LOCATION_PATH[locationIndex].buildings, buildings);
                updateWizardData(jobVM);
                setShowErrorsBldg(false)
            },
            onDelete: onDeleteBuilding,
            onSave: onSaveClicked,
        },
        primaryLocation: {
            readOnly: !isEditing,
            showErrors: showErrorsLocation || showErrorsBldg
        },
        locationAddressLookup: {
            authHeader: authHeader,
            stateField: { readOnly: false },
            countryField: { readOnly: true },
            postalCodeRequired: true,
            countryReadOnly: true,
            addressVM : _.get(locationVM, 'address'),
            hideButtons : true,
            hideBorder : true,
            onValidate,
            showErrorsAddlInts: showErrorsLocation || showErrorsBldg,
            addressType: CONSTANTS.ADDRESS_TYPE.POLICY_LOCATION,
            jobNumber: jobVM?.baseData?.jobType?.value?.code === JobType.SUBMISSION ? jobVM?.quoteID?.value : jobVM?.jobID?.value
        },
        locationTitle: {
            title: _.get(locationVM, 'locationNum.value') != null ? `${translator(messages.locationNumber)}${_.get(locationVM, 'locationNum.value')}` : `${translator(messages.locationTitle)}`
        },
        atLeastOneBuildingContainer: {
            visible: _.get(locationVM, 'buildings.length') === 0
        },
        atLeastOneBuildingText: {
            content: WMICRichTextUtil.translateRichText(translator(messages.atLeastOneBuilding)),
        }
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
            WMICListView,
            WMICAddressDetails
        }
    };

    return <ViewModelForm
        uiProps={metadata.componentContent}
        model={locationVM}
        onModelChange={updateModel}
        onValidationChange={onValidate}
        showErrors={showErrors}
        overrideProps={overrideProps}
        componentMap={resolvers.resolveComponentMap}
    />
}

export default WMICLocationDetailView;
