import React, {
    useContext, useCallback, useState, useEffect, useRef, useMemo
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { CurrencyValue } from '@jutro/components';
import { Accordion } from '@jutro/legacy/components';
// eslint-disable-next-line import/no-unresolved
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { ClausesUtil } from '@xengage/gw-policycommon-util-js';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication, withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { WMICScrollToError } from 'wmic-pe-components-platform-react';
import { WMICWizardChangeOrRenewalPage, useDocumentTitle, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICUserAccessUtil } from 'wmic-pe-portals-utils-js';
// eslint-disable-next-line no-unused-vars
import { messages as paymentMessages } from 'gw-capability-policychange-common-react';

import { WMICHOPolicyCoverages, WMICHODwellingCoverages, WMICHOWatercraftCoverages } from 'wmic-pe-capability-gateway-common-ho-react';
import WMICHOCoverageUtil from 'wmic-pe-capability-gateway-common-ho-react/utils/WMICHOCoverageUtil';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import metadata from './CoveragesPage.metadata.json5';
import messages from './CoveragesPage.messages';


const DWELLINGS_PATH = 'lobData.homeowners.coverables.dwellings';
const WATERCRAFT_PATH = 'lobData.homeowners.coverables.watercrafts';

const structureCustomQuote = (policyChangeVM, affectedQuote, clauses) => {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        quote: affectedQuote,
        quoteID: policyChangeVM.jobID.value,
        sessionUUID: policyChangeVM.sessionUUID.value,
        periodStart: policyChangeVM.baseData.periodStartDate.value,
        periodEnd: policyChangeVM.baseData.periodEndDate.value,
        coverages: clauses.homeowners
    };
};

const getCustomQuote = (vm, lobPath, quotePath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    const quoteOffering = _.get(vm, `${quotePath}.value`);

    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };

    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }

    return structureCustomQuote(vm, quoteOffering, clausesToUpdate);
};

const generateColumnData = (policyChangeVM) => {
    const lobOfferingPath = 'lobData.homeowners.offerings';
    const quoteOfferingPath = 'quoteData.offeredQuotes';

    const lobOfferings = _.get(policyChangeVM, `${lobOfferingPath}.value`);
    const quoteOfferings = _.get(policyChangeVM, `${quoteOfferingPath}.value`) || [];

    return lobOfferings.map((lobOffering, lobIndex) => {
        const quoteDataIndex = quoteOfferings.findIndex(
            (qdOffering) => qdOffering.branchCode === lobOffering.branchCode
        );
        const quoteData = quoteOfferings[quoteDataIndex];
        return {
            name: lobOffering.branchName,
            code: lobOffering.branchCode,
            quote: {
                path: `${quoteOfferingPath}.children[${quoteDataIndex}]`,
                data: quoteData
            },
            lob: {
                path: `${lobOfferingPath}.children[${lobIndex}]`,
                data: lobOffering
            }
        };
    }).filter(({ quote }) => !_.isUndefined(quote.data));
};

const getSelectedRiskId = (policyChangeVM, basePath) => {
    const riskPath = basePath.substring(0, basePath.indexOf('.coverages.children['));
    const riskVM = _.get(policyChangeVM, `${riskPath}.value`);
    return _.get(riskVM, 'publicID');
}

function CoveragesPage(props) {
    const [staleQuoteBranchCode, setStaleQuoteBranchCode] = useState(undefined);
    const [loadingClause, updateLoadingClause] = useState();
    const translator = useContext(TranslatorContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const {
        wizardData: policyChangeVM,
        updateWizardData,
        underwritingIssues
    } = props;
    const { authUserData: currentUser, authHeader } = useAuthentication();
    const [showErrors, setShowErrors] = useState(false);
    const { setWizardLoading } = useWizardModals();
    const [scrollToError, setScrollToError] = useState();
    const { onValidate, disregardFieldValidation, registerComponentValidation, initialValidation, isComponentValid } = useValidation('CoveragesPage');
    const viewModelService = useContext(ViewModelServiceContext);
    const { CustomQuoteService } = useDependencies('CustomQuoteService');

    const [initialPolicyChangeVM, setInitialPolicyChangeVM] = useState(undefined);
    const initialized = useRef(false);

    const canEditPolicyChange = useMemo(() => WMICUserAccessUtil.canEditPolicyChange(currentUser.permission_Ext), [currentUser.permission_Ext]);

    useDocumentTitle(translator(messages.coveragesTitle), policyChangeVM);

    useEffect(() => {
        setInitialPolicyChangeVM(viewModelService.clone(policyChangeVM));
        initialized.current = true;
        // Store the initial PolicyChangeVM when component is mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const writeValue = (value, path) => {
        _.set(policyChangeVM, path, value);
        updateWizardData(policyChangeVM);
    }

    const getNewPolicyChangeVM = (response, lobPath, quotePath, lobName) => {
        const clonedPolicyChangeVM = viewModelService.clone(policyChangeVM);
        const updatedClauses = _.get(response, `lobData.${lobName}.offerings[0].coverages`);
        // Update local offering with new one from xcenter
        _.set(clonedPolicyChangeVM, `${lobPath}.coverages`, updatedClauses);
        // Update local quote with new one from xcenter
        const status = _.get(response, 'quote.status', 'Draft');
        _.set(clonedPolicyChangeVM, `${quotePath}.status`, status);
        // Update premium with new one from xcenter
        _.set(clonedPolicyChangeVM, `${quotePath}.premium`, _.get(response, 'quoteData.offeredQuotes[0].premium'));
        // Update local errorsAndWarnings with new one from xcenter
        _.set(clonedPolicyChangeVM, 'errorsAndWarnings', response.errorsAndWarnings);
        return clonedPolicyChangeVM;
    }

    const onUpdateCustomQuote = (_basePath, lobPath, quotePath) => {
        const lobName = ClausesUtil.getLobNameFromPath(lobPath);
        const customQuote = getCustomQuote(policyChangeVM, lobPath, quotePath, lobName, true);
        return EndorsementService.updateCoveragesAndPolicyChangeData(
            [],
            { homeowners: customQuote.coverages },
            policyChangeVM.value,
            authHeader
        ).then((response) => {
            const riskID = getSelectedRiskId(policyChangeVM, _basePath);
            const dwellingCoverageIndex = _.get(policyChangeVM, `${lobPath}.coverages.dwellingCoverages.value`).findIndex((dwellingCoverage) => dwellingCoverage.publicID === riskID);
            const watercraftCoverageIndex = _.get(policyChangeVM, `${lobPath}.coverages.watercraftCoverages.value`).findIndex((watercraftCoverage) => watercraftCoverage.publicID === riskID);
            const newPolicyChangeVM = viewModelService.clone(policyChangeVM);
            newPolicyChangeVM.value = response;

            const removedFieldsFromLineCoverages = ClausesUtil.getRemovedClausesID(
                policyChangeVM,
                newPolicyChangeVM,
                `${lobPath}.coverages.lineCoverages`
            );
            const removedFieldsFromDwellingCoverages = dwellingCoverageIndex >= 0 ? ClausesUtil.getRemovedClausesID(
                policyChangeVM,
                newPolicyChangeVM,
                `${lobPath}.coverages.dwellingCoverages.children[${dwellingCoverageIndex}].coverages`
            ): [];
            const removedFieldsFromWatercraftCoverages = watercraftCoverageIndex >= 0 ?
                ClausesUtil.getRemovedClausesID(
                    policyChangeVM,
                    newPolicyChangeVM,
                    `${lobPath}.coverages.watercraftCoverages.children[${watercraftCoverageIndex}].coverages`
                ): []

            const allRemovedFields = [
                ...removedFieldsFromLineCoverages,
                ...removedFieldsFromDwellingCoverages,
                ...removedFieldsFromWatercraftCoverages
            ];
            disregardFieldValidation(allRemovedFields);
            updateWizardData(newPolicyChangeVM);
            updateLoadingClause(undefined);
        });
    };

    const changeSubmission = (value, changedPath) => {
        updateWizardData(ClausesUtil.setClauseValue(policyChangeVM, value, changedPath));
    }

    const syncCoverages = (value, changedPath, lobPath, quotePath) => {
        const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
        return onUpdateCustomQuote(basePath, lobPath, quotePath);
    }

    const changeSubmissionAndSync = (value, changedPath, lobPath, quotePath) => {
        changeSubmission(value, changedPath);
        return syncCoverages(value, changedPath, lobPath, quotePath);
    }

    const onNext = useCallback(async () => {
        try {
            if (!isComponentValid) {
                setShowErrors(true);
                setScrollToError(Date.now());
                return false;
            }

            setWizardLoading(true);
            const newPolicyChange = _.cloneDeep(policyChangeVM.value);
            policyChangeVM.value = await EndorsementService.saveEndorsement(
                [newPolicyChange],
                authHeader
            );

            return policyChangeVM;
        } finally {
            setWizardLoading(false);
        }
    }, [EndorsementService, authHeader, isComponentValid, policyChangeVM, setWizardLoading]);

    const convertCurrencyFormat = (basePremium) => {
        if (basePremium) {
            return (
                <div>
                    <span>{translator(messages.mainCoverages)}</span>
                    <span> : </span>
                    <CurrencyValue amount={basePremium.amount} currency={basePremium.currency} showFractions />
                </div>
            );
        }

        return (
            <div>
                <span>{translator(messages.mainCoverages)}</span>
            </div>
        )
    };
    const columnData = generateColumnData(policyChangeVM);

    const onScheduleChange = (schedule, path) => {
        const lobOfferingPath = 'lobData.homeowners.offerings.children[0]';
        const quoteOfferingPath = 'quoteData.offeredQuotes.children[0]';
        writeValue(schedule, `${path}.value`);
        const offering = _.get(policyChangeVM, `${lobOfferingPath}.value`);
        setStaleQuoteBranchCode(offering.branchCode);
        return onUpdateCustomQuote({}, lobOfferingPath, quoteOfferingPath);
    }

    const recalculate = (lobPath, quotePath) => {
        const lobName = ClausesUtil.getLobNameFromPath(lobPath);
        const customQuote = getCustomQuote(policyChangeVM, lobPath, quotePath, lobName);

        return CustomQuoteService.updateCustomQuote(customQuote, authHeader).then(
            (response) => {
                setStaleQuoteBranchCode(undefined);
                const newPolicyChangeVM = getNewPolicyChangeVM(
                    response,
                    lobPath,
                    quotePath,
                    lobName
                );
                updateWizardData(newPolicyChangeVM);
                return response;
            }
        );
    }

    const resetQuote = (lobPath, quotePath) => {
        const lobName = ClausesUtil.getLobNameFromPath(lobPath);
        const customQuote = getCustomQuote(initialPolicyChangeVM, lobPath, quotePath, lobName);

        return CustomQuoteService.forceUpdateCustomQuoteCoverages(customQuote, authHeader).then(
            (response) => {
                const newPolicyChangeVM = getNewPolicyChangeVM(
                    response,
                    lobPath,
                    quotePath,
                    lobName
                );
                updateWizardData(newPolicyChangeVM);
            }
        );
    }

    const onStaleQuoteBranchCode = useCallback(() => {
        return staleQuoteBranchCode;
    }, [staleQuoteBranchCode]);

    const commonOverrides = {
        'coverageColumnData': columnData,
        'quoteID': policyChangeVM.jobID.value,
        'changeSubmissionAndSync': changeSubmissionAndSync,
        'changeSubmission': changeSubmission,
        'syncCoverages': syncCoverages,
        'recalculate': recalculate,
        'resetQuote': resetQuote,
        'onStaleQuoteBranchCode': onStaleQuoteBranchCode,
        'underwritingIssues': underwritingIssues,
        'onValidate': onValidate,
        'readOnly': !canEditPolicyChange
    }

    const _iterableProps = () => {
        const dwellingOverrides = _.get(policyChangeVM, `${DWELLINGS_PATH}.children`).map((dwelling, index) => {
            const dwellingCoverages = WMICHOCoverageUtil.getDwellingCoveragesForRisk(_.get(policyChangeVM, 'lobData.homeowners'), dwelling.publicID.value);
            const dwellingOverride = {
                [`dwellingCoveragesIterableComponent${index}`]: {
                    'dwellingVM': dwelling,
                    'dwellingCoverages': dwellingCoverages,
                    'dwellingIndex': index,
                    'baseData': policyChangeVM.baseData,
                    jobVM: policyChangeVM,
                    ...commonOverrides
                }
            }

            return Object.assign({}, dwellingOverride);
        })

        const watercraftOverrides = _.get(policyChangeVM, `${WATERCRAFT_PATH}.children`).map((watercraft, index) => {
            const watercraftCoverages = WMICHOCoverageUtil.getWatercraftCoveragesForRisk(_.get(policyChangeVM, 'lobData.homeowners'), watercraft.publicID.value);
            const watercraftOverride = {
                [`watercraftCoveragesIterableComponent${index}`]: {
                    'watercraftVM': watercraft,
                    'watercraftCoverages': watercraftCoverages,
                    'watercraftIndex': index,
                    ...commonOverrides
                }
            }
            return Object.assign({}, watercraftOverride);
        })

        return Object.assign({}, ...dwellingOverrides, ...watercraftOverrides);
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            phoneWide: {
                labelPosition: 'top'
            }
        },
        dwellingCoveragesIterableAccordionContainer: {
            data: _.get(policyChangeVM, `${DWELLINGS_PATH}.children`)
        },
        watercraftCoveragesIterableAccordionContainer: {
            visible: (_.get(policyChangeVM, `${WATERCRAFT_PATH}.children`, []).length > 0),
            data: _.get(policyChangeVM, `${WATERCRAFT_PATH}.children`)
        },
        hoPolicyChangeBaseCoveragesId: {
            loadingClause,
            categoryDisplayName: convertCurrencyFormat(
                _.get(policyChangeVM.value, 'lobData.homeowners.offerings[0].coverages.basePremium')
            )
        },
        hoPolicyChangeAdditionalCoveragesId: {
            loadingClause,
            categoryDisplayName: translator(messages.additionalCoverages)
        },
        policyCoveragesContainer: {
            policyChangeVM,
            lineCoverages: _.get(policyChangeVM, 'lobData.homeowners.offerings.children[0].coverages.lineCoverages'),
            ...commonOverrides
        },
        ..._iterableProps()
    };

    const resolvers = {
        resolveCallbackMap: {
            onRecalculate: recalculate,
            onResetQuote: resetQuote,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onSyncCoverages: syncCoverages,
            onScheduleChange,
            onStaleQuoteBranchCode,
            onChangeClause: changeSubmission,
            onPrint: window.print,
            onValidate
        },
        resolveComponentMap: {
            WMICHODwellingCoverages,
            WMICHOPolicyCoverages,
            WMICHOWatercraftCoverages,
            Accordion
        }

    };
    const isPageLoading = useCallback(() => _.isUndefined(loadingClause), [loadingClause]);

    useEffect(() => {
        registerComponentValidation(isPageLoading);
    }, [isPageLoading, registerComponentValidation]);

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <WMICScrollToError counter={scrollToError}/>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

CoveragesPage.propTypes = wizardProps;

export default withAuthenticationContext(CoveragesPage);
