/* eslint-disable camelcase */
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { DropdownMenuButton } from '@jutro/components';
import { withViewModelService, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps, WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';

import { WMICRequiredText } from 'wmic-pe-components-platform-react';
import { WMICVehiclesDetailView } from 'wmic-pe-capability-gateway-common-pa-react'
import { useDocumentTitle, useWizardModals, WMICWizardChangeOrRenewalPage } from 'wmic-pe-portals-wizard-components-ui';
import {
    CONSTANTS,
    JURISDICTIONS,
    PAConstants,
    WMICLogger,
    ACTION_TYPES,
    WMICUserAccessUtil,
    WMICVariousUtil,
    WMICValidationUtil,
    WMICVehicleUtil,
    MODAL_CONSTANTS
} from 'wmic-pe-portals-utils-js';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import { EntityUtil } from '@xengage/gw-portals-util-js'

import { messages as policyChangeCommonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import metadata from './WMICPCPAVehiclesPage.metadata.json5';
import messages from './WMICPCPAVehiclesPage.messages';

import { DropdownMenuLink } from '@jutro/router';

function WMICPCPAVehiclesPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;

    const translator = useContext(TranslatorContext);
    const { authHeader, authUserData } = useAuthentication();
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        registerInitialComponentValidation
    } = useValidation('WMICPCPAVehiclesPage');
    const viewModelService = useContext(ViewModelServiceContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const { setWizardLoading } = useWizardModals();
    const { showInfo, showConfirm, showWarning, showError } = useWizardModals();

    const [showErrors, setShowErrors] = useState(false);
    const [vehicleAction, setVehicleAction] = useState(ACTION_TYPES.NONE);
    const [additionalNamedInsureds, updateAdditionalNamedInsureds] = useState([]);
    const [previousAdditionalNamedInsureds, updatePreviousAdditionalNamedInsureds] = useState([]);
    const [primaryNamedInsured, updatePrimaryNamedInsured] = useState({});
    const [drivers, updateDrivers] = useState([]);
    const [skeletonStruct, setSkeletonStruct] = useState({});
    const [vehicleTypes, updateVehicleTypes] = useState([]);

    const VEHICLES_PATH = 'lobData.personalAuto.coverables.vehicles';
    const DRIVERS_PATH = 'lobData.personalAuto.coverables.drivers';
    const LOB_PATH = 'lobData.personalAuto';
    const BASEDATA = 'baseData';

    useDocumentTitle(translator(messages.vehiclesTitle), policyChangeVM);

    const vehicleVMList = _.get(policyChangeVM, `${VEHICLES_PATH}.children`, []);

    const { maintainFurtherStepsVisitedSubmitted, setIsCustomStepsInvalidated } = useContext(WizardContext);

    const noVehicles = () => {
        return _.isEmpty(_.get(policyChangeVM, `${VEHICLES_PATH}.value`, []))
    }

    const initVehicleData = useCallback(async () => {
        const lobData = _.get(policyChangeVM, LOB_PATH);
        const driversTmp = _.get(policyChangeVM, DRIVERS_PATH);
        const baseData = _.get(policyChangeVM, BASEDATA);

        const additionalNameInsuredsSub = _.get(baseData, `additionalNamedInsureds_WMIC.value`);
        const additionalNameInsuredsTmp = [];

        const skeletonStructure = await EndorsementService.getSkeletonStructure(
            policyChangeVM.value,
            authHeader
        );

        const vehicleStruct = _.get(skeletonStructure, VEHICLES_PATH)
        setSkeletonStruct(vehicleStruct);

        if (additionalNameInsuredsSub && !_.isEmpty(additionalNameInsuredsSub)) {
            additionalNameInsuredsSub.forEach((nameInsured) => {
                const additionalNameInsured = viewModelService.create(
                    nameInsured,
                    'pc',
                    'wmic.edge.ca.capabilities.policyjob.draft.dto.AdditionalNamedInsuredDTO_WMIC'
                );
                _.set(additionalNameInsured, 'isDriver', false);
                if (_.get(additionalNameInsured, 'subtype.value')
                    && _.isEqual(_.get(additionalNameInsured, 'subtype.value.code'), CONSTANTS.Person)) {
                    const isDriver = driversTmp.value.some((driver) => _.isEqual(
                            _.get(additionalNameInsured, 'contactPublicID.value'),
                            _.get(driver, 'person.publicID.value')
                        ));
                    _.set(additionalNameInsured, 'isDriver', isDriver);
                }
                additionalNameInsuredsTmp.push(additionalNameInsured);
                updateAdditionalNamedInsureds(additionalNameInsuredsTmp);
            });
        }

        const primaryNamedInsuredTmp = _.get(baseData, 'primaryNamedInsured_WMIC');
        _.set(primaryNamedInsuredTmp, 'relationshipToPrimaryInsured_WMIC', 'insured');
        _.set(primaryNamedInsuredTmp, 'isDriver', false);
        const isDriver = driversTmp.value.some((driver) => _.isEqual(
                _.get(primaryNamedInsuredTmp, 'contactPublicID'),
                _.get(driver, 'person.publicID')
            ));
        _.set(primaryNamedInsuredTmp, 'isDriver', isDriver);
        updatePrimaryNamedInsured(primaryNamedInsuredTmp);
        updatePreviousAdditionalNamedInsureds(_.get(lobData, 'updatePreviousAdditionalNamedInsureds.value'));
        const driversFiltered = _.filter(driversTmp.value, (driver) => driver.assignment_WMIC === CONSTANTS.ASSIGNMENT.ASSIGNED);
        updateDrivers(driversFiltered);
    }, [EndorsementService, authHeader, policyChangeVM, viewModelService]);

    useEffect(() => {
        setWizardLoading(true);
        const typelist = viewModelService.productMetadata.get('pc').types
            .getTypelist('VehicleType')
            .getCodesForCategory(
                {
                    code: CONSTANTS.COUNTRY.CA, typelist: { name: 'Country' }
                }
            );
        updateVehicleTypes(typelist);
        initVehicleData();

        const vehicles = _.get(policyChangeVM, VEHICLES_PATH)

        vehicles.forEach((vehicle, index) => {
            _.unset(policyChangeVM, `lobData.personalAuto.coverables.vehicles.value[${index}].flexDTO`);
        })

        setWizardLoading(false);

        registerInitialComponentValidation(() => !noVehicles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const canEditPolicyChange = useMemo(() => WMICUserAccessUtil.canEditPolicyChange(authUserData.permission_Ext), [authUserData.permission_Ext]);

    const ensureUniqueVin = useCallback((vehicle) => {
        const vehicleMatch = _.filter(vehicleVMList, { vin: { value: vehicle.vin } });
        if (!vehicleMatch) {
            return undefined;
        }
        if (vehicleMatch.length === 1) {
            return !_.isEqual(vehicleMatch[0].value, vehicle) ? vehicleMatch[0] : undefined;
        }
        return _.find(vehicleMatch, (v) => v !== vehicle);
    }, [vehicleVMList]);

    const getVehicleDescription = (vehicle) => (`${vehicle.year ? `${vehicle.year} ` : ''}
            ${vehicle.make ? `${vehicle.make} ` : ''}
            ${vehicle.model ? `${vehicle.model} ` : ''}`)

    const isValid = useCallback((selectedVehicleVM) => {
        const vinMatchedVehicle = ensureUniqueVin(selectedVehicleVM.value);
        if (vinMatchedVehicle) {
            showInfo({
                title: messages.vinIsNotUnique,
                message: translator(messages.vinMatchMsg,
                    { vehicle: getVehicleDescription(vinMatchedVehicle.value) })
            })
            return false;
        }
        
        const isVinSearchRequired = WMICVehicleUtil.vinSearchRequired(selectedVehicleVM.value);
        if (isVinSearchRequired) {
            showWarning({
                title: messages.actionRequired,
                message: messages.vinSearchRequired,
                icon: MODAL_CONSTANTS.ICON.WARNING,
                confirmButtonText: messages.close,
            })
            .then(() => WMICVariousUtil.scrollToId('vehicleInfoAccordion'));
            return false;
        }

        return true;
    }, [ensureUniqueVin, translator, showInfo, showWarning]);

    const checkAndReportVehicleTypesCombinationValidity = useCallback(() => {
        const ontarioVehicles = policyChangeVM.baseData
            && policyChangeVM.baseData.lobData
            && policyChangeVM.baseData.lobData.value
            && policyChangeVM.baseData.lobData.value.personalAuto
            && policyChangeVM.baseData.lobData.value.personalAuto.coverables.vehicles
            && policyChangeVM.baseData.lobData.value.personalAuto.coverables.vehicles.filter(
                (vehicle) => vehicle.garageLocation && vehicle.garageLocation.state === JURISDICTIONS.ONTARIO);
        if (ontarioVehicles && ontarioVehicles.length > 1) {
            const hasAuto = ontarioVehicles.some((vehicle) => vehicle.vehicleType === PAConstants.personalAutoVehicleType);
            const hasMotorcycle = ontarioVehicles.some((vehicle) => vehicle.vehicleType === PAConstants.motorcycleVehicleType);
            const hasSnowVehicle = ontarioVehicles.some((vehicle) => vehicle.vehicleType === PAConstants.snowVehicleType);
            let errorMessage = '';
            if (hasAuto) {
                if (hasSnowVehicle) {
                    errorMessage = translator(messages.snowVehicleCombinationError);
                }
                if (hasMotorcycle) {
                    errorMessage = hasSnowVehicle
                        ? translator(messages.snowMotorcycleVehicleCombinationError)
                        : translator(messages.motorcycleVehicleCombinationError);
                }
            } else if (hasMotorcycle && hasSnowVehicle) {
                errorMessage = translator(messages.snowMotorcycleVehicleCombination);
            }
            if (errorMessage !== '') {
                showError({
                    title: translator(messages.errorVehicleCombinationError),
                    message: errorMessage,
                    status: CONSTANTS.ERROR,
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            }
        }
        return true;
    }, [policyChangeVM.baseData, translator, showError]);

    const createVehicle = (person, skeletonStructureCreateVehicle) => {
        const vehicle = {
            tempID: EntityUtil.nextId(),
            vinOverride_WMIC: false,
            leaseOrRent: false,
            rateAtPrimaryLocation_WMIC: true,
            addAdditionalInterests_WMIC: false,
            mileageUnit_WMIC: 'KM',
            vehicleInspectionRequired_WMIC: false,
            drivers: [],
            registeredOwners_WMIC: [],
            commercialVehicle_WMIC: {},
            msrp_WMIC: null,
            costNew: null
        };

        if (skeletonStructureCreateVehicle.length && skeletonStructureCreateVehicle[0].metaDataMap) {
            vehicle.metaDataMap = skeletonStructureCreateVehicle[0].metaDataMap;
        }

        if (person && person.primaryAddress) {
            vehicle.licenseState = person.primaryAddress.state;
        }
        return vehicle;
    };

    const initializeVehicleValues = (vehicle, isUnderEditing, existingVehicle) => {
        _.set(vehicle, 'isUnderEditing', { value: isUnderEditing });
        _.set(vehicle, 'isAddingAdditionalInterest', { value: false });
        _.set(vehicle, 'isAddingCustomization', { value: false });
        _.set(vehicle, 'isAddingGarageLocation', { value: false });
        _.set(vehicle, 'isAddingPrimaryLocation', { value: false });
        _.set(vehicle, 'isAddingDriver', { value: false });
        _.set(vehicle, 'searchCompleted', { value: existingVehicle });
    };

    const createVehicleAddress = () => ({
            country: CONSTANTS.COUNTRY.CA,
            addressType: CONSTANTS.MAILING_ADDRESS
        })

    const onEditVehicle = useCallback(() => {
        _.set(policyChangeVM, 'isEditingPage.value', true);
        setVehicleAction(ACTION_TYPES.EDIT);
    }, [policyChangeVM]);

    const onCreateVehicle = useCallback(async(vehicleType) => {
        if (maintainFurtherStepsVisitedSubmitted.flag) {
            maintainFurtherStepsVisitedSubmitted.flag = false;
            setIsCustomStepsInvalidated(true);
        }
        updateWizardData(policyChangeVM);

        const accountHolder = _.get(policyChangeVM, 'baseData.accountHolder.value');
        const vehicle = createVehicle(accountHolder, skeletonStruct);
        _.set(vehicle, 'vehicleType', vehicleType);
        let vehicleAddress = createVehicleAddress();
        const accountAddress = _.get(policyChangeVM, "baseData.accountAddresses_WMIC.value[0]"); // vehicle usage component does not set this address properly
        if (accountAddress) {
            vehicleAddress = accountAddress;
        }

        _.set(vehicle, 'primaryLocation_WMIC', vehicleAddress);
        _.set(vehicle, 'garageLocation', vehicleAddress);
        _.set(vehicle, 'ratingJurisdiction', _.get(policyChangeVM, 'baseData.baseState.value.code'));
        _.set(vehicle, 'businessSegment_WMIC', CONSTANTS.BUSINESS_SEGMENT_CODES.PERSONAL);

        const vehiclesVM = _.get(policyChangeVM, VEHICLES_PATH);
        const { _xCenter, _dtoName } = vehiclesVM;
        const _vehicleVM = viewModelService.create(vehicle, _xCenter, _dtoName);


        initializeVehicleValues(_vehicleVM, true, false);
        policyChangeVM.lobData.personalAuto.coverables.vehicles.value.push(_vehicleVM.value);

        await WMICVehicleUtil.updateVehicleList(policyChangeVM, EndorsementService, authHeader);

        setVehicleAction(ACTION_TYPES.ADD);
        return _vehicleVM;
    }, [maintainFurtherStepsVisitedSubmitted, policyChangeVM, setIsCustomStepsInvalidated, skeletonStruct, updateWizardData, viewModelService]);

    const saveVehicle = useCallback(async (newPolicyChangeVM) => {
        try {
            setWizardLoading(true, translator(messages.savingVehicle));
            _.unset(newPolicyChangeVM.value, 'bindData');

            const result = await EndorsementService.saveEndorsement(
                [newPolicyChangeVM],
                authHeader
            );
            _.extend(policyChangeVM.value, result);
            updateWizardData(policyChangeVM);
            setShowErrors(false);
            return true;
        } catch (error) {
            WMICLogger.error(translator(messages.saveVehicleFailed), error);
            return false;
        } finally {
            _.set(policyChangeVM, 'isEditingPage.value', false);
            setWizardLoading(false);
        };

    }, [EndorsementService, setWizardLoading, authHeader, updateWizardData, translator, policyChangeVM])

    const onSaveVehicle = useCallback((vehicleVM) => {
        const valid = isValid(vehicleVM);
        if (!valid || !checkAndReportVehicleTypesCombinationValidity()) {
            setShowErrors(true);
            return false;
        }

        return saveVehicle(policyChangeVM.value)
    }, [isValid, checkAndReportVehicleTypesCombinationValidity, policyChangeVM, saveVehicle]);

    const onDeleteVehicle = useCallback((vehicle, index) => {
        showConfirm({
            title: translator(messages.removeVehicleTitle),
            message: translator(messages.removeVehicle, { vehicle: getVehicleDescription(vehicle.value) })
        })
            .then((response) => {
                if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
                    policyChangeVM.value.lobData.personalAuto.coverables.vehicles.splice(index, 1);
                    return saveVehicle(policyChangeVM.value);
                }
                return false;
            });
    }, [saveVehicle, showConfirm, policyChangeVM, translator]);

    const getVehicleDescriptionCell = (item) => {
        const vehicle = item;
        return (
            <span>
                {getVehicleDescription(vehicle)}
                <div>
                    {_.get(vehicle, 'vin')}
                </div>
            </span>
        );
    };

    const renderAddVehicleButton = useCallback(({ isEditing, onClick }) => (
        <DropdownMenuButton
            id="addNewAdditionalInterestButton"
            buttonText={translator(messages.newVehicle)}
            icon="mi-add"
            disabled={isEditing} >
            {vehicleTypes.map((vehicleType, index) => (
                <DropdownMenuLink onClick={() => onClick(vehicleType.code, index)}>
                    {translator({
                        id: vehicleType.name,
                        defaultMessage: vehicleType.code
                    })}
                </DropdownMenuLink>
            ))}
        </DropdownMenuButton>
    ), [translator, vehicleTypes]);

    const onNext = useCallback(async () => {
        if (!isComponentValid) {
            setShowErrors(true);
            return false;
        }

        if (canEditPolicyChange) {
            if (!checkAndReportVehicleTypesCombinationValidity()) {
                return false;
            }
        } else {
            return false;
        }
        try {
            setWizardLoading(true);

            const result = await EndorsementService.saveEndorsement(
                [policyChangeVM.value],
                authHeader
            );

            _.extend(policyChangeVM.value, result);
            return policyChangeVM;
        } finally {
            setWizardLoading(false);
        }
    }, [isComponentValid, canEditPolicyChange, checkAndReportVehicleTypesCombinationValidity, setWizardLoading, policyChangeVM, EndorsementService, authHeader]);

    const onCancelVehicle = () => {
        _.set(policyChangeVM, 'isEditingPage.value', false);
        updateWizardData(policyChangeVM);
    }

    const overrideProps = {
        vehiclesListView: {
            value: vehicleVMList,
            clickable: true,
            onSave: onSaveVehicle,
            onDelete: onDeleteVehicle,
            readOnly: !canEditPolicyChange,
            toCreate: onCreateVehicle,
            toEdit: onEditVehicle,
            toUndoCreate: () => {
                // to undo new vehicle creation, simply remove the last vehicle in the array (it's always the one being "added")
                const vehicles = _.get(policyChangeVM.value, VEHICLES_PATH);
                vehicles.splice(vehicles.length - 1, 1);
                _.set(policyChangeVM.value, VEHICLES_PATH, vehicles);
                _.set(policyChangeVM, 'isEditingPage.value', false);
                updateWizardData(policyChangeVM);
            },
            onCancel: onCancelVehicle,
            showCancelModal: true,
            detailViewComponent: WMICVehiclesDetailView,
            onValidate,
            showErrors,
            startOpen: true,
            detailViewComponentProps: {
                jobVM: policyChangeVM,
                updateWizardData,
                vehicleAction,
                additionalNamedInsureds,
                previousAdditionalNamedInsureds,
                primaryNamedInsured,
                drivers,
                isReadOnlyUser: !canEditPolicyChange,
                skeletonStruct
            },
            renderAddButton: renderAddVehicleButton,
            VMData: [
                {
                    headerText: translator(messages.vehicleNumber),
                    path: 'vehicleNumber_WMIC'
                },
                {
                    headerText: translator(messages.theftIdentifier),
                    path: 'theftIdentifier_Ext',
                    visibilityCondition: item => item?.theftIdentifier_Ext?.aspects?.ocular
                },
                {
                    headerText: translator(messages.vehicleType),
                    path: 'vehicleType'
                },
                {
                    headerText: translator(messages.vehicleDescription),
                    getData: (item) => getVehicleDescriptionCell(item.value)
                },
                {
                    headerText: translator(messages.vehicleBusinessSegment),
                    path: 'businessSegment_WMIC'
                }
            ]
        },
    };

    const resolvers = {
        resolveCallbackMap: {
            onAddVehicleClick: onCreateVehicle
        },
        resolveComponentMap: {
            WMICRequiredText,
        }
    };

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(policyChangeCommonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyChangeVM}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                callbackMap={resolvers.resolveCallbackMap}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICPCPAVehiclesPage.propTypes = wizardProps;

export default withViewModelService(WMICPCPAVehiclesPage);
