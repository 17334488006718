import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { TranslatorContext } from '@jutro/locale';
import WMICHOCoverageUtil from "wmic-pe-capability-gateway-common-ho-react/utils/WMICHOCoverageUtil";
import WMICIterableDwellingComponent from '../WMICIterableDwellingComponent/WMICIterableDwellingComponent';
import WMICIterableWatercraftComponent from '../WMICIterableDwellingComponent/WMICIterableWatercraftComponent'
import WMICScheduledCoverages from '../WMICScheduledCoverages/WMICScheduledCoverages';
import metadata from './WMICDwellingInfo.metadata.json5';
import messages from './WMICDwellingInfo.messages';


function WMICDwellingInfo(props) {
    const { homeOwnersData, isPolicyChangeOrRenewal, jobVM } = props;
    const translator = useContext(TranslatorContext);
    
    if (_.isEmpty(homeOwnersData)) {
        return null;
    }

    const getRiskOverrides = (riskType) => _.get(homeOwnersData.coverables, `${riskType}s.children`, []).map((risk, index) => {
            const coverable = _.get(homeOwnersData.coverables, `${riskType}s.children`)[index];
            const allCoverages = _.get(homeOwnersData.offerings.children[0].coverages, `${riskType}Coverages.children`);
            const coverages = WMICHOCoverageUtil.getAssociatedCoveragesForRisk(allCoverages, coverable.publicID.value);

            const riskOverride = {
                [`${riskType}IterableComponent${index}`]: {
                    [`${riskType}`]: coverable,
                    [`${riskType}Coverages`]: coverages,
                    isPolicyChangeOrRenewal, 
                    jobVM
                }
            }

            return Object.assign({}, riskOverride);
        })

    const getCategorizedPolicyCoverages = () => {
        const rawLineCoverages =  WMICHOCoverageUtil.getLineCoverages(homeOwnersData);
        
        return WMICHOCoverageUtil.categorizeLineCoverages(rawLineCoverages, true);
    }

    const policyCoverages = getCategorizedPolicyCoverages();

    const _iterableProps = () => {
        const dwellingOverrides = getRiskOverrides('dwelling');
        const watercraftOverrides = getRiskOverrides('watercraft');
        
        return Object.assign({}, ...dwellingOverrides, ...watercraftOverrides);
    }

    const overrides = {
        dwellingTableIterableContainer: {
            data: homeOwnersData.coverables.dwellings.children
        },
        watercraftTableIterableContainer: {
            data: homeOwnersData.coverables.watercrafts.children
        },
        watercraftContainer: {
            visible: homeOwnersData.coverables.watercrafts.children.length > 0,
        },
        optionalLiabilityCoverages : {
            coverageList: _.get(policyCoverages, 'optionalLiabilityCoverages.coverages'),
            coverageHeaderText: translator(messages.optionalLiabilityCoverages),
            isPolicyChangeOrRenewal, 
            coverageComparisons: _.get(jobVM, 'value.lineLevelCoverageComparisons'),
            jobVM
        },
        optionalPropertyCoverages : {
            coverageList: _.get(policyCoverages, 'optionalPropertyCoverages.coverages'),
            coverageHeaderText: translator(messages.optionalPropertyCoverages),
            isPolicyChangeOrRenewal,
            coverageComparisons: _.get(jobVM, 'value.lineLevelCoverageComparisons'), 
            jobVM
        },
        scheduledCoveragesContainer: {
            scheduledItems: isPolicyChangeOrRenewal ? _.get(jobVM, 'scheduledItems') : _.get(homeOwnersData, 'coverables.scheduledItems'),
            scheduledItemsAdjustments: _.get(homeOwnersData, 'coverables.scheduledItemsAdjustments'),
            isPolicyChangeOrRenewal, 
            jobVM
        },
        ..._iterableProps()
    };

    const resolvers = {
        resolveComponentMap: {
            WMICIterableDwellingComponent,
            WMICIterableWatercraftComponent,
            WMICScheduledCoverages
        }
    };

    return (
        <ViewModelForm
            model={homeOwnersData}
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

WMICDwellingInfo.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICDwellingInfo;
