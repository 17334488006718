const MAX_DAYS_IN_PAST = 30;
const DATE_FORMAT = 'MM/DD/YYYY';

const VEHICLE_TYPES = {
    auto: 'Private Passenger',
    trailer: 'Trailer',
    motorhome: 'Motorhome',
    motorcycle: 'Motorcycle',
    camper: 'Camper',
    lighttruck: 'Light Truck',
    truckvan: 'Truck/Van',
    atv: 'All Terrain Vehicle',
    snow: 'Snow Vehicle',
    moped: 'Moped/Scooter'
};

const VEHICLE_TYPES_ALLOWS_TO_REASSIGN = ['auto', 'lighttruck', 'truckvan', 'motorhome', 'camper', 'trailer'];

const POLICY_ATTACHMENTS = ['trailer', 'camper', 'motorhome'];
const POLICY_ATTACHMENTS_NONCAMPER = ['trailer', 'motorhome'];
const POLICY_ATTACHMENTS_DRIVERLESS = ['trailer', 'camper'];

const PERMITTED_FUTURE_POLICY_CHANGES = ['Renewal'];

const STEPS = {
    PICK_VEHICLE: 'pickVehicle',
    INITIAL: 'initial',
    EFFECTIVE_DATE: 'effectiveDate',
    VEHICLE_USING: 'vehicleUsing',
    ASSIGN_PRIMARY_DRIVERS: 'assignPrimaryDrivers',
    ASSIGN_OCCASIONAL_DRIVERS: 'assignOccasionalDrivers',
    ERROR: 'error',
};

const STATE_KEYS = {
    JOB_ID: 'jobID',
    REASON: 'reason',
    EFFECTIVE_DATE: 'effectiveDate',
    VEHICLE_TO_REMOVE: 'vehicleToRemove',
    REMAINING_VEHICLES: 'remainingVehicles',
    OCCASIONAL_DRIVERS: 'occasionalDrivers',
    DRIVERS_TO_ASSIGN: 'driversToAssign',
    ORIGINAL_VEHICLES: 'originalVehicles',
    STATUS: 'status',
    REASON_SELECTION_ERROR: 'reasonSelectionError'
}

const VIEW_KEYS = {
    STEP: 'step',
    CURRENT_VEHICLE_NUMBER: 'currentVehicleNumber',
    PREV_STEP: 'prevStep',
    IS_LOADING: 'isLoading',
    PREPARING_QUOTE: 'preparingQuote',
    HAS_CLICKED_CONTINUE: 'hasClickedContinue'
}

const ANNUAL_MILEAGE_UNDER12000_TOGGLE = {
    TOGGLE_DISPLAY_NON: 'annualMileageToggleNotShownForMileageOver12000',
    TOGGLE_UNKNOWN: 'annualMileageToggleUnselected', 
    TOGGLE_REMAIN_UNKNOWN: 'annualMileageToggleRemainUnselectedAfterOptionGiven', 
    TOGGLE_YES: 'annualMileageToggleYes',
    TOGGLE_NO: 'annualMileageToggleNo'
}

const DRIVER_KEYS = {
    CODE: 'code',
    NAME: 'name',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName'
}

const OCCASIONAL_DRIVER_KEYS = {
    FIXED_ID: 'fixedId',
    DISPLAY_NAME: 'displayName',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    VEHICLE_ID: 'vehicleId',
    ASSIGN_VEHICLE_ERROR: 'assignVehicleError'
}

const VEHICLE_KEYS = {
    FIXED_ID: 'fixedId',
    PUBLIC_ID: 'publicId',
    DISPLAY_NAME: 'displayName',
    ANNUAL_MILEAGE: 'annualMileage',
    ANNUAL_MILEAGE_UNDER12000_TOGGLE: "annualMileageUnder12000Toggle", //this is for UI logic of wizard step of remaining vehicle usage/mileage.
    MAKE: 'make',
    MODEL: 'model',
    PRIMARY_USE: 'primaryUse',
    VEHICLE_NUMBER: 'vehicleNumber',
    VEHICLE_TYPE: 'vehicleType',
    YEAR: 'year',
    SHELL: 'shell',
    CAMPER_ATTACHED: 'camperAttached',
    PRINCIPAL_DRIVER: 'principalDriver',
    ASSIGN_DRIVER_ERROR: 'assignDriverError',
    VIN: 'vin',
    OCCASIONAL_DRIVERS: 'occasionalDrivers'
}

const INITIAL_MODEL_STATE = {
    [STATE_KEYS.JOB_ID]: null,
    [STATE_KEYS.REASON]: null,
    [STATE_KEYS.EFFECTIVE_DATE]: null,
    [STATE_KEYS.VEHICLE_TO_REMOVE]: null,
    [STATE_KEYS.REMAINING_VEHICLES]: [],
    [STATE_KEYS.OCCASIONAL_DRIVERS]: [],
    [STATE_KEYS.DRIVERS_TO_ASSIGN]: [],
    [STATE_KEYS.ORIGINAL_VEHICLES]: [],
    [STATE_KEYS.STATUS]: null,
    [STATE_KEYS.REASON_SELECTION_ERROR]: null
};

const INITIAL_VIEW_STATE = {
    [VIEW_KEYS.STEP]: STEPS.INITIAL,
    [VIEW_KEYS.CURRENT_VEHICLE_NUMBER]: null,
    [VIEW_KEYS.PREV_STEP]: null,
    [VIEW_KEYS.IS_LOADING]: true,
    [VIEW_KEYS.PREPARING_QUOTE]: false,
    [VIEW_KEYS.HAS_CLICKED_CONTINUE]: false
};

const REASONS_FOR_DELETING = [
    { code: 'SOLD', name: 'Sold' },
    { code: 'TRADE_IN', name: 'Trade-in' },
    { code: 'GIFTED', name: 'Gifted' },
    { code: 'TOTAL_LOSS', name: 'Total loss' },
    { code: 'INSURED', name: 'Insured elsewhere' },
    { code: 'NOT_WORKING', name: 'Non-operational / Not working' },
    { code: 'REPOSSESSED', name: 'Repossessed' },
];

const PRIMARY_USE = [
    { code: 'commuting', name: 'Drive to work / school' },
    { code: 'business', name: 'Business' },
    { code: 'pleasure', name: 'Pleasure' },
];

const PRIMARY_USE_TEXT = {
    commuting: 'Drive to work / school',
    business: 'Business',
    pleasure: 'Pleasure'
}

const CAN_EDIT_EFFECTIVE_DATE = [ 'SOLD', 'GIFTED' ];

const CHANGES_TAKE_EFFECT_AFTER_MIDNIGHT = [ 'NOT_WORKING', 'REPOSSESSED' ];

const AGENT_ASSISTED_FLOW = [ 'TOTAL_LOSS', 'TRADE_IN', 'INSURED' ];

const PREFILLED_STORAGE_KEYS = {
    PA_POLICY_CHANGE_JOB_NUMBER: 'PA_POLICY_CHANGE_JOB_NUMBER'
};

const MESSAGE_TYPES = {
    EFFECTIVE_DATE: 'effectiveDate',
    VEHICLE_USING: 'vehicleUsing',
    VEHICLE_MILEAGE_REDUCED: 'vehicleMileageReduced',
    VEHICLE_USING_REVIEW: 'vehicleUsingReview',
    PRIMARY_DRIVERS_ASSIGNMENT: 'primaryDriversAssignment',
    INSURED_OR_SPOUSE_REG_OWNER: 'PAInsuredOrSpouseRegOwnerForVehicle',
    DEFAULT_ERROR: 'defaultError',
    LAST_EFFECTIVE_DAY_OF_TERM_BEFORE_RENEWAL: 'lastEffectiveDayOfTermBeforeRenewal',
    ATLEAST_ONE_VEHICLE_ASSIGNED_TO_DRIVER : 'atleastOneVehicleAssignedToDriver',
    SOLE_MOTORHOME_TRAILER_REMAINING: 'soleMotorhomeTrailerRemaining'
};

const getVehicleName = (vehicle) => {
    return vehicle ? `${vehicle.make} ${vehicle.model} ${vehicle.year} (${vehicle.vehicleNumber})` : '';
};

const COMMON_ANNUAL_MILEAGE = 12000;

export {
    VEHICLE_TYPES,
    VEHICLE_TYPES_ALLOWS_TO_REASSIGN,
    STATE_KEYS,
    VIEW_KEYS,
    ANNUAL_MILEAGE_UNDER12000_TOGGLE,
    INITIAL_MODEL_STATE,
    INITIAL_VIEW_STATE,
    MAX_DAYS_IN_PAST,
    DATE_FORMAT,
    STEPS,
    REASONS_FOR_DELETING,
    PRIMARY_USE,
    CAN_EDIT_EFFECTIVE_DATE,
    CHANGES_TAKE_EFFECT_AFTER_MIDNIGHT,
    AGENT_ASSISTED_FLOW,
    PREFILLED_STORAGE_KEYS,
    MESSAGE_TYPES,
    DRIVER_KEYS, 
    VEHICLE_KEYS,
    OCCASIONAL_DRIVER_KEYS,
    PRIMARY_USE_TEXT,
    POLICY_ATTACHMENTS,
    POLICY_ATTACHMENTS_NONCAMPER,
    POLICY_ATTACHMENTS_DRIVERLESS,
    PERMITTED_FUTURE_POLICY_CHANGES,
    getVehicleName,
    COMMON_ANNUAL_MILEAGE
};