import React, {
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react';
import _ from "lodash";
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { WMICWizardChangeOrRenewalPage, useDocumentTitle, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policychange-common-react';
import {WMICLogger, parseErrorMessage, WMICValidationUtil, WMICVariousUtil} from 'wmic-pe-portals-utils-js';
import { Loader } from '@jutro/components';
import { WMICErrorHandler } from "wmic-pe-capability-quoteandbind-common-react";
import metadata from './WMICChangeSummaryPage.metadata.json5';
import messages from './WMICChangeSummaryPage.messages';

function WMICChangeSummaryPage(props) {
    const {
        wizardData: policyChangeVM,
        updateWizardData
    } = props;
    const { authHeader } = useAuthentication();
    const { EndorsementService } = useDependencies('EndorsementService');
    const translator = useContext(TranslatorContext);
    const [policyChangeDiffData, updatePolicyData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const jobID = _.get(policyChangeVM, 'jobID.value');
    const jobType = _.get(policyChangeVM, 'baseData.jobType.value.code');
    const { setWizardLoading } = useWizardModals();
    const { isComponentValid, registerComponentValidation } = useValidation('WMICChangeSummaryPage');
    const hasNoDtoValidationErrors = useCallback(() => WMICValidationUtil.hasNoDtoValidationErrors(policyChangeVM) , [policyChangeVM]);

    useDocumentTitle(translator(messages.changeSummaryTitle), policyChangeVM);

    useEffect(() => {
        registerComponentValidation(hasNoDtoValidationErrors);
    }, [registerComponentValidation, hasNoDtoValidationErrors]);

    useEffect(() => {
        setIsLoading(true);
        EndorsementService.getPolicyChangeDiff_WMIC(
            jobID,
            authHeader
        ).then((response) => {
            updatePolicyData(response);
        }).catch((error) => {
            WMICLogger.error('WMICChangeSummaryPage getPolicyChangeDiff_WMIC', error);
        }).finally(() => setIsLoading(false));
    }, [EndorsementService, authHeader, jobID, policyChangeVM]);

    const onNext = useCallback(async () => {
        try {
            if (!isComponentValid) {
                return false;
            }
            setWizardLoading(true, translator(commonMessages.quoting));
            policyChangeVM.value = await EndorsementService.quoteEndorsement_Ext(
                [policyChangeVM.value],
                authHeader
            );
            updateWizardData(policyChangeVM);

            if (WMICValidationUtil.hasDtoValidationErrors(policyChangeVM)) {
                WMICVariousUtil.scrollToTop();
                return false;
            }

            return policyChangeVM;
        } catch(error) {
            WMICLogger.error('WMICChangeSummaryPage quoteEndorsement_Ext', error);
            WMICErrorHandler.processAsModal(parseErrorMessage(error));
            return false;
        } finally {
            setWizardLoading(false);
        }
        // Cannot include updateWizardData in the dependency array since calling it causes a new function to be created, which would cause in infinite loop in the useEffect below
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [EndorsementService, authHeader, jobID, policyChangeVM, setWizardLoading, translator, isComponentValid]);

    const overrideProps = {
        noChangeMsg: {
            visible: _.get(policyChangeDiffData, "policyChangeDiffItems", []).length === 0
        },
        policyChangeDiffComponent: {
            visible: _.get(policyChangeDiffData, "policyChangeDiffItems", []).length > 0,
            changeSummaryDiffItems: _.get(policyChangeDiffData, "policyChangeDiffItems", []),
            jobType,
        }
    };

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            disableNext={!isComponentValid}
            nextLabel={messages.nextButtonLabel}
        >
            {isLoading ? (
                <Loader loaded={!isLoading} />
            ) : (
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={policyChangeVM}
                    overrideProps={overrideProps}
                    onModelChange={updateWizardData}
                />
            )}
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICChangeSummaryPage.propTypes = wizardProps;

export default withViewModelService(WMICChangeSummaryPage);
