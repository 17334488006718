import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { POLICY_DETAILS, JURISDICTIONS, WMICDriverUtil, WMICJurisdictionUtil, CONSTANTS, WMICDateTimeService, PAConstants, WMICRichTextUtil } from 'wmic-pe-portals-utils-js';

import {TranslatorContext} from '@jutro/locale';
import {useValidation} from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

import WMICDriverClassHistoryComponent from './WMICDriverClassHistoryComponent/WMICDriverClassHistoryComponent'
import messages from './WMICLicenseInfoComponent.messages';
import metadata from './WMICLicenseInfoComponent.metadata.json5';

const JURISDICTION_OTHER = 'OTHER_WMIC';
const DRIVERS_PATH = 'lobData.personalAuto.coverables.drivers';
const DRIVER_PATHS = {
    DRIVER_CLASS_HISTORY: 'driverClassHistory_WMIC',
    DRIVER_CLASS_TYPE: 'driverClassType_WMIC',
    ASSIGNMENT: 'assignment_WMIC',
    LICENSE_COUNTRY: 'licenseCountry_WMIC',
    LICENSE_STATE:'licenseState',
    LICENSE_NUMBER: 'licenseNumber',
    STATE_LIC_THIRTY_DAY: 'getStateLicThirtyDay_WMIC',
    AB_RESIDENT_LESS_THAN_3YR: 'isABResidentLessThan3Yr_WMIC',
    CERTIFICATE_ISSUER: 'certificateIssuer_WMIC',
    CERTIFICATE_ISSUER_DESCRIBE: 'certificateIssuerDescribe_WMIC',
    ORDER_MVR: 'orderMVR_WMIC',
    NO_MVR_REASON: 'noMVRReason_WMIC',
    DO_NOT_ORDER_MVR_DETAILS: 'doNotOrderMVRDetails_WMIC',
    MVR_SELECTED_FOR_ORDER: 'selectedForMVROrder_Ext',
    LICENSE_EXPIRY_DATE: 'licenseExpiryDate_WMIC',
    US_CA_YEARS_LICENSED: 'uscanadaYearsLicensed_WMIC',
    INTERNATIONAL_PERMIT_NUMBER: 'internationalPermitNumber_WMIC',
    INTERNATIONAL_PERMIT_EXPIRY_DATE: 'internationalPermitExpiryDate_WMIC',
    EXP_LETTER_RECEIVED: 'experienceLetterReceived_WMIC',
    EXP_LETTER_DATE: 'experienceLetterDate_WMIC',
    OUTSIDE_LIC_COPY_RECEIVED: 'outsideLicCopyReceived_WMIC',
    OUTSIDE_RECORD_RECEIVED: 'outsideRecordReceived_WMIC',
    HAS_MOTO_COURSE: 'hasMotorCycleCourse_WMIC',
    MOTO_COURSE_DATE: 'motorcycleCourseDate_WMIC',
    HAS_DRIVER_COURSE: 'hasDriverCourse_WMIC',
    DATE_COMPLETE_TRAINING_CLASS: 'dateCompleteTrainingClass_wmic',
    ACCREDITED_BY: 'accreditedBy',
    ACCREDITED_BY_OTHER: 'otherAccreditedByDesc',
    EXP_CONTAINER: 'experienceContainer',
    COMPLETED_TRAINING_COURSE: 'completedTrainingCourse',
    TRAINING_COURSE_COMPLETION_DATE: 'trainingCourseCompletionDate'
}

const today = new Date();
const minDate = {
    year: today.getFullYear() - 150,
    month: today.getMonth(),
    day: today.getDate()
}
const maxDate = {
    year: today.getFullYear(),
    month: today.getMonth(),
    day: today.getDate()
}

function WMICLicenseInfoComponent(props) {
    const {
        id,
        jobVM,
        driverVM,
        baseData,
        updateDriver,
        onValidate,
        showErrors,
        isEditMode,
        isReadOnlyUser
    } = props;

    const translator = useContext(TranslatorContext);
    const ViewModelService = useContext(ViewModelServiceContext);

    const globalBaseState = useRef();
    const [certificateIssuers, setCertificateIssuers] = useState();
    const [yearsLicensed, setYearsLicensed] = useState();
    const [licenseClasses, setLicenseClasses] = useState();
    const [selectedDriverClass, setSelectedDriverClass] = useState();
    const [editingIndex, updateEditingIndex] = useState(-1);
    const [addingClassHistory, setAddingClassHistory] = useState(false);
    const [initialised, setInitialised] = useState(false);
    const [hasOneClass, setHasOneClass] = useState(false);

    const {onValidate: setComponentValidation, isComponentValid} = useValidation(id);

    const driversWithDuplicateLicence  = WMICDriverUtil.getDriversWithDuplicateLicense(_.get(jobVM.value, DRIVERS_PATH));
    const someDriverWithduplicateLicence = driversWithDuplicateLicence ?.length >= 1;

    const DRIVER_CLASS_TYPE_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('DriverClassType_WMIC');
    const JURISDICTION_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('Jurisdiction');
    const DRIVER_CERT_ISSUER_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('DriverCertIssuer_WMIC');
    const YEARS_LICENSED_TYPELIST = ViewModelService.productMetadata.get('pc').types.getTypelist('YearsLicensed_WMIC');

    const needsDriverClass = useCallback(() => {
        if (([CONSTANTS.ASSIGNMENT.UNASSIGNED, CONSTANTS.ASSIGNMENT.RESTRICTED, CONSTANTS.ASSIGNMENT.EXCLUDED].includes(_.get(driverVM, `${DRIVER_PATHS.ASSIGNMENT}.value.code`)))
        ||
        (![CONSTANTS.COUNTRY.CA, CONSTANTS.COUNTRY.US].includes(_.get(driverVM, `${DRIVER_PATHS.LICENSE_COUNTRY}.value.code`)))){
            return false;
        }

        return true;
    }, [driverVM])

    useEffect(() => {
        if (onValidate) {
            onValidate(isComponentValid && !someDriverWithduplicateLicence && (!needsDriverClass() || hasOneClass), id);
        }
    }, [isComponentValid, onValidate, id, someDriverWithduplicateLicence, hasOneClass, needsDriverClass]);

    const willDriverGetLicWithinXDays = () => {
        if (globalBaseState.current === undefined) { return }

        const baseStateCode = globalBaseState.current.value.code;
        let days;

        switch (baseStateCode) {
            case JURISDICTIONS.ONTARIO:
                days = '60'
                break;
            case JURISDICTIONS.QUEBEC:
                days = '180'
                break
            default:
                days = '90'
        }

        const baseStateName = translator({'id': globalBaseState.current.value.name});

        // eslint-disable-next-line consistent-return
        return translator(messages.getStateLicThirtyDay, { baseState: baseStateName, days });
    };

    const isDomesticDriverLicense = () => {
        const licenseCountry = _.get(driverVM, `${DRIVER_PATHS.LICENSE_COUNTRY}.value`);

        return licenseCountry?.code && [CONSTANTS.COUNTRY.CA, CONSTANTS.COUNTRY.US].includes(licenseCountry.code);
    };

    const isAssignedDriver = () => {
        const assignment = _.get(driverVM, `${DRIVER_PATHS.ASSIGNMENT}`)

        return assignment?.value?.code === CONSTANTS.DRIVER_TYPES.ASSIGNED;
    };

    const showDriverClassHistory = () => isAssignedDriver() && !!isDomesticDriverLicense();

    const showABResidentLabel = () => {
        if (globalBaseState.current === undefined) { return }

        const baseStateCode = globalBaseState.current.value.code;
        const baseStateName = translator({'id': globalBaseState.current.value.name});

        let label;

        switch (baseStateCode) {
            case JURISDICTIONS.ONTARIO:
                label = messages.isABResidentLessThan3YrON
                break;
            case JURISDICTIONS.QUEBEC:
                label = messages.isABResidentLessThan3YrQC;
                break;
            default:
                label = messages.isABResidentLessThan3YrDefault;
                break;
        }

        // eslint-disable-next-line consistent-return
        return translator(label, { baseState: baseStateName });
    };

    const isDriverLicenseState = (...codes) => {
        const licenseState = _.get(driverVM, `${DRIVER_PATHS.LICENSE_STATE}.value`);

        return licenseState && codes.includes(licenseState.code);
    };

    const setDriverCertificateIssuerOptions = () => {
        let driverCertificateIssuerOptions;
        const maritimeLicenseState = isDriverLicenseState(...JURISDICTIONS.MARITIMES);
        const licenseState = _.get(driverVM, `${DRIVER_PATHS.LICENSE_STATE}`);
        const certificateIssuer = _.get(driverVM, `${DRIVER_PATHS.CERTIFICATE_ISSUER}`);

        if (maritimeLicenseState || isDriverLicenseState(JURISDICTIONS.ONTARIO, JURISDICTIONS.ALBERTA)) {
            const suffix = maritimeLicenseState ? 'CertIssuer_WMIC' : 'CertIssuer';
            const filterName = `${licenseState.value.code}${suffix}`;

            driverCertificateIssuerOptions = DRIVER_CERT_ISSUER_TYPELIST.getFilter(filterName).codes;
        } else {
            driverCertificateIssuerOptions = certificateIssuer.aspects.availableValues;
        }

        const driverCertIssuerAvailablevalues = driverCertificateIssuerOptions.map((item) => {
            const availableCertJoinKey = {
                code: item.code,
                name: translator({id: item.name})
            }

            return availableCertJoinKey;
        });

        setCertificateIssuers(driverCertIssuerAvailablevalues);
    }

    const isForeignDriverLicense = () => {
        const licenseCountry = _.get(driverVM, `${DRIVER_PATHS.LICENSE_COUNTRY}.value`);

        return licenseCountry?.code && licenseCountry?.typelist.getFilter('ReciprocalLicenses').allows(licenseCountry);
    };

    const isInternationalPermit = () => {
        const licenseCountry = _.get(driverVM, `${DRIVER_PATHS.LICENSE_COUNTRY}.value`);

        return licenseCountry?.code && !(isDomesticDriverLicense() || isForeignDriverLicense());
    };

    const onLicenseCountryChanged = (value, path) => {
        _.set(driverVM, path, value);
        _.set(driverVM, `${DRIVER_PATHS.ORDER_MVR}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.NO_MVR_REASON}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.DO_NOT_ORDER_MVR_DETAILS}.value`, undefined);

        if (isDomesticDriverLicense()) {
            _.set(driverVM, `${DRIVER_PATHS.LICENSE_STATE}.value`, undefined);
            _.set(driverVM, `${DRIVER_PATHS.LICENSE_EXPIRY_DATE}.value`, undefined);
        } else{
            _.set(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`, []);
            _.set(driverVM, `${DRIVER_PATHS.LICENSE_STATE}.value`, JURISDICTION_TYPELIST.getCode(JURISDICTION_OTHER));
            _.set(driverVM, `${DRIVER_PATHS.ORDER_MVR}.value`, true);
            WMICDriverUtil.removeNewlyAddedMvrs(driverVM);
            _.set(driverVM, `${DRIVER_PATHS.US_CA_YEARS_LICENSED}.value`, YEARS_LICENSED_TYPELIST.getCode('0'));

            if (!isInternationalPermit()) {
                _.set(driverVM, `${DRIVER_PATHS.INTERNATIONAL_PERMIT_NUMBER}.value`, undefined);
                _.set(driverVM, `${DRIVER_PATHS.INTERNATIONAL_PERMIT_EXPIRY_DATE}.value`, undefined);
            }
        }

        setDriverCertificateIssuerOptions();
        updateDriver(driverVM);
    };

    const onABResidentLessThan3YrChanged = (value, path) => {
        _.set(driverVM, path, value);
        _.set(driverVM, `${DRIVER_PATHS.EXP_LETTER_RECEIVED}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.EXP_LETTER_DATE}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.OUTSIDE_LIC_COPY_RECEIVED}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.OUTSIDE_RECORD_RECEIVED}.value`, undefined);

        if(WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.QUEBEC)) {
            _.set(driverVM, `${DRIVER_PATHS.US_CA_YEARS_LICENSED}.value`, undefined);
        }

        updateDriver(driverVM);
    };

    const showNoDriverLicenseWarning = () => !!(_.get(driverVM, 'publicID.value')
            && !(_.get(driverVM, 'licenseNumber.value')
                && _.get(driverVM, 'licenseNumber.value').length > 0));

    const onLicenseNumberChanged = (value, path) => {
        _.set(driverVM, path, value);
        updateDriver(driverVM);
    }

    const createDriverClassVM = (driverClass = {}) => {
        const driverClassViewModelContext = {
            'LicenseState': driverVM.licenseState.value ? driverVM.licenseState.value : undefined
        };

        return ViewModelService.create(
            driverClass,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.personalauto.coverables.dto.DriverClassHistoryDTO_WMIC',
            driverClassViewModelContext);
    };

    const getDriverClassTypeCodes = (stateCode) => {

        const getDriverClassFilterName = (stateCodeParam) => {
            let filterName;

            if (stateCodeParam === JURISDICTIONS.ONTARIO) {
                filterName = 'home_address_province_for_ontario';
            } else if (stateCodeParam === JURISDICTIONS.ALBERTA || stateCodeParam === JURISDICTIONS.YUKON) {
                filterName = 'driver_class_type';
            } else if (POLICY_DETAILS.DRIVER_CLASS_STATE_CODES.includes(stateCodeParam)) {
                filterName = `${stateCodeParam}ClassCodes`;
            } else {
                filterName = getDriverClassFilterName(globalBaseState.current.value.code);
            }

            return filterName;
        };

        const filterName = getDriverClassFilterName(stateCode);

        return DRIVER_CLASS_TYPE_TYPELIST.getFilter(filterName).codes.map((item) => {
            const availableClassJoinKey = {
                code: item.code,
                name: translator({id: item.name})
            }

            return availableClassJoinKey;
        });
    };

    const setDriverClassOptions = () => {
        let licenseCodeValue;
        const licenseState = _.get(driverVM, `${DRIVER_PATHS.LICENSE_STATE}.value`);
        const licenseCountry = _.get(driverVM, `${DRIVER_PATHS.LICENSE_COUNTRY}.value`);

        if (licenseState) {
            licenseCodeValue = _.get(licenseState, 'code');
        } else {
            licenseCodeValue = _.get(licenseCountry, 'code');
        }

        const driverClassOptions = getDriverClassTypeCodes(licenseCodeValue);

        setLicenseClasses(driverClassOptions)
    }

    const onLicenseStateChanged = (value, path) => {
        _.set(driverVM, path, value);
        _.set(driverVM, `${DRIVER_PATHS.STATE_LIC_THIRTY_DAY}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`, []);
        _.set(driverVM, `${DRIVER_PATHS.AB_RESIDENT_LESS_THAN_3YR}.value`, false);
        _.set(driverVM, `${DRIVER_PATHS.CERTIFICATE_ISSUER}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.MVR_SELECTED_FOR_ORDER}.value`, false);

        if (!isDriverLicenseState(JURISDICTIONS.ONTARIO) && WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.ONTARIO)) {
            _.set(driverVM, `${DRIVER_PATHS.STATE_LIC_THIRTY_DAY}.value`, true);
        }

        setDriverCertificateIssuerOptions();
        setAddingClassHistory(false);
        // wait for driver update
        new Promise((resolve) => {
            resolve(updateDriver(driverVM));
        }).then(() => {
            setDriverClassOptions();
        })
    };

    useEffect(() => {
        const driverClassHistory = _.get(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}`);

        const yearsLicensedOptions = WMICDriverUtil.getYearsLicensedWMICTypelist(
            _.get(baseData, 'baseState.value.code'),
            _.get(baseData, 'rateAsOfDate.value')
        ).map((option) => ({
            ...option,
            displayName: {
                id: option.name,
                defaultMessage: option.name
            }
        }));

        setYearsLicensed(yearsLicensedOptions);

        if (!isDomesticDriverLicense() && driverClassHistory && driverClassHistory.value) {
            _.set(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`, []);
        }

        globalBaseState.current = _.get(baseData, 'baseState');
        setDriverCertificateIssuerOptions()
        setDriverClassOptions();
        updateDriver(driverVM);
        setInitialised(true);
        setHasOneClass(_.get(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`, []).length > 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addDriverClass = () => {
        const aSelectedDriverClass = createDriverClassVM()

        setSelectedDriverClass(aSelectedDriverClass);
        setAddingClassHistory(true);
        updateEditingIndex(-1);
        updateDriver(driverVM);
    }

    const editClassHistory = (driverClass, index) => {
        const aSelectedDriverClass = createDriverClassVM(driverClass.value);

        updateEditingIndex(index);
        setSelectedDriverClass(aSelectedDriverClass);
        setAddingClassHistory(true);
    }

    const driverHasMotorcycleClass = () => {
        const driverClassHistory = _.get(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`);

        return driverClassHistory?.length > 0 && _.find(driverClassHistory, (item) => PAConstants.motorcycleClasses.includes(item.driverClassType_WMIC));
    };

    const doesDriverClassTypeHaveTraining = (licenseState, driverClassType) => {
        if (globalBaseState.current === undefined) { return }

        let hasTraining = false;

        const getDriverClassFilterName = (licenseStateParam) => {
            let filterName;

            if(POLICY_DETAILS.DRIVER_TRAINING_COURSE_CLASS_STATE_CODES.includes(licenseStateParam)) {
                filterName = `BluePass${licenseStateParam}TrainingCourseClassCodes`;
            } else if (licenseStateParam !== globalBaseState.current.value.code) {
                filterName = getDriverClassFilterName(globalBaseState.current.value.code);
            }

            return filterName;
        };

        const filterName = getDriverClassFilterName(licenseState);

        if(filterName) {
            hasTraining = DRIVER_CLASS_TYPE_TYPELIST.getFilter(filterName).allows(driverClassType);
        }

        // eslint-disable-next-line consistent-return
        return hasTraining;
    };

    const checkYearsLicensedBasedOnJurisdiction = (yearsLicensedParam, licenseState) => {
        if (JURISDICTIONS.ALBERTA || JURISDICTIONS.YUKON || JURISDICTIONS.ONTARIO) {
            return yearsLicensedParam >= 0 && yearsLicensedParam <= 7;
        }

        if (JURISDICTIONS.MARITIMES.includes(licenseState)) {
            return yearsLicensedParam >= 0 && yearsLicensedParam < 6;
        }

        if (licenseState !== globalBaseState.current.value.code) {
            return checkYearsLicensedBasedOnJurisdiction(yearsLicensedParam, globalBaseState.current.value.code);
        }

        return false;
    };

    const driverQualifiesForTrainingQuestions = () => {
        const periodStartDate = _.get(baseData, 'periodStartDate.value');
        const driverClassHistory = _.get(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`);
        const licenseState = _.get(driverVM, `${DRIVER_PATHS.LICENSE_STATE}.value`);

        const datePeriodStart = new Date(
            periodStartDate.year,
            periodStartDate.month,
            periodStartDate.day
        );

        return driverClassHistory?.length > 0 &&
            _.find(driverClassHistory,
                (item) => {
                    if (!doesDriverClassTypeHaveTraining(licenseState?.code, item.driverClassType_WMIC)) {
                        return false;
                    }

                    const dateLicensed = new Date(item.dateLicensed.year, item.dateLicensed.month, item.dateLicensed.day);
                    const aYearsLicensed = moment(datePeriodStart).diff(dateLicensed, 'years', false);

                    return checkYearsLicensedBasedOnJurisdiction(aYearsLicensed, licenseState?.code);
                });
    };

    const resetMotorcycleAndDrivingTrainingIfNeeded = () => {
        if (!driverHasMotorcycleClass()) {
            _.set(driverVM, `${DRIVER_PATHS.HAS_MOTO_COURSE}.value`, undefined);
            _.set(driverVM, `${DRIVER_PATHS.MOTO_COURSE_DATE}.value`, undefined);
        }

        if (!driverQualifiesForTrainingQuestions()) {
            _.set(driverVM, `${DRIVER_PATHS.HAS_DRIVER_COURSE}.value`, undefined);
            _.set(driverVM, `${DRIVER_PATHS.DATE_COMPLETE_TRAINING_CLASS}.value`, undefined);
            _.set(driverVM, `${DRIVER_PATHS.CERTIFICATE_ISSUER}.value`, undefined);
            _.set(driverVM, `${DRIVER_PATHS.CERTIFICATE_ISSUER_DESCRIBE}.value`, undefined);
        }
    };

    const resetAllCommercialTrainingFields = () => {
        _.set(driverVM, `${DRIVER_PATHS.COMPLETED_TRAINING_COURSE}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.TRAINING_COURSE_COMPLETION_DATE}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.ACCREDITED_BY}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.ACCREDITED_BY_OTHER}.value`, undefined);
    };

    const resetCommercialTrainingFieldsIfNeeded = () => {
        if (!WMICDriverUtil.yearsLicensedLessThanThreeYears(driverVM)) {
            resetAllCommercialTrainingFields();
        }
    };

    const removeClassHistory = (_driverClass, index) => {
        if (_.get(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`).length === 1) {
            return;
        }

        _.get(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`).splice(index, 1);
        resetMotorcycleAndDrivingTrainingIfNeeded();
        resetCommercialTrainingFieldsIfNeeded();
        WMICDriverUtil.resetGoodStudentDiscountIfNeeded(driverVM, baseData)
        setHasOneClass(_.get(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`, []).length > 0)
        updateDriver(driverVM)
    }

    const cancelClassHistory = () => {
        setAddingClassHistory(false);
    };

    const saveClassHistory = (driverClass) => {
        if (editingIndex !== -1) {
            _.get(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`).splice(editingIndex, 1, driverClass.value);
        } else {
            const driverHistoryArr = _.get(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`, [])

            driverHistoryArr.push(driverClass.value);
            _.set(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`, driverHistoryArr)
        }

        setHasOneClass(_.get(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.value`, []).length > 0)
        resetMotorcycleAndDrivingTrainingIfNeeded();
        resetCommercialTrainingFieldsIfNeeded();
        WMICDriverUtil.resetGoodStudentDiscountIfNeeded(driverVM, baseData)
        setAddingClassHistory(false);
        updateDriver(driverVM)
    };

    const hasExperienceLetterReceivedChanged = (value, path) => {
        _.set(driverVM, path, value);
        _.set(driverVM, `${DRIVER_PATHS.EXP_LETTER_DATE}.value`, null);
        updateDriver(driverVM);
    };

    const onCertificateIssuerChange = (value, path) => {
        _.set(driverVM, path, value);
        _.set(driverVM, `${DRIVER_PATHS.CERTIFICATE_ISSUER_DESCRIBE}.value`, undefined);
        updateDriver(driverVM);
    };

    const showDriverTrainingCourse = () => isAssignedDriver() && !!driverQualifiesForTrainingQuestions();

    const hasDriverCourseChanged = (value, path) => {
        _.set(driverVM, path, value);
        _.set(driverVM, `${DRIVER_PATHS.DATE_COMPLETE_TRAINING_CLASS}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.CERTIFICATE_ISSUER}.value`, undefined);
        _.set(driverVM, `${DRIVER_PATHS.CERTIFICATE_ISSUER_DESCRIBE}.value`, undefined);
        updateDriver(driverVM);
    };

    const showMotorcycleDriverTrainingCourse = () => isAssignedDriver() && !!driverHasMotorcycleClass();

    const displayDriverClassDate = (data) => data.dateLicensed?.value ? <span>{WMICDateTimeService.toMidnightDate(data.dateLicensed.value)}</span> : '';

    const showDriverDeclaration = () => isAssignedDriver();

    const getDriverClassType = (data) => {
        let driverClass = '';

        if (_.get(data, `${DRIVER_PATHS.DRIVER_CLASS_TYPE}.value`)) {
            driverClass = _.find(licenseClasses, (licenseClass) => licenseClass.code === _.get(data, `${DRIVER_PATHS.DRIVER_CLASS_TYPE}.value.code`));
        }

        return {'id': driverClass?.code, 'defaultMessage': driverClass?.name};
    }

    const isBaseStateAB = () => WMICJurisdictionUtil.isBaseState(baseData, JURISDICTIONS.ALBERTA)

    const isMaritimeBaseProvince  = () => WMICJurisdictionUtil.isBaseState(baseData, ...JURISDICTIONS.MARITIMES)

    const overrideProps = {
        '@field': {
            parentNode: driverVM,
            readOnly: !isEditMode || isReadOnlyUser
        },
        experienceLetterDate: {
            minDate: _.get(baseData, 'minPAExperienceLetterDate_WMIC.value'),
            maxDate: _.get(baseData, 'maxPAExperienceLetterDate_WMIC.value')
        },
        getStateLicThirtyDay: {
            label: willDriverGetLicWithinXDays()
        },
        experienceClassContainer: {
            visible:  _.get(driverVM, `${DRIVER_PATHS.EXP_CONTAINER}.aspects.ocular`)
        },
        experienceLetterContainer: {
            visible:  !isMaritimeBaseProvince()
        },
        driverClassHistory: {
            driverVM,
            updateDriver
        },
        classHistoryInfoMsg: {
            content: WMICRichTextUtil.translateRichText(translator(messages.atLeastOneDriverClass))
        },
        isABResidentLessThan3Yr: {
            label: showABResidentLabel()
        },
        uscanadaYearsLicensed: {
            availableValues: yearsLicensed
        },
        licenseNumber: {
            placeholder: isDriverLicenseState(JURISDICTIONS.ALBERTA) && translator(messages.licenseNumberPlaceHolder),
            secondaryLabel: showNoDriverLicenseWarning() && translator(messages.licenseWarning)
        },
        driverClassHistorySectionContainer: {
            visible: showDriverClassHistory()
        },
        driverClassHistoryDataList: {
            VMList: _.get(driverVM, `${DRIVER_PATHS.DRIVER_CLASS_HISTORY}.children`, []),
            VMData: [
                {
                    headerText: translator(messages.driverClassType),
                    getData: getDriverClassType
                },
                {
                    headerText: translator(messages.driverClassDateObtained),
                    getData: displayDriverClassDate
                }
            ],
            onEditAction: editClassHistory,
            onRemoveAction: removeClassHistory,
            isEditing: !isEditMode || addingClassHistory,
            readOnly: !isEditMode,
            updateSelectedCardIndex: updateEditingIndex,
            selectedCardIndex: editingIndex
        },
        driverClassHistoryForm: {
            onValidate: setComponentValidation,
            selectedDriverClass,
            visible: addingClassHistory,
            licenseClasses,
            saveClassHistory,
            cancelClassHistory,
            isEditMode,
            minDate,
            maxDate
        },
        certificateIssuer: {
            availableValues: certificateIssuers,
        },
        driverClassAddButton: {
            disabled: addingClassHistory,
            visible: isEditMode
        },
        driverClassHistoryInfoMsg: {
            visible: !hasOneClass
        },
        driverTrainingSectionContainer: {
            visible: showDriverTrainingCourse() || showMotorcycleDriverTrainingCourse()
        },
        driverTrainingContainer : {
            visible: showDriverTrainingCourse()
        },
        driverMotoTrainingContainer: {
            visible: showMotorcycleDriverTrainingCourse()
        },
        driverDeclarationSectionContainer: {
            visible: showDriverDeclaration()
        },
        motorcycleCourseDate: {
            maxDate,
            minDate
        },
        dateCourseCompleted: {
            maxDate,
            minDate
        },
        gridFieldsContainer:{
            visible : isBaseStateAB() && isAssignedDriver()
        },
        fraudConvictionDate: {
            maxDate,
            minDate
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICDriverClassHistoryComponent
        },
        resolveCallbackMap: {
            onLicenseNumberChanged,
            onLicenseStateChanged,
            onLicenseCountryChanged,
            onABResidentLessThan3YrChanged,
            addDriverClass,
            onCertificateIssuerChange,
            hasExperienceLetterReceivedChanged,
            hasDriverCourseChanged
        }
    };

    if (!initialised) {
        return null;
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={driverVM}
            overrideProps={overrideProps}
            onModelChange={updateDriver}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );
}

WMICLicenseInfoComponent.propTypes = {
    id: PropTypes.string.isRequired,
    driverVM: PropTypes.shape({
        person: PropTypes.shape({
            publicID: PropTypes.shape({})
        })
    }).isRequired,
    baseData: PropTypes.shape({}).isRequired,
    updateDriver: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isReadOnlyUser: PropTypes.bool.isRequired,
    showErrors: PropTypes.bool.isRequired,
};

export default WMICLicenseInfoComponent;
