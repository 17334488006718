import { useContext } from 'react';
import _ from 'lodash';
import { WizardContext } from 'wmic-pe-portals-custom-wizard-react';
import { CONSTANTS, WMICLogger } from 'wmic-pe-portals-utils-js';

export default function useDefaultedProperties(VMnode) {
    
    const { wizardData: jobVM } = useContext(WizardContext);

    const defaultedProperties = _.get(jobVM, 'defaultedProperties_WMIC.value');

    const getPropertyName = (node) => {
        const parentNode = _.get(node, '_ancestorChain.parent');
        if (parentNode) {
            const parentNodeClassName = _.get(parentNode, '_dtoName')?.toString();
            if (parentNodeClassName === CONSTANTS.AMOUNT_DTO_NAME) {
                return _.get(parentNode, '_accessorCode')?.toString().toLowerCase();
            }
        }
        return _.get(node, '_accessorCode')?.toLowerCase();
    }

    const propertyName = getPropertyName(VMnode);
    
    const getParentDTOclassInstanceID = (node) => {
        const parentNode = _.get(node, '_ancestorChain.parent');
        if (parentNode) {
            try {
                if (parentNode.accidentWaiverDriverEndorsementPublicID) {
                    return parentNode.accidentWaiverDriverEndorsementPublicID.value;
                }
                if (parentNode.creditConsentPublicId_Ext
                    && [CONSTANTS.PERSONAL_INFO_CONSENT_FORM, CONSTANTS.CREDIT_CONSENT_RECEIVED].includes(propertyName)) {
                    return parentNode.creditConsentPublicId_Ext.value;
                }
                if (parentNode.publicID) {
                    return parentNode.publicID.value;
                } 
                if (parentNode.publicId) {
                    return parentNode.publicId.value;
                } 
                if (parentNode.policyPublicID_WMIC) {
                    return parentNode.policyPublicID_WMIC.value;    
                } 
                if (parentNode.exteriorWallId) {
                    return parentNode.exteriorWallId;
                }
                
                const parentNodeClassName = _.get(parentNode, '_dtoName')?.toString();
                if (parentNodeClassName === CONSTANTS.AMOUNT_DTO_NAME) {
                    const grandParentNode = _.get(parentNode, '_ancestorChain.parent');
                    if (grandParentNode && grandParentNode.publicID) {
                        return grandParentNode.publicID.value;
                    }
                }
            } catch(ex) {
                WMICLogger.error(ex);
            }
        }
    }

    const getClassName = (node) => {
        const parentNode = _.get(node, '_ancestorChain.parent');
        if (parentNode) {
            const parentNodeClassName = _.get(parentNode, '_dtoName')?.toString();
            if (parentNodeClassName === CONSTANTS.AMOUNT_DTO_NAME) {
                return _.get(parentNode, '_ancestorChain.parent._dtoName');
            }
        }
        return _.get(node, '_ancestorChain.parent._dtoName');
    }

    const className = getClassName(VMnode);
    const parentDTOclassInstanceID = getParentDTOclassInstanceID(VMnode);
    
    const defaultedPropertiesArrayIndex = defaultedProperties?.findIndex((elem) =>
        elem.propertyName?.toLowerCase() === propertyName
        && elem.className === className
        && elem.classPublicID === parentDTOclassInstanceID
    )

    const defaultedPropertyValues = (defaultedPropertiesArrayIndex !== undefined && defaultedPropertiesArrayIndex !== -1) ? defaultedProperties[defaultedPropertiesArrayIndex] : undefined;
        
    return ({
        isDefaulted: defaultedPropertyValues !== undefined,
        isAccepted: defaultedPropertyValues && defaultedPropertyValues.status === CONSTANTS.REVIEWED,
        isVisible: defaultedPropertyValues && defaultedPropertyValues.visible === true,
        defaultedPropertiesArrayIndex: defaultedPropertiesArrayIndex
    })
}