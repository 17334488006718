import { defineMessages } from 'react-intl';

export default defineMessages({
    submissionNumber: {
        id: 'wmic-pe-portals-wizard-components-ui.Wizard.hooks.useDocumentTitle.Submission Number',
        defaultMessage: 'Submission'
    },
    policyNumber: {
        id: 'wmic-pe-portals-wizard-components-ui.Wizard.hooks.useDocumentTitle.Policy Number',
            defaultMessage: 'Policy'
    },
    gpaTitle: {
         id: 'wmic-pe-portals-wizard-components-ui.Wizard.hooks.useDocumentTitle.GPA Title',
            defaultMessage: 'BluePass'
    },
    policyChange: {
        id: 'wmic-pe-portals-wizard-components-ui.Wizard.hooks.useDocumentTitle.Policy Change',
        defaultMessage: 'Policy Change'
    },
    renewal: {
        id: 'wmic-pe-portals-wizard-components-ui.Wizard.hooks.useDocumentTitle.Renewal',
        defaultMessage: 'Renewal'
    },
    personalAuto: {
        id: 'wmic-pe-portals-wizard-components-ui.Wizard.hooks.useDocumentTitle.Personal Auto',
        defaultMessage: 'Personal Auto'        
    },
    personalProperty: {
        id: 'wmic-pe-portals-wizard-components-ui.Wizard.hooks.useDocumentTitle.Personal Property',        
        defaultMessage: 'Personal Property'
    },
    personalUmbrella: {
        id: 'wmic-pe-portals-wizard-components-ui.Wizard.hooks.useDocumentTitle.Personal Umbrella',        
        defaultMessage: 'Personal Umbrella Line'
    },
    commercialLinesSmallBusiness: {
        id: 'wmic-pe-portals-wizard-components-ui.Wizard.hooks.useDocumentTitle.Commercial Lines Small Business',        
        defaultMessage: 'Commercial Lines Small Business'
    }
    
});