import { defineMessages } from 'react-intl';

export default defineMessages({
    wmicHeaderSearchPlaceholder: {
        id: 'portal.index.Search',
        defaultMessage: 'Search'
    },
    wmicHeaderActionContactUs: {
        id: 'portal.index.Contact Us',
        defaultMessage: 'Contact Us'
    },
    contactUs: {
        id: 'agent.views.policy-landing.contactus',
        defaultMessage: 'Contact Us'
    },
    agentViewClose: {
        id: 'agent.views.job-uwissues.Close',
        defaultMessage: 'Close'
    },
    headerLogoAlt: {
        id: "portal.index.Wawanesa Insurance",
        defaultMessage: "Wawanesa Insurance"
    }
});
