import { defineMessages } from 'react-intl';

export default defineMessages({
    renewalDetailsTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetails.WMICRenewalDetailsPage.Title',
        defaultMessage: 'Renewal Details'
    },
    accountTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.accountTitle',
        defaultMessage: 'Account:'
    },
    personalAuto: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.personalAuto',
        defaultMessage: 'Automobile Renewal ({jobNumber})'
    },
    personalUmbrella: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.personalUmbrella',
        defaultMessage: 'Personal Umbrella Renewal ({jobNumber})'
    },
    homeowners: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.homeowners',
        defaultMessage: 'Personal Property Renewal ({jobNumber})'
    },
    continueSubmissionHeader: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.continueRenewalHeader',
        defaultMessage: 'Continue Renewal'
    },
    renewalUnderwritingIssuesCardCardTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.renewalUnderwritingIssuesCardCardTitle',
        defaultMessage: 'Underwriting Issues'
    },
    continueRenewalButton: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.continueRenewalButton',
        defaultMessage: 'Continue'
    },
    editRenewalButton: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.editRenewalButton',
        defaultMessage: 'Edit'
    },
    uwMustReviewTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.uwMustReviewTitle',
        defaultMessage: 'An underwriter must review the following ({count}):'
    },
    noOutstandingUWIssuesTitle: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.noOutstandingUWIssuesTitle',
        defaultMessage: 'There are no outstanding underwriting issues'
    },
    autoApprovedUWIssuesTitle: {
        id:'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.autoApprovedTitle',
        defaultMessage: 'The following will be auto-approved and may be subject to a compliance review'
    },
    underwritingIssuesRaised: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Underwriting issues have been raised',
        defaultMessage: 'You cannot complete this transaction until these issues have been resolved.'
    },
    underwritingReferTheRenewal: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Refer the renewal',
        defaultMessage: 'Refer the Renewal to an underwriter'
    },
    underwritingEditTheRenewal: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Edit the renewal',
        defaultMessage: 'Edit the renewal'
    },
    underwritingContactUnderwriter: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Contact an underwriter',
        defaultMessage: 'Please contact an underwriter if you have any concerns.'
    },
    underwriterApprovalNeeded: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Quoted: Underwriter Approval Needed',
        defaultMessage: ': Underwriter Approval Pending'
    },
    underwriterApprovalAndValidationNeeded: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Quoted: Validation and Underwriting Issues Have Been Raised',
        defaultMessage: ': Validation and Underwriting Issues Have Been Raised'
    },
    validationNeeded: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Quoted: Validation Issues Have Been Raised',
        defaultMessage: ': Validation Issues Have Been Raised'
    },
    renewalInProgress: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Renewal In Progress',
        defaultMessage: '{renewalStatus}{uwApprovalNeeded} - '
    },
    renewalInProgressDays: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Renewal In Progress Days Until Issue',
        defaultMessage: '{numberOfDays} Days Until Issued'
    },
    renewalInProgressOneDay: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Renewal In Progress One Day Until Issue',
        defaultMessage: '1 Day Until Issued'
    },
    renewalDocumentsReleased: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Renewal documents will be released in Days',
        defaultMessage: 'Renewal documents will be released in '
    },
    renewalDocumentsReleasedDays: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.In Days',
        defaultMessage: '{numberOfDays} Days'
    },
    renewalDocumentsReleasedOneDay: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.One Day',
        defaultMessage: '1 Day'
    },
    renewalDraft: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.RenewalDraft',
        defaultMessage: 'Draft'
    },
    renewalQuoted: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.RenewalQuoted',
        defaultMessage: 'Quoted'
    },
    renewalRenewing: {
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.RenewalRenewing',
        defaultMessage: 'Renewing'
    },
    renewalDraftStatus:{
        id: 'wmic-pe-capability-gateway-react.WMICRenewalDetailsPage.Renewal is in progress. Please continue to edit the renewal',
        defaultMessage: 'Renewal is in progress. Please continue to edit the renewal'
    }
});
