import { defineMessages } from 'react-intl';

export default defineMessages({
    policyDetailsTitle: {
        id: "wmic-pe-capability-gateway-quoteandbind-ho-react.pages.WMICPolicyDetails.WMICPolicyDetailsPage.Title",
        defaultMessage : "Policy Details"
    },
    defaultAnswers: {
        id: "quoteandbind.ho.views.ho-policy-details.Create Default Answer",
        defaultMessage : "Creating Default Answer"
    }
})
