import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteTitle : {
        id: 'wmic-pe-capability-quoteandbind-ho-react.pages.WMICHOQuotePage.WMICHOQuotePage.Title',
        defaultMessage: 'Quote',
    },
    cancelButtonLabel : {
        id: 'quoteandbind.ho.quotePage.saveAndExit',
        defaultMessage: 'Save and exit',
    }
});
